import debounce from 'lodash-es/debounce';
import { Subscription } from 'rxjs';
import { randomString } from '@profis-engineering/pe-ui-common/helpers/random';
import { IValidationAdditionalData, IValidatorFn } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { customErrorValidator } from '@profis-engineering/pe-ui-common/validators/custom-error.validator';

import {
    AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy,
    Optional, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import { Tooltip } from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import { ControlTitleLook, InfoPopup } from '@profis-engineering/pe-ui-common/components/control-title/control-title.common';
import {
    TextBoxAlign, TextBoxBackground, TextBoxBorder, TextBoxDisplay, TextBoxLook, TextBoxMargin, TextBoxType, valueChangeDelay
} from './text-box.common';

@Component({
    templateUrl: './text-box.component.html',
    styleUrls: ['./text-box.component.scss']
})
export class TextBoxComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input()
    public id = randomString(8);

    @Input()
    public title?: string;

    @Input()
    public value = '';

    @Input()
    public cssClass = '';

    @Input()
    public displayBorder = true;

    @Output()
    public valueChange = new EventEmitter<string>();

    @Output()
    public inputBlur = new EventEmitter<string>();

    @Output()
    public enterPressed = new EventEmitter<string>();

    @Input()
    public placeholder = '';

    @Input()
    public disabled = false;

    @Input()
    public type: TextBoxType = 'text';

    @Input()
    public minLength = 0;

    @Input()
    public maxLength = 524288;

    @Input()
    public tooltip?: Tooltip;

    @Input()
    public infoPopup?: InfoPopup;

    @Input()
    public infoPopupTooltip?: Tooltip;

    @Input()
    public submitted = false;

    @Output()
    public submittedChange = new EventEmitter<boolean>();

    @Input()
    public updateOnBlur = false;

    @Input()
    public debounceChanges = false;

    @Input()
    public preventFormSubmit = false;

    // Look
    @Input()
    public look = TextBoxLook.Normal;

    @Input()
    public marginBottom = 'Normal';

    @Input()
    public borderTop = TextBoxBorder.Normal;

    @Input()
    public borderBottom = TextBoxBorder.Normal;

    @Input()
    public borderLeft = TextBoxBorder.Normal;

    @Input()
    public borderRight = TextBoxBorder.Normal;

    @Input()
    public paddingRight = 0;

    @Input()
    public textAlign = TextBoxAlign.Start;

    @Input()
    public textBackground = TextBoxBackground.Normal;

    @Input()
    public display = TextBoxDisplay.Normal;

    @Input()
    public height: number | undefined;

    @Input()
    public width: number | undefined;

    // Validation
    @Input()
    public validators?: IValidatorFn[];

    @Input()
    public validationData?: IValidationAdditionalData;

    @Output()
    public isValid = new EventEmitter<boolean>();

    // Internal
    @ViewChild('ngModel')
    public ngModel!: NgModel;

    public additionalClasses = '';
    public controlTitleStyle = ControlTitleLook.Normal;

    private form: NgForm;
    private ngSubmitSubscription!: Subscription;
    private debouncedValueChangeFn?: ((value: string) => void);

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() form: NgForm
    ) {
        this.form = form;
    }

    public get showValidationErrors() {
        if (!this.validationData?.showValidationErrors) {
            return false;
        }

        if (
            this.ngModel == null
            || this.ngModel.disabled
            || this.ngModel.valid
            || !this.ngModel.touched && !this.submitted
        ) {
            return false;
        }

        return true;
    }

    public ngAfterViewInit(): void {
        // add control to the form and sync submitted
        if (this.form != null) {
            if (this.ngModel != null) {
                this.form.addControl(this.ngModel);
            }

            this.ngSubmitSubscription = this.form.ngSubmit.subscribe(() => {
                if (this.submitted != this.form.submitted) {
                    this.submitted = this.form.submitted;
                    this.submittedChange.emit(this.submitted);
                }
            });
        }

        if (this.ngModel != null && this.ngModel.statusChanges != null) {
            // sync isValid
            this.ngModel.statusChanges.subscribe(() => {
                this.isValid.emit((this.ngModel.disabled || this.ngModel.valid) ?? true);
            });
        }

        this.updateValidators();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['validators'] != null
            || changes['validationData'] != null
        ) {
            this.updateValidators();
        }

        if (this.debounceChanges) {
            this.debouncedValueChangeFn = debounce((value) => {
                this.valueChange.emit(value);
            }, valueChangeDelay);
        }
        else {
            this.debouncedValueChangeFn = undefined;
        }

        // CSS classes
        this.setCssClasses();
    }

    public ngOnDestroy(): void {
        // remove control from form and unsubscribe from ngSubmit
        if (this.form != null) {
            if (this.ngModel != null) {
                this.form.removeControl(this.ngModel);
            }

            this.ngSubmitSubscription?.unsubscribe();
        }
    }

    public ngModelChange(value: string) {
        this.value = value;

        // We might want to debounce change ...
        if (this.debouncedValueChangeFn != null) {
            this.debouncedValueChangeFn(this.value);
        }
        else {
            this.valueChange.emit(this.value);
        }

        // Do not wait for blur event, mark control as touched
        if (!this.ngModel.control.touched) {
            this.ngModel.control.markAsTouched();
        }

        // Remove custom message on the first change (do not call this.updateValidators() too many times as it might be slow)
        if (this.validationData?.customErrorMessage != null) {
            this.validationData.customErrorMessage = undefined;
            this.updateValidators();
        }
    }

    public onEnterPressed(event: Event) {
        const elt = event.target as HTMLInputElement;
        if (elt != null) {
            this.inputBlur.emit(elt.value);
            this.enterPressed.emit(elt.value);
        }

        if (this.preventFormSubmit) {
            event.preventDefault();
        }
    }

    private updateValidators() {
        if (this.ngModel != null) {
            const validators = [...(this.validators ?? [])];
            if (this.validationData?.customErrorMessage != null) {
                validators.push(customErrorValidator(this.validationData?.customErrorMessage));
            }

            this.ngModel.control.setValidators(validators);
            this.ngModel.control.updateValueAndValidity();
            this.changeDetectorRef.detectChanges();
        }
    }

    private setCssClasses() {
        const additionalCssClasses: string[] = [];

        switch (this.look) {
            case TextBoxLook.HiltiStyled:
                additionalCssClasses.push('hilti-styled');
                this.controlTitleStyle = ControlTitleLook.HiltiStyled;
                break;

            case TextBoxLook.Large:
                additionalCssClasses.push('large');
                this.controlTitleStyle = ControlTitleLook.Normal;
                break;

            default:
                this.controlTitleStyle = ControlTitleLook.Normal;
                break;
        }

        switch (this.marginBottom) {
            case TextBoxMargin.None:
                additionalCssClasses.push('no-bottom-margin');
                break;

            case TextBoxMargin.Normal:
            default:
                break;
        }

        switch (this.borderTop) {
            case TextBoxBorder.None:
                additionalCssClasses.push('no-top-border');
                break;

            case TextBoxBorder.Normal:
            default:
                break;
        }

        switch (this.borderBottom) {
            case TextBoxBorder.None:
                additionalCssClasses.push('no-bottom-border');
                break;

            case TextBoxBorder.Normal:
            default:
                break;
        }

        switch (this.borderLeft) {
            case TextBoxBorder.None:
                additionalCssClasses.push('no-left-border');
                break;

            case TextBoxBorder.Normal:
            default:
                break;
        }

        switch (this.borderRight) {
            case TextBoxBorder.None:
                additionalCssClasses.push('no-right-border');
                break;

            case TextBoxBorder.Normal:
            default:
                break;
        }

        switch (this.textAlign) {
            case TextBoxAlign.Center:
                additionalCssClasses.push('text-center');
                break;
            case TextBoxAlign.End:
                additionalCssClasses.push('text-end');
                break;


            case TextBoxAlign.Start:
            default:
                break;
        }

        switch (this.display) {
            case TextBoxDisplay.Bold:
                additionalCssClasses.push('bold');
                break;

            case TextBoxDisplay.Normal:
                additionalCssClasses.push('normal');
                break;
        }

        switch (this.textBackground) {
            case TextBoxBackground.None:
                additionalCssClasses.push('text-background-white');
                break;

            case TextBoxBackground.Normal:
            default:
                break;
        }

        this.additionalClasses = additionalCssClasses.join(' ');
    }
}
