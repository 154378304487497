<pe-control-title class="control-title" [focusControlId]="id" [title]="title">
</pe-control-title>
<div
    ngbDropdown
    [container]="container"
    [placement]="placement"
    class="dropdown-wrapper"
>
    <button
        type="button"
        class="button button-default dropdown-button"
        [id]="id"
        ngbDropdownToggle
        [disabled]="disabled"
        (click)="detectChanges()"
    >
        <span class="text">{{ (selectedItem && selectedItem.value) ? selectedItem.text : placeholder }}</span>
        <span class="caret"></span>
    </button>
    <div ngbDropdownMenu>
        <button
            *ngFor="let item of items"
            class="button-item"
            [class.selected]="item === selectedItem"
            ngbDropdownItem
            (click)="selectValue(item.value)"
        >
            {{ item.text }}
        </button>
    </div>
</div>
