import {
    Component,
    OnInit,
    OnChanges,
    SimpleChanges,
    Input,
} from '@angular/core';
import { distinctUntilChanged, map, shareReplay } from 'rxjs';
import { DeckingCommonZonesRowComponent } from './../../../../../components/decking-main-bottom/decking-zones-common/decking-common-zones-row.component';
import { RelevantLoads } from './../../../../../entities/decking-code-list/enums/relevant-loads';
import {
    DeckingFieldState,
    DeckingSubstitutionFieldState,
    IDeckingFieldStateValue,
    IDeckingSubstitutionFieldStateValue,
} from './../../../../../entities/decking-design/decking-field-state';
import {
    IndividualZoneModel,
    SubstitutionZoneModel,
} from './../../../../../entities/decking-substitution/substitution-zone';
import { DeckingSubstitutionService } from './../../../../../services/decking-design/decking-substitution.service';
import { FieldState } from './../../../../../entities/enums/field-state';
import { TextBoxAlign } from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import { CalculationState } from 'src/decking/entities/enums/calculation-state';
import { ScopeCheckSeverity } from 'src/decking/entities/decking-design/scope-check-result';
import { DeckingSubstitutionScopeChecksService } from '../../../../../services/decking-scope-checks/decking-substitution-scope-checks.service';
import { DirtyState } from 'src/decking/entities/enums/dirty-state';
import { DeckingSubstitutionZonesService } from 'src/decking/services/decking-zones/substitution-zones.service';
import { ModalService } from 'src/decking/services/external/modal.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { DeckingLoadTypes } from 'src/decking/entities/enums/load-types';
import { SubstitutionType } from 'src/decking/entities/decking-code-list/enums/substitution-type';

@Component({
    selector: '[decking-substitution-zones-row]',
    templateUrl: './decking-substitution-zones-row.component.html',
    styleUrls: ['./decking-substitution-zones-row.component.scss'],
})
export class DeckingSubstitutionZonesRowComponent
    extends DeckingCommonZonesRowComponent
    implements OnInit, OnChanges
{
    @Input()
    public zone: SubstitutionZoneModel;

    loadAlign = TextBoxAlign.Center;
    disableLoads = false;
    disableDFDetails = false;
    checkboxOldValue = false;
    disableOptimiseButton = true;
    public readonly dropDownPlacement = 'bottom-left top-left auto';
    displayZeroValue = false;
    substitutionTypeEnum = SubstitutionType;

    constructor(
        public deckingSubstitutionService: DeckingSubstitutionService,
        public deckingSubstitutionScopeChecksService: DeckingSubstitutionScopeChecksService,
        private deckingZoneService: DeckingSubstitutionZonesService,
        private modalService: ModalService,
        public localizationService: LocalizationService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isSelected$ =
            this.deckingSubstitutionService.currentDeckingSubstitution$.pipe(
                map(
                    (substitution) =>
                        this.index === substitution.currentZoneIndex
                ),
                distinctUntilChanged()
            );
        this.isSubstitutionRequiredShearStiffnessSetting$ =
            this.deckingSubstitutionService.isSubstitutionRequiredShearStiffnessSetting$;
        this.isSubstitutionRequiredUpliftSubmittalSetting$ =
            this.deckingSubstitutionService.isSubstitutionRequiredUpliftSubmittalSetting$;
        this.isSubstitutionRelevantLoadAtZoneLevelSetting$ =
            this.deckingSubstitutionService.isSubstitutionRelevantLoadAtZoneLevelSetting$;
        this.disableLoadsOrDFDetailsForSpecifiedZone(
            this.zone.zoneSpecified.enableLoad
        );
        this.panelType$ = this.deckingSubstitutionService.currentDeckingSubstitution$.pipe(
            map(
                (substitution) =>
                    substitution.areas[substitution.currentAreaIndex].panelType.id
            ),
            distinctUntilChanged()
        );
    }

    public override ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        this.isDeckFilled$ = this.deckingSubstitutionService.currentArea$.pipe(
            map(
                (currentArea) =>
                    currentArea.deckFill.value ===
                    'Agito.Hilti.PE.Decking.CalculationService.DeckFill.NoFill'
            ),
            distinctUntilChanged(),
            shareReplay(1)
        );
        this.disableLoadsOrDFDetailsForSpecifiedZone(
            this.zone.zoneSpecified.enableLoad
        );
        this.handleOptimiseClick();
    }

    public valueSelected<TId, TValue>(
        item: DeckingFieldState<TId, TValue> | IDeckingFieldStateValue<TValue>
    ) {
        if (item != null) {
            item.fieldState = FieldState.Selected;
        }
        this.zone.resetAlternatives = false;
        this.valueChanged();
        this.handleOptimiseClick();
    }

    public substitutedZonevalueSelected<TId, TValue>(
        item:
            | DeckingSubstitutionFieldState<TId, TValue>
            | IDeckingSubstitutionFieldStateValue<TValue>
    ) {
        if (item != null) {
            item.fieldState = FieldState.Selected;
        }
        if (
            item != null &&
            this.zone?.zoneSubstituted?.calculationState !=
                CalculationState.Empty
        ) {
            item.dirtyState = DirtyState.Dirty;
        }
        this.valueChanged();
    }

    public specifiedLoadChange(loadType: DeckingLoadTypes, loadValue: number) {
        const oldValue = this.getLoadOldValue(loadType);
        this.setLoadValue(loadType, loadValue);

        if (!this.checkZoneHasDefaultValues()) {
            this.modalService.openConfirmChange({
                id: 'confirm-delete-template',
                title: this.localizationService.getString(
                    'Agito.Hilti.Profis3.Decking.Zones.LoadChangeConfirmationModal.Title'
                ),
                message: this.localizationService.getString(
                    'Agito.Hilti.Profis3.Decking.Zones.LoadChangeConfirmationModal.Message'
                ),
                confirmButtonText: this.localizationService.getString(
                    'Agito.Hilti.Profis3.Decking.Zones.LoadChangeConfirmationModal.ConfirmButtonText'
                ),
                cancelButtonText: this.localizationService.getString(
                    'Agito.Hilti.Profis3.Decking.Zones.LoadChangeConfirmationModal.CancelButtonText'
                ),
                onCancel: (modal) => {
                    modal.close();
                    this.setLoadValue(loadType, oldValue);
                },
                onConfirm: async (modal) => {
                    modal.close();
                    this.resetZone();
                    this.valueChanged();
                },
            });
            return;
        }
        this.valueChanged();
    }

    public setLoadValue(loadType: DeckingLoadTypes, loadValue: number) {
        if (loadType == DeckingLoadTypes.Q) {
            this.zone.zoneSpecified.q.value = loadValue;
        } else if (loadType == DeckingLoadTypes.W) {
            this.zone.zoneSpecified.w.value = loadValue;
        } else {
            this.zone.zoneSpecified.g.value = loadValue;
        }
    }

    public getLoadOldValue(loadType: DeckingLoadTypes): number {
        if (loadType == DeckingLoadTypes.Q) {
            return this.zone.zoneSpecified.q.value;
        } else if (loadType == DeckingLoadTypes.W) {
            return this.zone.zoneSpecified.w.value;
        } else {
            return this.zone.zoneSpecified.g.value;
        }
    }

    public resetZone() {
        const currentSettings =
            this.deckingSubstitutionService.getCurrentSubstitution().settings;
        const defaultZone: SubstitutionZoneModel =
            this.deckingZoneService.getDefaultZone(currentSettings);

        this.zone.zoneSpecified.frameFastener =
            defaultZone.zoneSpecified.frameFastener;
        this.zone.zoneSpecified.sidelapConnector =
            defaultZone.zoneSpecified.sidelapConnector;
        this.zone.zoneSpecified.pattern = defaultZone.zoneSpecified.pattern;
        this.zone.zoneSpecified.side = defaultZone.zoneSpecified.side;
        this.zone.zoneSpecified.result = defaultZone.zoneSpecified.result;
        this.zone.zoneSpecified.g = defaultZone.zoneSpecified.g;
        this.zone.zoneSpecified.w = defaultZone.zoneSpecified.w;
        this.zone.zoneSpecified.q = defaultZone.zoneSpecified.q;
        this.zone.zoneSpecified.calculationState = CalculationState.Empty;

        this.zone.zoneSubstituted.deckGauge =
            defaultZone.zoneSubstituted.deckGauge;
        this.zone.zoneSubstituted.frameFastener =
            defaultZone.zoneSubstituted.frameFastener;
        this.zone.zoneSubstituted.sidelapConnector =
            defaultZone.zoneSubstituted.sidelapConnector;
        this.zone.zoneSubstituted.pattern = defaultZone.zoneSubstituted.pattern;
        this.zone.zoneSubstituted.side = defaultZone.zoneSubstituted.side;
        this.zone.zoneSubstituted.g = defaultZone.zoneSubstituted.g;
        this.zone.zoneSubstituted.w = defaultZone.zoneSubstituted.w;
        this.zone.zoneSubstituted.q = defaultZone.zoneSubstituted.q;
        this.zone.zoneSubstituted.result = defaultZone.zoneSubstituted.result;
        this.zone.zoneSubstituted.calculationState = CalculationState.Empty;
        this.zone.alternatives = [];
    }

    public checkZoneHasDefaultValues(): boolean {
        const currentSettings =
            this.deckingSubstitutionService.getCurrentSubstitution().settings;
        const zoneDefault: SubstitutionZoneModel =
            this.deckingZoneService.getDefaultZone(currentSettings);
        let defaultValues = true;

        if (
            this.zone.zoneSpecified.pattern !=
                zoneDefault.zoneSpecified.pattern ||
            this.zone.zoneSpecified.frameFastener !=
                zoneDefault.zoneSpecified.frameFastener ||
            this.zone.zoneSpecified.sidelapConnector !=
                zoneDefault.zoneSpecified.sidelapConnector ||
            this.zone.zoneSpecified.side != zoneDefault.zoneSpecified.side
        ) {
            defaultValues = false;
        }

        return defaultValues;
    }

    public valueChanged(isDirty = true): void {
        this.deckingSubstitutionService.updateZone(
            this.zone,
            this.index,
            isDirty
        );
    }

    public selectCurrentZone(): void {
        this.deckingSubstitutionService.setCurrentZone(this.index);
    }

    public setZoneType(relevantLoads: RelevantLoads): void {
        this.zone.relevantLoads = {
            value: relevantLoads,
        };
        this.deckingSubstitutionService.updateZone(this.zone, this.index);
    }

    public getClassByFieldState(
        individualZoneModel: IndividualZoneModel,
        field:
            | 'deckGauge'
            | 'pattern'
            | 'frameFastener'
            | 'sidelapConnector'
            | 'side'
    ): string {
        const { calculationState, result, [field]: item } = individualZoneModel;
        const compareResults =
            this.deckingSubstitutionScopeChecksService.compareZoneResultsValidation(
                this.zone
            );

        if (!item && calculationState !== CalculationState.NotSolution) {
            return 'not-selected-design';
        }

        const isCalculationStateSubstituted =
            calculationState === CalculationState.Substituted ||
            calculationState === CalculationState.PartialSubstituted;

        const isFieldStateSubstituted =
            item?.fieldState === FieldState.Substituted;

        if (isFieldStateSubstituted && isCalculationStateSubstituted) {
            return 'substituted-design';
        }

        const isFailedStatus =
            (calculationState !== CalculationState.Empty &&
                compareResults === -1) ||
            calculationState === CalculationState.Failed ||
            (calculationState === CalculationState.NotSolution &&
                result.failedScopeChecks.some(
                    (sChk) => sChk.severity === ScopeCheckSeverity.Critical
                ));

        if (isFailedStatus) {
            return 'failed-design';
        }

        return '';
    }

    public getClassByCalculationState(
        individualZoneModel: IndividualZoneModel
    ): string {
        const { calculationState, result } = individualZoneModel;
        const compareResults =
            this.deckingSubstitutionScopeChecksService.compareZoneResultsValidation(
                this.zone
            );

        const isFailedStatusOptimized =
            (calculationState !== CalculationState.Empty &&
                compareResults === -1) ||
            calculationState === CalculationState.Failed ||
            (calculationState === CalculationState.NotSolution &&
                result.failedScopeChecks.some(
                    (sChk) => sChk.severity === ScopeCheckSeverity.Critical
                ));

        if (isFailedStatusOptimized) {
            return 'failed-design';
        }

        if (
            (calculationState === CalculationState.Optimized ||
                calculationState === CalculationState.Substituted) &&
            compareResults === 1
        ) {
            return 'substituted-design';
        }

        if (
            calculationState === CalculationState.Specified ||
            calculationState === CalculationState.PartialSubstituted
        ) {
            return 'specified-or-partialSubstituted-design';
        }

        return '';
    }

    onLoadChanged(selected: boolean) {
        if (this.isSubstitutionOptimized()) {
            this.checkboxOldValue = !selected;
            this.zone.zoneSpecified.enableLoad = selected;
            this.openConfirmationModal();
        } else {
            this.checkboxOldValue = !selected;
            this.zone.zoneSpecified.enableLoad = selected;
            this.disableLoadsOrDFDetailsForSpecifiedZone(selected);
            this.handleOptimiseClick();
            this.valueChanged();
        }
       
    }

    disableLoadsOrDFDetailsForSpecifiedZone(selected: boolean) {
        this.disableLoads = !selected;
        this.disableDFDetails = selected;
        this.resetSpecifiedZoneDetails();
   
    }

    isSubstitutionOptimized(): boolean {
        return (
            this.zone.zoneSubstituted.q.value !== 0 ||
            this.zone.zoneSubstituted.w.value !== 0 ||
            this.zone.zoneSubstituted.g.value !== 0
        );
    }

    openConfirmationModal() {
        let isModalCanceled = true;
        let isModalConfirmed = false;
        const modalResult = this.modalService.openConfirmChange({
            id: 'confirm-delete-template',
            title: this.localizationService.getString(
                'Agito.Hilti.Profis3.Decking.Zones.LoadChangeConfirmationModal.Title'
            ),
            message: this.localizationService.getString(
                'Agito.Hilti.Profis3.Decking.Zones.LoadChangeConfirmationModal.Message'
            ),
            confirmButtonText: this.localizationService.getString(
                'Agito.Hilti.Profis3.Decking.Zones.LoadChangeConfirmationModal.ConfirmButtonText'
            ),
            cancelButtonText: this.localizationService.getString(
                'Agito.Hilti.Profis3.Decking.Zones.LoadChangeConfirmationModal.CancelButtonText'
            ),
            onCancel: (modal) => {
                isModalCanceled = false;
                this.zone.zoneSpecified.enableLoad = this.checkboxOldValue;
                modal.close();
            },
            onConfirm: async (modal) => {
                isModalCanceled = false;
                isModalConfirmed = true;
                modal.close();
            },
        });

        modalResult.closed.then(() => {
            if (isModalCanceled) {
                this.zone.zoneSpecified.enableLoad = this.checkboxOldValue;
            }
            if (isModalConfirmed) {
                this.resetZone();
                this.disableLoads = this.checkboxOldValue;
                this.disableDFDetails = !this.checkboxOldValue;
                this.handleOptimiseClick();
                this.valueChanged();
            }
        });
    }

    resetSpecifiedZoneDetails() {
        if (this.disableDFDetails && !this.isSubstitutionOptimized()) {
            const currentSettings = this.deckingSubstitutionService.getCurrentSubstitution().settings;
            const defaultZone: SubstitutionZoneModel = this.deckingZoneService.getDefaultZone(currentSettings);

            const resetValue = (value: number) => value !== 0 ? value : 0;

            if (
                this.zone.zoneSpecified.frameFastener !== null &&
                this.zone.zoneSpecified.sidelapConnector !== null &&
                this.zone.zoneSpecified.pattern !== null &&
                this.zone.zoneSpecified.side !== null &&
                this.zone.zoneSpecified.deckGauge !== null &&
                this.zone.zoneSpecified.enableLoad
            ) {
                this.zone.zoneSpecified.q.value = 0;
                this.zone.zoneSpecified.w.value = 0;
                this.zone.zoneSpecified.g.value = 0;
            } else {
                this.zone.zoneSpecified.q.value = resetValue(this.zone.zoneSpecified.q.value);
                this.zone.zoneSpecified.w.value = resetValue(this.zone.zoneSpecified.w.value);
                this.zone.zoneSpecified.g.value = resetValue(this.zone.zoneSpecified.g.value);
            }

            this.zone.zoneSpecified.frameFastener = defaultZone.zoneSpecified.frameFastener;
            this.zone.zoneSpecified.sidelapConnector = defaultZone.zoneSpecified.sidelapConnector;
            this.zone.zoneSpecified.pattern = defaultZone.zoneSpecified.pattern;
            this.zone.zoneSpecified.side = defaultZone.zoneSpecified.side;
            this.zone.zoneSpecified.result = defaultZone.zoneSpecified.result;
            this.zone.zoneSpecified.calculationState = CalculationState.Empty;
        }

        if (
            this.disableLoads &&
            this.zone.zoneSpecified.frameFastener == null &&
            this.zone.zoneSpecified.sidelapConnector == null &&
            this.zone.zoneSpecified.pattern == null &&
            this.zone.zoneSpecified.side == null
        ) {
            this.displayZeroValue = false;
        }
        else if (
            this.disableLoads &&
            this.zone.zoneSpecified.frameFastener != null &&
            this.zone.zoneSpecified.sidelapConnector != null &&
            this.zone.zoneSpecified.pattern != null &&
            this.zone.zoneSpecified.side != null
        ){        
            this.displayZeroValue = true;
        }
    }

    handleOptimiseClick() {
        if (
            this.zone.zoneSpecified.frameFastener !== null &&
            this.zone.zoneSpecified.sidelapConnector !== null &&
            this.zone.zoneSpecified.pattern !== null &&
            this.zone.zoneSpecified.side !== null &&
            this.zone.zoneSpecified.deckGauge !== null &&
            !this.zone.zoneSpecified.enableLoad
        ) {
            this.disableOptimiseButton = false;
        } else if (
            this.zone.zoneSpecified.deckGauge !== null &&
            this.zone.zoneSpecified.enableLoad
        ) {
            this.disableOptimiseButton = false;
        } else {
            this.disableOptimiseButton = true;
        }
    }
}
