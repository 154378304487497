<app-top-bar *ngIf="!isDeckingNewHeaderEnabled" [showSaveAsTemplateButton]="isFeatureEnabled && (isTemplate$ | async) === false"
    designTitle="{{(designTitle$ | async)}}"
    (settingsButtonClick)="openDiaphragmDesign()"
    (saveAsTemplateButtonClick)="topBarSaveAsTemplateClick()">
    <app-top-bar-menu menu></app-top-bar-menu>
</app-top-bar>

<pe-main-header *ngIf="isDeckingNewHeaderEnabled" class="main-header"
    designName= "{{(designTitle$ | async)}}"
    [designRegion]="regionId"
    [beforeLogout]="beforeLogout"
    [openDesignSettings]="openDiaphragmDesign"
    [openSaveAsTemplate]="((isTemplate$ | async) ? null : topBarSaveAsTemplateClick)"
    [startTour]="startTour"
    [openGeneralNotes]="openGeneralNotes"
    [displayLDFlags]="displayLDFlags"
    [useNewHomePage]="isNewHomePage"
    [setIsInternalLicenseSwitchAvailable] = "false"
    (menuOpened)="menuOpened()"
    (hiltiDataPrivacyUrlOpened)="hiltiDataPrivacyUrlOpened()"
    (regionLinkOpened)="regionLinkOpened()">
</pe-main-header>
