import { Injectable } from '@angular/core';
import { map, Observable, Subject, withLatestFrom } from 'rxjs';
import { Substitute } from '../../../../entities/decking-substitution/calculation/substitute';
import { DeckingBaseSubstitutionCalculationsService } from '../common/decking-base-substitution-calculations.service';

@Injectable({
  providedIn: 'root'
})
export class DeckingSubstitutionCalculationService extends DeckingBaseSubstitutionCalculationsService<Substitute> {

  private substituteAction = new Subject<number[]>();

  substituteAll() {
    this.deckingSubstitutionService.pushUndoStack(true);
    this.substituteAction.next(this.getZoneIndexesToSubstitute());
  }

  substituteZone(zoneIndex: number) {
    this.deckingSubstitutionService.pushUndoStack(true);
    this.substituteAction.next([zoneIndex]);
  }

  protected buildTriggerCalculationObservable(): Observable<Substitute> {
    return this.substituteAction.pipe(
      withLatestFrom(this.deckingSubstitutionService.currentDeckingSubstitution$),
      map(([zoneIndex, currentSubstitution]) => new Substitute({
        areaIndex: this.deckingSubstitutionService.getCurrentAreaIndex(),
        zoneIndex
      },
        currentSubstitution.id
      ))
    );
  }

  protected callCalculation(calculationInput: Substitute): void {
    this.deckingSubstitutionSignalRService.substituteDesign(calculationInput);
  }
  protected getSubstitutionId(calculationInput: Substitute): string {
    return calculationInput.substitutionId;
  }

  private getZoneIndexesToSubstitute(): number[] {
    const zoneIndexes: number[] = [];
    for (let i = 0; i < this.deckingSubstitutionService.currentArea.zones.length; i++) {
      if (!this.deckingSubstitutionService.currentArea.zones[i].isLocked.value) {
        zoneIndexes.push(i);
      }
    }
    return zoneIndexes;
  }
}
