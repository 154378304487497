import { DeckingDesign } from 'src/decking/entities/decking-design/decking-design';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserService } from 'src/decking/services/external/browser.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';

export class BaseExportComponent {
    public loading = false;

    constructor(
        protected activeModal: NgbActiveModal,
        protected browser: BrowserService,
        protected localization: LocalizationService
      ) { }

    protected DownloadDeckingFile(path: string, currentDesign: DeckingSubstitution | DeckingDesign){
        (currentDesign as any).isDeckingDesign = true;
        const blob = this.browser.base64toBlob(this.browser.toBase64(currentDesign), 'application/json');
        const filename = this.localization.getString('Agito.Hilti.Profis3.Decking.TemplateProjectName') + '_' + currentDesign.name + '.pe';
        this.browser.downloadBlob(blob, filename, false, false, path)
          .then(_ => {
            this.close();
          })
          .catch(err => {
            if (err instanceof Error) {
              console.error(err);
            }
            this.loading = false;
          });
      }

      public close(): void {
        this.activeModal.close();
      }
}