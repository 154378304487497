import { ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { IDeckingMessage } from 'src/decking/entities/decking-design/decking-message';
import { LocalizationService } from 'src/decking/services/external/localization.service';

export class BaseDeckingMainRightComponent {
    saving$: Observable<boolean>;
    public savingSubscription!: Subscription;
    disable$: Observable<boolean>;
    protected incorrectAreas: Map<string, string> = new Map();
    protected incorrectDeckTypeArea: Map<string, string> = new Map();
    protected failingScopeCheckAreas: Map<string, string> = new Map();
    protected readonly areaParameter = 'AreaName';

    public messages$: Observable<{ criticalMessages: IDeckingMessage[]; scopeCheckMessages: IDeckingMessage[]; deckCheckMessages: IDeckingMessage[] }>;
    public scopeCheckMessages$: Observable<IDeckingMessage[]>;
    public areas$: Observable<Set<string>>;

    protected readonly MAX_AREA_NAME_LENGTH: number = 22;

    constructor(
        protected localization: LocalizationService,
        protected cdr: ChangeDetectorRef
    ){}
    
    protected detectChanges() {
        this.cdr.detach();
        this.cdr.detectChanges();
        this.cdr.reattach();
    }

    protected getAreasMessage(areaName: string) {
        return this.translationText('Agito.Hilti.Profis3.Decking.Messages.AreasWarning.Text').replace('{' + this.areaParameter + '}', areaName.length > this.MAX_AREA_NAME_LENGTH ? areaName.substring(0, this.MAX_AREA_NAME_LENGTH) + '...' : areaName + '.');
    }

    protected getDeckTypeMessage(areaName: string) {
        return this.translationText('Agito.Hilti.Profis3.Decking.Messages.AreasDeckingWarning.Text').replace('{' + this.areaParameter + '}', areaName.length > this.MAX_AREA_NAME_LENGTH ? areaName.substring(0, this.MAX_AREA_NAME_LENGTH) + '...' : areaName + '.');
    }

    protected translationText(key: string): string {
        return this.localization.getString(key);
    }
}
