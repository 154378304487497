import { DeckingCodeListService } from  './../../../services/decking-code-list/decking-code-list.service';
import { Injectable } from '@angular/core';
import { BaseAreaFactory } from './base-area-factory';
import { SubstitutionAreaModel } from './../../../entities/decking-substitution/substitution-area';
import { DeckingSubstitutionZonesService } from  './../../../services/decking-zones/substitution-zones.service';
import { DeckingTabsWrapperService } from  './../../../services/decking-tabs-wrapper/decking-tabs-wrapper.service';
import { SubstitutionSettings } from './../../../entities/settings/substitution-settings';

@Injectable({
  providedIn: 'root'
})
export class DeckingSubstitutionAreaFactoryService extends BaseAreaFactory {

  constructor(
    protected override deckingTabsWrapperService: DeckingTabsWrapperService,
    protected override deckingCodeListService: DeckingCodeListService,
    private deckingZonesService: DeckingSubstitutionZonesService
  ) {
    super(deckingTabsWrapperService, deckingCodeListService);
  }

  buildDefaultDeckingArea(currentAreasCount: number, substitutionSettings: SubstitutionSettings): SubstitutionAreaModel {
    const defaultArea = this.getDefaultBaseArea(currentAreasCount, substitutionSettings) as SubstitutionAreaModel;
    defaultArea.zones = [ this.deckingZonesService.getDefaultZone(substitutionSettings) ];
    return defaultArea;
  }
}
