<div *ngIf="currentArea$ | async as currentArea">
    <div id="zones-bottom-container" *ngIf="{ value: areResultsExpanded$ | async } as areResultsExpanded">
        <div id="zones_table">
            <table class="table table-borderless" aria-describedby="Zones management table">
                <colgroup>
                    <col id="zone_selection_col" />
                    <col id="zone_name_col" />
                    <col id="zone_type_col" />

                    <!--Dimensions-->
                    <!--S-->
                    <col class="default_columns_size" *ngIf="currentArea.zoneSpacing.value" />

                    <!--Q,W,G-->
                    <col class="default_columns_size"/>
                    <col class="default_columns_size" *ngIf="isRequiredUpliftSubmittalSetting$ | async" />
                    <col class="default_columns_size" *ngIf="isRequiredShearStiffnessSetting$ | async" />

                    <!-- Details -->
                    <col id="deck_gauge_col" />
                    <col id="pattern_col" />
                    <col id="frame_fastener_col" [class.expanded]="!areResultsExpanded.value"/>
                    <col id="sidelap_connector_col" [class.expanded]="!areResultsExpanded.value" />
                    <col id="side_col" [class.expanded]="!areResultsExpanded.value"/>

                    <!-- Results-->
                    <!--Q, W, G -->
                    <col *ngIf="areResultsExpanded.value" class="default_columns_size"/>
                    <col *ngIf="areResultsExpanded.value && (isRequiredUpliftSubmittalSetting$ | async)" class="default_columns_size"/>
                    <col *ngIf="areResultsExpanded.value && (isRequiredShearStiffnessSetting$ | async)" class="default_columns_size"/>

                    <!--Action buttons-->
                    <col id="action_buttons_col" />
                </colgroup>
                <thead
                    decking-zones-header
                    [areaName]="currentArea.name.value"
                    [zoneSpacing]="currentArea.zoneSpacing.value"
                    [areResultsExpanded]="areResultsExpanded.value"
                    (expandResults)="expandResults()"
                    (collapseResults)="collapseResults()"
                ><tr class="hideHeader"><th></th></tr></thead>
                <tbody>
                    <tr
                        decking-zones-row
                        *ngFor="let zone of currentArea.zones; let i = index; trackBy: trackByFn"
                        [attr.id]="'zones-row-' + i"
                        [index]="i"
                        [zone]="zone"
                        [deckType]="currentArea.deckType.id"
                        [deckGaugeItems]="deckGaugeItems$ | async"
                        [patternItems]="patternItems$ | async"
                        [frameItems]="frameItems$ | async"
                        [sidelapItems]="sidelapItems$ | async"
                        [lengthUnit]="lengthUnit$  | async"
                        [stressUnit]="stressUnit$ | async"
                        [forcePerLengthUnit]="forcePerLengthUnit$ | async"
                        [shearStiffnessUnit]="shearStiffnessUnit$ | async"
                        [forceUnit]="forceUnit$ | async"
                        [zoneSpacing]="currentArea.zoneSpacing.value"
                        [saving]="(saving$ | async)"
                        [areResultsExpanded]="areResultsExpanded.value"
                        [canDeleteZone]="canDeleteZone$ | async"
                        [class.success-design]="zone.result?.isDesignCorrect?.value"
                        [class.danger-design]="
                            zone.result?.isDesignCorrect != null &&
                            !zone.result.isDesignCorrect.value
                        "
                    ><sub>{{zone | json}}</sub></tr>
                </tbody>
            </table>
        </div>
        <div id="buttons_container">
            <decking-zones-action-buttons
                [currentArea]="currentArea"
                [saving]="saving$ | async"
            ></decking-zones-action-buttons>
        </div>
    </div>
</div>
