import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FastenerEstimationComponent } from './fastener-estimation/fastener-estimation.component';

import { DeflectionCalculationComponent } from './../../../components/decking-popup/deflection-calculation/deflection-calculation.component';
import { DeckType } from './../../../entities/decking-code-list/enums/deck-type';
import { AreaModel } from './../../../entities/decking-design/area-model';
import { ZoneModel } from './../../../entities/decking-design/zone-model';
import { MessageType } from './../../../entities/enums/message-types';
import { DeckingDesignService } from './../../../services/decking-design/decking-design.service';
import { DeckingTrackingService } from './../../../services/decking-tracking/decking-tracking.service';
import { LocalizationService } from './../../../services/external/localization.service';
import { BaseDeckingMainRightMessagesComponent } from './base-decking-main-right-messages-component';


@Component({
  selector: 'decking-main-right-messages',
  templateUrl: './decking-main-right-messages.component.html',
  styleUrls: ['./decking-main-right-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeckingMainRightMessagesComponent extends BaseDeckingMainRightMessagesComponent {

  constructor(
    private modalService: NgbModal,
    private deckingDesignService: DeckingDesignService,
    private trackingService: DeckingTrackingService,
    public localization: LocalizationService
  ) {
    super();
    this.isMissingVerifiedZones$ = this.deckingDesignService.currentArea$.pipe(
      map((a: { zones: ZoneModel[] }) => a.zones.find(z => z.result?.isDesignCorrect) === undefined),
      distinctUntilChanged());
    this.isSteelRoofDeck$ = this.deckingDesignService.currentArea$.pipe(
      map((area: AreaModel) => area.deckType.id === DeckType.SteelroofDeck),
      distinctUntilChanged());
    this.disableDeflectionCalculation$ = combineLatest([this.isMissingVerifiedZones$, this.isSteelRoofDeck$]).pipe(
      map(([isMissingVerifiedZones, isSteelRoofDeck]) => isMissingVerifiedZones || !isSteelRoofDeck),
      distinctUntilChanged()
    );

    this.MessagesWithButton = [
      {
        Type: MessageType.WithButton,
        Title: this.translationText('Agito.Hilti.Profis3.Decking.Messages.DeflectionCalculation.Title'),
        Text: this.translationText('Agito.Hilti.Profis3.Decking.Messages.DeflectionCalculation.Text'),
        ButtonText: this.translationText('Agito.Hilti.Profis3.Decking.Messages.DeflectionCalculation.ButtonText'),
        ButtonClick: this.openDeflectionCalculationModal.bind(this),
        disabled$: this.disableDeflectionCalculation$
      },
      {
        Type: MessageType.WithButton,
        Title: this.translationText('Agito.Hilti.Profis3.Decking.Messages.FastenerEstimation.Title'),
        Text: this.translationText('Agito.Hilti.Profis3.Decking.Messages.FastenerEstimation.Text'),
        ButtonText: this.translationText('Agito.Hilti.Profis3.Decking.Messages.FastenerEstimation.ButtonText'),
        ButtonClick: this.openFastenerEstimationModal.bind(this),
        disabled$: this.isMissingVerifiedZones$
      }
    ];
  }

  private translationText(key: string): string {
    return this.localization.getString(key);
  }

  private openDeflectionCalculationModal(): void {
    const modalRef = this.modalService.open(DeflectionCalculationComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'deflectionCalculationModal';
    this.trackingService.setDeflectionCalculationUsed(true);
    this.trackingService.trackDeckingActivity(this.deckingDesignService.getCurrentDesign());
  }

  private openFastenerEstimationModal(): void {
    const modalRef = this.modalService.open(FastenerEstimationComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'fastenerEstimationModal';
    this.trackingService.setFastenerEstimationUsed(true);
    this.trackingService.trackDeckingActivity(this.deckingDesignService.getCurrentDesign());
  }
}
