import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeckingDesign } from './../../../entities/decking-design/decking-design';
import { ZoneAlternative } from './../../../entities/decking-design/zone-alternative-model';
import { DeckingDesignService } from  './../../../services/decking-design/decking-design.service';
import { environment } from './../../../../environments/environmentDecking';
import { ApiService } from  './../../../services/external/api.service';

@Injectable({
    providedIn: 'root'
})
export class DeckingZonesAlternativesService {

    constructor(
        private apiService: ApiService,
        private deckingDesignService: DeckingDesignService) {
    }

    public async getAlternatives(currentDesign: DeckingDesign): Promise<ZoneAlternative[]> {
        const url = `${environment.deckingCalculationServiceUrl}api/ZoneAlternatives/` + currentDesign.currentAreaIndex + `/` + currentDesign.currentZoneIndex;
        const responsePromise = await this.apiService.request<ZoneAlternative[]>(new HttpRequest('POST', url, currentDesign));
        return responsePromise.body;
    }

    public async setAlternatives(design: DeckingDesign): Promise<void>{
        design.areas.forEach(area => {
            area.zones.forEach(zone => {
                if(!zone.result.numberOfEdgeSupportConnections) {
                    zone.result.numberOfEdgeSupportConnections = {value: 0};
                }
            });
        });
        const url = `${environment.deckingDesignServiceUrl}api/DesignCommand/save`;
        try {
            const response = await this.apiService.request<DeckingDesign>(new HttpRequest('POST', url, design));
            const savedDesign = response.body;
            savedDesign.saved = true;
            this.deckingDesignService.setDesign(savedDesign);
          } catch (e) {
            console.error(e);
          }
    }
}
