import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { LocalizationService } from './../../../../services/external/localization.service';
import { AreaModel } from './../../../../entities/decking-design/area-model';
import { DeckingDesignService } from './../../../../services/decking-design/decking-design.service';

@Component({
  selector: 'app-fastener-estimation',
  templateUrl: './fastener-estimation.component.html',
  styleUrls: ['./fastener-estimation.component.scss']
})
export class FastenerEstimationComponent implements OnInit {

  public currentArea$: Observable<AreaModel>;

  constructor(
    public activeModal: NgbActiveModal,
    private localization: LocalizationService,
    public deckingDesignService: DeckingDesignService) { }

  public get FastenerEditDescription(): string {
    return this.localization.getString('Agito.Hilti.Profis3.Decking.Areas.FastenerEstimation.Description');
  }

  ngOnInit(): void {
    this.currentArea$ = this.deckingDesignService.currentArea$;
  }

  public close(currentArea: AreaModel): void {
    this.deckingDesignService.updateZones(currentArea.zones);
    this.activeModal.close();
  }
}
