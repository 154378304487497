import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { DeckingDesignService } from './../../../services/decking-design/decking-design.service';
import { DeckingDesignVerificationService } from './../../../services/decking-design/calculation/verification/decking-design-verification.service';
import { DeckingUnitsHelperService } from './../../../services/decking-units-helper/decking-units-helper.service';
import { DeckingCodeListService } from './../../../services/decking-code-list/decking-code-list.service';
import { DeckingZonesResultsService } from './../../../services/decking-zones/results/decking-zones-results.service';
import { DeckingCommonZonesComponent } from '../decking-zones-common/decking-common-zones.component';
import { Observable, combineLatest, distinctUntilChanged, map } from 'rxjs';
import { AreaModel } from 'src/decking/entities/decking-design/area-model';
import { ZoneModel } from 'src/decking/entities/decking-design/zone-model';
import { includeSprites } from 'src/decking/sprites';

@Component({
    selector: 'decking-design-zones',
    templateUrl: './decking-zones.component.html',
    styleUrls: ['./decking-zones.component.scss'],
    changeDetection:ChangeDetectionStrategy.Default
})
export class DeckingZonesComponent extends DeckingCommonZonesComponent  implements OnInit {
    public currentArea$: Observable<AreaModel>;
    public zoneSelected$: Observable<ZoneModel>;

    constructor(
        public deckingDesignService: DeckingDesignService,
        public deckingVerificationService: DeckingDesignVerificationService,
        private deckingZonesResultsService: DeckingZonesResultsService,
        public override deckingCodeListService: DeckingCodeListService,
        public override deckingUnitsHelperService: DeckingUnitsHelperService,
        public override elementRef: ElementRef<HTMLElement>,
        public override cdr: ChangeDetectorRef        
    ) { 
        super(deckingCodeListService, deckingUnitsHelperService, elementRef, cdr);
    }

    public override ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,
            'sprite-seismic-icon',
            'sprite-wind-icon',
        );
        this.zoneSelected$ = this.deckingDesignService.currentZone$;
        this.currentArea$ = this.deckingDesignService.currentArea$;
        this.currentSettings$ = this.deckingDesignService.currentSettings$;        
        this.areResultsExpanded$ = this.deckingZonesResultsService.areResultsExpanded$;
        this.saving$ = this.deckingVerificationService.saving$; 
        super.ngOnInit();
        this.canDeleteZone$ = combineLatest([this.saving$, this.deckingDesignService.canDeleteZone$]).pipe(
            map(([saving, canDeleteZone]) => canDeleteZone && !saving)
        );
        this.isRequiredShearStiffnessSetting$ = this.deckingDesignService.isRequiredShearStiffnessSetting$;
        this.isRequiredUpliftSubmittalSetting$ = this.deckingDesignService.isRequiredUpliftSubmittalSetting$;
        this.currentAreaSubscription = this.deckingDesignService.currentArea$.subscribe(() => {
            this.detectChanges();
        });
        this.savingSubscription = this.saving$.subscribe(() => {
            this.detectChanges();
        });
    }

    public override initDropDowns(): void {
        this.panelId$ = this.currentArea$.pipe(map(a => a.deckPanel.id), distinctUntilChanged());
        this.panelType$ = this.currentArea$.pipe(map(a => a.panelType.id), distinctUntilChanged());
        this.panelWidth$ = this.currentArea$.pipe(map(a => a.panelWidth.value), distinctUntilChanged());
        super.initDropDowns();
    }

    public expandResults(): void {
        this.deckingZonesResultsService.expandResults();
    }

    public collapseResults(): void {
        this.deckingZonesResultsService.collapseResults();
    }
    
}
