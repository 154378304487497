<!--Common structure for all designs-->
<div id="layout-container">
    <div id="layout-topbar-container">
        <!--Common header for all designs-->
        <ng-content select="[designer-layout-top]"></ng-content>
    </div>
    <div id="layout-main-container">
        <div id="layout-left-container" [hidden]="!designerLayoutService.leftColumnVisible">
            <!--Common left bar with home and favorites button-->
            <decking-main-left>
                <!--Dinamic Tabs Wrapper that needs to be injected to display custom menu tabs-->
                <ng-content select="[designer-layout-left-tabs-wrapper]"></ng-content>
            </decking-main-left>
        </div>
        <div id="layout-center-container">
            <div id="layout-center">
                <!--Custom Center component that needs to be injected-->
                <ng-content select="[designer-layout-center]"></ng-content>
            </div>
            <div id="layout-bottom">
                <!--Custom Bottom component that needs to be injected-->
                <ng-content select="[designer-layout-bottom]"></ng-content>
            </div>
        </div>
        <div id="layout-right-container" [hidden]="!designerLayoutService.rightColumnVisible">
            <!--Custom Right component that needs to be injected-->
            <ng-content select="[designer-layout-right]"></ng-content>
        </div>
    </div>
</div>