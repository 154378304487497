import { Design, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { DeckingUrlPath } from '../../entities/module-constants';
import { DeckingDocumentService } from '../decking-document/decking-document.service';
import { ApiService } from '../external/api.service';
import { ChangesService } from '../external/changes.service';
import { ImportService } from '../external/import.service';
import { LocalizationService } from '../external/localization.service';
import { LoggerService } from '../external/logger.service';
import { RoutingService } from '../external/routing.service';
import { UserService } from '../external/user.service';
import { BaseDesign } from './../../entities/decking-design/base-design';

export abstract class DeckingImportBaseService {

    constructor(
        protected apiService: ApiService,
        protected routingService: RoutingService,
        protected documentService: DeckingDocumentService,
        protected changesService: ChangesService,
        protected user: UserService,
        protected localization: LocalizationService,
        protected logger: LoggerService,
        protected importService: ImportService) { }

    public abstract importDFDesign(projectDesign: File, projectId: string): void;
    public abstract createAndOpenFromProjectDesign(design: BaseDesign, projectId: string): void;
    public abstract updateDesignFromExternalFile(oldDesign: IBaseDesign, projectDesign: BaseDesign): Promise<Design>;

    public readFileAsArrayBuffer(inputFile: File): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            const temporaryFileReader = new FileReader();

            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException('Problem parsing input file.'));
            };
            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result as ArrayBuffer);
            };
            temporaryFileReader.readAsArrayBuffer(inputFile);
        });
    }

    public arrayBufferToBase64(buffer: any): string {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    protected openImportNewWindow(designId: string) {
        window.open(DeckingUrlPath.mainDecking + designId);
        // reset importedFromDesign after opening new window
        this.importService.importedFromDesign = false;
    }

    protected openImportSameWindow(designId: string) {
        this.routingService.navigateToUrl(DeckingUrlPath.mainDecking + designId);
      }
}