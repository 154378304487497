<decking-collapsable-striped-title [isExpanded]="true" [buttonId]="'reportModal-summarySec-openButton'">
    <span id="reportModal-summarySec-title" collapsable-title
        l10n="Agito.Hilti.Profis3.Decking.Report.Title.Summary"></span>
    <div collapsable-content>
        <div class="row">
            <div class="container">
                <div class="item">
                    <p class="field">
                        <label id="reportModal-summarySec-evaluationTitle"
                            l10n="Agito.Hilti.Profis3.Decking.Design.DeckProperties.EvaluationServiceReport"></label>
                        <span id="reportModal-summarySec-evaluationValue">
                            ESR-2776
                        </span>
                    </p>
                </div>
                <div class="item">
                    <p class="field">
                        <label id="reportModal-summarySec-proofTitle"
                            l10n="Agito.Hilti.Profis3.Decking.Design.DeckProperties.Proof"></label>
                        <span id="reportModal-summarySec-proofValue">
                            {{designStandard}}
                        </span>
                    </p>
                </div>
                <div class="item">
                    <p class="field">
                        <label id="reportModal-summarySec-designTitle"
                            l10n="Agito.Hilti.Profis3.Decking.Design.DeckProperties.DesignMethod"></label>
                        <span id="reportModal-summarySec-designValue">
                            {{designMethod}}
                        </span>
                    </p>
                </div>
                <div class="item">
                    <p class="field">
                        <label id="reportModal-summarySec-relevantLoads"
                            l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.RelevantLoads"></label>
                        <span id="reportModal-summarySec-baseValue">
                            {{relevantLoadsType}}
                        </span>
                    </p>
                </div>
                <div class="item">
                    <p class="field">
                        <label id="reportModal-summarySec-relevantLoads"
                            l10n="Agito.Hilti.Profis3.Decking.Areas.DeckProperties.DeckType"></label>
                        <span id="reportModal-summarySec-DeckType">
                            {{deckType}}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</decking-collapsable-striped-title>
