import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { DeckingDesign } from './../../entities/decking-design/decking-design';
import { environment } from './../../../environments/environmentDecking';
import { ApiService } from  './../../services/external/api.service';
import { ZoneTemplateModel } from './../../entities/decking-design/zone-template-model';

@Injectable({
    providedIn: 'root'
})
export class ZoneTemplateService {
    responsePromise: Promise<HttpResponse<DeckingDesign>>;
    responseZoneTemplatePromise: Promise<HttpResponse<ZoneTemplateModel>>;

    constructor(
        private apiService: ApiService
    ) {}

    public async getZoneTemplateById(zoneTemplateId: string): Promise<ZoneTemplateModel> {
        const url = `${environment.deckingDesignServiceUrl}api/ZoneTemplatedQuery/${zoneTemplateId}`;
        this.responseZoneTemplatePromise = this.apiService.request<ZoneTemplateModel>(new HttpRequest('GET', url));
        return (await this.responseZoneTemplatePromise).body;
    }

    public async getZoneTemplates(): Promise<ZoneTemplateModel[]> {
        const url = `${environment.deckingDesignServiceUrl}api/ZoneTemplateQuery`;
        const response = this.apiService.request<ZoneTemplateModel[]>(new HttpRequest('GET', url));
        return (await response).body;
    }

    public async getZoneTemplatesByUserId(userId:string): Promise<ZoneTemplateModel[] > {
        const url = `${environment.deckingDesignServiceUrl}api/ZoneTemplateQuery/byUserId/${userId}`;
        try {
            const response = await this.apiService.request<ZoneTemplateModel[]>(new HttpRequest('GET', url));
            return response.body;
        } catch (error) {
            if (error && typeof error === 'object' && 'status' in error && error.status === 404) {
            return [];
        } else {              
            throw error;
            }
        }
    } 
    public async createZoneTemplate(zoneTemplate: ZoneTemplateModel): Promise<ZoneTemplateModel> {
        const url = `${environment.deckingDesignServiceUrl}api/zoneTemplateCommand`;
        const rData =  zoneTemplate ;
        return (await this.apiService.request<ZoneTemplateModel>(new HttpRequest('POST', url, rData))).body;
    }

    public async updateZoneTemplate(zoneTemplate: ZoneTemplateModel): Promise<ZoneTemplateModel> {
        const url = `${environment.deckingDesignServiceUrl}api/zoneTemplateCommand/update`;
        const rData = zoneTemplate;
        return (await this.apiService.request<ZoneTemplateModel>(new HttpRequest('PUT', url, rData))).body;
    }

    public async deleteZoneTemplate(zoneTemplate: ZoneTemplateModel): Promise<ZoneTemplateModel> {
        const url = `${environment.deckingDesignServiceUrl}api/zoneTemplateCommand/${zoneTemplate.id}`;        
        return (await this.apiService.request<ZoneTemplateModel>(new HttpRequest('DELETE', url))).body;
    }


}
