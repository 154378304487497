import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AreaModel, AreaSummaryModel } from 'src/decking/entities/decking-design/area-model';
import { DeckingDesignAreaFactoryService } from 'src/decking/services/decking-areas/factory/decking-design-area-factory.service';
import { DeckingDesignVerificationService } from 'src/decking/services/decking-design/calculation/verification/decking-design-verification.service';
import { DeckingDesignService } from 'src/decking/services/decking-design/decking-design.service';
import { DeckingTabsWrapperService } from 'src/decking/services/decking-tabs-wrapper/decking-tabs-wrapper.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { DeckingCommonAreasWrapperComponent } from '../decking-areas-common/decking-common-areas-wrapper.component';

@Component({
  selector: 'decking-design-areas-wrapper',
  templateUrl: './decking-design-areas-wrapper.component.html',
  styleUrls: ['./decking-design-areas-wrapper.component.scss']
})
export class DeckingDesignAreasWrapperComponent extends DeckingCommonAreasWrapperComponent implements OnInit, OnDestroy{

  areaSelected$: Observable<AreaModel>;

  constructor(
    public override localization: LocalizationService,
    public override deckingTabsWrapperService: DeckingTabsWrapperService,
    public deckingDesignService: DeckingDesignService,
    private areasFactory: DeckingDesignAreaFactoryService,
    private deckingDesignVerificationService: DeckingDesignVerificationService
  ) {
    super(localization,deckingTabsWrapperService);
  }

  ngOnInit(): void {
    this.saving$ = this.deckingDesignVerificationService.saving$;
    this.configureTooltips();
    this.setObservables(this.deckingDesignService.currentAreasSummary$);
  }

  public override setObservables(currentAreasSummary$: Observable<AreaSummaryModel[]>): void {
    this.areaSelected$ = this.deckingDesignService.currentArea$;
    super.setObservables(currentAreasSummary$);
  }

  public override addArea() {
    super.addArea();
    this.deckingDesignService.addNewArea(this.defaultArea());
  }

  private defaultArea(): AreaModel {
    return this.areasFactory.buildDefaultDeckingArea(this.currentAreasSummary?.length, this.deckingDesignService.getCurrentDesign().settings);
  }

}
