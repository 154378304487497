import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CollapseLook } from '@profis-engineering/pe-ui-common/components/section/section.common';
import { LocalizationService } from './../../../services/external/localization.service';

@Component({
  templateUrl: './decking-settings-menu.component.html',
  styleUrls: ['./decking-settings-menu.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class DeckingSettingsMenuComponent {
  public collapsed = false;

  @Input() deckingEnabled = true;

  @Input() rowDiaphragmSelected: boolean;

  @Output() selectRowDiaphragm = new EventEmitter();

  public collapseSectionBoxLook = CollapseLook.SectionBox;

  constructor(
    public localization: LocalizationService
  ) { }

  public onSectionCollapsedChange(): void {
    this.collapsed = !this.collapsed;
  }
}