import { SupportConstruction } from '../decking-code-list/enums/support-construction';
import { DeckFill } from '../decking-code-list/enums/deck-fill';
import { ZoneModel } from './zone-model';
import { IDeckingValue } from './decking-value';
import { IDeckingDocument } from './decking-document';
import { Beam } from './beam';
import { DeflectionCalculation } from './deflection-calculation';
import { PanelType } from '../decking-code-list/enums/panel-type';
import { DeckType } from '../decking-code-list/enums/deck-type';

export class BaseAreaModel {
    public id: string;
    public eTag: string;
    public name: IDeckingValue<string>;
    public deckFill: IDeckingDocument<DeckFill, string>;
    public deckType: IDeckingDocument<DeckType, string>;
    public compressiveStrength: IDeckingDocument<number, number>;
    public fillThickness: IDeckingValue<number>;
    public deckPanel: IDeckingDocument<number, string>;
    public panelType: IDeckingDocument<PanelType, string>;
    public panelWidth: IDeckingDocument<number, number>;
    public supportConstruction: IDeckingDocument<SupportConstruction, string>;
    public zoneSpacing: IDeckingValue<boolean>;
    public beam: Beam;
    public fu: IDeckingValue<number>;
    public fy: IDeckingValue<number>;
    public isDirty: boolean;
}

export class AreaModel extends BaseAreaModel {
    public isDeflectionCalculationUpdate: boolean;
    public deflectionCalculation: DeflectionCalculation;
    public zones: ZoneModel[];
}

export class AreaSummaryModel {
    public id: string;
    public eTag: string;
    public name: IDeckingValue<string>;
}
