<div class="modal-content" >
    <div class="modal-header">
        <h3 id="panelType-headerModal-panelTitle" class="modal-title" l10n="Agito.Hilti.Profis3.Decking.NestableInterlocking.Title"></h3>

        <button id="panelType-headerModal-closeButton" type="button" class="close" (click)="close()">
            <span>&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="section-row bottom-spacing">
            <span l10n="Agito.Hilti.Profis3.Decking.NestableInterlocking.Description"></span>
        </div>
        <div class="section-row bottom-spacing">
            <span l10n="Agito.Hilti.Profis3.Decking.NestableInterlocking.Nestable"></span>
        </div>
        <div class="section-row">
            <div class="image-container">
                <span class="image decking-sprite decking-sprite-responsive decking-sprite-nestable"></span>
            </div>
        </div>
        <div class="section-row bottom-spacing">
            <div class="description">
                <span l10n="Agito.Hilti.Profis3.Decking.NestableInterlocking.Nestable.Image"></span>
            </div>
        </div>
        <div class="section-row bottom-spacing">
            <span l10n="Agito.Hilti.Profis3.Decking.NestableInterlocking.Interlocking"></span>
        </div>
        <div class="section-row">
            <div class="image-container">
                <span class="image decking-sprite decking-sprite-responsive decking-sprite-interlocking"></span>
            </div>
        </div>
        <div class="section-row bottom-spacing">
            <div class="description">
                <span l10n="Agito.Hilti.Profis3.Decking.NestableInterlocking.Interlocking.Image"></span>
            </div>
        </div>
        <div class="section-row">
            <span [innerHtml]="AdditionalInformation"></span>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.Decking.NestableInterlocking.Button.Ok"
            (click)="close()">
        </button>
    </div>
</div>