<decking-collapsable-striped-title buttonId="reportModal-areaSec-openButton">
    <span [ngClass]="{'text-danger': reportInfo.selectedAreas.length < 1 }" collapsable-title id="reportModal-areaSec-title"
        l10n="Agito.Hilti.Profis3.Decking.Report.Title.AreaSelection"></span>
    <div collapsable-content>
        <div class="col-12" *ngIf="reportInfo.selectedAreas.length < 1">
            <span l10n="Agito.Hilti.Profis3.Decking.Report.SaveReportError.NoArea" class="text-danger"></span>
        </div>
        <div class="row">
            <div class="col-4" *ngFor="let area of areas;let i = index">
                <label class="area-selection">
                    <input class="checkbox-button" [id]="'reportModal-areaSec-area' + (i + 1) +'-checkbox'"
                        type="checkbox" [checked]="selectedArea(area.id)"
                        (change)="onAreaSelected($any($event).target.checked, area.id)" />
                    <span [id]="'reportModal-areaSec-area' + (i + 1) +'-label'" class="decking-report-area-name">
                        {{area.name.value}}
                    </span>
                </label>
            </div>
        </div>
    </div>
</decking-collapsable-striped-title>
