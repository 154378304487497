import { combineLatest } from 'rxjs';
import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { MessageType } from 'src/decking/entities/enums/message-types';
import { DeckType } from 'src/decking/entities/decking-code-list/enums/deck-type';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { SubstitutionZoneModel } from 'src/decking/entities/decking-substitution/substitution-zone';
import { SubstitutionAreaModel } from 'src/decking/entities/decking-substitution/substitution-area';
import { BaseDeckingMainRightMessagesComponent } from '../../../decking-main-right/decking-main-right-messages/base-decking-main-right-messages-component';
import { SubstitutionFastenerEstimationComponent } from './substitution-fastener-estimation/substitution-fastener-estimation.component';
import { DeckingSubstitutionTrackingService } from '../../../../services/decking-tracking/decking-substitution-tracking.service';

@Component({
  selector: 'substitution-main-right-messages',
  templateUrl: './substitution-main-right-messages.component.html',
  styleUrls: ['./substitution-main-right-messages.component.scss'],
})
export class SubstitutionMainRightMessagesComponent extends BaseDeckingMainRightMessagesComponent {

  constructor(
    private modalService: NgbModal,
    private deckingSubstitutionService: DeckingSubstitutionService,
    private substitutionTrackingService: DeckingSubstitutionTrackingService,
    public localization: LocalizationService
  ) {
    super();
    this.isMissingVerifiedZones$ = this.deckingSubstitutionService.currentArea$.pipe(
      map((a: { zones: SubstitutionZoneModel[] }) => a?.zones.find(z => z.zoneSubstituted?.result?.isDesignCorrect) === undefined),
      distinctUntilChanged());
    this.isSteelRoofDeck$ = this.deckingSubstitutionService.currentArea$.pipe(
      map((area: SubstitutionAreaModel) => area.deckType.id === DeckType.SteelroofDeck),
      distinctUntilChanged());
    this.disableDeflectionCalculation$ = combineLatest([this.isMissingVerifiedZones$, this.isSteelRoofDeck$]).pipe(
      map(([isMissingVerifiedZones, isSteelRoofDeck]) => isMissingVerifiedZones || !isSteelRoofDeck),
      distinctUntilChanged()
    );

    this.MessagesWithButton = [
      {
        Type: MessageType.WithButton,
        Title: this.translationText('Agito.Hilti.Profis3.Decking.Messages.FastenerEstimation.Title'),
        Text: this.translationText('Agito.Hilti.Profis3.Decking.Messages.FastenerEstimation.Text'),
        ButtonText: this.translationText('Agito.Hilti.Profis3.Decking.Messages.FastenerEstimation.ButtonText'),
        ButtonClick: this.openFastenerEstimationModal.bind(this),
        disabled$: this.isMissingVerifiedZones$
      }
    ];
  }

  private translationText(key: string): string {
    return this.localization.getString(key);
  }

  private openFastenerEstimationModal(): void {
    const modalRef = this.modalService.open(SubstitutionFastenerEstimationComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'fastenerEstimationModal';
    this.substitutionTrackingService.setFastenerEstimationUsed(true);
    this.substitutionTrackingService.trackSubstitutionActivity(this.deckingSubstitutionService.getCurrentSubstitution());
  }
}
