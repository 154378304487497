<ng-container *ngIf="internalDeckingSettings; else isLoading">
    <div class="content-group">

        <div class="content fixed-inputs"
            [class.no-margin]="collapseRegion.UnitsAndParams">
            <p class="title-strike title" [class.no-margin]="collapseRegion.UnitsAndParams" id="diaphragmDesign-units">
                <span l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.Units"></span>
                <pe-collapse
                    class="section-collapse"
                    [container]="'section-units-parameters'"
                    [collapsed]="collapseRegion.UnitsAndParams"
                    (collapsedChange)="collapseRegion.UnitsAndParams = $any($event).detail">
                </pe-collapse>
            </p>

            <div class="content" *ngIf="!collapseRegion.UnitsAndParams">
                <decking-button-group
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.SetUnits' | l10n:localization.selectedLanguage"
                    [items]="measureUnits.items"
                    [(selectedValue)]="isImperial"
                    (selectedValueChange)="measureUnitsUpdated($event)"
                ></decking-button-group>
                <button
                    id="reset-units"
                    type="button"
                    class="button button-default reset-button"
                    (click)="resetUnits()"
                >
                    {{'Agito.Hilti.Profis3.Decking.ApplicationSettings.Reset' | l10n:localization.selectedLanguage}}
                </button>
            </div>

            <div class="content-controls section-units-parameters" *ngIf="!collapseRegion.UnitsAndParams">
                <pe-dropdown
                    id="diaphragmDesign-length"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Length' | l10n:localization.selectedLanguage"
                    [items]="lengthItems"
                    [selectedValue]="internalDeckingSettings.length.index"
                    (selectedValueChange)="onDesignSettingInputChanged($any($event).detail, 'length');onLengthInputChanged()"
                    >
                </pe-dropdown>
                <pe-dropdown
                    id="diaphragmDesign-area"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Area' | l10n:localization.selectedLanguage"
                    [items]="areaItems"
                    [selectedValue]="internalDeckingSettings.area.index"
                    (selectedValueChange)="onDesignSettingInputChanged($any($event).detail, 'area')"
                    >
                </pe-dropdown>
                <pe-dropdown
                    id="diaphragmDesign-stress"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Stress' | l10n:localization.selectedLanguage"
                    [items]="stressItems"
                    [selectedValue]="internalDeckingSettings.stress.index"
                    (selectedValueChange)="onDesignSettingInputChanged($any($event).detail, 'stress')"
                    >
                </pe-dropdown>
                <pe-dropdown
                    id="diaphragmDesign-force"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Force' | l10n:localization.selectedLanguage"
                    [items]="forceItems"
                    [selectedValue]="internalDeckingSettings.force.index"
                    (selectedValueChange)="onDesignSettingInputChanged($any($event).detail, 'force')"
                    >
                </pe-dropdown>
                <pe-dropdown
                    id="diaphragmDesign-moment"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Moment' | l10n:localization.selectedLanguage"
                    [items]="momentItems"
                    [selectedValue]="internalDeckingSettings.moment.index"
                    (selectedValueChange)="onDesignSettingInputChanged($any($event).detail, 'moment')"
                    >
                </pe-dropdown>
                <pe-dropdown
                    id="diaphragmDesign-forceLength"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.ForcePerLength' | l10n:localization.selectedLanguage"
                    [items]="forcePerLengthItems"
                    [selectedValue]="internalDeckingSettings.forcePerLength.index"
                    (selectedValueChange)="onDesignSettingInputChanged($any($event).detail, 'forcePerLength')"
                    >
                </pe-dropdown>
                <pe-dropdown
                    id="diaphragmDesign-momentLength"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.MomentPerLength' | l10n:localization.selectedLanguage"
                    [items]="momentPerLengthItems"
                    [selectedValue]="internalDeckingSettings.momentPerLength.index"
                    (selectedValueChange)="onDesignSettingInputChanged($any($event).detail, 'momentPerLength')"
                    >
                </pe-dropdown>
                <pe-dropdown
                    id="deckDesignUnits-shearStiffness"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.ShearStiffness' | l10n:localization.selectedLanguage"
                    [items]="shearStiffnessItems"
                    [selectedValue]="internalDeckingSettings.shearStiffness.index"
                    (selectedValueChange)="onDesignSettingInputChanged($any($event).detail, 'shearStiffness')"
                    >
                </pe-dropdown>
                <pe-numeric-text-box
                    *ngIf="isSubstitutionEnabled"
                    SelectTextOnClick
                    id="diaphragmDesign-wasteFactor"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.WasteFactor' | l10n:localization.selectedLanguage"
                    [unit]="PercentUnit"
                    [value]="internalDeckingSettings?.wasteFactor?.value ?? 5"
                    (valueChange)="wasteFactorSelectedValueChange($any($event).detail)"
                    [increment]="1"
                    [minValue]="0"
                    [maxValue]="50"
                    [precision]="0"
                    [required]="true">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="content"
            [class.no-margin]="collapseRegion.DesignReference">
            <p class="title-strike title" [class.no-margin]="collapseRegion.UnitsAndParams" id="diaphragmDesign-designReference">
                <span l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.DesignReference"></span>
                <pe-collapse
                    class="section-collapse"
                    [container]="'section-design-reference'"
                    [collapsed]="collapseRegion.DesignReference"
                    (collapsedChange)="collapseRegion.DesignReference = $any($event).detail">
                </pe-collapse>
            </p>

            <div class="content-controls section-design-reference" *ngIf="!collapseRegion.DesignReference">
                <pe-dropdown
                    id="diaphragmDesign-designMethod"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.DesignMethod' | l10n:localization.selectedLanguage"
                    [items]="designMethodItems"
                    [selectedValue]="internalDeckingSettings.designMethod.index"
                    (selectedValueChange)="getDesignMethod($any($event).detail)"
                    [disabled]="isTemplate"
                    >
                </pe-dropdown>
                <pe-dropdown
                id="diaphragmDesign-designStandard"
                *ngIf="isMultiCodeEnabled"
                class="control"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.DesignStandard' | l10n:localization.selectedLanguage"
                [items]="designStandardItems"
                [selectedValue]="internalDeckingSettings.designStandard.index"
                (selectedValueChange)="getDesignStandard($any($event).detail)"
                [disabled]="isTemplate"
                >
            </pe-dropdown>
                <pe-dropdown
                    id="diaphragmDesign-relevantLoad"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.RelevantLoads' | l10n:localization.selectedLanguage"
                    [items]="relevantLoadItems"
                    [selectedValue]="internalDeckingSettings.relevantLoads.index"
                    (selectedValueChange)="getRelevantLoads($any($event).detail)"
                    [disabled]="isTemplate"
                    >
                </pe-dropdown>
            </div>
        </div>

        <div class="content" *ngIf="isSubstitutionEnabled"
            [class.no-margin]="collapseRegion.designMode">
            <p class="title-strike title" [class.no-margin]="collapseRegion.UnitsAndParams" id="diaphragmDesign-designMode">
                <span l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.DesignMode"></span>
                <pe-collapse
                    class="section-collapse"
                    [container]="'section-design-mode'"
                    [collapsed]="collapseRegion.designMode"
                    (collapsedChange)="collapseRegion.designMode = $any($event).detail">
                </pe-collapse>
            </p>

            <div class="content-controls section-design-mode" *ngIf="!collapseRegion.designMode">
                <pe-radio-button
                    class="radio-button-group control"
                    [id]="modeSelectorOptions.id"
                    [items]="modeSelectorOptions.items"
                    [selectedValue]="modeSelected"
                    (selectedValueChange)="modeSelectedValueChange($event)"
                    [disabled]="(addEditType !== addEditTypeEnum.add) || hideSubstitutionDesignSetting"
                    >
                </pe-radio-button>
            </div>
        </div>

        <div class="content"
            [class.no-margin]="collapseRegion.DataEntry">
            <p class="title-strike title" [class.no-margin]="collapseRegion.DataEntry" id="diaphragmDesign-dataEntry">
                <span l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.DataEntryOptions"></span>
                <pe-collapse
                    class="section-collapse"
                    [container]="'section-data-entry'"
                    [collapsed]="collapseRegion.DataEntry"
                    (collapsedChange)="collapseRegion.DataEntry = $any($event).detail">
                </pe-collapse>
            </p>

            <div class="section-data-entry" *ngIf="!collapseRegion.DataEntry">
                <div class="content-controls">
                    <pe-dropdown
                        id="diaphragmDesign-connectors"
                        class="control"
                        [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.DefinitionOfSidelapConnectors' | l10n:localization.selectedLanguage"
                        [items]="sidelapConnectorItems"
                        [selectedValue]="internalDeckingSettings.definitionOfSidelapConnectors.index"
                        (selectedValueChange)="getDefinitionOfSidelapConnectors($any($event).detail)"
                        [disabled]="isTemplate"
                        >
                    </pe-dropdown>
                    <p class="control">
                        <span *ngIf="isSidelapConnectorChanged" class="text-danger">
                            {{ 'Agito.Hilti.Profis3.Decking.ApplicationSettings.DefinitionOfSidelapConnectors.ChangeWarning' | l10n:localization.selectedLanguage }}
                        </span>
                    </p>
                </div>


            <div class="content-controls fixed-inputs" *ngIf="internalDeckingSettings.definitionOfSidelapConnectors.index == sidelapConnectorsType.ByConnectionSpacing">
                <pe-numeric-text-box
                    SelectTextOnClick
                    [id]="'diaphragmDesign-sidelapFrom'"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.From' | l10n:localization.selectedLanguage"
                    [unit]="ConnectorsUnitFromLength"
                    [value]="internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingFrom.value"
                    (valueChange)="internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingFrom.value = $any($event).detail"
                    [increment]="1"
                    [minValue]="ONE_INCH_IN_MM"
                    [maxValue]="internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingTo.value"
                    [required]="true"
                    [disabled]="isTemplate">
                </pe-numeric-text-box>
                <pe-numeric-text-box
                    SelectTextOnClick
                    [id]="'deckDesignData-sidelapToInput'"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.To' | l10n:localization.selectedLanguage"
                    [unit]="ConnectorsUnitFromLength"
                    [value]="internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingTo.value"
                    (valueChange)="internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingTo.value = $any($event).detail"
                    [increment]="1"
                    [minValue]="internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingFrom.value"
                    [maxValue]="maxValueSidelapToInput"
                    [required]="true"
                    (valueChange)="onSidelapConnectorToInputChanged()"
                    [disabled]="isTemplate">
                </pe-numeric-text-box>
                <pe-numeric-text-box
                    SelectTextOnClick
                    [id]="'deckDesignData-sidelapIncrementInput'"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Increment' | l10n:localization.selectedLanguage"
                    [unit]="ConnectorsUnitFromLength"
                    [value]="internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingIncrement.value"
                    (valueChange)="internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingIncrement.value = $any($event).detail"
                    [minValue]="ONE_INCH_IN_MM"
                    [maxValue]="internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingTo.value > maxValueSidelapIncrement ? maxValueSidelapIncrement :  internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingTo.value"
                    [required]="true"
                    [disabled]="isTemplate">
                </pe-numeric-text-box>
            </div>

            <div class="content-controls fixed-inputs" *ngIf="internalDeckingSettings.definitionOfSidelapConnectors.index == sidelapConnectorsType.ByNumberOfConnections">
                <pe-numeric-text-box
                    SelectTextOnClick
                    [id]="'deckDesignData-sidelapFromInput'"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.From' | l10n:localization.selectedLanguage"
                    [unit]="''"
                    [value]="internalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsFrom.value"
                    (valueChange)="internalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsFrom.value = $any($event).detail"
                    [increment]="1"
                    [minValue]="1"
                    [maxValue]="internalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsTo.value"
                    [disabled]="isTemplate">
                </pe-numeric-text-box>
                <pe-numeric-text-box
                    SelectTextOnClick
                    [id]="'deckDesignData-sidelapToInput'"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.To' | l10n:localization.selectedLanguage"
                    [unit]="''"
                    [value]="internalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsTo.value"
                    (valueChange)="internalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsTo.value = $any($event).detail"
                    [increment]="1"
                    [minValue]="1"
                    [maxValue]="50"
                    [disabled]="isTemplate">
                </pe-numeric-text-box>
                <pe-numeric-text-box
                    SelectTextOnClick
                    [id]="'deckDesignData-sidelapIncrementInput'"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Increment' | l10n:localization.selectedLanguage"
                    [unit]="''"
                    [value]="internalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsIncrement.value"
                    (valueChange)="internalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsIncrement.value = $any($event).detail"
                    [increment]="1"
                    [minValue]="1"
                    [maxValue]="1000"
                    [disabled]="isTemplate">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="content"
            [class.no-margin]="collapseRegion.ZoneInput">
            <p class="title-strike title" [class.no-margin]="collapseRegion.ZoneInput" id="diaphragmDesign-zoneInput">
                <span l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.ZoneInputOptions"></span>
                <pe-collapse
                    class="section-collapse"
                    [container]="'section-zone-input'"
                    [collapsed]="collapseRegion.ZoneInput"
                    (collapsedChange)="collapseRegion.ZoneInput = $any($event).detail">
                </pe-collapse>
            </p>
            <div class="horizontal-container">
                <div *ngIf="isDesignMode || isnewCustomDesignOpen">
                    <div *ngIf="!collapseRegion.ZoneInput">
                        <label class="label" id="deckDesignData-zoneInput-label" *ngIf="isSubstitutionEnabled">
                            {{ 'Agito.Hilti.Profis3.Decking.ApplicationSettings.Menu.DiaphragmConfiguration' | l10n:localization.selectedLanguage }}
                        </label>
                        <div class="content-controls section-zone-input">
                            <pe-checkbox-button
                                class="control"
                                [items]="zoneInputCheckboxesItems"
                                [selectedValues]="zoneInputCheckboxesCheckedItems"
                                (selectedValuesChange)="onZoneInputCheckboxesChanged($any($event).detail)"
                                [disabled]="isTemplate">
                            </pe-checkbox-button>
                        </div>
                    </div>
                </div>

                <div *ngIf="!collapseRegion.ZoneInput">
                    <div *ngIf="showSubstitutionConfiguration()">
                        <label class="label" id="deckSubstitutionData-zoneInput-label">
                            {{ 'Agito.Hilti.Profis3.Decking.ApplicationSettings.Menu.SubstitutionConfiguration' | l10n:localization.selectedLanguage }}
                        </label>
                        <div class="content-controls section-zone-input">
                            <pe-checkbox-button
                                class="control"
                                [items]="substitutionZoneInputCheckboxesItems"
                                [selectedValues]="substitutionZoneInputCheckboxesCheckedItems"
                                (selectedValuesChange)="onSubstitutionZoneInputCheckboxesChanged($any($event).detail)"
                                [disabled]="isTemplate">
                            </pe-checkbox-button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div>
    </div>
</ng-container>
<ng-template #isLoading>
    <div class="d-flex justify-content-center">
        <div class="spinner-border m-5 loading-spinner" role="status">
        </div>
    </div>
</ng-template>
