<div class="content-group" *ngIf="deckingSetting">
    <div class="content design-region">
        <p class="title-strike" l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.General"></p>

        <div class="content-controls">
            <pe-dropdown
                id="deckDesignRegion-regionDrop"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Region' | l10n:localization.selectedLanguage"
                [items]="regionsItems"
                [selectedValue]="deckingSetting.region?.index"
                (selectedValueChange)="onChangeRegion($any($event).detail)"
                class="control"
                [disabled]="submitting || loading">
            </pe-dropdown>

            <div class="design-type-status control-row info">
                <p id="deckDesignRegion-localsLabel">{{regionDescription}}</p>
            </div>
        </div>

        <div class="content-controls">
            <span class="local-steel-material-disclaimer"
                id="deckDesignRegion-guidelinesLabel"
                l10n="Agito.Hilti.Profis3.AddEditDesign.LocalSteelMaterialDisclaimer"></span>
        </div>
    </div>

    <div class="content"  *ngIf="showWasteFactor">
        <p class="title-strike title" id="diaphragmDesign-defaultApplication"
            l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.DefaultApplication">
        </p>
    
        <div class="content-controls section-default-application">
            <pe-radio-button
                    class="radio-button-group control"
                    [disabled]="submitting || loading"
                    [id]="defaultApplicationControl.id"
                    [title]="defaultApplicationControl.title"
                    [items]="defaultApplicationControl.items"
                    [look]="defaultApplicationControl.look"
                    [selectedValue]="deckingSetting.designMode?.value"
                    (selectedValueChange)="defaultApplicationControlSelectedValueChange($any($event).detail)">
            </pe-radio-button>
        </div>
    </div>

    <div class="content fixed-inputs">
        <p class="title-strike"
            l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.Units"
            id="deckDesignUnits-unitsLabel"></p>

            <div class="content">
                <decking-button-group
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.SetUnits' | l10n:localization.selectedLanguage"
                    [items]="measureUnits.items"
                    [(selectedValue)]="isImperial"
                    (selectedValueChange)="measureUnitsUpdated($event)"
                ></decking-button-group>
                <button
                    id="reset-units"
                    type="button"
                    class="button button-default reset-button"
                    (click)="resetUnits()"
                >
                    {{'Agito.Hilti.Profis3.Decking.ApplicationSettings.Reset' | l10n:localization.selectedLanguage}}
                </button>
            </div>

        <div class="content-controls">
            <pe-dropdown
                id="deckDesignUnits-lengthDrop"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Length' | l10n:localization.selectedLanguage"
                [items]="lengthItems"
                [selectedValue]="deckingSetting.length?.index"
                (selectedValueChange)="onLengthChanged($any($event).detail)"
                class="control"
                [disabled]="submitting || loading">
            </pe-dropdown>
            <pe-dropdown
                id="deckDesignUnits-areaDrop"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Area' | l10n:localization.selectedLanguage"
                [items]="areaItems"
                [selectedValue]="deckingSetting.area?.index"
                (selectedValueChange)="onAreaChanged($any($event).detail)"
                class="control"
                [disabled]="submitting || loading">
            </pe-dropdown>
            <pe-dropdown
                id="deckDesignUnits-stressDrop"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Stress' | l10n:localization.selectedLanguage"
                [items]="stressItems"
                [selectedValue]="deckingSetting.stress?.index"
                (selectedValueChange)="onStressChanged($any($event).detail)"
                class="control"
                [disabled]="submitting || loading">
            </pe-dropdown>
            <pe-dropdown
                id="deckDesignUnits-forceDrop"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Force' | l10n:localization.selectedLanguage"
                [items]="forceItems"
                [selectedValue]="deckingSetting.force?.index"
                (selectedValueChange)="onForceChanged($any($event).detail)"
                class="control"
                [disabled]="submitting || loading">
            </pe-dropdown>
            <pe-dropdown
                id="deckDesignUnits-momentDrop"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Moment' | l10n:localization.selectedLanguage"
                [items]="momentItems"
                [selectedValue]="deckingSetting.moment?.index"
                (selectedValueChange)="onMomentChanged($any($event).detail)"
                class="control"
                [disabled]="submitting || loading">
            </pe-dropdown>
            <pe-dropdown
                id="deckDesignUnits-forceLengthDrop"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.ForcePerLength' | l10n:localization.selectedLanguage"
                [items]="forcePerLengthItems"
                [selectedValue]="deckingSetting.forcePerLength?.index"
                (selectedValueChange)="onForcePerLengthChanged($any($event).detail)"
                class="control"
                [disabled]="submitting || loading">
            </pe-dropdown>
            <pe-dropdown
                id="deckDesignUnits-momentLengthDrop"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.MomentPerLength' | l10n:localization.selectedLanguage"
                [items]="momentPerLengthItems"
                [selectedValue]="deckingSetting.momentPerLength?.index"
                (selectedValueChange)="onMomentPerLengthChanged($any($event).detail)"
                class="control"
                [disabled]="submitting || loading">
            </pe-dropdown>
            <pe-dropdown
                id="deckDesignUnits-shearStiffness"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.ShearStiffness' | l10n:localization.selectedLanguage"
                [items]="shearStiffnessItems"
                [selectedValue]="deckingSetting.shearStiffness?.index"
                (selectedValueChange)="onShearStiffnessChanged($any($event).detail)"
                class="control"
                [disabled]="submitting || loading">
            </pe-dropdown>
            <pe-numeric-text-box
                *ngIf="showWasteFactor"
                SelectTextOnClick
                id="diaphragmDesign-wasteFactor"
                class="control"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.WasteFactor' | l10n:localization.selectedLanguage"
                [unit]="PercentUnit"
                [value]="deckingSetting.wasteFactor?.value"
                (valueChange)="wasteFactorSelectedValueChange($any($event).detail)"
                [increment]="1"
                [minValue]="0"
                [maxValue]="50"
                [precision]="0"
                [disabled]="loading || loading"
                [required]="true">
            </pe-numeric-text-box>
        </div>
    </div>

    <div class="content">
        <p class="title-strike"
            l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.DesignReference"
            id="deckDesignDesign-designRefLabel"></p>

        <div class="content-controls">
            <pe-dropdown
                id="deckDesignDesign-designMethodDrop"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.DesignMethod' | l10n:localization.selectedLanguage"
                [items]="designMethodItems"
                [selectedValue]="deckingSetting.designMethod?.index"
                (selectedValueChange)="onDesignMethodChanged($any($event).detail)"
                class="control"
                [disabled]="submitting || loading">
            </pe-dropdown>
            <pe-dropdown
            id="deckDesignDesign-designStandardDrop"
            *ngIf="isMultiCodeEnabled"
            [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.DesignStandard' | l10n:localization.selectedLanguage"
            [items]="designStandardItems"
            [selectedValue]="deckingSetting.designStandard?.index"
            (selectedValueChange)="onDesignStandardChanged($any($event).detail)"
            class="control"
            [disabled]="submitting || loading">
        </pe-dropdown>
            <pe-dropdown
                id="deckDesignDesign-relevantLoadDrop"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.RelevantLoads' | l10n:localization.selectedLanguage"
                [items]="relevantLoadItems"
                [selectedValue]="deckingSetting.relevantLoads?.index"
                (selectedValueChange)="onDefaultLoadsChanged($any($event).detail)"
                class="control"
                [disabled]="submitting || loading">
            </pe-dropdown>
        </div>
    </div>

    <div class="content">
        <p class="title-strike"
            l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.DataEntryOptions"
            id="deckDesignData-dataEntryLabel"></p>

        <div class="content-controls">
            <pe-dropdown
                id="deckDesignData-connectorsDrop"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.DefinitionOfSidelapConnectors' | l10n:localization.selectedLanguage"
                [items]="sidelapConnectorItems"
                [selectedValue]="deckingSetting.definitionOfSidelapConnectors?.index"
                (selectedValueChange)="onDefinitionOfSidelapConnectorChanged($any($event).detail)"
                class="control"
                [disabled]="submitting || loading">
            </pe-dropdown>
            <span class="control"></span>
        </div>

        <div class="content-controls fixed-inputs" *ngIf="deckingSetting.definitionOfSidelapConnectors?.index == SidelapConnectorTypes.connector1">
            <pe-numeric-text-box
                SelectTextOnClick
                [id]="'deckDesignData-sidelapFromInput'"
                class="control"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.From' | l10n:localization.selectedLanguage"
                [unit]="ConnectorsUnitFromLength"
                [value]="deckingSetting.sidelapsSpacing.sidelapSpacingFrom.value"
                (valueChange)="deckingSetting.sidelapsSpacing.sidelapSpacingFrom.value=$any($event).detail"
                [minValue]="ONE_INCH_IN_MM"
                [maxValue]="deckingSetting.sidelapsSpacing.sidelapSpacingTo.value"
                [disabled]="loading || loading"
                [required]="true">
            </pe-numeric-text-box>
            <pe-numeric-text-box
                SelectTextOnClick
                [id]="'deckDesignData-sidelapToInput'"
                class="control"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.To' | l10n:localization.selectedLanguage"
                [unit]="ConnectorsUnitFromLength"
                [value]="deckingSetting.sidelapsSpacing.sidelapSpacingTo.value"
                (valueChange)="deckingSetting.sidelapsSpacing.sidelapSpacingTo.value=$any($event).detail"
                [minValue]="deckingSetting.sidelapsSpacing.sidelapSpacingFrom.value"
                [maxValue]="maxValueSidelapToInput"
                [disabled]="submitting || loading"
                [required]="true"
                (valueChange)="onSidelapConnectorToInputChanged()">
            </pe-numeric-text-box>
            <pe-numeric-text-box
                SelectTextOnClick
                [id]="'deckDesignData-sidelapIncrementInput'"
                class="control"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Increment' | l10n:localization.selectedLanguage"
                [unit]="ConnectorsUnitFromLength"
                [value]="deckingSetting.sidelapsSpacing.sidelapSpacingIncrement.value"
                (valueChange)="deckingSetting.sidelapsSpacing.sidelapSpacingIncrement.value=$any($event).detail"
                [minValue]="ONE_INCH_IN_MM"
                [maxValue]="deckingSetting.sidelapsSpacing.sidelapSpacingTo.value > maxValueSidelapIncrement ? maxValueSidelapIncrement :  deckingSetting.sidelapsSpacing.sidelapSpacingTo.value"
                [disabled]="submitting || loading"
                [required]="true">
            </pe-numeric-text-box>
        </div>

        <div class="content-controls fixed-inputs" *ngIf="deckingSetting.definitionOfSidelapConnectors?.index == SidelapConnectorTypes.connector2">
            <pe-numeric-text-box
                SelectTextOnClick
                [id]="'deckDesignData-sidelapFromInput'"
                class="control"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.From' | l10n:localization.selectedLanguage"
                [unit]="''"
                [value]="deckingSetting.sidelapsNumber.numberOfSidelapsFrom.value" 
                (valueChange)="deckingSetting.sidelapsNumber.numberOfSidelapsFrom.value = $any($event).detail"
                [increment]="1"
                [minValue]="1" 
                [maxValue]="deckingSetting.sidelapsNumber.numberOfSidelapsTo.value"
                [disabled]="loading || loading">
            </pe-numeric-text-box>
            <pe-numeric-text-box
                SelectTextOnClick
                [id]="'deckDesignData-sidelapToInput'" 
                class="control"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.To' | l10n:localization.selectedLanguage"
                [unit]="''"
                [value]="deckingSetting.sidelapsNumber.numberOfSidelapsTo.value" 
                (valueChange)="deckingSetting.sidelapsNumber.numberOfSidelapsTo.value = $any($event).detail"
                [increment]="1"
                [minValue]="deckingSetting.sidelapsNumber.numberOfSidelapsFrom.value" 
                [maxValue]="50"
                [disabled]="loading || loading">
            </pe-numeric-text-box>
            <pe-numeric-text-box
                SelectTextOnClick
                [id]="'deckDesignData-sidelapIncrementInput'"
                class="control"
                [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Increment' | l10n:localization.selectedLanguage"
                [unit]="''"
                [value]="deckingSetting.sidelapsNumber.numberOfSidelapsIncrement.value" 
                (valueChange)="deckingSetting.sidelapsNumber.numberOfSidelapsIncrement.value = $any($event).detail"
                [increment]="1"
                [minValue]="1"
                [maxValue]="5"
                [disabled]="submitting || loading">
            </pe-numeric-text-box>
        </div>
    </div>

    <div class="content">
        <p class="title-strike"
            l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.ZoneInputOptions"
            id="deckDesignZone-zoneInputLabel"></p>

            <div class="horizontal-container">
                <div>
                    <label class="label" id="deckDesignData-zoneInput-label" *ngIf="isSubstitutionEnabled">
                        {{ 'Agito.Hilti.Profis3.Decking.ApplicationSettings.Menu.DiaphragmConfiguration' | l10n:localization.selectedLanguage }}
                    </label>
                    <div class="content-controls">
                        <pe-checkbox-button
                            class="control"
                            [items]="zoneInputCheckboxesItems"
                            [selectedValues]="zoneInputCheckboxesCheckedItems"
                            (selectedValuesChange)="onZoneInputCheckboxesChanged($any($event).detail)"
                            [disabled]="submitting || loading">
                        </pe-checkbox-button>
                    </div>
                </div>
            
                <div *ngIf="isSubstitutionEnabled">
                    <label class="label" id="deckSubstitutionData-zoneInput-label">
                        {{ 'Agito.Hilti.Profis3.Decking.ApplicationSettings.Menu.SubstitutionConfiguration' | l10n:localization.selectedLanguage }}
                    </label>
                    <div class="content-controls">
                        <pe-checkbox-button
                            class="control"
                            [items]="substitutionZoneInputCheckboxesItems"
                            [selectedValues]="substitutionZoneInputCheckboxesCheckedItems"
                            (selectedValuesChange)="onSubstitutionZoneInputCheckboxesChanged($any($event).detail)"
                            [disabled]="submitting || loading">
                        </pe-checkbox-button>
                    </div>
                </div>
            </div>
            
    </div>
</div>
