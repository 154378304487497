import { Injectable } from '@angular/core';
import { ReportTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import { ReportTemplateBaseService } from './decking-report-template-base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportTemplateService extends ReportTemplateBaseService {
  baseService!: ReportTemplateBaseService;

  public setBaseService(baseService: ReportTemplateBaseService) {
    this.baseService = baseService;
    
    this.getTemplates = baseService.getTemplates.bind(baseService);
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  public async getTemplates(options?: ApiOptions): Promise<ReportTemplateEntity[]> {
    return {} as Promise<ReportTemplateEntity[]>;
  }
}