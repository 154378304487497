export enum CalculationState {
    Empty = 0,
    Verified = 1,
    Failed = 2,
    Optimized = 3,
    PartialOptimized = 4,
    NotSolution = 5,
    Specified = 6,
    Substituted = 7,
    PartialSubstituted = 8
}
