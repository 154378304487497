import { Injectable } from '@angular/core';
import { getJSONDateWithTimezoneOffset } from '@profis-engineering/pe-ui-common/helpers/date-time-helper';
import { TrackOnProjectOpenRequest } from '../../entities/tracking/track-on-design-open-request';
import { HttpRequest } from '@angular/common/http';
import { ReportType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import { ApiService } from '../external/api.service';
import { LocalizationService } from '../external/localization.service';
import { UserService } from '../external/user.service';
import { DeckingUserSettingsService } from '../decking-user-settings/user-settings.service';
import { DeckingDesign } from '../../entities/decking-design/decking-design';
import { environment } from '../../../environments/environmentDecking';
import { AreaModel } from '../../entities/decking-design/area-model';
import { DeckingDesignTrackingData, TrackingData, DesignUsageCounter } from '../../entities/decking-design/decking-tracking-model';
import { LicenseService } from '../external/license.service';
import { CommonUserSettingsService } from '../external/common-user-settings.service';
import { BaseDeckingTrackingService } from './base-decking-tracking.service';
import { DeckingDesignModeType } from '../../entities/enums/decking-design-mode-type';

@Injectable({
    providedIn: 'root',
})
export class DeckingTrackingService extends BaseDeckingTrackingService {
    override internalTrackingData: Partial<TrackingData> = {
        fastenerEstimationUsed: false,
        deflectionCalcuationUsed: false,
    };

    declare usageCounter: DesignUsageCounter;

    constructor(
        private apiService: ApiService,
        private localizationService: LocalizationService,
        private deckingUserSettingsService: DeckingUserSettingsService,
        protected override userSettingsService: CommonUserSettingsService,
        protected override userService: UserService,
        protected override licenseService: LicenseService
    ) {
        super(userSettingsService, userService, licenseService);
        this.resetUsageCounter();
    }

    public async trackDesignOpened(design: DeckingDesign): Promise<void> {
        this.resetUsageCounter(design);
        if (environment.deckingTrackingServiceEnabled) {
            const url = `${environment.deckingTrackingServiceUrl}api/Tracking/TrackOnProjectDesignOpen`;
            const data = this.getDesignTrackingData(design);

            const request = new HttpRequest('POST', url, data, {
                responseType: 'json',
            });

            await this.apiService.request(request, {
                supressErrorMessage: true,
            });
        }
    }

    public async trackDesignClosed(design: DeckingDesign): Promise<void> {
        if (environment.deckingTrackingServiceEnabled) {
            const url = `${environment.deckingTrackingServiceUrl}api/Tracking/TrackOnProjectDesignClose`;
            const data = this.deckingTrackingDataFactory(design);


            const request = new HttpRequest('POST', url, data, {
                responseType: 'json',
            });

            await this.apiService.request(request, {
                supressErrorMessage: true,
            });
        }
    }

    getDesignTrackingData(
        design: DeckingDesign
    ): TrackOnProjectOpenRequest {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        return {
            designId: design.id,
            designType: this.diaphragmDesignType,
            language: this.localizationService.selectedLanguage,
            region: this.deckingUserSettingsService.deckingSettings.region.value ?? '',
            timestamp: date.timestamp as any,
            userDetails: userDetails,
            uiVersion: environment.moduleVersion,
            isInternal: this.userService.isInternalHiltiUser(),
        };
    }

    deckingTrackingDataFactory(design: DeckingDesign): DeckingDesignTrackingData {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);
        // set UsageCounter

        return {
            projectType: design.projectType ?? DeckingDesignModeType.DesignMode,
            timestamp: date.timestamp,
            uiVersion: environment.moduleVersion,
            isInternal: this.userService.isInternalHiltiUser(),
            designId: design.id,
            projectOpenType: 0,
            language: this.localizationService.selectedLanguage,
            region: this.deckingUserSettingsService.deckingSettings.region.value ?? '',
            userDetails: userDetails,
            projectDesign: design,
            designMethod: design.settings.designMethod.value,
            designStandard: design.settings.designStandard.value,
            defaultLoad: design.settings.relevantLoads.value,
            stiffnessUsed: design.settings.requiredShearStiffness?.value,
            upliftUsed: design.settings.requiredUpliftSubmittal?.value,
            interactionCalculated: design.settings.calculateIntegration.value,
            fastenerEstimationUsed: this.internalTrackingData.fastenerEstimationUsed,
            deflectionCalculationUsed: this.internalTrackingData.deflectionCalcuationUsed,
            windVsSeismic: design.settings.windAndSeismicLoadsAtZoneLevel?.value,
            usageCounter: this.usageCounter
        };
    }

    async trackDeckingActivity(design: DeckingDesign): Promise<void> {
        if (environment.deckingTrackingServiceEnabled) {
            const url = `${environment.deckingTrackingServiceUrl}api/Tracking/TrackDeckingActivity`;
            const data = this.deckingTrackingDataFactory(design);
            const request = new HttpRequest('POST', url, data, {
                responseType: 'json',
            });
            await this.apiService.request(request, { supressErrorMessage: true });
        }
    }

    resetUsageCounter(design: DeckingDesign = null) {
        if (design) {
            this.usageCounter = {
                totalAreas: design.areas.length,
                totalZones: this.countZones(design.areas),
                reportCreated: 0,
                reportPreviewed: 0,
                reportType: 'none',
                specificationTextUsed: 0,
                designExported: 0,
                menuOpened: 0,
                onlineTechnicalInformation: 0
            };
        } else {
            this.usageCounter = {
                totalAreas: 0,
                totalZones: 0,
                reportCreated: 0,
                reportPreviewed: 0,
                reportType: '',
                specificationTextUsed: 0,
                designExported: 0,
                menuOpened: 0,
                onlineTechnicalInformation: 0
            };
        }

        this.internalTrackingData = {
            fastenerEstimationUsed: false,
            deflectionCalcuationUsed: false,
        };
    }

    public override countZones(areas: Array<AreaModel>) {
        let count = 0;
        areas.forEach((area) => (count += area.zones.length));
        return count;
    }

    setTrackReportType(reportType: ReportType): void {
        switch (reportType) {
            case ReportType.Short:
                this.usageCounter.reportType = 'short';
                break;
            case ReportType.Long:
                this.usageCounter.reportType = 'long';
                break;
            default:
                this.usageCounter.reportType = 'none';
                break;
        }
    }

    addSpecificationTextUsed(toAdd = 1): void {
        this.usageCounter.specificationTextUsed += toAdd;
    }

    setDeflectionCalculationUsed(isUsed: boolean): void {
        this.internalTrackingData.deflectionCalcuationUsed = isUsed;
    }

    menuOpened(menuOpened = 1): void {
        this.usageCounter.menuOpened += menuOpened;
    }

    hiltiDataPrivacyUrlOpened(urlOpened = 1): void {
        this.usageCounter.onlineTechnicalInformation += urlOpened;
    }

    regionLinkOpened(linkOpened = 1): void {
        this.usageCounter.onlineTechnicalInformation += linkOpened;
    }
}
