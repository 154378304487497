import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeckingSubstitution } from '../../../../entities/decking-substitution/decking-substitution';
import { DeckingSubstitutionSignalrService } from '../../../decking-design-signalr/decking-substitution-signalr.service';
import { DeckingSubstitutionService } from '../../decking-substitution.service';

@Injectable({
  providedIn: 'root'
})
export class DeckingSubstitutionSavedService {
  private subscription: Subscription;

  constructor(
    private deckingSubstiutionSignalRService: DeckingSubstitutionSignalrService,
    private deckingSubstitutionService: DeckingSubstitutionService
  ) { }

  init(): void {
    this.subscription = this.deckingSubstiutionSignalRService.substitutionDesignSaved$.subscribe((substitution: DeckingSubstitution) => {
      substitution.saved = true;
      this.deckingSubstitutionService.setSubstitution(substitution);
    });
  }

  dispose(): void {
    this.subscription.unsubscribe();
  }
}
