import { Component, ElementRef, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserService } from 'src/decking/services/external/browser.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { includeSprites } from 'src/decking/sprites';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { BaseExportComponent } from 'src/decking/components/decking-popup/export-design/base-export-component';
import { DeckingSubstitutionTrackingService } from '../../../../services/decking-tracking/decking-substitution-tracking.service';
import { SubstitutionSpecificationTextComponent } from '../substitution-specification-text/substitution-specification-text.component';

@Component({
  selector: 'app-export-substitution',
  templateUrl: './export-substitution.component.html',
  styleUrls: ['./export-substitution.component.scss']
})
export class ExportSubstitutionComponent extends BaseExportComponent implements OnInit {

  constructor(
    protected override activeModal: NgbActiveModal,
    protected override browser: BrowserService,
    protected override localization: LocalizationService,
    private deckingSubstitutionService: DeckingSubstitutionService,
    private substitutionTrackingService: DeckingSubstitutionTrackingService,
    private modalService: NgbModal,
    public elementRef: ElementRef<HTMLElement>
  ) { 
    super(activeModal, browser, localization);
  }

  public ngOnInit(): void {
    includeSprites(this.elementRef.nativeElement,
      'sprite-profis-file',
      'sprite-arrow-right',
      'sprite-specification-text'
    );
  }

  public async exportDesignToFile() {
    this.loading = true;
    const path = '/';
    const currentDesign = this.deckingSubstitutionService.getCurrentSubstitution();
    this.DownloadDeckingFile(path, currentDesign);
    this.substitutionTrackingService.addDesignExported();
    await this.substitutionTrackingService.trackSubstitutionActivity(this.deckingSubstitutionService.getCurrentSubstitution());
  }

  public async openSpecificationTextModal() {
    const modalRef = this.modalService.open(SubstitutionSpecificationTextComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'specificationTextModal';
    this.activeModal.close();
    this.substitutionTrackingService.addSpecificationTextUsed();
    await this.substitutionTrackingService.trackSubstitutionActivity(this.deckingSubstitutionService.getCurrentSubstitution());
  }
}
