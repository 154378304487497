import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HttpRequest } from '@angular/common/http';
import { IReportResponse } from  './../../services/decking-report/decking-report.service';
import { firstValueFrom, Subject } from 'rxjs';
import { environment } from './../../../environments/environmentDecking';
import { ApiService } from  './../../services/external/api.service';
import { BrowserService } from  './../../services/external/browser.service';
import { DeckingReportHubConnectionFactoryService } from  './../../services/decking-report/hub-connection-factory/decking-report-hub-connection-factory.service';

@Injectable({
    providedIn: 'root'
})
export class DeckingReportGenerationService
 {
    private hubConnection: signalR.HubConnection;

    constructor(
        private apiService: ApiService,
        private browser: BrowserService,
        private deckingReportHubConnectionFactory: DeckingReportHubConnectionFactoryService
    ) {
    }

    public async generateReport(designId: string, eTag: string, fileName: string): Promise<void> {
        const observableResult = new Subject<void>();
        await this.initHubConnection(designId);
        this.requestReportGeneration(designId, eTag, fileName, observableResult);
        return firstValueFrom(observableResult);
    }

    private requestReportGeneration(designId: string, eTag: string, fileName: string, observableResult: Subject<void>) {
        this.hubConnection.send('GenerateDesignerReport', {
            designId: designId,
            eTag: eTag
        });
        this.hubConnection.on('OnReportDataGenerated', async (reportResponse: IReportResponse) => await this.onReportDataGenerated(reportResponse, fileName, observableResult));
    }

    private async initHubConnection(designId: string): Promise<void> {
        this.hubConnection = this.deckingReportHubConnectionFactory.buildHubConnection();
        if (this.hubConnection.state != signalR.HubConnectionState.Connected) {
            if (environment.accessToken == 'cookie') {
                // request to get the needed cookie for authentication
                await this.apiService.request(new HttpRequest('GET', environment.signalRCoreInitSessionUrl, { withCredentials: true, }));
            }
            await this.hubConnection.start();
            await this.hubConnection.send('SubscribeToGroup', designId);
        }
    }

    private async onReportDataGenerated(reportResponse: IReportResponse, fileName: string, observableResult: Subject<void>): Promise<void> {
        const byteData = new Uint8Array(atob(reportResponse.base64Pdf).split('').map(char => char.charCodeAt(0)));
        const pdfFile = new Blob([byteData], { type: 'application/pdf' });
        await this.browser.downloadBlob(pdfFile, fileName + '.pdf', true, true);
        if(this.hubConnection.state != signalR.HubConnectionState.Disconnected) {
            await this.hubConnection.stop();
        }
        observableResult.next();
    }

    
    public async generateSubstitutionReport(substitutionId: string, eTag: string, fileName: string): Promise<void> {
        const observableResult = new Subject<void>();
        await this.initHubConnection(substitutionId);
        this.requestSubstitutionReportGeneration(substitutionId, eTag, fileName, observableResult);
        return firstValueFrom(observableResult);
    }

    private requestSubstitutionReportGeneration(substitutionId: string, eTag: string, fileName: string, observableResult: Subject<void>) {
        this.hubConnection.send('GenerateSubstitutionReport', {
            designId: substitutionId,
            eTag: eTag
        });
        this.hubConnection.on('OnReportDataGenerated', async (reportResponse: IReportResponse) => await this.onReportDataGenerated(reportResponse, fileName, observableResult));
    }
}
