import { Component, Input } from '@angular/core';
import { NumberService } from './../../../services/external/number.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

  @Input()
  public percentage: number;

  constructor(private numberService: NumberService) { }

  public transformValue(value: number) {
    if(!value) return '0%';
    return this.numberService.format(value, 0) + '%';
  }
}
