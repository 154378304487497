<tr>
    <!-- Active -->
    <td rowspan="2" class="center-vertical">
        <pe-radio-button
            *ngIf="selectedLoadItems"
            [id]="'radio-button-zonerow-' + index"
            [disabled]="saving"
            [items]="selectedLoadItems"
            [selectedValue]="isSelected$ | async"
            (selectedValueChange)="selectCurrentZone()"
        >
        </pe-radio-button>
    </td>

    <!-- Name -->
    <td rowspan="2" class="input-cell center-vertical bordered-col">
        <pe-text-box
            [id]="'zone-name-input-' + index"
            [marginBottom]="'None'"
            [disabled]="saving"
            [borderTop]="'None'"
            [borderBottom]="'None'"
            [borderLeft]="'None'"
            [borderRight]="'None'"
            [value]="zone.name.value"
            [updateOnBlur]="true"
            (valueChange)="zone.name.value=$any($event).detail; valueChanged(false)"
            [maxLength]="60"
            SelectTextOnClick
            [display]="(isSelected$ | async )? 'Bold' : 'Normal' "
        ></pe-text-box>
    </td>

    <!-- Zone Type -->
    <td class="input-cell center-vertical bordered-col">
        <span
            id="zoneType-specified"
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.Specified"
        ></span>
    </td>

    <!-- Type -->
    <td rowspan="2" id="type_cell" class="center-vertical">
        <div id="type_buttons_container" class="type-buttons">
            <button
                type="button"
                class="type-button btn btn-default wind-load"
                [class.active]="zone.relevantLoads.value === relevantLoads.wind"
                [disabled]="saving || (isSubstitutionRelevantLoadAtZoneLevelSetting$ | async) == false"
                (click)="setZoneType(relevantLoads.wind)"
                [attr.id]="'zones-type-wind-button-' + index"
            >
                <span class="decking-sprite decking-sprite-wind-icon"></span>
            </button>
            <button
                type="button"
                class="type-button btn btn-default  wind-load"
                [class.active]="zone.relevantLoads.value === relevantLoads.seismic"
                [disabled]="saving || (isSubstitutionRelevantLoadAtZoneLevelSetting$ | async) == false"
                (click)="setZoneType(relevantLoads.seismic)"
                [attr.id]="'zones-type-seismic-button-' + index"
            >
                <span class="decking-sprite decking-sprite-seismic-icon"></span>
            </button>
        </div>
    </td>

    <!-- S -->
    <td *ngIf="zoneSpacing" class="input-cell center-vertical bordered-col" rowspan="2">
        <decking-numeric-text-box
            SelectTextOnClick
            [id]="'S-input-' + index"
            [value]="zone.s.value"
            [showStepper]="false"
            [updateOnBlur]="true"
            [required]="true"
            [unit]="lengthUnit"
            [minValue]="304.8"
            [maxValue]="18288"
            [disabled]="saving"
            [displayBorder]="false"
            (valueChange)="zone.s.value=$any($event).detail; valueChanged()"
            [display]="(isSelected$ | async )? 'Bold' : 'Normal' "
        ></decking-numeric-text-box>
    </td>

    <!-- DETAILS -->
    <!-- Gauge -->
    <td class="details input-cell">
        <decking-bootstrap-dropdown
            [id]="'deckgauge-dropdown-' + index"
            [items]="deckGaugeItems"
            [(selectedValue)]="$any(zone.zoneSpecified).deckGauge"
            (selectedValueChange)="substitutedZonevalueSelected($any(zone.zoneSpecified).deckGauge)"
            [disabled]="saving"
            [container]="'body'"
            [placement]="dropDownPlacement"
            [display]="(isSelected$ | async )? 'Bold' : 'Normal' "
        >
        </decking-bootstrap-dropdown>
    </td>

    <!-- Pattern -->
    <td class="details input-cell">
        <decking-bootstrap-dropdown
            [id]="'pattern-dropdown-' + index"
            [items]="$any(patternItems)"
            [disabled]="saving || disableDFDetails"
            [(selectedValue)]="$any(zone.zoneSpecified).pattern"
            (selectedValueChange)="valueSelected($any(zone.zoneSpecified).pattern)"
            [container]="'body'"
            [placement]="dropDownPlacement"
        >
        </decking-bootstrap-dropdown>
    </td>

    <!-- Frame Fastener -->
    <td class="details input-cell">
        <decking-bootstrap-dropdown
            [id]="'framefastener-dropdown-' + index"
            [disabled]="saving || disableDFDetails"
            [items]="$any(specifiedFrameItems)"
            [(selectedValue)]="$any(zone.zoneSpecified).frameFastener"
            (selectedValueChange)="valueSelected($any(zone.zoneSpecified).frameFastener)"
            [container]="'body'"
            [placement]="dropDownPlacement"
        >
        </decking-bootstrap-dropdown>
    </td>

    <!-- Sidelap Connector -->
    <td class="details input-cell">
        <decking-bootstrap-dropdown
            [id]="'sidelapconnector-dropdown-' + index"
            [items]="$any(sidelapItems | filterHiltiProducts: substitutionTypeEnum.Specified : (panelType$ | async))"
            [disabled]="saving || disableDFDetails"
            [(selectedValue)]="$any(zone.zoneSpecified).sidelapConnector"
            (selectedValueChange)="valueSelected($any(zone.zoneSpecified).sidelapConnector)"
            [container]="'body'"
            [placement]="dropDownPlacement"
        >
        </decking-bootstrap-dropdown>
    </td>

    <!-- Side spacing -->
    <td class="details input-cell">
        <decking-substitution-zone-side
            [id]="'spacing-dropdown-' + index"
            [notSelectedText]="' '"
            [saving]="saving || disableDFDetails"
            [currentZone]="zone.zoneSpecified"
            (valueChanged)="valueSelected($any(zone.zoneSpecified).side)"
            [container]="'body'"
            [placement]="'bottom-left'"
        >
        </decking-substitution-zone-side>
    </td>


    <!-- Enable Loads  -->
    <td class="input-cell center-vertical bordered-col">
        <div>
            <input
            class="checkbox-button"
            [id]="'enable-loads-' + index"
            type="checkbox"
            [checked]="zone.zoneSpecified.enableLoad"
            (change)="onLoadChanged($any($event).target.checked)"
            />
        </div>
    </td>
    <!-- Q -->
    <td class="input-cell" style="color: white;">
        <pe-numeric-text-box
            SelectTextOnClick
            [id]="'Q-input-' + index"
            [value]="disableDFDetails ? zone.zoneSpecified.q.value : (displayZeroValue ? zone.zoneSpecified.q.value: null)"
            [showStepper]="false"
            [updateOnBlur]="true"
            [required]="true"
            [unit]="forcePerLengthUnit"
            [minValue]="0"
            [maxValue]="87.5633954605"
            [disabled]="saving || disableLoads"
            (valueChange)="specifiedLoadChange(0, $any($event).detail)"
            [precision]="2"
            [textAlign]="loadAlign"
            [appendUnit]="false"
        ></pe-numeric-text-box>
    </td>

    <!-- W -->
    <td class="input-cell" [class.d-none]="(isSubstitutionRequiredUpliftSubmittalSetting$ | async) == false">
        <pe-numeric-text-box
            SelectTextOnClick
            [id]="'W-input-' + index"
            [value]="disableDFDetails ? zone.zoneSpecified.w.value : (displayZeroValue? zone.zoneSpecified.w.value: null)"
            [showStepper]="false"
            [updateOnBlur]="true"
            [required]="true"
            [unit]="stressUnit"
            [minValue]="0"
            [maxValue]="0.07182000000000001"
            (valueChange)="specifiedLoadChange(1, $any($event).detail)"
            [disabled]="
                (saving || disableLoads || deckType === deckTypes.ConcreteFilledDeck && ((isDeckFilled$ | async ) === false))"
            [precision]="2"
            [textAlign]="loadAlign"
            [appendUnit]="false"
        >
        </pe-numeric-text-box>
    </td>

    <!-- G -->
    <td class="input-cell" [class.d-none]="(isSubstitutionRequiredShearStiffnessSetting$ | async) == false">
        <pe-numeric-text-box
            SelectTextOnClick
            [id]="'G-input-' + index"
            [value]="disableDFDetails ? zone.zoneSpecified.g.value : (displayZeroValue? zone.zoneSpecified.g.value: null)"
            [showStepper]="false"
            [updateOnBlur]="true"
            [required]="true"
            [unit]="shearStiffnessUnit"
            (valueChange)="specifiedLoadChange(2, $any($event).detail)"
            [disabled]="saving || disableLoads"
            [minValue]="0"
            [maxValue]="35025.367064830236"
            [precision]="2"
            [textAlign]="loadAlign"
            [appendUnit]="false"
        >
        </pe-numeric-text-box>
    </td>
    <!-- Action buttons -->
    <td rowspan="2" id="action_buttons_cell" class="center-vertical">
        <decking-substitution-zones-row-action-buttons
            [zone]="zone"
            [index]="index"
            [saving]="saving || disableOptimiseButton"
            [canDeleteZone]="canDeleteZone"
        ></decking-substitution-zones-row-action-buttons>
    </td>
</tr>
<tr>
    <!-- Zone Type -->
    <td class="input-cell center-vertical bordered-col">
        <span
            id="zoneType-substituted"
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.Substituted"
        ></span>        
    </td>

    <!-- DETAILS -->
    <!-- Gauge -->
    <td class="details input-cell" [ngClass]="getClassByFieldState(zone.zoneSubstituted, 'deckGauge')">
        <decking-bootstrap-dropdown
            [id]="'deckgauge-dropdown-2-' + index"
            [items]="deckGaugeItems"
            [(selectedValue)]="$any(zone.zoneSubstituted).deckGauge"
            (selectedValueChange)="valueSelected($any(zone.zoneSubstituted).deckGauge)"
            [disabled]="saving"
            [container]="'body'"
            [placement]="dropDownPlacement"
        >
        </decking-bootstrap-dropdown>
    </td>

    <!-- Pattern -->
    <td class="details input-cell" [ngClass]="getClassByFieldState(zone.zoneSubstituted, 'pattern')">
        <decking-bootstrap-dropdown
            [id]="'pattern-dropdown-2-' + index"
            [items]="$any(patternItems)"
            [disabled]="saving"
            [(selectedValue)]="$any(zone.zoneSubstituted).pattern"
            (selectedValueChange)="valueSelected($any(zone.zoneSubstituted).pattern)"
            [container]="'body'"
            [placement]="dropDownPlacement"
        >
        </decking-bootstrap-dropdown>
    </td>

    <!-- Frame Fastener -->
    <td class="details input-cell" [ngClass]="getClassByFieldState(zone.zoneSubstituted, 'frameFastener')">
        <decking-bootstrap-dropdown
            [id]="'framefastener-dropdown-2-' + index"
            [disabled]="saving"
            [items]="$any(substitutedFrameItems)"
            [(selectedValue)]="$any(zone.zoneSubstituted).frameFastener"
            (selectedValueChange)="valueSelected($any(zone.zoneSubstituted).frameFastener)"
            [container]="'body'"
            [placement]="dropDownPlacement"
        >
        </decking-bootstrap-dropdown>
    </td>

    <!-- Sidelap Connector -->
    <td class="details input-cell" [ngClass]="getClassByFieldState(zone.zoneSubstituted, 'sidelapConnector')">
        <decking-bootstrap-dropdown
            [id]="'sidelapconnector-dropdown-2-' + index"
            [items]="$any(sidelapItems)"
            [disabled]="saving"
            [(selectedValue)]="$any(zone.zoneSubstituted).sidelapConnector"
            (selectedValueChange)="valueSelected($any(zone.zoneSubstituted).sidelapConnector)"
            [container]="'body'"
            [placement]="dropDownPlacement"
        >
        </decking-bootstrap-dropdown>
    </td> 

    <!-- Side spacing -->
    <td class="details input-cell" [ngClass]="getClassByFieldState(zone.zoneSubstituted, 'side')">
        <decking-substitution-zone-side
            [id]="'spacing-dropdown-2-' + index"
            [cssClass]="getClassByFieldState(zone.zoneSubstituted, 'side')"
            [notSelectedText]="' '"
            [saving]="saving"
            [currentZone]="zone.zoneSubstituted"
            (valueChanged)="valueSelected($any(zone.zoneSubstituted).side)"
            [container]="'body'"
            [placement]="'bottom-left'"
        >
        </decking-substitution-zone-side>
    </td>

    <!-- Enable Loads  -->
    <td class="details input-cell">
    </td>
    <!-- Q -->
    <td class="input-cell" [ngClass]="getClassByCalculationState(zone.zoneSubstituted)">
        <decking-unit-value-display
            SelectTextOnClick
            [id]="'Q-input-2-' + index"
            [value]="zone.zoneSubstituted.q.value"
            [unit]="forcePerLengthUnit"
            [appendUnit]="false"
        ></decking-unit-value-display>
    </td>

    <!-- W -->
    <td class="input-cell" [ngClass]="getClassByCalculationState(zone.zoneSubstituted)" [class.d-none]="(isSubstitutionRequiredUpliftSubmittalSetting$ | async) == false">
        <decking-unit-value-display
            SelectTextOnClick
            [id]="'W-input-2-' + index"
            [value]="zone.zoneSubstituted.w.value"
            [unit]="stressUnit"
            [appendUnit]="false"
        >
        </decking-unit-value-display>
    </td>

    <!-- G -->
    <td class="input-cell"  [ngClass]="getClassByCalculationState(zone.zoneSubstituted)" [class.d-none]="(isSubstitutionRequiredShearStiffnessSetting$ | async) == false">
        <decking-unit-value-display
            SelectTextOnClick
            [id]="'G-input-2-' + index"
            [value]="zone.zoneSubstituted.g.value"
            [unit]="shearStiffnessUnit"
            [appendUnit]="false"
        >
        </decking-unit-value-display>
    </td>
</tr>
<tr class="separator"></tr>