import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
    selector: '[SelectTextOnClick]'
})
export class SelectTextDirective {
    constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        const targetElement = event.target as HTMLElement;
        const tagName = targetElement.tagName;
        let inputElement;
        if (tagName === 'DECKING-NUMERIC-TEXT-BOX' || tagName === 'PE-NUMERIC-TEXT-BOX') {
            const nodes = [...this.elementRef.nativeElement.shadowRoot.childNodes];
            // Need to access deep inside the common pe-numeric-text-box to get the input
            inputElement = nodes.find((node: any) => node.className === 'input-textbox').shadowRoot.querySelector('input');
        }
        if (tagName === 'DECKING-TEXT-BOX' || tagName === 'PE-TEXT-BOX') {
            // Need to access deep inside the common pe-text-box to get the input
            inputElement = this.elementRef.nativeElement.shadowRoot.querySelector('input');
        }
        if (tagName === 'INPUT') {
            inputElement = this.elementRef.nativeElement.querySelector('input');
        }
        if (inputElement) {
            this.renderer.selectRootElement(inputElement).select();
        }
    }
}
