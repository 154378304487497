import { DeckingDesignModeType } from '../enums/decking-design-mode-type';
import { BaseSettings } from '../settings/design-settings';

export class BaseDesign {
    public id: string;
    public documentId?: string;
    public version: string;
    public eTag: string;
    public name: string;
    public currentAreaIndex: number;
    public currentZoneIndex: number;
    public saved = false;
    public isDirty: boolean;
    public projectType? = DeckingDesignModeType.DesignMode;
    public isNew? = true;
    public settings: BaseSettings;
}

export class BaseTemplate {
    public templateId: string;
}