<div class="modal-content" *ngIf="currentArea$ | async; let currentArea">
    <div class="modal-header">
        <h3 id="fastenerEdit-headerModal-panelTitle" class="modal-title"
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Areas.FastenerEstimation.Title"></h3>

        <button id="fastenerEdit-headerModal-closeButton" type="button" class="close" (click)="close(currentArea)">
            <span>&times;</span>
        </button>
    </div>

    <div class="modal-body">

        <div class="fastener-wasteFactor">
            <pe-numeric-text-box SelectTextOnClick id="fastener-wasteFactor-input" class="control"
                [title]="'Agito.Hilti.Profis3.Decking.Substitution.Areas.FastenerEstimation.WasteFactor' | l10n:localization.selectedLanguage"
                [unit]="PercentUnit" 
                [value]="currentArea.zones[0].fastenerEstimation.wasteFactor.value ?? 5" 
                (valueChange)="changeWasteFactor(currentArea, $any($event).detail);"
                [increment]="1" 
                [minValue]="0" 
                [maxValue]="50" 
                [precision]="0"
                [disabled]="saving$ | async"
                [required]="true">
            </pe-numeric-text-box>
            <div class="section fastener-wasteFactor-noteLabel">
                <span
                    l10n="Agito.Hilti.Profis3.Decking.Substitution.Areas.FastenerEstimation.WasteFactorDescription"></span>
            </div>
        </div>

        <div class="fastener-noteLabel">
            <span l10n="Agito.Hilti.Profis3.Decking.Substitution.Areas.FastenerEstimation.Description"></span>
        </div>

        <table class="fastener-table" aria-label="Fastener Estimation">
            <thead>
                <tr class="fastener-table-row-header">
                    <th class="table-body-header"></th>
                    <th class="table-body-header">{{
                        'Agito.Hilti.Profis3.Decking.Substitution.Areas.FastenerEstimation.ZoneArea' |
                        l10n:localization.selectedLanguage }}</th>
                    <th class="table-body-header">{{
                        'Agito.Hilti.Profis3.Decking.Substitution.Areas.FastenerEstimation.PerimeterFastenerSpacing' |
                        l10n:localization.selectedLanguage }}</th>
                    <th class="table-body-header">{{
                        'Agito.Hilti.Profis3.Decking.Substitution.Areas.FastenerEstimation.AverageJoistSpacing' |
                        l10n:localization.selectedLanguage }}</th>
                    <th class="table-body-header"
                        l10n="Agito.Hilti.Profis3.Decking.Substitution.Areas.FastenerEstimation.FrameFasteners"></th>
                    <th class="table-body-header"
                        l10n="Agito.Hilti.Profis3.Decking.Substitution.Areas.FastenerEstimation.SidelapConnectors"></th>
                </tr>
            </thead>
            <tbody>
                <tr app-substitution-fastener-estimation-row
                [index]="i" [currentArea]="currentArea" [deckingZone]="zone" *ngFor="let zone of currentArea.zones | filterSubstitutedZones; let i = index">

                </tr>
            </tbody>
        </table>

    </div>


    <div class="modal-footer">
        <button type="button" id="fastenerEdit-footerPanel-closeButton" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Areas.FastenerEstimation.Close" (click)="close(currentArea)">
        </button>
    </div>
</div>