import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/internal/Observable';
import { SubstitutionAreaModel } from '../../../../../entities/decking-substitution/substitution-area';
import { DeckingSubstitutionService } from '../../../../../services/decking-design/decking-substitution.service';
import { LocalizationService } from '../../../../../services/external/localization.service';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DeckingSpecificationCalculationService } from '../../../../../services/decking-design/calculation/specification/decking-specification-calculation.service';

@Component({
  selector: 'app-substitution-fastener-estimation',
  templateUrl: './substitution-fastener-estimation.component.html',
  styleUrls: ['./substitution-fastener-estimation.component.scss']
})
export class SubstitutionFastenerEstimationComponent implements OnInit {

  public currentArea$: Observable<SubstitutionAreaModel>;
  public saving$: Observable<boolean>;
  PercentUnit: Unit = Unit.percent;

  constructor(
    public activeModal: NgbActiveModal,
    public localization: LocalizationService,
    private deckingSubstitutionService: DeckingSubstitutionService,
    private deckingSpecificationCalculationService: DeckingSpecificationCalculationService
  ) {}
  
  ngOnInit(): void {
    this.currentArea$ = this.deckingSubstitutionService.currentArea$;
    this.saving$ = this.deckingSpecificationCalculationService.saving$;
  }

  changeWasteFactor(currentArea: SubstitutionAreaModel, wasteFactor: number): void {
    currentArea.zones.forEach((zone) => {
      zone.fastenerEstimation.wasteFactor.value = wasteFactor;
      zone.isFastenerEstimationUpdate = true;
    });
    this.deckingSubstitutionService.updateZones(currentArea.zones);
  }

  public close(currentArea: SubstitutionAreaModel): void {
    this.deckingSubstitutionService.updateZones(currentArea.zones);
    this.activeModal.close();
  }
}
