import { Injectable } from '@angular/core';
import { LocalizationService } from  './../../services/external/localization.service';

@Injectable({
  providedIn: 'root'
})
// Service in charge to keep all logic related with Areas Tab.
// This will keep current index selected, so any AreaComponent could access to this value.
// Also, this will provide specific logic that the UI needs.
export class DeckingTabsWrapperService {
  public allAreasCollapsed = false;
  private readonly minimumNumberAreas = 1;

  constructor(private localization: LocalizationService)
  { }

  public DefaultAreaTitle(currentTotalAreas: number): string {
    return this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.Area') + ' ' + (currentTotalAreas + 1);
  }

  public showDeleteOption(currentTotalAreas: number): boolean {
    return currentTotalAreas > this.minimumNumberAreas;
  }
}
