import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { ZoneModel } from './../../entities/decking-design/zone-model';
import { DeckingCodeListService } from  './../../services/decking-code-list/decking-code-list.service';
import { AreaModel } from './../../entities/decking-design/area-model';
import { CalculationState } from './../../entities/enums/calculation-state';
import { DesignSettings } from './../../entities/settings/design-settings';
import { NumberComparator } from 'src/decking/helpers/numbers/number-comparator';
import { DeckingDefaultFactoryService } from  './../../services/decking-design/factory/decking-default-factory.service';
import { BaseZones } from './base-zones';


@Injectable({
  providedIn: 'root'
})
// This service is in charge to provide the Zones of the selected Area using DeckingTabsWrapperService.
export class DeckingZonesService extends BaseZones {

  constructor(
    private deckingCodeListService: DeckingCodeListService
  ) {
    super();
  }

  public areZoneInputsEqual(currentZone: ZoneModel, newZone: ZoneModel): boolean {
    return currentZone.name.value === newZone.name.value &&
      NumberComparator.equal(currentZone.l?.value, newZone.l?.value) &&
      NumberComparator.equal(currentZone.q?.value, newZone.q?.value) &&
      NumberComparator.equal(currentZone.s?.value, newZone.s?.value) &&
      NumberComparator.equal(currentZone.w?.value, newZone.w?.value) &&
      NumberComparator.equal(currentZone.g?.value, newZone.g?.value) &&
      NumberComparator.equal(currentZone.fastenerEstimation?.area?.value, newZone.fastenerEstimation?.area?.value) &&
      NumberComparator.equal(currentZone.fastenerEstimation?.perimeterSpacing?.value, newZone.fastenerEstimation?.perimeterSpacing?.value) &&
      currentZone.relevantLoads?.value === newZone.relevantLoads?.value &&
      currentZone.deckGauge?.value === newZone.deckGauge?.value &&
      currentZone.pattern?.id === newZone.pattern?.id &&
      currentZone.frameFastener?.id === newZone.frameFastener?.id &&
      currentZone.sidelapConnector?.id === newZone.sidelapConnector?.id &&
      currentZone.isLocked.value === newZone.isLocked.value &&
      currentZone.side?.value === newZone.side?.value;
  }

  public getDefaultZone(settings: DesignSettings): ZoneModel {
    return {
      id: uuidv4(),
      name: { value: 'Zone' },
      relevantLoads: { value: settings.relevantLoads.id },
      // Dimensions
      l: { value: 0 },
      s: {
        value: DeckingDefaultFactoryService.DEFAULT_JOIST_SPACING_IN_MM_US
      },
      q: { value: 0 },
      w: { value: 0 },
      g: { value: 0 },
      // Dropdowns
      deckGauge: this.deckingCodeListService.GetDefaultDeckGaugeDropdownItem(),
      pattern: this.deckingCodeListService.GetDefaultPatternDropdownItem(),
      frameFastener: this.deckingCodeListService.GetDefaultFrameFastenerDropdownItem(),
      sidelapConnector: this.deckingCodeListService.GetDefaultSidelapConnectorDropdownItem(),
      side: null,
      // Results
      result: this.getDefaultResult(),
      // Fastener Estimation
      fastenerEstimation: {
        area: { value: 0 },
        perimeterSpacing: { value: 0 },
        frameFasteners: { value: 0 },
        sidelapConnectors: { value: 0 }
      },
      isLocked: { value: false },
      calculationState: CalculationState.Empty,
      isDirty: false,
      isFastenerEstimationUpdate: false,
      resetAlternatives: true
    };
  }

  public canDeleteZone(area: AreaModel): boolean {
    return area.zones.length > 1;
  }

  public hasUnCalculatedZones(area: AreaModel): boolean {
    return area.zones.some(zone => !zone.result?.isDesignCorrect);
  }
}
