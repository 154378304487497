<decking-collapsable-up [defaultExpanded]="true">
    <div class="collapsable-title message-collapsible">
        <span class="decking-sprite decking-sprite-info"></span>
        <span class="messages-title">{{'Agito.Hilti.Profis3.Decking.Messages.Messages.Title' | l10n:localization.selectedLanguage}}</span>
    </div>
    <ng-template appCollapsableContent>
        <div *ngIf="messages">
            <div *ngFor="let message of messages.criticalMessages" class="message-container">
                <app-message-basic
                    [Title]="message.Title"
                    [Text]="message.Text"
                    [MessageSprite] = "'decking-sprite-warning'"
                    [MessageStyle] = "'warning'">
                </app-message-basic>
            </div>
            <div *ngFor="let message of messages.deckCheckMessages" class="message-container">
                <app-message-basic
                    [Title]="message.Title"
                    [Text]="message.Text"
                    [MessageSprite] = "'decking-sprite-warning'"
                    [MessageStyle] = "'warning'">
                </app-message-basic>
            </div>
            <div *ngFor="let message of messages.scopeCheckMessages" class="message-container">
                <app-message-basic
                    *ngIf="message.Type === messageTypes.Warning"
                    [Title]="message.Title"
                    [Text]="message.Text"
                    [MessageSprite] = "'decking-sprite-info'"
                    [MessageStyle] = "'info'">
                </app-message-basic>
                <app-message-basic *ngIf="message.Type === messageTypes.Basic" [Title]="message.Title" [Text]="message.Text">
                </app-message-basic>
            </div>
            <div *ngFor="let message of MessagesWithButton" class="message-container">
                <app-message-with-button
                    [Title]="message.Title"
                    [Text]="message.Text"
                    [disabled]="saving || (message.disabled$ | async)"
                    [ButtonText]="message.ButtonText"
                    (ButtonClick)="message.ButtonClick()">
                </app-message-with-button>
            </div>
        </div>
    </ng-template>
</decking-collapsable-up>
