import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { MessageType } from 'src/decking/entities/enums/message-types';
import { IDeckingMessage } from 'src/decking/entities/decking-design/decking-message';
import { DefinitionOfSidelapConnectors } from 'src/decking/entities/decking-code-list/enums/definition-sidelap-connectors';
import { SubstitutionZoneModel } from 'src/decking/entities/decking-substitution/substitution-zone';
import { SubstitutionAreaModel } from 'src/decking/entities/decking-substitution/substitution-area';
import { BaseScopeChecksService } from './base-scope-checks';
import { ScopeCheckResult, ScopeCheckSeverity } from 'src/decking/entities/decking-design/scope-check-result';
import { UnitService } from '../external/unit.service';
import { DeckingUnitsHelperService } from '../decking-units-helper/decking-units-helper.service';
import { LocalizationService } from '../external/localization.service';

@Injectable({
    providedIn: 'root'
})
export class DeckingSubstitutionScopeChecksService extends BaseScopeChecksService {
    private readonly specifiedPrefixKey = 'Agito.Hilti.Profis3.Decking.ScopeCheckId.SubstitutionPrefix';

    public constructor(
        protected override unit: UnitService,
        protected override deckingUnitsHelperService: DeckingUnitsHelperService,
        protected override localization: LocalizationService
    ) {
        super(unit, deckingUnitsHelperService, localization);
    }

    public zoneHasFailingScopeChecks(zone: SubstitutionZoneModel): boolean {
        if (!zone) return false;
        return ((zone.zoneSubstituted?.result?.failedScopeChecks.length > 0) || (zone.zoneSpecified?.result?.failedScopeChecks.length > 0));
    }

    public zoneHasCriticalScopeChecks(zone: SubstitutionZoneModel): boolean {
        if (!zone) return false;
        return (zone.zoneSubstituted?.result?.failedScopeChecks.some((scope) => scope.severity === ScopeCheckSeverity.Critical) || zone.zoneSpecified?.result?.failedScopeChecks.some((scope) => scope.severity === ScopeCheckSeverity.Critical));
    }

    public areaHasFailingScopeChecks(area: SubstitutionAreaModel): boolean {
        if (!area) return false;
        return area.zones.some(zone => this.zoneHasFailingScopeChecks(zone));
    }

    public areaHasCriticalScopeChecks(area: SubstitutionAreaModel): boolean {
        if (!area) return false;
        return area.zones.some(zone => this.zoneHasCriticalScopeChecks(zone));
    }

    public getAreaCriticalScopeChecksZones(area$: Observable<SubstitutionAreaModel>): Observable<string[]> {
        return area$.pipe(
            map((area: SubstitutionAreaModel) => {
                const failingZones: string[] = [];
                area.zones.forEach((zone) => {
                    if (this.zoneHasCriticalScopeChecks(zone))
                        failingZones.push(zone.id);
                });
                return failingZones;
            }),
            distinctUntilChanged()
        );
    }

    public getAreaFailingScopeChecksMessages(area: SubstitutionAreaModel, currentSettingsLengthUnit: Unit, definitionOfSidelapConnectors: DefinitionOfSidelapConnectors, frameFastener: number) {
        let response: {
            scopeCheckMessages: IDeckingMessage[];
            criticalMessages: IDeckingMessage[];
        } = { scopeCheckMessages: [], criticalMessages: [] };

        if (!area) return response;
        area.zones.forEach(zone => {            
            if (zone.zoneSpecified?.result?.failedScopeChecks.length > 0) {
                zone.zoneSpecified.result.failedScopeChecks.forEach((scopeCheck) => {
                    response = this.getSpecifiedScopeCheckMessage(response, area, zone, scopeCheck, currentSettingsLengthUnit, definitionOfSidelapConnectors, frameFastener);
                });
            }

            if (zone.zoneSubstituted?.result?.failedScopeChecks.length > 0) {
                zone.zoneSubstituted.result.failedScopeChecks.forEach((scopeCheck) => {
                    response = this.getScopeCheckMessage(response, area, zone, scopeCheck, currentSettingsLengthUnit, definitionOfSidelapConnectors, frameFastener);
                });
            }
        });
        return response;
    }

    private getSpecifiedScopeCheckMessage(response: { scopeCheckMessages: IDeckingMessage[]; criticalMessages: IDeckingMessage[] }, area: SubstitutionAreaModel, zone: SubstitutionZoneModel, scopeCheck: ScopeCheckResult, currentSettingsLengthUnit: Unit, definitionOfSidelapConnectors: DefinitionOfSidelapConnectors, frameFastener: number) { 
        const message = this.translationText(this.specifiedPrefixKey) + this.substituteTextProperties(this.translationText(this.getKey(scopeCheck.scopeCheckTranslationId.value, definitionOfSidelapConnectors, frameFastener, currentSettingsLengthUnit)), scopeCheck, currentSettingsLengthUnit, area.name.value, zone.name.value);
        
        response.scopeCheckMessages.push({
            Type: MessageType.Basic,
            Title: scopeCheck.scopeCheckTranslationId.value,
            Text: message,
            ButtonText: null,
            ButtonClick: null,
            disabled$: of(false)
        } as IDeckingMessage);

        return response;
    }

    private getScopeCheckMessage(response: { scopeCheckMessages: IDeckingMessage[]; criticalMessages: IDeckingMessage[] }, area: SubstitutionAreaModel, zone: SubstitutionZoneModel, scopeCheck: ScopeCheckResult, currentSettingsLengthUnit: Unit, definitionOfSidelapConnectors: DefinitionOfSidelapConnectors, frameFastener: number) {
        if (scopeCheck.severity === ScopeCheckSeverity.Critical) {
            response.criticalMessages.push({
                Type: MessageType.Warning,
                Title: scopeCheck.scopeCheckTranslationId.value,
                Text: this.substituteTextProperties(this.translationText(this.getKey(scopeCheck.scopeCheckTranslationId.value, definitionOfSidelapConnectors, frameFastener, currentSettingsLengthUnit)), scopeCheck, currentSettingsLengthUnit, area.name.value, zone.name.value),
                ButtonText: null,
                ButtonClick: null,
                disabled$: of(false)
            } as IDeckingMessage);
        } else {
            response.scopeCheckMessages.push({
                Type: scopeCheck.severity === ScopeCheckSeverity.Info ? MessageType.Basic : MessageType.Warning,
                Title: scopeCheck.scopeCheckTranslationId.value,
                Text: this.substituteTextProperties(this.translationText(this.getKey(scopeCheck.scopeCheckTranslationId.value, definitionOfSidelapConnectors, frameFastener, currentSettingsLengthUnit)), scopeCheck, currentSettingsLengthUnit, area.name.value, zone.name.value),
                ButtonText: null,
                ButtonClick: null,
                disabled$: of(false)
            } as IDeckingMessage);
        }

        return response;
    }

    public hasRequiredInformationAreaValidation(area: SubstitutionAreaModel): boolean {
        return area?.zones.some(zone => {
            return this.hasUncalculatedZonesValidation(zone) || this.hasUnselectedRequiredFieldsValidation(zone);
        });
    }

    public hasUncalculatedZonesValidation(zone: SubstitutionZoneModel): boolean {
        return !zone.zoneSpecified.result?.isDesignCorrect && !zone.zoneSubstituted.result?.isDesignCorrect;
    }

    public hasUnselectedRequiredFieldsValidation(zone: SubstitutionZoneModel): boolean {
        return zone.zoneSpecified.deckGauge == null
            || zone.zoneSubstituted.frameFastener == null
            || zone.zoneSubstituted.sidelapConnector == null
            || zone.zoneSubstituted.pattern == null
            || zone.zoneSubstituted.side == null
            || zone.zoneSubstituted.deckGauge == null
            || zone.zoneSubstituted.frameFastener == null
            || zone.zoneSubstituted.sidelapConnector == null
            || zone.zoneSubstituted.pattern == null
            || zone.zoneSubstituted.side == null;
    }

    public hasDeckGaugeUnselectedValidation(zone: SubstitutionZoneModel): boolean {
        return zone.zoneSpecified.deckGauge == null;
    }

    /**
     * Results in substituted row should be greater or equal than specified ones
     * @param zone 
     * @returns 1 if greater, 0 if equal, -1 if lesser
     */
    public compareZoneResultsValidation(zone: SubstitutionZoneModel) {
        const { zoneSubstituted, zoneSpecified } = zone;

        const qComparison = zoneSubstituted.q.value - zoneSpecified.q.value;
        const wComparison = zoneSubstituted.w.value - zoneSpecified.w.value;
        const gComparison = zoneSubstituted.g.value - zoneSpecified.g.value;

        if (qComparison > 0 && wComparison > 0 && gComparison > 0) {
            return 1;
        } else if (qComparison < 0 || wComparison < 0 || gComparison < 0) {
            return -1;
        } else {
            return 0;
        }
    }
}
