import { Component, ElementRef, Input } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { includeSprites } from 'src/decking/sprites';

@Component({
  templateUrl: './zone-orientation.component.html',
  styleUrls: ['./zone-orientation.component.scss']
})
export class ZoneOrientationComponent {

  @Input()
  public modalInstance!: ModalInstance;

  constructor(private elementRef: ElementRef<HTMLElement>) {
    includeSprites(this.elementRef.nativeElement,
      'sprite-zone-x',
      'sprite-zone-y',
    );
  }

  public close(): void {
    this.modalInstance.close();
  }
}
