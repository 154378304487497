import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DeckingSubstitution } from '../../entities/decking-substitution/decking-substitution';
import { ApiService } from '../external/api.service';
import { FeatureVisibilityService } from '../external/feature-visibility.service';
import { ErrorHandlerService } from '../decking-error-handler/error-handler.service';
import { LocalizationService } from '../external/localization.service';
import { LoggerService } from '../external/logger.service';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../environments/environmentDecking';
import { BaseSignalrService } from './base-signalr';
import { Substitute } from '../../entities/decking-substitution/calculation/substitute';
import { CommonUserSettingsService } from '../external/common-user-settings.service';

@Injectable({
    providedIn: 'root'
})
export class DeckingSubstitutionSignalrService extends BaseSignalrService {

    substitutionDesignCalculated$: Observable<DeckingSubstitution>;
    substitutionDesignSaved$: Observable<DeckingSubstitution>;

    protected substitutionDesignCalculated = new Subject<DeckingSubstitution>();
    protected substitutionDesignSaved = new Subject<DeckingSubstitution>();

    constructor(
        protected override apiService: ApiService,
        protected override zone: NgZone,
        protected override featureVisibilityService: FeatureVisibilityService,
        protected override ErrorHandlerService: ErrorHandlerService,
        protected override localizationService: LocalizationService,
        protected override userSettings: CommonUserSettingsService,
        protected override loggerService: LoggerService
    ) {
        super(apiService, zone, featureVisibilityService, ErrorHandlerService, localizationService, userSettings, loggerService);
        this.substitutionDesignCalculated$ = this.substitutionDesignCalculated.asObservable();
        this.substitutionDesignSaved$ = this.substitutionDesignSaved.asObservable();
    }

    public init(): void {
        try {
            if (this.deckingEnabled && !this.connection) {
                // connection options
                const options = {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                };

                // connection creation
                this.connection = new signalR.HubConnectionBuilder()
                    .withUrl(`${environment.deckingDesignServiceSignalRUrl}substitutionHub`, options)
                    .configureLogging(signalR.LogLevel.Warning)
                    .build();

                // subscribing to client function invocation from server
                this.connection.on('OnSubstitutionSaved', (design: DeckingSubstitution) => this.onSubstitutionSaved(design));
                this.connection.on('OnSaveSubstitutionFailed', (design: DeckingSubstitution) => this.onSubstitutionDesignFailed(design));
                this.connection.on('OnSpecified', (design: DeckingSubstitution) => this.onSpecified(design));
                this.connection.on('OnSpecifyFailed', (design: DeckingSubstitution) => this.onSubstitutionDesignFailed(design));
                this.connection.on('OnSubstituted', (design: DeckingSubstitution) => this.onSubstituted(design));
                this.connection.on('OnSubstituteFailed', (design: DeckingSubstitution) => this.onSubstitutionDesignFailed(design));
            }
        } catch (e) {
            console.error(e);
        }
    }


    public async specifyDesign(deckingSubstitution: DeckingSubstitution): Promise<void> {
        try {
            await this.checkConnection(deckingSubstitution.id, 'SubscribeToSubstitution');
            await this.connection.send('Specify', deckingSubstitution);
        }
        catch (e) {
            let title = '';
            let message = '';
            [title, message] = this.getSignalRErrorFieldValues();
            this.ErrorHandlerService.showDesignErrorModal(title, message, undefined, 'Specify');
            throw e;
        }
    }

    public async saveSubstitution(deckingSubstitution: DeckingSubstitution): Promise<void> {
        try {
            await this.checkConnection(deckingSubstitution.id, 'SubscribeToSubstitution');
            await this.connection.send('SaveSubstitution', deckingSubstitution);
        }
        catch (e) {
            let title = '';
            let message = '';
            [title, message] = this.getSignalRErrorFieldValues();
            this.ErrorHandlerService.showDesignErrorModal(title, message, undefined, 'saveSubstitution');
            throw e;
        }
    }

    public async substituteDesign(substitute: Substitute): Promise<void> {
        try {
            await this.checkConnection(substitute.substitutionId, 'SubscribeToSubstitution');
            await this.connection.send('Substitute', substitute);
        }
        catch (e) {
            let title = '';
            let message = '';
            [title, message] = this.getSignalRErrorFieldValues();
            this.ErrorHandlerService.showDesignErrorModal(title, message, undefined, 'substituteDesign');
            throw e;
        }
    }

    private onSubstitutionSaved = (deckingDesignSaved: DeckingSubstitution) => {
        // Explicitly run on angular because signal r library runs the code outside the angular zone (see https://angular.io/guide/zone)
        this.zone.run(() => {
            this.substitutionDesignSaved.next(deckingDesignSaved);
        });
    };

    private onSpecified = (deckingDesignSaved: DeckingSubstitution) => {
        this.updateDesignState(deckingDesignSaved);
    };

    private onSubstituted = (deckingDesignSaved: DeckingSubstitution) => {
        this.updateDesignState(deckingDesignSaved);
    };

    private onSubstitutionDesignFailed = (deckingDesignSaved: DeckingSubstitution) => {
        let title = '';
        let message = '';
        [title, message] = this.getSignalRErrorFieldValues();
        this.updateDesignState(deckingDesignSaved);
        this.ErrorHandlerService.showDesignErrorModal(title, message, undefined, 'OnSubstitutionDesignFailed');
    };

    private updateDesignState = (deckingDesignSaved: DeckingSubstitution) => {
        // Explicitly run on angular because signal r library runs the code outside the angular zone (see https://angular.io/guide/zone)
        this.zone.run(() => {
            this.substitutionDesignCalculated.next(deckingDesignSaved);
        });
    };

}
