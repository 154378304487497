import { Injectable } from '@angular/core';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    PaperSize, ReportType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import { CommonRegion as RegionCodelist } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { DesignSettings } from './../../../entities/settings/design-settings';
import { IDeckingUserSettings, IUserSettingDocument, IUserSettingRegion, IUserUnitSettingDocument } from './../../../entities/settings/decking-user-settings';
import { CommonCodeListService } from './../../../services/external/common-code-list.service';
import { LocalizationService } from  './../../../services/external/localization.service';
import { DeckingUserSettingsService } from  './../../../services/decking-user-settings/user-settings.service';
import { DeckingDocumentService } from  './../../../services/decking-document/decking-document.service';
import { IDeckingDocument } from './../../../entities/decking-design/decking-document';
import { Region } from './../../../entities/decking-code-list/enums/region';
import { DesignMethod } from './../../../entities/decking-code-list/enums/design-method';
import { DesignMethodConverter } from './../../../entities/decking-code-list/enums/helpers/design-method-converter';
import { RelevantLoadsConverter } from './../../../entities/decking-code-list/enums/helpers/relevant-loads-converter';
import { RelevantLoads } from './../../../entities/decking-code-list/enums/relevant-loads';
import { SidelapsSpacingSettings } from './../../../entities/settings/sidelaps/sidelaps-spacing-settings';
import { SidelapsNumberSettings } from './../../../entities/settings/sidelaps/sidelaps-number-settings';
import { DefinitionOfSidelapConnectors } from './../../../entities/decking-code-list/enums/definition-sidelap-connectors';
import { DeckingReport } from './../../../entities/decking-design/decking-report-info';
import { IDeckingValue } from './../../../entities/decking-design/decking-value';
import { SharedEnvironmentService } from '../../external/shared-environment.service';
import { DesignStandard } from 'src/decking/entities/decking-code-list/enums/design-standard';
import { DesignStandardConverter } from 'src/decking/entities/decking-code-list/enums/helpers/design-standard-converter';
@Injectable({
    providedIn: 'root'
})
// This class is used to set all default values for Decking when a new design is created.
export class BaseDefaultFactory {
    public static DEFAULT_JOIST_SPACING_IN_MM_US = 1220;
    public static DEFAULT_JOIST_SPACING_IN_MM_CA = 1219.2;
    public static DEFAULT_JOIST_THICKNESS_IN_MM_US = 1.5;
    public static DEFAULT_JOIST_THICKNESS_IN_MM_CA = 1.5;
    public static SUBSTITUTION_DEFAULT_JOIST_THICKNESS_IN_MM_US = 6.35;
    public static SUBSTITUTION_DEFAULT_JOIST_THICKNESS_IN_MM_CA = 6.35;
    public static DEFAULT_CONCRETE_FILL_THICKNESS_IN_MM_US = 63.5;
    public static DEFAULT_CONCRETE_FILL_THICKNESS_IN_MM_CA = 64;
    public static DEFAULT_ROOF_DECKPANEL_ID = 25;
    public static readonly DESIGN_CURRENT_VERSION = '2024-01-07';
    public static readonly SUBSTITUTION_CURRENT_VERSION = '2024-01-07';

    constructor(
        protected userSettingsService: DeckingUserSettingsService,
        protected localization: LocalizationService,
        protected document: DeckingDocumentService,
        protected commonCodeList: CommonCodeListService,
        protected sharedEnvironmentService: SharedEnvironmentService,
    ) { }

    protected buildSettings(settings: IDeckingUserSettings): DesignSettings {
        return {
            region: this.getRegion(settings.region),
            designMethod: this.getDesignMethod(settings.designMethod),
            designStandard: this.getDesignStandard(settings.designStandard),
            relevantLoads: this.getRelevantLoads(settings.relevantLoads),
            sidelapsNumberSettings: this.getSidelapsNumberSettings(settings.sidelapsNumber),
            sidelapsSpacingSettings: this.getSidelapsSpacingSettings(settings.sidelapsSpacing),
            definitionOfSidelapConnectors: this.getDefinitionOfSidelapConnectors(settings.definitionOfSidelapConnectors),
            length: this.getDeckingDocumentByUnit(settings.length),
            area: this.getDeckingDocumentByUnit(settings.area),
            stress: this.getDeckingDocumentByUnit(settings.stress),
            moment: this.getDeckingDocumentByUnit(settings.moment),
            force: this.getDeckingDocumentByUnit(settings.force),
            forcePerLength: this.getDeckingDocumentByUnit(settings.forcePerLength),
            momentPerLength: this.getDeckingDocumentByUnit(settings.momentPerLength),
            shearStiffness: this.getDeckingDocumentByUnit(settings.shearStiffness),
            requiredShearStiffness: settings.requiredShearStiffness,
            requiredUpliftSubmittal: settings.requiredUpliftSubmittal,
            substitutionRequiredShearStiffness: settings.substitutionRequiredShearStiffness,
            substitutionRequiredUpliftSubmittal: settings.substitutionRequiredUpliftSubmittal,
            substitutionWindAndSeismicLoadsAtZoneLevel: settings.substitutionWindAndSeismicLoadsAtZoneLevel,
            calculateIntegration: settings.calculateIntegration,
            windAndSeismicLoadsAtZoneLevel: settings.windAndSeismicLoadsAtZoneLevel,
            isImperial: { value: settings?.isImperial?.value ?? settings.region.index === 1 },
            wasteFactor: { value: settings?.wasteFactor?.value ?? 5 }
        };
    }

    protected getRegion(regionSettings: IUserSettingRegion): IDeckingDocument<Region, string> {
        const codeLists = this.commonCodeList.commonCodeLists[CommonCodeList.Region] as RegionCodelist[];
        const settingsRegion = codeLists.find((row) => row.countryCode === regionSettings.countryCode);
        return {
            id: settingsRegion.id,
            index: regionSettings.index,
            value: regionSettings.value
        };
    }

    public getSettingsRegion(region: IDeckingDocument<Region, string>): IUserSettingRegion {
        const deckingSettings = this.userSettingsService.deckingSettingsCollection;
        return deckingSettings.region.find(row => row.index === region.index);
    }

    public getRegionById(designSettingsRegionId: number) {
        const settingsRegion = (this.commonCodeList.commonCodeLists[CommonCodeList.Region] as RegionCodelist[]).find((row) => row.id === designSettingsRegionId);
        const userSettingRegion = this.userSettingsService.deckingSettingsCollection.regionDefault.find(e => e.region.countryCode === settingsRegion.countryCode).region;
        return {
            id: settingsRegion.id,
            index: userSettingRegion.index,
            value: userSettingRegion.value
        };
    }

    public createDeckingDesignName(projectId: string, isSubstitutionBuilder = false) {
        const project = this.document.findProjectById(projectId);
        const sufixName = isSubstitutionBuilder ? this.localization.getString('Agito.Hilti.Profis3.Decking.Substitution.ShortName') : this.localization.getString('Agito.Hilti.Profis3.Decking.QuickStart.DesignType');
        let name = sufixName + ' - ' + this.localization.moment(new Date()).format('ll');

        if (project != null) {
            const projectForName = project.parentId != null
                ? this.document.findProjectById(project.parentId)
                : project;
            name = this.document.createUniqueName(name, Object.values(projectForName.designs).map((item) => item.designName));
        }

        return name;
    }

    protected getDesignMethod(designMethodSettings: IUserSettingDocument): IDeckingDocument<DesignMethod, string> {
        return {
            id: DesignMethodConverter.getDesignMethodFromString(designMethodSettings.value),
            index: designMethodSettings.index,
            value: designMethodSettings.value
        };
    }
    protected getDesignStandard(designStandardSettings: IUserSettingDocument): IDeckingDocument<DesignStandard, string> {
        return {
            id: DesignStandardConverter.getDesignStandardFromString(designStandardSettings.value),
            index: designStandardSettings.index,
            value: designStandardSettings.value
        };
    }

    protected getRelevantLoads(relevantLoadsSettings: IUserSettingDocument): IDeckingDocument<RelevantLoads, string> {
        return {
            id: RelevantLoadsConverter.getRelevantLoadFromString(relevantLoadsSettings.index),
            index: relevantLoadsSettings.index,
            value: relevantLoadsSettings.value
        };
    }

    protected getSidelapsSpacingSettings(sidelapsSpacing: SidelapsSpacingSettings): SidelapsSpacingSettings {
        return sidelapsSpacing;
    }

    protected getSidelapsNumberSettings(sidelapsNumber: SidelapsNumberSettings): SidelapsNumberSettings {
        return sidelapsNumber;
    }

    protected getDefinitionOfSidelapConnectors(definitionOfSidelapSettings: IUserSettingDocument): IDeckingDocument<DefinitionOfSidelapConnectors, string> {
        return {
            id: definitionOfSidelapSettings.index,
            value: definitionOfSidelapSettings.value,
            index: definitionOfSidelapSettings.index
        };
    }

    protected getDefaultReportInfo(): DeckingReport {
        return {
            projectTitle: { value: '' } as IDeckingValue<string>,
            projectNumber: { value: '' } as IDeckingValue<string>,
            structuralPlanSheetNumber: { value: '' } as IDeckingValue<string>,
            checkedBy: { value: '' } as IDeckingValue<string>,
            preparedBy: { value: '' } as IDeckingValue<string>,
            reportComments: { value: '' } as IDeckingValue<string>,

            selectedAreas: [],

            company: { value: '' } as IDeckingValue<string>,
            address: { value: '' } as IDeckingValue<string>,
            contactPerson: { value: '' } as IDeckingValue<string>,
            phoneNumber: { value: '' } as IDeckingValue<string>,
            email: { value: '' } as IDeckingValue<string>,
            startPageNumbering: { value: 1 } as IDeckingValue<number>,

            date: { value: new Date().toLocaleDateString() } as IDeckingValue<string>,
            version: { value: this.sharedEnvironmentService.data?.applicationVersion } as IDeckingValue<string>,
            logo: { value: '' } as IDeckingValue<string>,
            faxNumber: { value: '' } as IDeckingValue<string>,
            paperSize: { value: PaperSize.Letter } as IDeckingValue<PaperSize>,
            removeProjectHeader: { value: false } as IDeckingValue<boolean>,
            templateOptions: [],

            templateIdSelected: { value: 0 } as IDeckingValue<number>,
            reportLanguageId: { value: 1033 } as IDeckingValue<number>,

            reportType: { value: ReportType.Short },
        } as DeckingReport;
    }

    protected getDeckingDocumentByUnit(unitString: IUserUnitSettingDocument): IDeckingDocument<Unit, string> {
        return {
            id: unitString.unitValue,
            value: unitString.value,
            index: unitString.index
        };
    }
}
