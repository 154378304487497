import { Injectable } from '@angular/core';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { DefinitionOfSidelapConnectors } from './../../entities/decking-code-list/enums/definition-sidelap-connectors';
import { AreaModel } from './../../entities/decking-design/area-model';
import { IDeckingMessage } from './../../entities/decking-design/decking-message';
import { ScopeCheckSeverity } from './../../entities/decking-design/scope-check-result';
import { ZoneModel } from './../../entities/decking-design/zone-model';
import { MessageType } from './../../entities/enums/message-types';
import { DeckingUnitsHelperService } from './../../services/decking-units-helper/decking-units-helper.service';
import { LocalizationService } from './../../services/external/localization.service';
import { UnitService } from './../../services/external/unit.service';
import { BaseScopeChecksService } from './base-scope-checks';

@Injectable({
    providedIn: 'root'
})
export class DeckingScopeChecksService extends BaseScopeChecksService {

    protected constructor(
        protected override unit: UnitService,
        protected override  deckingUnitsHelperService: DeckingUnitsHelperService,
        protected override  localization: LocalizationService
    ) {
        super(unit, deckingUnitsHelperService, localization);
    }

    public zoneHasFailingScopeChecks(zone: ZoneModel): boolean {
        if (!zone) return false;
        return zone.result && zone.result.failedScopeChecks.length > 0;
    }

    public zoneHasCriticalScopeChecks(zone: ZoneModel): boolean {
        if (!zone) return false;
        return zone.result?.failedScopeChecks.some((scope) => scope.severity === ScopeCheckSeverity.Critical);
    }

    public areaHasFailingScopeChecks(area: AreaModel): boolean {
        if (!area) return false;
        return area.zones.some(zone => this.zoneHasFailingScopeChecks(zone));
    }

    public areaHasCriticalScopeChecks(area: AreaModel): boolean {
        if (!area) return false;
        return area.zones.some(zone => this.zoneHasCriticalScopeChecks(zone));
    }

    public getAreaCriticalScopeChecksZones(area$: Observable<AreaModel>): Observable<string[]> {
        return area$.pipe(
            map((area: AreaModel) => {
                const failingZones: string[] = [];
                area.zones.forEach((zone) => {
                    if (this.zoneHasCriticalScopeChecks(zone))
                        failingZones.push(zone.id);
                });
                return failingZones;
            }),
            distinctUntilChanged()
        );
    }

    public getAreaFailingScopeChecksMessages(area: AreaModel, currentSettingsLengthUnit: Unit, definitionOfSidelapConnectors: DefinitionOfSidelapConnectors, frameFastener: number) {
        const response: {
            scopeCheckMessages: IDeckingMessage[];
            criticalMessages: IDeckingMessage[];
        } = { scopeCheckMessages: [], criticalMessages: [] };

        if (!area) return response;
        area.zones.forEach(zone => {
            if (zone.result && zone.result.failedScopeChecks.length > 0) {
                zone.result.failedScopeChecks.forEach((scopeCheck) => {
                    if (scopeCheck.severity === ScopeCheckSeverity.Critical) {
                        response.criticalMessages.push({
                            Type: MessageType.Warning,
                            Title: scopeCheck.scopeCheckTranslationId.value,
                            Text: this.substituteTextProperties(this.translationText(this.getKey(scopeCheck.scopeCheckTranslationId.value, definitionOfSidelapConnectors, frameFastener, currentSettingsLengthUnit)), scopeCheck, currentSettingsLengthUnit, area.name.value, zone.name.value),
                            ButtonText: null,
                            ButtonClick: null,
                            disabled$: of(false)
                        });
                    } else {
                        response.scopeCheckMessages.push({
                            Type: scopeCheck.severity === ScopeCheckSeverity.Info ? MessageType.Basic : MessageType.Warning,
                            Title: scopeCheck.scopeCheckTranslationId.value,
                            Text: this.substituteTextProperties(this.translationText(this.getKey(scopeCheck.scopeCheckTranslationId.value, definitionOfSidelapConnectors, frameFastener, currentSettingsLengthUnit)), scopeCheck, currentSettingsLengthUnit, area.name.value, zone.name.value),
                            ButtonText: null,
                            ButtonClick: null,
                            disabled$: of(false)
                        });
                    }

                });
            }
        });
        return response;
    }
}
