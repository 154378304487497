<decking-collapsable-striped-title buttonId="reportModal-layoutSec-openButton">
    <span collapsable-title id="reportModal-layoutSec-title"
        l10n="Agito.Hilti.Profis3.Decking.Report.Title.Layout"></span>
    <div collapsable-content>
        <div class="row">
            <div class="col">
                <div class="field mb-3">
                    <label l10n="Agito.Hilti.Profis3.Decking.Report.Layout.Template"></label>
                    <div class="row">
                        <div class="col">
                            <pe-dropdown [attr.id]="'reportModal-layoutSec-template'" class="select-template"
                                [items]="templateDropdown.items" [selectedValue]="reportInfo.templateIdSelected.value"
                                (selectedValueChange)="onTemplateSelectedValueChange($any($event).detail)"
                                [validators]="templateDropdown.validators">
                            </pe-dropdown>
                        </div>
                        <div class="col">
                            <button class="button button-default edit-template" type="button"
                                id="reportModal-layoutSec-editButton" l10n="Agito.Hilti.Profis3.ReportTemplates.Edit"
                                (click)="openReportTemplates()">
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="reportInfo.templateIdSelected.value !== 0; else elseTemplate">
                <div class="col bordered-column">
                    <p class="field">
                        <label l10n="Agito.Hilti.Profis3.ExportReport.Layout.Company"></label>
                        <span class="template-data">{{reportInfo.company.value}}</span>
                    </p>
                    <p class="field">
                        <label l10n="Agito.Hilti.Profis3.ExportReport.Layout.Address"></label>
                        <span>{{reportInfo.address.value}}</span>
                    </p>
                    <p class="field">
                        <label l10n="Agito.Hilti.Profis3.ExportReport.Layout.ContactPerson"></label>
                        <span>{{reportInfo.contactPerson.value}}</span>
                    </p>
                    <p class="field">
                        <label l10n="Agito.Hilti.Profis3.ExportReport.Layout.PhoneNumber"></label>
                        <span>{{reportInfo.phoneNumber.value}}</span>
                    </p>
                    <p class="field">
                        <label l10n="Agito.Hilti.Profis3.ExportReport.Layout.Email"></label>
                        <span>{{reportInfo.email.value}}</span>
                    </p>
                    <p class="field">
                        <label l10n="Agito.Hilti.Profis3.Decking.Layout.PaperSize"></label>
                        <ng-container [ngSwitch]="reportInfo.paperSize.value">
                            <span l10n="Agito.Hilti.Profis3.CodeList.ReportPaperSizeEntity.A4.Description"
                                *ngSwitchCase="1"></span>
                            <span l10n="Agito.Hilti.Profis3.CodeList.ReportPaperSizeEntity.Letter.Description"
                                *ngSwitchCase="2"></span>
                        </ng-container>
                    </p>
                    <p class="field">
                        <label l10n="Agito.Hilti.Profis3.ExportReport.Layout.ReportType"></label>
                        <decking-report-template-display
                            [reportTemplateType]="reportTypeFromTemplate"></decking-report-template-display>
                        <br><label [ngClass]="{'text-danger': !(reportInfo.reportType.value) }"
                            *ngIf="!isReportTypeSupported"
                            l10n="Agito.Hilti.Profis3.Decking.Report.Layout.ReportType.NotSupported"></label>
                    </p>
                </div>
            </ng-container>
            <ng-template #elseTemplate>
                <div class="col bordered-column">
                    <p class="field">
                        <label l10n="Agito.Hilti.Profis3.Decking.Report.Layout.Company">
                        </label>
                        <pe-text-box SelectTextOnClick [maxLength]="120" [attr.id]="'reportModal-layoutSec-company'"
                            [value]="reportInfo.company.value"
                            (valueChange)="reportInfo.company.value=$any($event).detail">
                        </pe-text-box>
                    </p>
                    <p class="field">
                        <label l10n="Agito.Hilti.Profis3.Decking.Report.Layout.Address">
                        </label>
                        <pe-text-box SelectTextOnClick [maxLength]="120" [attr.id]="'reportModal-layoutSec-address'"
                            [value]="reportInfo.address.value"
                            (valueChange)="reportInfo.address.value=$any($event).detail">
                        </pe-text-box>
                    </p>
                    <p class="field">
                        <label l10n="Agito.Hilti.Profis3.Decking.Report.Layout.ContactPerson">
                        </label>
                        <pe-text-box SelectTextOnClick [maxLength]="120" [attr.id]="'reportModal-layoutSec-contact'"
                            [value]="reportInfo.contactPerson.value"
                            (valueChange)="reportInfo.contactPerson.value=$any($event).detail">
                        </pe-text-box>
                    </p>
                    <p class="field">
                        <label l10n="Agito.Hilti.Profis3.Decking.Report.Layout.PhoneNumber">
                        </label>
                        <pe-text-box SelectTextOnClick [maxLength]="120" [attr.id]="'reportModal-layoutSec-phone'"
                            [value]="reportInfo.phoneNumber.value"
                            (valueChange)="reportInfo.phoneNumber.value=$any($event).detail">
                        </pe-text-box>
                    </p>
                    <p class="field">
                        <label l10n="Agito.Hilti.Profis3.Decking.Report.Layout.Email">
                        </label>
                        <pe-text-box SelectTextOnClick [maxLength]="120" [attr.id]="'reportModal-layoutSec-email'"
                            [value]="reportInfo.email.value" (valueChange)="reportInfo.email.value=$any($event).detail"
                            [validators]="[emailValidator]">
                        </pe-text-box>
                    </p>
                </div>
            </ng-template>

            <div class="col">
                <p class="field" *ngIf="isReportTypeAvailable()">
                    <pe-dropdown
                        class="reportType-dropdown control-row control control-container"
                        [attr.id]="'reportModal-layoutSec-reportType'"
                        [title]="'Agito.Hilti.Profis3.Decking.Report.Layout.ReportType' | l10n:localization.selectedLanguage"
                        [items]="reportTypesDropdown.items"
                        [selectedValue]="reportInfo.reportType.value"
                        (selectedValueChange)="reportInfo.reportType.value=$any($event).detail"
                        [validators]="[requiredValidator]">
                    </pe-dropdown>
                </p>
                <ng-container *ngIf="reportInfo.templateIdSelected.value === 0">
                    <p class="field">
                        <pe-dropdown 
                            [disabled]=true  
                            class="paperSize-dropdown control-row" 
                            [attr.id]="'reportModal-layoutSec-paperSize'"
                            [title]="'Agito.Hilti.Profis3.Decking.Layout.PaperSize' | l10n:localization.selectedLanguage"
                            [items]="paperSizeDropdown.items" 
                            [selectedValue]="reportInfo.paperSize.value"
                            (selectedValueChange)="reportInfo.paperSize.value=$any($event).detail">
                        </pe-dropdown>
                    </p>
                </ng-container>
                <p class="field">
                    <pe-dropdown 
                        *ngIf="languageDropdown?.items" 
                        class="language-dropdown control-row"
                        [attr.id]="'reportModal-layoutSec-language'"
                        [title]="'Agito.Hilti.Profis3.Decking.Report.Layout.Language' | l10n:localization.selectedLanguage"
                        [items]="languageDropdown.items" 
                        [selectedValue]="reportInfo.reportLanguageId.value"
                        (selectedValueChange)="reportInfo.reportLanguageId.value=$any($event).detail"
                        [validators]="languageDropdown.validators">
                    </pe-dropdown>
                </p>
                <p class="field">
                    <pe-dropdown 
                        class="units-dropdown control-row" 
                        [attr.id]="'reportModal-layoutSec-units'"
                        [title]="'Agito.Hilti.Profis3.DesignSettings.Units' | l10n:localization.selectedLanguage"
                        [items]="unitsDropdown.items" 
                        [selectedValue]="useSIUnits"
                        (selectedValueChange)="setUnitsDropdownValue($any($event).detail);">
                    </pe-dropdown>
                </p>
                <div id="start_page_numbering_container">
                    <p class="field">
                        <pe-numeric-text-box 
                            SelectTextOnClick 
                            [attr.id]="'reportModal-layoutSec-startPage'"
                            [title]="'Agito.Hilti.Profis3.Decking.Report.Layout.StartPageNumbering' | l10n:localization.selectedLanguage"
                            [defaultStepperValue]="1" 
                            [value]="reportInfo.startPageNumbering.value"
                            (valueChange)="reportInfo.startPageNumbering.value=$any($event).detail">
                        </pe-numeric-text-box>
                    </p>
                </div>
                <p class="field" id="decking_report_layout_format" *ngIf="deckingMainService.getSelectedModeType() != deckingDesignModeType.SubstitutionBuilderMode">
                    <label id="reportModal-layoutSec-formatLabel"
                        l10n="Agito.Hilti.Profis3.Decking.Report.Layout.Format">
                    </label>
                    <span id="reportModal-layoutSec-formatDescription">
                        <span class="inline" 
                            l10n="Agito.Hilti.Profis3.Decking.Report.Layout.ReportType">
                        </span> 
                        =
                        <decking-report-template-display
                            [reportTemplateType]="reportInfo.reportType.value">
                        </decking-report-template-display>
                    </span>
                </p>
            </div>
        </div>
    </div>
</decking-collapsable-striped-title>