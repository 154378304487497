<decking-collapsable-striped-title buttonId="reportModal-detailsSec-openButton">
    <span collapsable-title id="reportModal-detailsSec-title"
        l10n="Agito.Hilti.Profis3.Decking.Report.Title.Details"></span>
    <div collapsable-content id="decking_report_options_details">
        <div class="row">
            <div class="col">
                <p class="field">
                    <label l10n="Agito.Hilti.Profis3.Decking.Report.Details.ProjectTitle">
                    </label>
                    <pe-text-box 
                        [maxLength]="120" 
                        [attr.id]="'reportModal-detailsSec-projectTitle'"
                        [value]="reportInfo.projectTitle.value" 
                        (valueChange)="reportInfo.projectTitle.value=$any($event).detail">
                    </pe-text-box>
                </p>
            </div>
            <div class="col">
                <p class="field">
                    <label l10n="Agito.Hilti.Profis3.Decking.Report.Details.ProjectNumber">
                    </label>
                    <pe-text-box 
                        [maxLength]="120" 
                        [attr.id]="'reportModal-detailsSec-projectNumber'" 
                        [value]="reportInfo.projectNumber.value"
                        (valueChange)="reportInfo.projectNumber.value=$any($event).detail">
                    </pe-text-box>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="field">
                    <label l10n="Agito.Hilti.Profis3.Decking.Report.Details.StructuralPlanSheetNumber">
                    </label>
                    <pe-text-box 
                        [maxLength]="120" 
                        [attr.id]="'reportModal-detailsSec-structuralPlan'"
                        [value]="reportInfo.structuralPlanSheetNumber.value"
                        (valueChange)="reportInfo.structuralPlanSheetNumber.value=$any($event).detail">
                    </pe-text-box>
                </p>
            </div>
            <div class="col">
                <p class="field">
                    <label l10n="Agito.Hilti.Profis3.Decking.Report.Details.PreparedBy">
                    </label>
                    <pe-text-box 
                        [maxLength]="120" 
                        [attr.id]="'reportModal-detailsSec-prepared'" 
                        [value]="reportInfo.preparedBy.value"
                        (valueChange)="reportInfo.preparedBy.value=$any($event).detail">
                    </pe-text-box>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="field">
                    <label l10n="Agito.Hilti.Profis3.Decking.Report.Details.CheckedBy">
                    </label>
                    <pe-text-box 
                        [maxLength]="120" 
                        [attr.id]="'reportModal-detailsSec-checked'" 
                        [value]="reportInfo.checkedBy.value"
                        (valueChange)="reportInfo.checkedBy.value=$any($event).detail">
                    </pe-text-box>
                </p>
            </div>
            <div class="col">
                <p class="field">
                    <label l10n="Agito.Hilti.Profis3.Decking.Report.Details.ReportComments">
                    </label>
                    <textarea [attr.id]="'reportModal-detailsSec-report'" class="notes-input input" rows="4" maxlength="60"
                        [(ngModel)]="reportInfo.reportComments.value"></textarea>
                </p>
            </div>
        </div>
    </div>
</decking-collapsable-striped-title>
