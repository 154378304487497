import { Injectable } from '@angular/core';
import { BaseZones } from './base-zones';
import { DeckingCodeListService } from './../../services/decking-code-list/decking-code-list.service';
import { IndividualZoneModel, SubstitutionZoneModel } from './../../entities/decking-substitution/substitution-zone';
import { NumberComparator } from 'src/decking/helpers/numbers/number-comparator';
import { v4 as uuidv4 } from 'uuid';
import { DeckingDefaultFactoryService } from './../../services/decking-design/factory/decking-default-factory.service';
import { CalculationState } from './../../entities/enums/calculation-state';
import { SubstitutionAreaModel } from './../../entities/decking-substitution/substitution-area';
import { SubstitutionSettings } from './../../entities/settings/substitution-settings';


@Injectable({
  providedIn: 'root'
})
// This service is in charge to provide the Zones of the selected Area using DeckingTabsWrapperService.
export class DeckingSubstitutionZonesService extends BaseZones {

  constructor(
    private deckingCodeListService: DeckingCodeListService
  ) {
    super();
  }

  public areZoneInputsEqual(currentZone: SubstitutionZoneModel, newZone: SubstitutionZoneModel): boolean {
    return currentZone.name.value === newZone.name.value &&
      currentZone.relevantLoads?.value === newZone.relevantLoads?.value &&
      NumberComparator.equal(currentZone.s?.value, newZone.s?.value) &&
      NumberComparator.equal(currentZone.fastenerEstimation?.wasteFactor?.value, newZone.fastenerEstimation?.wasteFactor?.value) &&
      NumberComparator.equal(currentZone.fastenerEstimation?.area?.value, newZone.fastenerEstimation?.area?.value) &&
      NumberComparator.equal(currentZone.fastenerEstimation?.perimeterSpacing?.value, newZone.fastenerEstimation?.perimeterSpacing?.value) &&
      NumberComparator.equal(currentZone.fastenerEstimation?.averageJoistSpacing?.value, newZone.fastenerEstimation?.averageJoistSpacing?.value) &&
      currentZone.isLocked.value === newZone.isLocked.value &&
      NumberComparator.equal(currentZone.zoneSpecified.q?.value, newZone.zoneSpecified.q?.value) &&
      NumberComparator.equal(currentZone.zoneSpecified.w?.value, newZone.zoneSpecified.w?.value) &&
      NumberComparator.equal(currentZone.zoneSpecified.g?.value, newZone.zoneSpecified.g?.value) &&
      currentZone.zoneSpecified.deckGauge?.value === newZone.zoneSpecified.deckGauge?.value &&
      currentZone.zoneSpecified.pattern?.id === newZone.zoneSpecified.pattern?.id &&
      currentZone.zoneSpecified.frameFastener?.id === newZone.zoneSpecified.frameFastener?.id &&
      currentZone.zoneSpecified.sidelapConnector?.id === newZone.zoneSpecified.sidelapConnector?.id &&
      currentZone.zoneSpecified.side?.value === newZone.zoneSpecified.side?.value &&
      currentZone.zoneSpecified.enableLoad === newZone.zoneSpecified.enableLoad &&
      NumberComparator.equal(currentZone.zoneSubstituted.q?.value, newZone.zoneSubstituted.q?.value) &&
      NumberComparator.equal(currentZone.zoneSubstituted.w?.value, newZone.zoneSubstituted.w?.value) &&
      NumberComparator.equal(currentZone.zoneSubstituted.g?.value, newZone.zoneSubstituted.g?.value) &&
      currentZone.zoneSubstituted.deckGauge?.value === newZone.zoneSubstituted.deckGauge?.value &&
      currentZone.zoneSubstituted.pattern?.id === newZone.zoneSubstituted.pattern?.id &&
      currentZone.zoneSubstituted.frameFastener?.id === newZone.zoneSubstituted.frameFastener?.id &&
      currentZone.zoneSubstituted.sidelapConnector?.id === newZone.zoneSubstituted.sidelapConnector?.id &&
      currentZone.zoneSubstituted.side?.value === newZone.zoneSubstituted.side?.value;
  }

  public getDefaultZone(settings: SubstitutionSettings): SubstitutionZoneModel {
    return {
      id: uuidv4(),
      name: { value: 'Zone' },
      relevantLoads: { value: settings.relevantLoads.id },
      // Dimensions
      s: {
        value: DeckingDefaultFactoryService.DEFAULT_JOIST_SPACING_IN_MM_US
      },
      // Fastener Estimation
      fastenerEstimation: {
        area: { value: 0 },
        perimeterSpacing: { value: 0 },
        frameFasteners: { value: 0 },
        sidelapConnectors: { value: 0 },
        averageJoistSpacing: { value: 0 },
        wasteFactor: { value: settings.wasteFactor.value }
      },
      isLocked: { value: false },
      isFastenerEstimationUpdate: false,
      zoneSpecified: this.getDefaultIndividualZone(),
      zoneSubstituted: this.getDefaultIndividualZone(),
      resetAlternatives: true
    };
  }

  protected getDefaultIndividualZone(): IndividualZoneModel {
    return {
      id: uuidv4(),
      // Dimensions
      q: { value: 0 },
      w: { value: 0 },
      g: { value: 0 },
      // Dropdowns
      deckGauge: this.deckingCodeListService.GetDefaultSubstitutionDeckGaugeDropdownItem(),
      pattern: this.deckingCodeListService.GetDefaultPatternDropdownItem(),
      frameFastener: this.deckingCodeListService.GetDefaultFrameFastenerDropdownItem(),
      sidelapConnector: this.deckingCodeListService.GetDefaultSidelapConnectorDropdownItem(),
      side: null,
      // Results
      result: { ...this.getDefaultResult(), calculationStatus: 0 },
      calculationState: CalculationState.Empty,
      isDirty: false,
      panelMomentOfInertia: { value: 0 },
      enableLoad: false
    };
  }

  public canDeleteZone(area: SubstitutionAreaModel): boolean {
    return area?.zones.length > 1;
  }
}
