import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PatternListItem } from 'src/decking/entities/decking-code-list/code-list/pattern-list-item';
import { AreaModel } from 'src/decking/entities/decking-design/area-model';
import { DeckingDesign } from 'src/decking/entities/decking-design/decking-design';
import { DeckingFieldState } from 'src/decking/entities/decking-design/decking-field-state';
import { ZoneModel } from 'src/decking/entities/decking-design/zone-model';
import { FieldState } from 'src/decking/entities/enums/field-state';
import { DeckingCodeListService } from 'src/decking/services/decking-code-list/decking-code-list.service';
import { DeckingDesignService } from 'src/decking/services/decking-design/decking-design.service';
import { DeckingUserSettingsService } from 'src/decking/services/decking-user-settings/user-settings.service';
import { ProductSelectorService } from 'src/decking/services/decking-zones/product-selector/product-selector.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { OfflineService } from 'src/decking/services/external/offline.service';
import { includeSprites, includeSpritesForProductImages } from 'src/decking/sprites';
import { BaseProductSelectorComponent } from '../../common/product-selector/base-product-selector.component';
import { Observable, distinctUntilChanged, map } from 'rxjs';
import { DefinitionOfSidelapConnectors } from 'src/decking/entities/decking-code-list/enums/definition-sidelap-connectors';
import { DeckingMainService } from 'src/decking/services/decking-main/decking-main.service';

@Component({
    selector: 'design-product-selector',
    templateUrl: './design-product-selector.component.html',
    styleUrls: ['./design-product-selector.component.scss'],
})
export class DesignProductSelectorComponent 
    extends BaseProductSelectorComponent
    implements OnInit, OnChanges
{
    @Input()
    currentArea: AreaModel;
    @Input()
    currentDeckingZone: ZoneModel;
    currentDesign: DeckingDesign;

    connectorSpacingTitle$: Observable<string>;

    constructor(
        protected override deckingCodeListService: DeckingCodeListService,
        protected override productSelectorService: ProductSelectorService,
        protected override localization: LocalizationService,
        protected override offlineService: OfflineService,
        protected override deckingMainService: DeckingMainService,
        private deckingDesignService: DeckingDesignService,
        private userSettingsService: DeckingUserSettingsService,
        private elementRef: ElementRef<HTMLElement>
    ) {
        super(
            deckingCodeListService,
            productSelectorService,
            localization,
            offlineService,
            deckingMainService
        );

        this.connectorSpacingTitle$ =
            this.deckingDesignService.currentSettings$.pipe(
                map((s) =>
                    s.definitionOfSidelapConnectors.id ===
                    DefinitionOfSidelapConnectors.ByConnectionSpacing
                        ? 'FastenerSpacing'
                        : 'SideByConnectors'
                ),
                distinctUntilChanged()
            );
    }

    ngOnInit(): void {
        includeSprites(
            this.elementRef.nativeElement,
            'sprite-arrow-right-medium',
            'sprite-arrow-left-medium',
            'sprite-info',
            'sprite-warning',
            'sprite-lines',
            'sprite-lines-expanded',
            'sprite-export-design',
            'sprite-duplicate-design',
            'sprite-openfile-d-light',
            'sprite-decking-shear',
            'sprite-decking-diaphragm-shear',
            'sprite-decking-shear-stiffness',
            'sprite-decking-tension',
            'sprite-decking-uplift',
            'sprite-decking-perimeter-spacing',
            'sprite-long-arrow-right-white',
            'sprite-decking-areas',
            'sprite-profis-file',
            'sprite-arrow-right',
            'sprite-specification-text'
        );
        includeSpritesForProductImages(
            this.elementRef.nativeElement,
            'sprite-no-image-available',
            'sprite-ped-0030002',
            'sprite-ped-058291',
            'sprite-ped-090944',
            'sprite-ped-090954',
            'sprite-ped-090969',
            'sprite-ped-135507',
            'sprite-ped-eaf430'
        );

        this.currentDesign = this.deckingDesignService.getCurrentDesign();
        this.defaultExpanded = !this.currentDesign?.isNew;
        this.expanded$ = this.productSelectorService.expanded$;
        this.deckingDesignService.currentSettings$.subscribe(
            (settings) => {
                // Get the settings from Design
                this.designSettings = settings;
                // If the design settings not exists then get the settings from user settings
                if (!this.designSettings) {
                    this.designSettings =
                        this.userSettingsService.deckingSettings;
                }
            }
        );
        this.initializeComponents(
            this.currentArea.deckPanel,
            this.currentArea.panelWidth,
            this.currentArea.panelType,
            this.currentDeckingZone.pattern
        );

    }

    public valueChanged(): void {
        this.deckingDesignService.updateCurrentZone(
            this.currentDeckingZone
        );
    }

    public onChangeFramePattern(pattern: PatternListItem) {
        this.patternDropdown.selectedValue = pattern;
        this.currentDeckingZone.pattern = {
            id: pattern.id,
            index: pattern.index,
            value: this.isLengthImperial ? pattern.patternSI : pattern.pattern,
            fieldState: FieldState.Selected,
        };
        this.valueChanged();
    }

    // Assign the frame fastener selected in Product Selector to the Current Zone
    public onChangeFrameFastener(
        frameFastener: DeckingFieldState<number, string>
    ) {
        this.currentDeckingZone.frameFastener = frameFastener;
        this.valueChanged();
    }

    // Assign the sidelap connector selected in Product Selector to the Current Zone
    public onChangeSidelapConnector(
        sidelapConnector: DeckingFieldState<number, string>
    ) {
        this.currentDeckingZone.sidelapConnector =
            sidelapConnector;
        this.valueChanged();
    }

    ngOnChanges(_: SimpleChanges): void {
        if (this.designSettings) {
            this.initializeComponents(
                this.currentArea.deckPanel,
                this.currentArea.panelWidth,
                this.currentArea.panelType,
                this.currentDeckingZone.pattern
            );
        }

        this.frameFastenerOnlinePage = this.getFrameFastenerOnlinePage(
            this.currentDeckingZone?.frameFastener
        );
        this.sideLapFastenersOnlinePage = this.getSideLapFastenersOnlinePage(
            this.currentDeckingZone?.sidelapConnector
        );
    }
}
