import { IDeckingValue } from './decking-value';

export class DeflectionCalculation {
    public length: IDeckingValue<number> = {
        value: 60960 // default in millimeters
    };
    public width: IDeckingValue<number> = {
        value: 30480 // default in millimeters
    };
    public angleAreaA: IDeckingValue<number> = {
        value: 0
    };
    public momentOfInertiaI: IDeckingValue<number> = {
        value: 0
    };
    public wWind: IDeckingValue<number> = {
        value: 0
    };
    public flangeDeflectionWind: IDeckingValue<number> = {
        value: 0
    };
    public webDeflectionWind: IDeckingValue<number> = {
        value: 0
    };
    public totalDeflectionWind: IDeckingValue<number> = {
        value: 0
    };
    public wSeismic: IDeckingValue<number> = {
        value: 0
    };
    public flangeDeflectionSeismic: IDeckingValue<number> = {
        value: 0
    };
    public webDeflectionSeismic: IDeckingValue<number> = {
        value: 0
    };
    public totalDeflectionSeismic: IDeckingValue<number> = {
        value: 0
    };
    public diaphragmDeflectionResult = '';
}
