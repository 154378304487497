<pe-control-title
    class="control-title"
    [title]="title">
</pe-control-title>
<div class="products">
    <div class="product" *ngFor="let product of products;" [ngClass]="selectedValue?.id == product.id ? 'selected' : ''" (click)="selectItem(product)">
        <div class="element">
            <div class="image-container">
                <span class="decking-sprite sprite-responsive" [ngClass]="product.image"></span>
            </div>
        </div>
        <div class="element">
            {{product.name}}
        </div>
    </div>
</div>




