import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LeftBarRow } from './../../entities/decking-design/left-bar-row';

@Injectable({
  providedIn: 'root'
})
export class MainLeftService {

  public leftBarRows: LeftBarRow[] = [];

  tabSelectedChange: Subject<string> = new Subject<string>();

  public initRows(): void{
      this.leftBarRows = [];
  }

}
