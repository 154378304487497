import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription, switchMap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/internal/Observable';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { map } from 'rxjs/internal/operators/map';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';

import { DeckingDesignService } from './../../services/decking-design/decking-design.service';
import { DeckingDesign } from './../../entities/decking-design/decking-design';
import { FeatureVisibilityService } from './../../services/external/feature-visibility.service';
import { CommonCodeListService } from './../../services/external/common-code-list.service';
import { ModalService } from './../../services/external/modal.service';
import { IAddEditDesignComponentInput } from './../../entities/decking-design/add-edit-design';
import { DesignTypeId } from './../../entities/enums/design-types';
import { SaveAsDeckingTemplateComponent } from './../../components/decking-popup/save-as-decking-template/save-as-decking-template.component';
import { DeckingUserSettingsService } from 'src/decking/services/decking-user-settings/user-settings.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { DeckingTrackingService } from 'src/decking/services/decking-tracking/decking-tracking.service';
import { DesignTemplateService } from './../../services/external/design-template.service';
import { DesignTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';

@Component({
    selector: 'app-main-top',
    templateUrl: './main-top.component.html'
})
export class DeckingMainTopComponent implements OnInit, OnDestroy {
    @Input()
    public processDesignClose!: () => Promise<void>;
    @Input()
    public processDesignBrowserUnload!: () => Promise<void>;

    private regions: { [id: string]: CommonRegion };

    public currentDocument: IDesignListItem;
    public deckingDesign: DeckingDesign;
    public templateId: string;
    public isTemplate: boolean;
    public isFeatureEnabled: boolean;
    public isTemplate$: Observable<boolean>;
    public deckingDesign$: Observable<DeckingDesign>;
    public designTitle$: Observable<string>;
    public isDeckingNewHeaderEnabled: boolean;
    public regionId: number;
    public userLogout = false;
    public projectDesign: any;
    public templateDetails: DesignTemplateEntity;

    public currentDocument$: Subscription;
    constructor(
        private modal: ModalService,
        private deckingDesignService: DeckingDesignService,
        private commonCodeList: CommonCodeListService,
        private featureVisibilityService: FeatureVisibilityService,
        private modalService: NgbModal,
        private deckingUserSettingsService: DeckingUserSettingsService,
        public localizationService: LocalizationService,
        private trackingService: DeckingTrackingService,
        private designTemplate: DesignTemplateService
    ) { }

    public get isNewHomePage() {
        return this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
    }

    ngOnInit(): void {
        this.isFeatureEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_ProjectTemplates');
        this.isDeckingNewHeaderEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_NewHeader');
        this.deckingDesign$ = this.deckingDesignService.currentDeckingDesign$;
        this.isTemplate$ = this.deckingDesignService.currentDeckingDesign$.pipe(map(design => design.isTemplate), distinctUntilChanged());
        this.currentDocument$ = this.deckingDesignService.currentDocument$.subscribe((data: IDesignListItem) => {
            this.currentDocument = data;
          });
        this.designTitle$ = combineLatest([this.isTemplate$, this.deckingDesign$]).pipe(
            switchMap(async ([isTemplate, deckingDesign]) => {
            if (isTemplate) {
                this.isTemplate = true;
                this.deckingDesign = deckingDesign;
                this.templateId = deckingDesign.documentId;
                this.templateDetails = await this.designTemplate.getById(this.templateId);
                this.projectDesign = JSON.parse(this.templateDetails.ProjectDesign);
            }
            return isTemplate ? deckingDesign.name : this.currentDocument?.designName;
        }), distinctUntilChanged());
        this.regionId = this.getRegionByCountryCode(this.deckingUserSettingsService.deckingSettings.region.countryCode).id;
        this.onCloseEventTrack = this.onCloseEventTrack.bind(this);
        this.beforeLogout = this.beforeLogout.bind(this);
        this.openDiaphragmDesign = this.openDiaphragmDesign.bind(this);
        this.topBarSaveAsTemplateClick = this.topBarSaveAsTemplateClick.bind(this);
        this.openGeneralNotes = this.openGeneralNotes.bind(this);
        this.startTour = this.startTour.bind(this);

        this.TrackDataOnTabClose();
    }

    ngOnDestroy(): void {
        if(this.currentDocument$) {
            this.currentDocument$.unsubscribe();
        }
        window.removeEventListener('beforeunload', this.onCloseEventTrack, false);
    }

    public openDiaphragmDesign() {
        let region = null;
        const mockData: IAddEditDesignComponentInput = {
            addEditType: AddEditType.edit,
            selectedModuleDesignInfo: null,
            designType: DesignTypeId.DiaphragmDesign,
            unitLength: Unit.ft
        };

        if (this.isTemplate) {
            this.deckingDesignService.loadDeckingDesign(this.projectDesign.designId, this.deckingDesign.documentId);
            this.regions = this.normalizeArray(this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[], 'id');
            region = this.regions[this.templateDetails.RegionId];
        } else {
            region = (this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[])
                        .find((row) => row.id == this.currentDocument.metaData.region);
        }

        mockData.design = {
            id: this.isTemplate ? this.deckingDesign.id : this.currentDocument.id,
            name: this.isTemplate ? this.deckingDesign.name : this.currentDocument.designName,
            projectId: this.isTemplate ? '' : this.currentDocument.projectId,
            projectName: this.isTemplate ? '' : this.currentDocument.projectName,
            region: region,
            designType: DesignTypeId.DiaphragmDesign,
            displayDesignType: this.isTemplate ? DisplayDesignType.template : DisplayDesignType.design,
            unitLength: null,
            unitLengthLarge: null,
            unitArea: null,
            unitStress: null,
            unitStressSmall: null,
            unitForce: null,
            unitMoment: null,
            unitTemperature: null,
            unitForcePerLength: null,
            unitMomentPerLength: null,
            unitDensity: null,
            unitAreaPerLength: null,
            designTemplateDocumentId: this.deckingDesign?.documentId,
        };
        this.modal.openAddEditDesignFromModule(mockData);
    }

    public topBarSaveAsTemplateClick(): void {
        this.modalService.open(SaveAsDeckingTemplateComponent, { size: 'md' });
    }

    public async beforeLogout() {
        if (!this.isTemplate) {
            this.userLogout = true; // we set this to true so we ignore beforeunload event
            await this.processDesignClose();
        }
    }

    public openGeneralNotes() {
        const copyText = this.localizationService.getString('Agito.Hilti.Profis3.GeneralNotes.CopyText');
        const text = this.localizationService.getString('Agito.Hilti.Profis3.GeneralNotes.DisplayText');

        this.modal.openGeneralNotes(text, copyText);
    }

    public menuOpened() {
        this.trackingService.menuOpened();
    }

    public hiltiDataPrivacyUrlOpened() {
        this.trackingService.hiltiDataPrivacyUrlOpened();
    }

    public regionLinkOpened() {
        this.trackingService.regionLinkOpened();
    }

    public get displayLDFlags() {
        return this.featureVisibilityService.isFeatureEnabled('LD_AutomatedPage');
    }

    public TrackDataOnTabClose() {
        window.addEventListener('beforeunload', this.onCloseEventTrack, false);
    }

    public startTour() {
        this.modal.openVirtualTourPopup();
    }

    private onCloseEventTrack() {
        if (!this.userLogout) {
            this.processDesignBrowserUnload();
        }
    }

    private getRegionByCountryCode(countryCode: string) {
        const regionCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(region => region.countryCode == countryCode);
    }


    private normalizeArray<T>(array: T[], indexKey: keyof T) {
        const normalizedObject: any = {};
        array.forEach(element => {
            const key = element[indexKey];
            normalizedObject[key] = element;
        });

        return normalizedObject as { [key: string]: T };
    }

}
