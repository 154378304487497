import { Injectable } from '@angular/core';
import {
    FeatureFlagFromPeUi, FeaturesVisibilityServiceInjected
} from '@profis-engineering/pe-ui-common/services/feature-visibility.common';

export type FeatureFlag =
    FeatureFlagFromPeUi |
    'Decking_Global' |
    'Decking_ProjectTemplates' |
    'Decking_SubstitutionBuilder' |
    'Decking_ZoneTemplates' |
    'Decking_NewLabel' |
    'Decking_NewHeader' |
    'LD_AutomatedPage' |
    'Decking_MultiCodes';

@Injectable({
    providedIn: 'root'
})
export class FeatureVisibilityService extends FeaturesVisibilityServiceInjected<FeatureFlag> { }
