import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitService } from './../../../services/external/unit.service';


/* Component to display a value with its unit
   This component uses the unit service to format the value and
   the unit to be consistent with other components like the app numeric text box
*/
@Component({
    selector: 'decking-unit-value-display',
    templateUrl: './unit-value-display.component.html',
})
export class UnitValueDisplayComponent implements OnChanges, AfterViewInit {
    @Input()
    unit: Unit;

    @Input()
    value: number;

    @Input()
    precision?: number;

    @Input()
    decimalSeparator: string;

    @Input()
    groupSeparator: string;

    @Input()
    uiProperty: UIProperty;

    @Input()
    toFixed: boolean;

    @Input()
    appendUnit = true;

    formattedText: string;
    tooltipText: string;

    @ViewChild('formattedTextContainer', { static: false })
    formattedTextContainer: ElementRef;

    constructor(private unitService: UnitService) {
        const resize$ = fromEvent(window, 'resize').pipe(debounceTime(500));

        resize$.subscribe(() => {
            this.updateTooltip();
        });
    }

    ngOnChanges(_: SimpleChanges): void {
        const convertedValue = this.getConvertedValue();
        this.formattedText = this.unitService.formatUnitValueArgs(convertedValue.value, convertedValue.unit, this.precision, this.decimalSeparator, this.groupSeparator,
            this.uiProperty, this.appendUnit, this.toFixed);
    }

    ngAfterViewInit() {
        this.updateTooltip();
    }

    private updateTooltip(): void {
        if (this.formattedTextContainer) {
            const ellipsisSize = 12; // size of elipsis in px
            const element = this.formattedTextContainer.nativeElement;
            const isEllipsisActive = Number(element?.offsetWidth) > Number(element?.offsetParent?.offsetWidth) - ellipsisSize;
            this.tooltipText = isEllipsisActive ? this.formattedText : '';
        }
    }

    private getConvertedValue() {
        const unitGroup = this.unitService.getUnitGroupFromUnit(this.unit);
        const internalUnit = this.unitService.getInternalUnit(unitGroup);
        return this.unitService.convertUnitValueToUnit({
            unit: internalUnit,
            value: this.value
        }, this.unit);
    }
}

export const enum UIProperty {
    AnchorTheory_C2C_CustomReinforcementOverstrength = 10445,
    AnchorTheory_C2C_ExistingConcreteEmbedmentDepth = 10443,
    AnchorTheory_C2C_IsAnchorTheoryDisplayed = 10447,
    AnchorTheory_C2C_IsOverwrittenFromESR = 10441,
    AnchorTheory_C2C_IsYieldDesign = 10446,
    AnchorTheory_C2C_PhiBond = 10490,
    AnchorTheory_C2C_PhiBreakout = 10491,
    AnchorTheory_C2C_PhiTension = 10442,
    AnchorTheory_C2C_ReinforcementOverstrength = 10444,
    AnchorTheory_C2C_ReinforcementOverstrengthSelectedValue = 10452,
    AnchorTheory_C2C_ReinforcementTensionCondition = 10492,
    AnchorTheory_C2C_SyncPhiBondPhiBreakout = 10489,
    Application_C2C_Connection_Angle = 10516,
    Application_C2C_Connection_CrossSectionExistingStructure = 10518,
    Application_C2C_Connection_CrossSectionNewStructure = 10517,
    Application_C2C_Connection_IsExtensionAtSupport = 10523,
    Application_C2C_Connection_IsXDirectionContinuous = 10515,
    Application_C2C_Connection_RotateAnticlockwise = 10520,
    Application_C2C_Connection_RotateClockwise = 10519,
    Application_C2C_IsCustomDesign = 10687,
    ConcreteReinforcement_C2C_ReinforcementType = 10698,
    ExistingStructure_C2C_BaseMaterial_BaseMaterial = 10148,
    ExistingStructure_C2C_BaseMaterial_CrackedConcrete = 10146,
    ExistingStructure_C2C_BaseMaterial_FcCube = 10150,
    ExistingStructure_C2C_BaseMaterial_FcCyl = 10149,
    ExistingStructure_C2C_BaseMaterial_NewBaseMaterial = 10682,
    ExistingStructure_C2C_BaseMaterial_NewFcCyl = 10688,
    ExistingStructure_C2C_BaseMaterial_ParallelCracksBottom = 10171,
    ExistingStructure_C2C_BaseMaterial_ParallelCracksTop = 10147,
    ExistingStructure_C2C_BaseMaterial_ValueSelectedType = 10186,
    ExistingStructure_C2C_DepthNegative = 10207,
    ExistingStructure_C2C_DepthPositive = 10206,
    ExistingStructure_C2C_Diameter = 10522,
    ExistingStructure_C2C_ExistingStructure_AtSupport = 10211,
    ExistingStructure_C2C_ExistingStructure_MinCoverBottom = 10160,
    ExistingStructure_C2C_ExistingStructure_MinCoverTop = 10159,
    ExistingStructure_C2C_FrameNode_FrameNodeEntry = 10164,
    ExistingStructure_C2C_FrameNode_ManualEntry = 10163,
    ExistingStructure_C2C_Height = 10204,
    ExistingStructure_C2C_Installation_DrillingAid = 10157,
    ExistingStructure_C2C_Installation_DrillingType = 10155,
    ExistingStructure_C2C_Installation_HoleType = 10156,
    ExistingStructure_C2C_IsConcreteSurfaceRoughenedh = 10500,
    ExistingStructure_C2C_Length = 10166,
    ExistingStructure_C2C_Product_RebarStandard = 10715,
    ExistingStructure_C2C_Reinforcement_RebarPoints = 10645,
    ExistingStructure_C2C_Reinforcement_SurfaceReinforcement = 10162,
    ExistingStructure_C2C_Surface_Roughness = 10158,
    ExistingStructure_C2C_SurfaceTreatment_SurfaceTreatment = 10707,
    ExistingStructure_C2C_Temperature_Installation = 10154,
    ExistingStructure_C2C_Temperature_LongTerm = 10152,
    ExistingStructure_C2C_Temperature_ShortTerm = 10151,
    ExistingStructure_C2C_Thickness = 10167,
    ExistingStructure_C2C_TransversePressure = 10161,
    ExistingStructure_C2C_Width = 10165,
    ExistingStructure_C2C_BaseMaterial_ElasticityModulus = 10711,
    ExistingStructure_C2C_BaseMaterial_NewElasticityModulus = 10712,
    ExistingStructure_C2C_BaseMaterial_NewFcCube = 10710,
    General_C2C_ApplicationType = 10015,
    General_C2C_ConnectionType = 10014,
    Installation_C2C_ContactSurfaceCondition_ACI31811 = 10508,
    Load_C2C_ValueUpdate_ForceZ = 10700,
    Loads_C2C_AlphaSus = 10010,
    Loads_C2C_BottomForceZ = 10200,
    Loads_C2C_Category = 10252,
    Loads_C2C_ConfinedCoreDepth = 10736,
    Loads_C2C_ConsiderMaximumReinforcement = 10641,
    Loads_C2C_ConsiderMinimumReinforcement = 10640,
    Loads_C2C_CrackWidth = 10752,
    Loads_C2C_DefinitionOfMinMaxReinforcement = 10643,
    Loads_C2C_DeltaFs = 10637,
    Loads_C2C_DesignForYield = 10144,
    Loads_C2C_DesignWorkingLife = 10634,
    Loads_C2C_DynamicZone1 = 10257,
    Loads_C2C_DynamicZone2 = 10258,
    Loads_C2C_DynamicZone3 = 10259,
    Loads_C2C_DynamicZone4 = 10260,
    Loads_C2C_DynamicZoneGeneric = 10261,
    Loads_C2C_ExistingConcreteFailureMode = 10250,
    Loads_C2C_ExistingReinforcementFireTemperature = 10702,
    Loads_C2C_FireApplicationType = 10753,
    Loads_C2C_FireDuration = 10011,
    Loads_C2C_FireInputType = 10701,
    Loads_C2C_ForceX = 10172,
    Loads_C2C_ForceY = 10463,
    Loads_C2C_ForceZ = 10173,
    Loads_C2C_InterfaceShear = 10638,
    Loads_C2C_InterfaceShearType = 10639,
    Loads_C2C_IsAnchorTheoryDisplayed = 10731,
    Loads_C2C_IsYieldDesign = 10730,
    Loads_C2C_LoadCombinations = 10141,
    Loads_C2C_LoadCombinationsCalculationType = 10142,
    Loads_C2C_LoadDefinitionSection = 10636,
    Loads_C2C_LoadDefinitionType = 10143,
    Loads_C2C_LoadingDefinition_Beta = 10276,
    Loads_C2C_LoadingDefinition_Z = 10277,
    Loads_C2C_LoadingDefinitionType = 10275,
    Loads_C2C_LoadType = 10013,
    Loads_C2C_MaxAmplitude = 10009,
    Loads_C2C_MaxSteelStress = 10007,
    Loads_C2C_MomentX = 10174,
    Loads_C2C_MomentY = 10175,
    Loads_C2C_MomentZ = 10512,
    Loads_C2C_NormalStress = 10018,
    Loads_C2C_NormalStressACI = 10285,
    Loads_C2C_NormalStressCSA = 10286,
    Loads_C2C_OptimizationType = 10016,
    Loads_C2C_OverlayFailureMode = 10251,
    Loads_C2C_PartOfStaticLoad = 10008,
    Loads_C2C_PostInstallReinforcementFireTemperature = 10705,
    Loads_C2C_PostInstallReinforcementFireTemperatureForIntersection = 10706,
    Loads_C2C_SeismicLoadMultiplier = 10734,
    Loads_C2C_SelectedLoadCombinationId = 10145,
    Loads_C2C_ShowLoadInputs = 10012,
    Loads_C2C_SpecialMomentsFrame = 10735,
    Loads_C2C_StrutAndTie_M2 = 10720,
    Loads_C2C_StrutAndTie_M3 = 10723,
    Loads_C2C_StrutAndTie_N2 = 10718,
    Loads_C2C_StrutAndTie_N3 = 10721,
    Loads_C2C_StrutAndTie_V2 = 10719,
    Loads_C2C_StrutAndTie_V3 = 10722,
    Loads_C2C_SustainedForceZ = 10478,
    Loads_C2C_SustainedLoad = 10699,
    Loads_C2C_SustainedMomentX = 10513,
    Loads_C2C_SustainedMomentY = 10479,
    Loads_C2C_TedX = 10464,
    Loads_C2C_TedY = 10465,
    Loads_C2C_UseGermanNationalAnnex = 10729,
    Loads_C2C_Zone1 = 10181,
    Loads_C2C_Zone2 = 10182,
    Loads_C2C_Zone3 = 10183,
    Loads_C2C_Zone4 = 10184,
    Loads_C2C_ZoneGeneric = 10185,
    NewStructure_C2C_AdditionalInformation_CoverOfTension = 10246,
    NewStructure_C2C_AdditionalInformation_TransversePressure = 10247,
    NewStructure_C2C_BottomReinforcement_BondConditions = 10244,
    NewStructure_C2C_BottomReinforcement_ConcreteCoverLayer1 = 10233,
    NewStructure_C2C_BottomReinforcement_ConcreteCoverLayer10 = 10243,
    NewStructure_C2C_BottomReinforcement_ConcreteCoverLayer2 = 10234,
    NewStructure_C2C_BottomReinforcement_ConcreteCoverLayer3 = 10235,
    NewStructure_C2C_BottomReinforcement_ConcreteCoverLayer4 = 10236,
    NewStructure_C2C_BottomReinforcement_ConcreteCoverLayer5 = 10237,
    NewStructure_C2C_BottomReinforcement_ConcreteCoverLayer6 = 10238,
    NewStructure_C2C_BottomReinforcement_ConcreteCoverLayer7 = 10239,
    NewStructure_C2C_BottomReinforcement_ConcreteCoverLayer8 = 10240,
    NewStructure_C2C_BottomReinforcement_ConcreteCoverLayer9 = 10242,
    NewStructure_C2C_BottomReinforcement_MinimumReinforcement = 10245,
    NewStructure_C2C_BottomReinforcement_NumberOfLayers = 10232,
    NewStructure_C2C_Depth = 10178,
    NewStructure_C2C_Diameter = 10521,
    NewStructure_C2C_Offset = 10170,
    NewStructure_C2C_ShearReinforcement_InclinationCompression = 10249,
    NewStructure_C2C_ShearReinforcement_InclinationShear = 10264,
    NewStructure_C2C_ShearReinforcement_ShearReinforcement = 10248,
    NewStructure_C2C_Thickness = 10168,
    NewStructure_C2C_TopReinforcement_BondConditions = 10230,
    NewStructure_C2C_TopReinforcement_ConcreteCoverLayer1 = 10220,
    NewStructure_C2C_TopReinforcement_ConcreteCoverLayer10 = 10229,
    NewStructure_C2C_TopReinforcement_ConcreteCoverLayer2 = 10221,
    NewStructure_C2C_TopReinforcement_ConcreteCoverLayer3 = 10222,
    NewStructure_C2C_TopReinforcement_ConcreteCoverLayer4 = 10223,
    NewStructure_C2C_TopReinforcement_ConcreteCoverLayer5 = 10224,
    NewStructure_C2C_TopReinforcement_ConcreteCoverLayer6 = 10225,
    NewStructure_C2C_TopReinforcement_ConcreteCoverLayer7 = 10226,
    NewStructure_C2C_TopReinforcement_ConcreteCoverLayer8 = 10227,
    NewStructure_C2C_TopReinforcement_ConcreteCoverLayer9 = 10228,
    NewStructure_C2C_TopReinforcement_MinimumReinforcement = 10231,
    NewStructure_C2C_TopReinforcement_NumberOfLayers = 10219,
    NewStructure_C2C_Width = 10169,
    NewStructure_CalculationData_C2C_AreaOfConcreteShear = 10405,
    NewStructure_CalculationData_C2C_AreaOfSteel = 10406,
    NewStructure_CalculationData_C2C_Optimized = 10404,
    NewStructure_CalculationData_C2C_PermanentNewCompression = 10407,
    NewStructure_CalculationData_C2C_UseFullCrossSection = 10733,
    NewStructure_ExistingConcrete_C2C_EndCoverC1 = 10484,
    NewStructure_ExistingConcrete_C2C_ExtensionAtIntersection = 10398,
    NewStructure_ExistingConcrete_C2C_ExtensionAtSupport = 10397,
    NewStructure_ExistingConcrete_C2C_Height = 10395,
    NewStructure_ExistingConcrete_C2C_Length = 10396,
    NewStructure_ExistingConcrete_C2C_Width = 10394,
    NewStructure_NewConcrete_C2C_AdditionalOffset = 10501,
    NewStructure_NewConcrete_C2C_Height = 10400,
    NewStructure_NewConcrete_C2C_OffsetBottom = 10473,
    NewStructure_NewConcrete_C2C_OffsetLeft = 10474,
    NewStructure_NewConcrete_C2C_OffsetRight = 10475,
    NewStructure_NewConcrete_C2C_OffsetTop = 10480,
    NewStructure_NewConcrete_C2C_Width = 10399,
    NewStructure_Rotation_C2C_Angle = 10401,
    NewStructure_Rotation_C2C_RotateAnticlockwise = 10403,
    NewStructure_Rotation_C2C_RotateClockwise = 10402,
    Option_C2C_AdvancedRebarSettingsSteelStrainLimit = 10483,
    Option_C2C_AlphaCC = 10370,
    Option_C2C_AlphaCT = 10642,
    Option_C2C_CustomPictures = 10384,
    Option_C2C_DebugMode_DisableSc = 10690,
    Option_C2C_DecisiveLoadCombinationId = 10743,
    Option_C2C_DesignMethodGroup = 10006,
    Option_C2C_DesignMethodGroups = 10635,
    Option_C2C_DesignStandard = 10005,
    Option_C2C_E = 10651,
    Option_C2C_EpsilonC2 = 10648,
    Option_C2C_EpsilonCU2 = 10649,
    Option_C2C_EpsilonUD = 10650,
    Option_C2C_ExistingReinforcementAlpha3 = 10659,
    Option_C2C_ExistingReinforcementAlpha4 = 10660,
    Option_C2C_GammaRd = 10732,
    Option_C2C_HighestLoadedRebar = 10676,
    Option_C2C_K2 = 10647,
    Option_C2C_MaximumAreaReinforcement = 10666,
    Option_C2C_MaximumAreaReinforcementInput = 10667,
    Option_C2C_MinCoverTop = 10652,
    Option_C2C_MinimumAreaReinforcement = 10664,
    Option_C2C_MinimumAreaReinforcementInput = 10665,
    Option_C2C_NoteInstallationData = 10381,
    Option_C2C_NoteLoadCaseResultingRebarForces = 10382,
    Option_C2C_NoteShearLoad = 10380,
    Option_C2C_NoteTensionLoad = 10383,
    Option_C2C_OptimizationStrategy = 10675,
    Option_C2C_PartialReductionFactorConcrete = 10001,
    Option_C2C_PartialReductionFactorFire = 10002,
    Option_C2C_PartialReductionFactorSteel = 10000,
    Option_C2C_PartialSafetyFactorConcrete = 10654,
    Option_C2C_PartialSafetyFactorSteel = 10653,
    Option_C2C_ReportAddress = 10386,
    Option_C2C_ReportCompanyName = 10385,
    Option_C2C_ReportContactPerson = 10387,
    Option_C2C_ReportEmail = 10389,
    Option_C2C_ReportFirstPage = 463,
    Option_C2C_ReportLanguageId = 462,
    Option_C2C_ReportLoadCombinationId = 10739,
    Option_C2C_ReportNotes = 10377,
    Option_C2C_ReportPaperSize = 460,
    Option_C2C_ReportPhoneNumber = 10388,
    Option_C2C_ReportRebarApplication = 10379,
    Option_C2C_ReportTemplateId = 10378,
    Option_C2C_ReportType = 461,
    Option_C2C_SelectedDesignMethod = 10713,
    Option_C2C_ShearInclination = 10672,
    Option_C2C_ShearReinforcementConcreteMember = 10670,
    Option_C2C_ShearStrutAngle = 10671,
    Option_C2C_SpacingMaximum = 10668,
    Option_C2C_SpacingMaximumInput = 10669,
    Option_C2C_StrutTies = 10673,
    Option_C2C_StrutTiesInput = 10674,
    Option_C2C_TransversePressure = 10663,
    Option_C2C_TrimbleConnectFolderId = 10393,
    Option_C2C_TrimbleConnectLocation = 10391,
    Option_C2C_TrimbleConnectReportName = 10392,
    Option_C2C_TrimbleConnectUpload = 10390,
    Option_C2C_UseHiltiRebarDesign = 10502,
    Options_C2C_MetricEquation = 10754,
    Overlay_C2C_BaseMaterial_CrackedConcrete = 10073,
    Overlay_C2C_BaseMaterial_ExistingStructure = 10074,
    Overlay_C2C_BaseMaterial_ExistingStructure_FcCube = 10079,
    Overlay_C2C_BaseMaterial_ExistingStructure_FcCyl = 10078,
    Overlay_C2C_BaseMaterial_LightweightConcrete = 10266,
    Overlay_C2C_BaseMaterial_NewStructure = 10075,
    Overlay_C2C_BaseMaterial_NewStructure_FcCube = 10077,
    Overlay_C2C_BaseMaterial_NewStructure_FcCyl = 10076,
    Overlay_C2C_BaseMaterial_TensileStrength = 10267,
    Overlay_C2C_BaseMaterial_ValueSelectedTypeExisting = 10080,
    Overlay_C2C_BaseMaterial_ValueSelectedTypeNew = 10081,
    Overlay_C2C_ConcreteBlock_ConcreteDensity = 10272,
    Overlay_C2C_ExistingStructure_ConcreteDensity = 10439,
    Overlay_C2C_ExistingStructure_ContactSurfaceCondition = 10469,
    Overlay_C2C_ExistingStructure_DrillingAngle = 10470,
    Overlay_C2C_ExistingStructure_LightweightConcrete = 10467,
    Overlay_C2C_ExistingStructure_TensileStrength = 10471,
    Overlay_C2C_Geometry_BeamLength = 10046,
    Overlay_C2C_Geometry_BeamWidth = 10043,
    Overlay_C2C_Geometry_ConcreteCoverBottom = 10049,
    Overlay_C2C_Geometry_ConcreteCoverTop = 10050,
    Overlay_C2C_Geometry_ConcreteHeightHNew = 10054,
    Overlay_C2C_Geometry_ConcreteHeightHold = 10048,
    Overlay_C2C_Geometry_ConcreteShearInterface = 10284,
    Overlay_C2C_Geometry_ConcreteShearInterfaceGroup = 10282,
    Overlay_C2C_Geometry_ConcreteShearInterfaceMode = 10283,
    Overlay_C2C_Geometry_GenericLength = 10140,
    Overlay_C2C_Geometry_GenericWidth = 10139,
    Overlay_C2C_Geometry_MinSideCover = 10704,
    Overlay_C2C_Geometry_SlabLength = 10045,
    Overlay_C2C_Geometry_SlabWidth = 10042,
    Overlay_C2C_Geometry_WallHeight = 10047,
    Overlay_C2C_Geometry_WallWidth = 10044,
    Overlay_C2C_Installation_ContactSurfaceCondition = 10476,
    Overlay_C2C_Installation_DrillingAngle = 10279,
    Overlay_C2C_Installation_DrillingType = 10137,
    Overlay_C2C_Installation_HoleType = 10138,
    Overlay_C2C_OverlayPosition_Position = 10132,
    Overlay_C2C_Product_RebarStandard = 10716,
    Overlay_C2C_Reinforcement_ConcreteReinforcement = 10051,
    Overlay_C2C_Reinforcement_ConcreteReinforcementNew = 10504,
    Overlay_C2C_Reinforcement_ConstructiveReinforcement = 10369,
    Overlay_C2C_Reinforcement_DetailedDefinition = 10493,
    Overlay_C2C_Reinforcement_DiameterId = 10287,
    Overlay_C2C_Reinforcement_Ktr = 10268,
    Overlay_C2C_Reinforcement_NumberOfBars = 10496,
    Overlay_C2C_Reinforcement_ReinforcedInterface = 10368,
    Overlay_C2C_Reinforcement_ReinforcementControlCracking = 10053,
    Overlay_C2C_Reinforcement_ReinforcementControlCrackingNew = 10505,
    Overlay_C2C_Reinforcement_ReinforcementControlCrackingNewTitle = 10507,
    Overlay_C2C_Reinforcement_ReinforcementControlCrackingTitle = 10506,
    Overlay_C2C_Reinforcement_Spacing = 10495,
    Overlay_C2C_Reinforcement_SpliceClass = 10288,
    Overlay_C2C_Reinforcement_SpliceToExistingReinforcement = 10280,
    Overlay_C2C_Reinforcement_TotalCrossSectionalArea = 10494,
    Overlay_C2C_Reinforcement_YieldStrength = 10281,
    Overlay_C2C_SurfaceTreatment_JointRoughness = 10136,
    Overlay_C2C_SurfaceTreatment_SurfaceTreatment = 10263,
    Overlay_C2C_Temperature_Installation = 10135,
    Overlay_C2C_Temperature_LongTerm = 10134,
    Overlay_C2C_Temperature_ShortTerm = 10133,
    Overlay_C2C_Zones_ConsideringEdgeArea = 10084,
    Overlay_C2C_Zones_isZ1WidthLocked = 10089,
    Overlay_C2C_Zones_isZ2WidthLocked = 10090,
    Overlay_C2C_Zones_isZ3WidthLocked = 10091,
    Overlay_C2C_Zones_isZ4WidthLocked = 10092,
    Overlay_C2C_Zones_NumberOfZones = 10082,
    Overlay_C2C_Zones_Symmetric = 10083,
    Overlay_C2C_Zones_Z1Width = 10085,
    Overlay_C2C_Zones_Z2Width = 10086,
    Overlay_C2C_Zones_Z3Width = 10087,
    Overlay_C2C_Zones_Z4Width = 10088,
    Overlay_ExistingConcrete_C2C_EndCoverC1 = 10488,
    Overlay_Rotation_C2C_Angle = 10485,
    Overlay_Rotation_C2C_RotateAnticlockwise = 10487,
    Overlay_Rotation_C2C_RotateClockwise = 10486,
    OverlayInstallation_C2C_ContactSurfaceCondition_ACI31811 = 10509,
    OverlayInstallation_C2C_IsConcreteSurfaceRoughened = 10499,
    Product_C2C_AllowedFastenerSizes = 10684,
    Product_C2C_AnchorLength = 10205,
    Product_C2C_ApprovalLanguages = 10033,
    Product_C2C_ApprovalUrls = 10741,
    Product_C2C_Arrangment_BondCondition = 10590,
    Product_C2C_Arrangment_Cover = 10589,
    Product_C2C_Arrangment_Diameter = 10586,
    Product_C2C_Arrangment_NumberBars = 10656,
    Product_C2C_Arrangment_NumberBarsX = 10587,
    Product_C2C_Arrangment_NumberBarsY = 10588,
    Product_C2C_BottomEmbedmentDepth = 10028,
    Product_C2C_BottomLayer1_Diameter = 10579,
    Product_C2C_BottomLayer1_MinSideCover = 10726,
    Product_C2C_BottomLayer1_NumberOfBars = 10623,
    Product_C2C_BottomLayer1_Show = 10631,
    Product_C2C_BottomLayer1_SideCover = 10693,
    Product_C2C_BottomLayer1_Spacing = 10624,
    Product_C2C_BottomLayer1_SpacingBarOption = 10580,
    Product_C2C_BottomLayer1_TopCover = 10581,
    Product_C2C_BottomLayer2_Diameter = 10582,
    Product_C2C_BottomLayer2_MinSideCover = 10727,
    Product_C2C_BottomLayer2_NumberOfBars = 10625,
    Product_C2C_BottomLayer2_Show = 10632,
    Product_C2C_BottomLayer2_SideCover = 10694,
    Product_C2C_BottomLayer2_Spacing = 10626,
    Product_C2C_BottomLayer2_SpacingBarOption = 10583,
    Product_C2C_BottomLayer2_TopCover = 10584,
    Product_C2C_BottomLayerBondCondition = 10585,
    Product_C2C_BottomLayersCount = 10686,
    Product_C2C_BottomRebarDiameter = 10021,
    Product_C2C_BottomRebarDiameterGroup = 10039,
    Product_C2C_BottomRebarEmbedmentGroup = 10040,
    Product_C2C_BottomRebarSpacingGroup = 10041,
    Product_C2C_CalculationMode = 10570,
    Product_C2C_CompressionConfinement = 10714,
    Product_C2C_ConcreteZones_Z1Edge1EdgeDistanceX = 10297,
    Product_C2C_ConcreteZones_Z1Edge1EdgeDistanceY = 10298,
    Product_C2C_ConcreteZones_Z1Edge1RowsNumberX = 10291,
    Product_C2C_ConcreteZones_Z1Edge1RowsNumberY = 10292,
    Product_C2C_ConcreteZones_Z1Edge1SpacingX = 10294,
    Product_C2C_ConcreteZones_Z1Edge1SpacingY = 10295,
    Product_C2C_ConcreteZones_Z1Edge2EdgeDistanceX = 10306,
    Product_C2C_ConcreteZones_Z1Edge2EdgeDistanceY = 10307,
    Product_C2C_ConcreteZones_Z1Edge2RowsNumberX = 10300,
    Product_C2C_ConcreteZones_Z1Edge2RowsNumberY = 10301,
    Product_C2C_ConcreteZones_Z1Edge2SpacingX = 10303,
    Product_C2C_ConcreteZones_Z1Edge2SpacingY = 10304,
    Product_C2C_ConcreteZones_Z1EdgeDistanceX = 10100,
    Product_C2C_ConcreteZones_Z1EdgeDistanceXOpposite = 10510,
    Product_C2C_ConcreteZones_Z1EdgeDistanceY = 10101,
    Product_C2C_ConcreteZones_Z1EdgeDistanceYOpposite = 10511,
    Product_C2C_ConcreteZones_Z1RowsNumberX = 10094,
    Product_C2C_ConcreteZones_Z1RowsNumberY = 10095,
    Product_C2C_ConcreteZones_Z1SpacingX = 10097,
    Product_C2C_ConcreteZones_Z1SpacingY = 10098,
    Product_C2C_ConcreteZones_Z2Edge1EdgeDistanceX = 10315,
    Product_C2C_ConcreteZones_Z2Edge1EdgeDistanceY = 10316,
    Product_C2C_ConcreteZones_Z2Edge1Group = 10371,
    Product_C2C_ConcreteZones_Z2Edge1RowsNumberX = 10309,
    Product_C2C_ConcreteZones_Z2Edge1RowsNumberY = 10310,
    Product_C2C_ConcreteZones_Z2Edge1SpacingX = 10312,
    Product_C2C_ConcreteZones_Z2Edge1SpacingY = 10313,
    Product_C2C_ConcreteZones_Z2Edge2EdgeDistanceX = 10324,
    Product_C2C_ConcreteZones_Z2Edge2EdgeDistanceY = 10325,
    Product_C2C_ConcreteZones_Z2Edge2Group = 10372,
    Product_C2C_ConcreteZones_Z2Edge2RowsNumberX = 10318,
    Product_C2C_ConcreteZones_Z2Edge2RowsNumberY = 10319,
    Product_C2C_ConcreteZones_Z2Edge2SpacingX = 10321,
    Product_C2C_ConcreteZones_Z2Edge2SpacingY = 10322,
    Product_C2C_ConcreteZones_Z2EdgeDistanceX = 10109,
    Product_C2C_ConcreteZones_Z2EdgeDistanceY = 10110,
    Product_C2C_ConcreteZones_Z2RowsNumberX = 10103,
    Product_C2C_ConcreteZones_Z2RowsNumberY = 10104,
    Product_C2C_ConcreteZones_Z2SpacingX = 10106,
    Product_C2C_ConcreteZones_Z2SpacingY = 10107,
    Product_C2C_ConcreteZones_Z3Edge1EdgeDistanceX = 10333,
    Product_C2C_ConcreteZones_Z3Edge1EdgeDistanceY = 10334,
    Product_C2C_ConcreteZones_Z3Edge1Group = 10373,
    Product_C2C_ConcreteZones_Z3Edge1RowsNumberX = 10327,
    Product_C2C_ConcreteZones_Z3Edge1RowsNumberY = 10328,
    Product_C2C_ConcreteZones_Z3Edge1SpacingX = 10330,
    Product_C2C_ConcreteZones_Z3Edge1SpacingY = 10331,
    Product_C2C_ConcreteZones_Z3Edge2EdgeDistanceX = 10342,
    Product_C2C_ConcreteZones_Z3Edge2EdgeDistanceY = 10343,
    Product_C2C_ConcreteZones_Z3Edge2Group = 10374,
    Product_C2C_ConcreteZones_Z3Edge2RowsNumberX = 10336,
    Product_C2C_ConcreteZones_Z3Edge2RowsNumberY = 10337,
    Product_C2C_ConcreteZones_Z3Edge2SpacingX = 10339,
    Product_C2C_ConcreteZones_Z3Edge2SpacingY = 10340,
    Product_C2C_ConcreteZones_Z3EdgeDistanceX = 10118,
    Product_C2C_ConcreteZones_Z3EdgeDistanceY = 10119,
    Product_C2C_ConcreteZones_Z3RowsNumberX = 10112,
    Product_C2C_ConcreteZones_Z3RowsNumberY = 10113,
    Product_C2C_ConcreteZones_Z3SpacingX = 10115,
    Product_C2C_ConcreteZones_Z3SpacingY = 10116,
    Product_C2C_ConcreteZones_Z4Edge1EdgeDistanceX = 10351,
    Product_C2C_ConcreteZones_Z4Edge1EdgeDistanceY = 10352,
    Product_C2C_ConcreteZones_Z4Edge1Group = 10375,
    Product_C2C_ConcreteZones_Z4Edge1RowsNumberX = 10345,
    Product_C2C_ConcreteZones_Z4Edge1RowsNumberY = 10346,
    Product_C2C_ConcreteZones_Z4Edge1SpacingX = 10348,
    Product_C2C_ConcreteZones_Z4Edge1SpacingY = 10349,
    Product_C2C_ConcreteZones_Z4Edge2EdgeDistanceX = 10360,
    Product_C2C_ConcreteZones_Z4Edge2EdgeDistanceY = 10361,
    Product_C2C_ConcreteZones_Z4Edge2Group = 10376,
    Product_C2C_ConcreteZones_Z4Edge2RowsNumberX = 10354,
    Product_C2C_ConcreteZones_Z4Edge2RowsNumberY = 10355,
    Product_C2C_ConcreteZones_Z4Edge2SpacingX = 10357,
    Product_C2C_ConcreteZones_Z4Edge2SpacingY = 10358,
    Product_C2C_ConcreteZones_Z4EdgeDistanceX = 10127,
    Product_C2C_ConcreteZones_Z4EdgeDistanceY = 10128,
    Product_C2C_ConcreteZones_Z4RowsNumberX = 10121,
    Product_C2C_ConcreteZones_Z4RowsNumberY = 10122,
    Product_C2C_ConcreteZones_Z4SpacingX = 10124,
    Product_C2C_ConcreteZones_Z4SpacingY = 10125,
    Product_C2C_ConcreteZones_ZoneEdgeAreaGroup = 10472,
    Product_C2C_ConcreteZones_ZoneEdgeDistanceGroup = 10271,
    Product_C2C_ConcreteZones_ZoneRowsNumberGroup = 10269,
    Product_C2C_ConcreteZones_ZoneSpacingGroup = 10270,
    Product_C2C_ConnectorEmbedmentDepth = 10064,
    Product_C2C_ConnectorEmbedmentDepthMode = 10065,
    Product_C2C_ConnectorEmbedmentDepthNew = 10274,
    Product_C2C_ConnectorEmbedmentDepthSelection = 10218,
    Product_C2C_ConnectorEmbedmentGroup = 10066,
    Product_C2C_ConnectorFamily = 10067,
    Product_C2C_ConnectorNumber = 10069,
    Product_C2C_ConnectorOverstrength = 10455,
    Product_C2C_ConnectorOverstrengthValue = 10456,
    Product_C2C_ConnectorSize = 10071,
    Product_C2C_ConnectorType = 10070,
    Product_C2C_ConnectorViewApproval = 10068,
    Product_C2C_ConnectorViewUKTAApproval = 10758,
    Product_C2C_ConnectorViewUKTAApprovalCheckbox = 10760,
    Product_C2C_DefineOtherParameters = 10633,
    Product_C2C_DistanceCipPir = 10742,
    Product_C2C_EpoxyCoated = 10265,
    Product_C2C_ExcessReinforcement = 10451,
    Product_C2C_ExcessReinforcement_AsProv = 10749,
    Product_C2C_ExcessReinforcement_AsReq = 10748,
    Product_C2C_ExcessReinforcement_AsReqProv = 10750,
    Product_C2C_ExcessReinforcement_OverwriteCalculatedAsProv = 10751,
    Product_C2C_FastenerItem = 10063,
    Product_C2C_IncludeReinforcementCompression = 10591,
    Product_C2C_IrregularPlacementLabel = 10703,
    Product_C2C_MinConcreteCoverOptimization = 10689,
    Product_C2C_OptimizedLayout = 10481,
    Product_C2C_OverlayCalculationMode = 10762,
    Product_C2C_OverlayEmbedmentDepthExisting = 10765,
    Product_C2C_OverlayEmbedmentDepthExistingSelection = 10780,
    Product_C2C_OverlayEmbedmentDepthMode = 10764,
    Product_C2C_OverlayEmbedmentDepthNew = 10766,
    Product_C2C_OverlayEmbedmentDepthNewSelection = 10781,
    Product_C2C_OverlayOptimizationType = 10763,
    Product_C2C_Overstrength = 10449,
    Product_C2C_OverstrengthValue = 10450,
    Product_C2C_PirRebarSpacing = 10756,
    Product_C2C_PostInstalledRebar_DefineConditions = 10678,
    Product_C2C_PostInstalledRebar_MinDiameter = 10737,
    Product_C2C_PostInstalledRebar_RebarPointInputX = 10657,
    Product_C2C_PostInstalledRebar_RebarPointInputY = 10658,
    Product_C2C_PostInstalledRebar_RebarPoints = 10655,
    Product_C2C_ProductFilters = 10062,
    Product_C2C_RebarEpoxyCoated = 10448,
    Product_C2C_RebarFamily = 10031,
    Product_C2C_RebarItem = 10503,
    Product_C2C_RebarMaterialFyk = 10592,
    Product_C2C_RebarMaterialFyk2D = 10728,
    Product_C2C_RebarNumber = 10034,
    Product_C2C_RebarSize = 10035,
    Product_C2C_RebarType = 10440,
    Product_C2C_RecommenededFireRebarFamilyId = 10782,
    Product_C2C_ReinforcementOption = 10571,
    Product_C2C_SelectedProductDetailed = 10072,
    Product_C2C_ShowBottomLayer = 10628,
    Product_C2C_ShowTopLayer = 10627,
    Product_C2C_TopEmbedmentDepth = 10027,
    Product_C2C_TopLayer1_Diameter = 10572,
    Product_C2C_TopLayer1_MinSideCover = 10724,
    Product_C2C_TopLayer1_NumberOfBars = 10619,
    Product_C2C_TopLayer1_Show = 10629,
    Product_C2C_TopLayer1_SideCover = 10691,
    Product_C2C_TopLayer1_Spacing = 10620,
    Product_C2C_TopLayer1_SpacingBarOption = 10573,
    Product_C2C_TopLayer1_TopCover = 10574,
    Product_C2C_TopLayer2_Diameter = 10575,
    Product_C2C_TopLayer2_MinSideCover = 10725,
    Product_C2C_TopLayer2_NumberOfBars = 10621,
    Product_C2C_TopLayer2_Show = 10630,
    Product_C2C_TopLayer2_SideCover = 10692,
    Product_C2C_TopLayer2_Spacing = 10622,
    Product_C2C_TopLayer2_SpacingBarOption = 10576,
    Product_C2C_TopLayer2_TopCover = 10577,
    Product_C2C_TopLayerBondCondition = 10578,
    Product_C2C_TopLayersCount = 10685,
    Product_C2C_TopRebarDiameter = 10019,
    Product_C2C_TopRebarDiameterGroup = 10036,
    Product_C2C_TopRebarEmbedmentGroup = 10037,
    Product_C2C_TopRebarSpacingGroup = 10038,
    Product_C2C_UKTAApprovalUrls = 10761,
    Product_C2C_UltimateStrength = 10498,
    Product_C2C_ViewApproval = 10032,
    Product_C2C_ViewUKTAApproval = 10757,
    Product_C2C_ViewUKTAApprovalCheckbox = 10759,
    Product_C2C_YieldStrength = 10497,
    Product_C2C_Zone1EdgeAreaEdge1Group = 10767,
    Product_C2C_Zone1EdgeAreaEdge2Group = 10768,
    Product_C2C_Zone1Group = 10775,
    Product_C2C_Zone2EdgeAreaEdge1Group = 10769,
    Product_C2C_Zone2EdgeAreaEdge2Group = 10770,
    Product_C2C_Zone2Group = 10776,
    Product_C2C_Zone3EdgeAreaEdge1Group = 10771,
    Product_C2C_Zone3EdgeAreaEdge2Group = 10772,
    Product_C2C_Zone3Group = 10777,
    Product_C2C_Zone4EdgeAreaEdge1Group = 10773,
    Product_C2C_Zone4EdgeAreaEdge2Group = 10774,
    Product_C2C_Zone4Group = 10778,
    Rebar_C2C_Reinforcement_SpliceClass = 10454,
    Rebar_C2C_Reinforcement_SpliceToExistingReinforcement = 10453,
    Reinforcement_C2C_BottomLayer_Layer1_MinSideCover = 10746,
    Reinforcement_C2C_BottomLayer_Layer2_MinSideCover = 10747,
    Reinforcement_C2C_CastInSplicedBarType = 10427,
    Reinforcement_C2C_CastInSplicedDiameterId = 10428,
    Reinforcement_C2C_CastInSplicedEpoxyCoated = 10430,
    Reinforcement_C2C_CastInSplicedGrade = 10429,
    Reinforcement_C2C_CastInSplicedLocationFactorId = 10437,
    Reinforcement_C2C_ConcreteReinforcement_Cover = 10717,
    Reinforcement_C2C_LinkReinforcement = 10740,
    Reinforcement_C2C_Longitudinal_Arrangement_FrontCover = 10709,
    Reinforcement_C2C_Longitudinal_BottomBarsL1 = 10558,
    Reinforcement_C2C_Longitudinal_BottomBarsL2 = 10567,
    Reinforcement_C2C_Longitudinal_BottomBondCondition = 10562,
    Reinforcement_C2C_Longitudinal_BottomDiameterIdL1 = 10555,
    Reinforcement_C2C_Longitudinal_BottomDiameterIdL2 = 10564,
    Reinforcement_C2C_Longitudinal_BottomLayer = 10552,
    Reinforcement_C2C_Longitudinal_BottomLayersCount = 10553,
    Reinforcement_C2C_Longitudinal_BottomShapeType = 10561,
    Reinforcement_C2C_Longitudinal_BottomSideCoverL1 = 10560,
    Reinforcement_C2C_Longitudinal_BottomSideCoverL2 = 10569,
    Reinforcement_C2C_Longitudinal_BottomSpacingBarsOptionL1 = 10556,
    Reinforcement_C2C_Longitudinal_BottomSpacingBarsOptionL2 = 10565,
    Reinforcement_C2C_Longitudinal_BottomSpacingL1 = 10557,
    Reinforcement_C2C_Longitudinal_BottomSpacingL2 = 10566,
    Reinforcement_C2C_Longitudinal_BottomTopCoverL1 = 10559,
    Reinforcement_C2C_Longitudinal_BottomTopCoverL2 = 10568,
    Reinforcement_C2C_Longitudinal_Layer_FrontCover = 10708,
    Reinforcement_C2C_Longitudinal_Option = 10525,
    Reinforcement_C2C_Longitudinal_ShowBottomLayerOne = 10554,
    Reinforcement_C2C_Longitudinal_ShowBottomLayerTwo = 10563,
    Reinforcement_C2C_Longitudinal_ShowTopLayerOne = 10534,
    Reinforcement_C2C_Longitudinal_ShowTopLayerTwo = 10543,
    Reinforcement_C2C_Longitudinal_TopBarsL1 = 10538,
    Reinforcement_C2C_Longitudinal_TopBarsL2 = 10547,
    Reinforcement_C2C_Longitudinal_TopBondCondition = 10542,
    Reinforcement_C2C_Longitudinal_TopDiameterIdL1 = 10535,
    Reinforcement_C2C_Longitudinal_TopDiameterIdL2 = 10544,
    Reinforcement_C2C_Longitudinal_TopLayer = 10532,
    Reinforcement_C2C_Longitudinal_TopLayersCount = 10533,
    Reinforcement_C2C_Longitudinal_TopShapeType = 10541,
    Reinforcement_C2C_Longitudinal_TopSideCoverL1 = 10540,
    Reinforcement_C2C_Longitudinal_TopSideCoverL2 = 10549,
    Reinforcement_C2C_Longitudinal_TopSpacingBarsOptionL1 = 10536,
    Reinforcement_C2C_Longitudinal_TopSpacingBarsOptionL2 = 10545,
    Reinforcement_C2C_Longitudinal_TopSpacingL1 = 10537,
    Reinforcement_C2C_Longitudinal_TopSpacingL2 = 10546,
    Reinforcement_C2C_Longitudinal_TopTopCoverL1 = 10539,
    Reinforcement_C2C_Longitudinal_TopTopCoverL2 = 10548,
    Reinforcement_C2C_LongitudinalLocationFactorId = 10425,
    Reinforcement_C2C_LongitudinalReinforcement_BondCondition = 10530,
    Reinforcement_C2C_LongitudinalReinforcement_Cover = 10529,
    Reinforcement_C2C_LongitudinalReinforcement_Diameter = 10526,
    Reinforcement_C2C_LongitudinalReinforcement_NumberOfBars = 10646,
    Reinforcement_C2C_LongitudinalReinforcement_NumberOfBarsX = 10527,
    Reinforcement_C2C_LongitudinalReinforcement_NumberOfBarsY = 10528,
    Reinforcement_C2C_LongitudinalReinforcement_Shape = 10531,
    Reinforcement_C2C_Materials_Fyk = 10524,
    Reinforcement_C2C_TopLayer_Layer1_MinSideCover = 10744,
    Reinforcement_C2C_TopLayer_Layer2_MinSideCover = 10745,
    Reinforcement_C2C_TransverseDetailedDefinition = 10409,
    Reinforcement_C2C_TransverseKtr = 10413,
    Reinforcement_C2C_TransverseNumberOfBars = 10412,
    Reinforcement_C2C_TransverseReinforcement_BottomBottomCoverL1 = 10607,
    Reinforcement_C2C_TransverseReinforcement_BottomBottomCoverL2 = 10610,
    Reinforcement_C2C_TransverseReinforcement_BottomDiameterIdL1 = 10605,
    Reinforcement_C2C_TransverseReinforcement_BottomDiameterIdL2 = 10608,
    Reinforcement_C2C_TransverseReinforcement_BottomLayer = 10603,
    Reinforcement_C2C_TransverseReinforcement_BottomSpacingL1 = 10606,
    Reinforcement_C2C_TransverseReinforcement_BottomSpacingL2 = 10609,
    Reinforcement_C2C_TransverseReinforcement_Define = 10593,
    Reinforcement_C2C_TransverseReinforcement_DefineOtherParameters = 10613,
    Reinforcement_C2C_TransverseReinforcement_Option = 10594,
    Reinforcement_C2C_TransverseReinforcement_ShowBottomLayerOne = 10617,
    Reinforcement_C2C_TransverseReinforcement_ShowBottomLayerTwo = 10618,
    Reinforcement_C2C_TransverseReinforcement_ShowTopLayerOne = 10615,
    Reinforcement_C2C_TransverseReinforcement_ShowTopLayerTwo = 10616,
    Reinforcement_C2C_TransverseReinforcement_StirrupsCover = 10614,
    Reinforcement_C2C_TransverseReinforcement_StirrupsDiameterId = 10611,
    Reinforcement_C2C_TransverseReinforcement_StirrupsSpacing = 10612,
    Reinforcement_C2C_TransverseReinforcement_TopDiameterIdL1 = 10597,
    Reinforcement_C2C_TransverseReinforcement_TopDiameterIdL2 = 10600,
    Reinforcement_C2C_TransverseReinforcement_TopLayer = 10595,
    Reinforcement_C2C_TransverseReinforcement_TopSpacingL1 = 10598,
    Reinforcement_C2C_TransverseReinforcement_TopSpacingL2 = 10601,
    Reinforcement_C2C_TransverseReinforcement_TopTopCoverL1 = 10599,
    Reinforcement_C2C_TransverseReinforcement_TopTopCoverL2 = 10602,
    Reinforcement_C2C_TransverseReinforcement_UnfavorableTolerance = 10683,
    Reinforcement_C2C_TransverseSpacing = 10411,
    Reinforcement_C2C_TransverseTotalCrossSectionalArea = 10410,
    Reinforcement_C2C_YieldStrengthTransverseReinforcement = 10755,
    SupportStructure_C2C_Depth = 10203,
    SupportStructure_C2C_Height = 10201,
    SupportStructure_C2C_Width = 10202,
    None = 0,
    Option_DesignName = 434,
    ProjectName = 435,
    Option_UnitLength = 437,
    Option_UnitArea = 438,
    Option_UnitStress = 439,
    Option_UnitForce = 440,
    Option_UnitMoment = 441,
    Option_UnitTemperature = 442,
    Option_RegionId = 443,
    ProjectDesignType = 445,
    Option_UnitForcePerLength = 677,
    Option_UnitMomentPerLength = 1078,
    Option_UnitDensity = 1107,
    Option_UnitAreaPerLength = 2044
}
