import { CodeList } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

export enum ProjectCodeList {
    Region,
    DesignStandard,
    DesignType,
    EdgeDistanceDisplayType,
    DesignMethodGroup,
    SteelGuidelines,
    ConcreteInCompressionMethod,
    ConcreteSafetyFactorGammaC,
    AdvancedCalculationType,
    BrickGroup
}

export abstract class CodeListServiceBase {
    public abstract projectCodeLists: Record<ProjectCodeList, CodeList[]>;
}

export class CodeListServiceInjected extends CodeListServiceBase {
    private baseService!: CodeListServiceBase;

    public get projectCodeLists(): Record<ProjectCodeList, CodeList[]> {
        return this.baseService.projectCodeLists;
    }

    public setBaseService(baseService: CodeListServiceBase) {
        this.baseService = baseService;
    }
}
