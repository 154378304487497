import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { DeckingSubstitutionAreaFactoryService } from  './../../../services/decking-areas/factory/decking-substitution-area-factory.service';
import { BaseDefaultFactory } from './base-default-factory';
import { DeckingUserSettingsService } from  './../../../services/decking-user-settings/user-settings.service';
import { LocalizationService } from  './../../../services/external/localization.service';
import { DeckingDocumentService } from  './../../../services/decking-document/decking-document.service';
import { CommonCodeListService } from  './../../../services/external/common-code-list.service';
import { DeckingSubstitution } from './../../../entities/decking-substitution/decking-substitution';
import { IDeckingUserSettings } from './../../../entities/settings/decking-user-settings';
import { DeckingDesignModeType } from './../../../entities/enums/decking-design-mode-type';
import { SubstitutionAreaModel } from './../../../entities/decking-substitution/substitution-area';
import { SubstitutionSettings } from './../../../entities/settings/substitution-settings';
import { SharedEnvironmentService } from '../../external/shared-environment.service';
import { DeckingDefaultFactoryService } from './decking-default-factory.service';

@Injectable({
    providedIn: 'root'
})
// This class is used to set all default values for Decking when a new design is created.
export class DeckingSubstitutionDefaultFactoryService extends BaseDefaultFactory {
    constructor(
        private areaFactoryService: DeckingSubstitutionAreaFactoryService,
        protected override userSettingsService: DeckingUserSettingsService,
        protected override localization: LocalizationService,
        protected override document: DeckingDocumentService,
        protected override commonCodeList: CommonCodeListService,
        protected override sharedEnvironmentService: SharedEnvironmentService,
    ) {
        super(userSettingsService, localization, document, commonCodeList, sharedEnvironmentService);
    }

    buildDefaultDeckingSubstitution(projectId: string, customName: string = null): DeckingSubstitution {
        return this.buildDeckingSubstitutionFromGeneralSettings(projectId, this.userSettingsService.deckingSettings, customName);
    }

    buildDefaultSubstitutionFromTemplate(projectId: string, customName: string = null, substitutionFromTemplate?: DeckingSubstitution): DeckingSubstitution {
        substitutionFromTemplate.id = uuidv4();
        substitutionFromTemplate.eTag = uuidv4();
        substitutionFromTemplate.name = customName ?? this.createDeckingDesignName(projectId, true);
        return substitutionFromTemplate;
    }

    buildDeckingSubstitutionFromGeneralSettings(projectId: string, substitutionSettings: IDeckingUserSettings, customName: string = null): DeckingSubstitution {
        return this.buildDeckingSubstitutionFromSettings(projectId, this.buildSettings(substitutionSettings), customName);
    }

    buildDeckingSubstitutionFromSettings(projectId: string, substitutionSettings: SubstitutionSettings, customName: string = null): DeckingSubstitution {
        return {
            id: uuidv4(),
            version: DeckingDefaultFactoryService.SUBSTITUTION_CURRENT_VERSION,
            name: customName ?? this.createDeckingDesignName(projectId, true),
            eTag: uuidv4(),
            areas: [
                this.getDefaultArea(substitutionSettings)
            ],
            currentAreaIndex: 0,
            currentZoneIndex: 0,
            saved: false,
            settings: substitutionSettings,
            report: this.getDefaultReportInfo(),
            isDirty: true,
            isNew:true,
            projectType: DeckingDesignModeType.SubstitutionBuilderMode
        };
    }

    getDefaultArea(substitutionSettings: SubstitutionSettings, areaCount = 0): SubstitutionAreaModel {
        return this.areaFactoryService.buildDefaultDeckingArea(areaCount, substitutionSettings);
    }
}
