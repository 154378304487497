import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { map } from 'rxjs/internal/operators/map';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { DeckingSubstitutionService } from '../services/decking-design/decking-substitution.service';
import { DefinitionOfSidelapConnectors } from './../../decking/entities/decking-code-list/enums/definition-sidelap-connectors';
import { BaseSidelapConnectorTitle } from './base-sidelap-connector-title';

@Pipe({
    name: 'substitutionSidelapConnectorTitle',
    pure: false,
})
export class SubstitutionSidelapConnectorTitle
    extends BaseSidelapConnectorTitle
    implements PipeTransform, OnDestroy
{
    constructor(
        private deckingSubstitutionService: DeckingSubstitutionService
    ) {
        super();
        this.isBySpacing$ =
            this.deckingSubstitutionService.currentSettings$.pipe(
                map(
                    (s) =>
                        s.definitionOfSidelapConnectors.id ===
                        DefinitionOfSidelapConnectors.ByConnectionSpacing
                ),
                distinctUntilChanged()
            );
            
        this.isBySpacing$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isBySpacing) => {
                this.connectorSpacingTitle = isBySpacing
                    ? 'FastenerSpacing'
                    : 'SideByConnectors';
            });
    }

    override transform(translationKey: string): string {
        return super.transform(translationKey);
    }
}
