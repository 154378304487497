import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { DesignSettings } from './../../../entities/settings/design-settings';
import { DeckingDesignAreaFactoryService } from  './../../../services/decking-areas/factory/decking-design-area-factory.service';
import { AreaModel } from './../../../entities/decking-design/area-model';
import { DeckingDesign } from './../../../entities/decking-design/decking-design';
import { DeckingUserSettingsService } from  './../../../services/decking-user-settings/user-settings.service';
import { LocalizationService } from  './../../../services/external/localization.service';
import { CommonCodeListService } from  './../../../services/external/common-code-list.service';
import { DeckingDocumentService } from  './../../../services/decking-document/decking-document.service';
import { IDeckingUserSettings } from './../../../entities/settings/decking-user-settings';
import { BaseDefaultFactory } from './base-default-factory';
import { DeckingDesignModeType } from './../../../entities/enums/decking-design-mode-type';
import { SharedEnvironmentService } from '../../external/shared-environment.service';

@Injectable({
    providedIn: 'root'
})
// This class is used to set all default values for Decking when a new design is created.
export class DeckingDefaultFactoryService extends BaseDefaultFactory {

    constructor(
        private areaFactoryService: DeckingDesignAreaFactoryService,
        protected override userSettingsService: DeckingUserSettingsService,
        protected override localization: LocalizationService,
        protected override document: DeckingDocumentService,
        protected override commonCodeList: CommonCodeListService,
        protected override sharedEnvironmentService: SharedEnvironmentService,
    ) {
        super(userSettingsService, localization, document, commonCodeList, sharedEnvironmentService);
    }

    buildDefaultDeckingDesign(projectId: string, customName: string = null): DeckingDesign {
        return this.buildDeckingDesignFromGeneralSettings(projectId, this.userSettingsService.deckingSettings, customName);
    }

    buildDefaultDesignFromTemplate(projectId: string, customName: string = null, designFromTemplate?: DeckingDesign): DeckingDesign {
        designFromTemplate.id = uuidv4();
        designFromTemplate.eTag = uuidv4();
        designFromTemplate.name = customName ? customName : this.createDeckingDesignName(projectId);
        return designFromTemplate;
    }

    buildDeckingDesignFromGeneralSettings(projectId: string, designSettings: IDeckingUserSettings, customName: string = null): DeckingDesign {
        return this.buildDeckingDesignFromSettings(projectId, this.buildSettings(designSettings), customName);
    }

    buildDeckingDesignFromSettings(projectId: string, designSettings: DesignSettings, customName: string = null): DeckingDesign {
        return {
            id: uuidv4(),
            version: DeckingDefaultFactoryService.DESIGN_CURRENT_VERSION,
            name: customName ? customName : this.createDeckingDesignName(projectId),
            eTag: uuidv4(),
            areas: [
                this.getDefaultArea(designSettings)
            ],
            currentAreaIndex: 0,
            currentZoneIndex: 0,
            saved: false,
            settings: designSettings,
            report: this.getDefaultReportInfo(),
            isDirty: true,
            isTemplate: false,
            isNew:true,
            projectType: DeckingDesignModeType.DesignMode
        };
    }

    getDefaultArea(designSettings: DesignSettings, areaCount = 0): AreaModel {
        return this.areaFactoryService.buildDefaultDeckingArea(areaCount, designSettings);
    }
}
