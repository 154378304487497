import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-message-with-button',
  templateUrl: './message-with-button.component.html',
  styleUrls: ['./message-with-button.component.scss']
})
export class MessageWithButtonComponent {

  @Input() Title: string;

  @Input() Text: string;

  @Input() ButtonText: string;

  @Input() disabled: boolean;

  @Output() ButtonClick = new EventEmitter();
}
