// import sprites info and images
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import mainSprite from './bundles/main.json';
import mainImage from './bundles/main.png';

// sprite types
export type MainSprite = keyof typeof mainSprite;

// add more sprites with |

export type SpriteClass = `decking-${MainSprite}`;

// add more sprites with ...
export const spritesInfo = {
    ...toSpriteInfo(mainSprite, 'main.png')
};

export const imagesUrl: Record<string, string> = {
    'main.png': mainImage
};

export function toSpriteInfo<T>(sprites: Record<string, T>, image: string): Record<string, T & { image: string }> {
    return Object.fromEntries((Object.entries(sprites) as [string, T & { image: string }][]).map(([spriteName, sprite]) => {
        sprite.image = image;

        return [spriteName, sprite];
    }));
}

export function includeSprites(root: Node | null, ...sprites: MainSprite[]): void {
    if (root != null) {
        let cssExists = false;
        let css = `
            .decking-sprite {
                display: block;
            }
        `;

        for (const sprite of sprites) {
            const spriteInfo = spritesInfo[sprite];

            css += `
                .decking-sprite.decking-${sprite} {
                    background-image: url(${imagesUrl[spriteInfo.image]});
                    background-position: -${spriteInfo.x}px -${spriteInfo.y}px;
                    width: ${spriteInfo.w}px;
                    min-width: ${spriteInfo.w}px;
                    height: ${spriteInfo.h}px;
                }
            `;

            cssExists = true;
        }

        if (cssExists) {
            const spriteStyle = document.createElement('style');
            spriteStyle.textContent = css;

            root.insertBefore(spriteStyle, root.firstChild);
        }
    }
}

export function includeSpritesForProductImages(root: Node | null, ...sprites: MainSprite[]): void {
    if (root != null) {
        let cssExists = false;
        let css ='';
        for (const sprite of sprites) {
            const spriteInfo = spritesInfo[sprite];
            const x = ((spriteInfo.x * 100) / (spriteInfo.tw - spriteInfo.w));
            const y = ((spriteInfo.y * 100) / (spriteInfo.th - spriteInfo.h));
            const bgWidth = spriteInfo.tw * 100 / spriteInfo.w;
            const paddingBottom = spriteInfo.h * 100 / spriteInfo.w;
            const width = spriteInfo.tw * 100 / spriteInfo.w;
            css +=`  
                   .decking-sprite.sprite-responsive.decking-${sprite}{
                    max-width: ${spriteInfo.w}px;
                    width: ${spriteInfo.w}px;
                    background-image: none;
                    background-position: 0% 0%;
                    height: auto;
                
                    &::after {
                        background-image: url(${imagesUrl[spriteInfo.image]});
                        background-position:  ${x}% ${y}%;
                        background-size: ${bgWidth}% auto;
                        padding-bottom: ${paddingBottom}%;
                        width: ${width}%;
                        display: block;
                    }
                }`;

            cssExists = true;
        }

        if (cssExists) {
            const spriteStyle = document.createElement('style');
            spriteStyle.textContent = css;

            root.insertBefore(spriteStyle, root.firstChild);
        }
    }
}

export function getSpriteAsIconStyle(sprite:MainSprite): IIconStyle{
    const spriteInfo = spritesInfo[sprite];

    return {
        'background-image': `url(${imagesUrl[spriteInfo.image]})`,
        'background-position': `-${spriteInfo.x}px -${spriteInfo.y}px`,
        'width': `${spriteInfo.w}px`,
        'min-width': `${spriteInfo.w}px`,
        'height': `${spriteInfo.h}px`,
    };
}
