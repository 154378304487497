import { Pipe, PipeTransform } from '@angular/core';
import { SubstitutionZoneModel } from '../entities/decking-substitution/substitution-zone';

@Pipe({
    name: 'filterSubstitutedZones',
    pure: false
})
export class FilterSubstitutedZones implements PipeTransform {
    transform(items: SubstitutionZoneModel[]): any {
        if (!items) {
            return items;
        }
        // The zones that have been already verified, will have a value in isDesignCorrect.
        return items.filter(item => item.zoneSubstituted.result.isDesignCorrect !== null && item.zoneSubstituted.result.isDesignCorrect !== undefined);
    }
}