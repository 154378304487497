<div class="modal-content">
    <div class="modal-header">
        <h3 id="fastenerSelection-headerModal-Title" class="modal-title" l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Title"></h3>

        <button id="fastenerSelection-headerModal-closeButton" type="button" class="close" (click)="close()">
            <span>&times;</span>
        </button>
    </div>
    <div class="modal-body info">
        <div class="section-row bottom-spacing">
            <span class="title" l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Description"></span>
        </div>

        <div class="section-row">
            <div class="container">
                <span class="title table-title child" l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table1.Title"></span>
            </div>
            <div class="image-container image-container-border">
                <span class="image decking-sprite sprite-responsive decking-sprite-bar-joist"></span>
            </div>
        </div>
        <div class="section-row">
            <table>
                <caption style="display: none;">Table 1 info</caption>
                <tr>
                    <th class="title" [innerHtml]="format('Agito.Hilti.Profis3.Decking.FastenerSelection.Table.ColumnName1' | l10n:localization.selectedLanguage, [1])"></th>
                    <th class="title" [innerHtml]="format('Agito.Hilti.Profis3.Decking.FastenerSelection.Table.ColumnName2' | l10n:localization.selectedLanguage, [2])"></th>
                    <th class="title" l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table.ColumnName3"></th>
                </tr>
                <tr>
                    <td rowspan="2" [innerHtml]="'Agito.Hilti.Profis3.Decking.FastenerSelection.Table1.Column1.Row' | l10n:localization.selectedLanguage"></td>
                    <td>
                        <div class="image-container">
                            <span class="image decking-sprite sprite-responsive decking-sprite-X-HSN24"></span>
                        </div>
                    </td>
                    <td l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table1.Column3.Row1"></td>
                </tr>
                <tr>
                    <td class="td-hidden"></td>
                    <td>
                        <div class="image-container">
                            <span class="image decking-sprite sprite-responsive decking-sprite-S-RT5-M9"></span>
                        </div>
                    </td>
                    <td l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table.Column3.Row"></td>
                </tr>
                <tr>
                    <td [innerHtml]="'Agito.Hilti.Profis3.Decking.FastenerSelection.Table1.Column1.Row3' | l10n:localization.selectedLanguage"></td>
                    <td>
                        <div class="image-container">
                            <span class="image decking-sprite sprite-responsive decking-sprite-S-MD12-24x1-5-8M-HWH5"></span>
                        </div>
                    </td>
                    <td l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table.Column3.Row"></td>
                </tr>
            </table>
        </div>
        <div class="section-row reference">
            <span [innerHtml]="'Agito.Hilti.Profis3.Decking.FastenerSelection.Table1.Reference1' | l10n:localization.selectedLanguage"></span>
        </div>
        <div class="section-row reference-bottom-spacing reference">
            <span l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table1.Reference2"></span>
        </div>

        <div class="section-row">
            <div class="container">
                <span class="title table-title child" l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table2.Title"></span>
            </div>
            <div class="image-container image-container-border">
                <span class="image decking-sprite sprite-responsive decking-sprite-StructuralSteel"></span>
            </div>
        </div>
        <div class="section-row">
            <table>
                <caption style="display: none;">Table 2 info</caption>
                <tr>
                    <th class="title" [innerHtml]="format('Agito.Hilti.Profis3.Decking.FastenerSelection.Table.ColumnName1' | l10n:localization.selectedLanguage, [1])"></th>
                    <th class="title" [innerHtml]="format('Agito.Hilti.Profis3.Decking.FastenerSelection.Table.ColumnName2' | l10n:localization.selectedLanguage, [2])"></th>
                    <th class="title" l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table.ColumnName3"></th>
                </tr>
                <tr>
                    <td [innerHtml]="'Agito.Hilti.Profis3.Decking.FastenerSelection.Table2.Column1.Row1' | l10n:localization.selectedLanguage"></td>
                    <td>
                        <div class="image-container">
                            <span class="image decking-sprite sprite-responsive decking-sprite-X-ENP-19L15"></span>
                        </div>
                    </td>
                    <td l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table2.Column3.Row1"></td>
                </tr>
            </table>
        </div>
        <div class="section-row reference">
            <span [innerHtml]="'Agito.Hilti.Profis3.Decking.FastenerSelection.Table2.Reference1' | l10n:localization.selectedLanguage"></span>
        </div>
        <div class="section-row reference-bottom-spacing-without-Image reference" >
            <span l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table2.Reference2"></span>
        </div>


        <div class="section-row">
            <span class="title" [innerHtml]="format('Agito.Hilti.Profis3.Decking.FastenerSelection.Table3.Title' | l10n:localization.selectedLanguage, [2])"></span>
        </div>
        <table>
            <caption style="display: none;">Table 3 info</caption>
            <tr>
                <th class="title" l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table3.ColumnName1"></th>
                <th class="title" [innerHtml]="format('Agito.Hilti.Profis3.Decking.FastenerSelection.Table.ColumnName2' | l10n:localization.selectedLanguage, [1])"></th>
                <th class="title" l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table.ColumnName3"></th>
            </tr>
            <tr>
                <td [innerHtml]="format('Agito.Hilti.Profis3.Decking.FastenerSelection.Table3.Column1.Row1' | l10n:localization.selectedLanguage, [3])"></td>
                <td>
                    <div class="image-container">
                        <span class="image decking-sprite sprite-responsive decking-sprite-S-SLC01M-HWH"></span>
                    </div>
                </td>
                <td l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table.Column3.Row"></td>
            </tr>
            <tr>
                <td l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table3.Column1.Row2"></td>
                <td>
                    <div class="image-container">
                        <span class="image decking-sprite sprite-responsive decking-sprite-S-SLCO2-MHWH"></span>
                    </div>
                </td>
                <td l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table.Column3.Row"></td>
            </tr>
            <tr>
                <td [innerHtml]="format('Agito.Hilti.Profis3.Decking.FastenerSelection.Table3.Column1.Row3' | l10n:localization.selectedLanguage, [4])"></td>
                <td>
                    <div class="image-container">
                        <span class="image decking-sprite sprite-responsive decking-sprite-Hilti10HWHScrew"></span>
                    </div>
                </td>
                <td l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table.Column3.Row"></td>
            </tr>
        </table>
        <div class="section-row reference">
            <span l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table3.Reference1"></span>
        </div>
        <div class="section-row reference">
            <span l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table3.Reference2"></span>
        </div>
        <div class="section-row reference">
            <span [innerHtml]="'Agito.Hilti.Profis3.Decking.FastenerSelection.Table3.Reference3' | l10n:localization.selectedLanguage"></span>
        </div>
        <div class="section-row reference-bottom-spacing reference">
            <span l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Table3.Reference4"></span>
        </div>

        <div class="section-row">
            <span [innerHtml]="additionalInformation"></span>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.Decking.FastenerSelection.Button.Ok"
            (click)="close()">
        </button>
    </div>
</div>
