import { DesignStandard } from '../../enums/design-standard';
export class DesignStandardConverter {
    static getDesignStandardFromString(value: string): DesignStandard {
        switch (value) {
            case 'AISI S310-16':
                return DesignStandard.aisi_16;
            case 'AISI S310-20':
                return DesignStandard.aisi_20;
        }
        return DesignStandard.aisi_20;
    }
}
