import { SharedEnvironmentData } from 'src/decking/entities/shared/sharedEnvironment';
import {
    ApplicationProviderService
} from 'src/decking/services/application-provider/application-provider.service';
import { CodeListService } from 'src/decking/services/external/code-list.service';
import { CodeListServiceBase } from 'src/decking/services/external/code-list.service.base';
import { DesignTemplateService } from 'src/decking/services/external/design-template.service';
import { LicenseService } from 'src/decking/services/external/license.service';
import { SharedEnvironmentService } from 'src/decking/services/external/shared-environment.service';

import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {
    IDesignListInfo, IModuleInitialData
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { ApiServiceBase } from '@profis-engineering/pe-ui-common/services/api.common';
import { BrowserServiceBase } from '@profis-engineering/pe-ui-common/services/browser.common';
import {
    CommonCodeListServiceBase
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    DesignTemplateServiceBase
} from '@profis-engineering/pe-ui-common/services/design-template.common';
import { ImportServiceBase } from '@profis-engineering/pe-ui-common/services/import.common';
import { LicenseServiceBase } from '@profis-engineering/pe-ui-common/services/license.common';
import {
    LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';
import { LoggerServiceBase } from '@profis-engineering/pe-ui-common/services/logger.common';
import { MathServiceBase } from '@profis-engineering/pe-ui-common/services/math.common';
import { ModalServiceBase } from '@profis-engineering/pe-ui-common/services/modal.common';
import { RoutingServiceBase } from '@profis-engineering/pe-ui-common/services/routing.common';
import { UnitServiceBase } from '@profis-engineering/pe-ui-common/services/unit.common';
import {
    UserSettingsServiceBase
} from '@profis-engineering/pe-ui-common/services/user-settings.common';
import { UserServiceBase } from '@profis-engineering/pe-ui-common/services/user.common';

import {
    DeckingDocumentBaseService
} from '../../services/decking-document/decking-document-base.service';
import { DeckingDocumentService } from '../../services/decking-document/decking-document.service';
import {
    ReportTemplateBaseService
} from '../../services/decking-report-template/decking-report-template-base.service';
import {
    ReportTemplateService
} from '../../services/decking-report-template/decking-report-template.service';
import { ApiService } from '../../services/external/api.service';
import { BrowserService } from '../../services/external/browser.service';
import { CommonCodeListService } from '../../services/external/common-code-list.service';
import { CommonUserSettingsService } from '../../services/external/common-user-settings.service';
import { FeatureVisibilityService } from '../../services/external/feature-visibility.service';
import {
    FeaturesVisibilityInfoService
} from '../../services/external/features-visibility-info.service';
import { ImportService } from '../../services/external/import.service';
import { LocalizationService } from '../../services/external/localization.service';
import { LoggerService } from '../../services/external/logger.service';
import { MathService } from '../../services/external/math.service';
import { ModalService } from '../../services/external/modal.service';
import { RoutingService } from '../../services/external/routing.service';
import { UnitService } from '../../services/external/unit.service';
import { UserService } from '../../services/external/user.service';

@Component({
    template: '',
    encapsulation: ViewEncapsulation.ShadowDom
})
export class UiDeckingInitComponent implements OnChanges{
    //#region Inputs
    // Common Services
    @Input()
    public localizationService!: LocalizationServiceBase;
    @Input()
    public mathService!: MathServiceBase;
    @Input()
    public unitService!: UnitServiceBase;
    @Input()
    public userService!: UserServiceBase<any>;
    @Input()
    public apiService!: ApiServiceBase;
    @Input()
    public reportTemplateService!: ReportTemplateBaseService;
    @Input()
    public loggerService!: LoggerServiceBase;
    @Input()
    public commonCodeListService!: CommonCodeListServiceBase;
    @Input()
    public documentService!: DeckingDocumentBaseService;
    @Input()
    public commonUserSettingsService!: UserSettingsServiceBase<any>;
    @Input()
    public browserService!: BrowserServiceBase;
    @Input()
    public modalService!: ModalServiceBase;
    @Input()
    public featuresVisibilityInfoService!: FeaturesVisibilityInfoService;
    @Input()
    public featureVisibilityService!: FeatureVisibilityService;
    @Input()
    public routingService!: RoutingServiceBase;
    @Input()
    public importService!: ImportServiceBase;
    @Input()
    public licenseService!: LicenseServiceBase;
    @Input()
    public peCodeListService!: CodeListServiceBase;
    @Input()
    public designTemplateService!: DesignTemplateServiceBase;
    // Decking Services
    @Input()
    public sharedEnvironmentData!: SharedEnvironmentData;
    //#endregion
    @Output()
    public init = new EventEmitter<IModuleInitialData>();

    constructor(
        // Common Services
        private localizationServiceInternal: LocalizationService,
        private mathSvc: MathService,
        private unitServiceInternal: UnitService,
        private userServiceInternal: UserService,
        private apiServiceInternal: ApiService,
        private reportTemplateServiceInternal: ReportTemplateService,
        private loggerServiceInternal: LoggerService,
        private commonCodeListServiceInternal: CommonCodeListService,
        private documentServiceInternal: DeckingDocumentService,
        private commonUserSettingsServiceInternal: CommonUserSettingsService,
        private browserServiceInternal: BrowserService,
        private modalServiceInternal: ModalService,
        private FeaturesVisibilityInfoServiceInternal: FeaturesVisibilityInfoService,
        private FeatureVisibilityServiceInternal: FeatureVisibilityService,
        private RoutingServiceInternal: RoutingService,
        private importServiceInternal: ImportService,
        private licenseServiceInternal: LicenseService,
        private peCodeListServiceInternal: CodeListService,
        private designTemplateSvc: DesignTemplateService,
        // Decking Services
        private applicationProviderService: ApplicationProviderService,
        protected sharedEnvironmentServiceInternal: SharedEnvironmentService
    ) { }

    public async ngOnChanges(changes: SimpleChanges) {
        try {
            // Common
            this.initService(changes['localizationService'], () => this.localizationServiceInternal.setBaseService(this.localizationService));
            this.initService(changes['mathService'], () => this.mathSvc.setBaseService(this.mathService));
            this.initService(changes['unitService'], () => this.unitServiceInternal.setBaseService(this.unitService));
            this.initService(changes['userService'], () => this.userServiceInternal.setBaseService(this.userService));
            this.initService(changes['apiService'], () => this.apiServiceInternal.setBaseService(this.apiService));
            this.initService(changes['reportTemplateService'], () => this.reportTemplateServiceInternal.setBaseService(this.reportTemplateService));
            this.initService(changes['loggerService'], () => this.loggerServiceInternal.setBaseService(this.loggerService));
            this.initService(changes['commonCodeListService'], () => this.commonCodeListServiceInternal.setBaseService(this.commonCodeListService));
            this.initService(changes['documentService'], () => this.documentServiceInternal.setBaseService(this.documentService));
            this.initService(changes['commonUserSettingsService'], () => this.commonUserSettingsServiceInternal.setBaseService(this.commonUserSettingsService));
            this.initService(changes['browserService'], () => this.browserServiceInternal.setBaseService(this.browserService));
            this.initService(changes['modalService'], () => this.modalServiceInternal.setBaseService(this.modalService));
            this.initService(changes['featuresVisibilityInfoService'], () => this.FeaturesVisibilityInfoServiceInternal.setBaseService(this.featuresVisibilityInfoService));
            this.initService(changes['featureVisibilityService'], () => this.FeatureVisibilityServiceInternal.setBaseService(this.featureVisibilityService));
            this.initService(changes['routingService'], () => this.RoutingServiceInternal.setBaseService(this.routingService));
            this.initService(changes['importService'], () => this.importServiceInternal.setBaseService(this.importService));
            this.initService(changes['licenseService'], () => this.licenseServiceInternal.setBaseService(this.licenseService));
            this.initService(changes['peCodeListService'], () => this.peCodeListServiceInternal.setBaseService(this.peCodeListService));
            this.initService(changes['designTemplateService'], () => this.designTemplateSvc.setBaseService(this.designTemplateService));
            // Decking
            this.initService(changes['sharedEnvironmentData'], () => this.sharedEnvironmentServiceInternal.initialize(this.sharedEnvironmentData));

            await this.applicationProviderService.initializeSettings();

            const initialData = this.getInitialData();
            this.init.emit(initialData);
        }
        catch (error) {
            console.error(error);
        }
    }

    private initService(change: SimpleChange, initFn: () => void) {
        if (change != null) {
            initFn();
        }
    }

    private getInitialData(): IModuleInitialData {
        return {
            // Quick start
            quickStartApplications: this.applicationProviderService.getQuickStartApplications(),
            // Design list info
            designListInfo: this.applicationProviderService.getDesignListInfo() as IDesignListInfo[],
            // Design types
            designTypes: this.applicationProviderService.getDesignTypes,
            // Import Provider
            importDesignProvider: this.applicationProviderService.getDeckingImportProvider(),
        } as IModuleInitialData;
    }
}
