import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDesignTemplateDocument } from '@profis-engineering/pe-ui-common/services/design-template.common';
import { ProjectAndDesignView } from '@profis-engineering/pe-ui-common/services/user.common';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { DesignTemplateService } from './../../../services/external/design-template.service';
import { LocalizationService } from './../../../services/external/localization.service';
import { DesignTypeId } from './../../../entities/enums/design-types';
import { UserService } from './../../../services/external/user.service';
import { RoutingService } from './../../../services/external/routing.service';
import { DeckingDesignService } from './../../../services/decking-design/decking-design.service';
import { DeckingDesign } from './../../../entities/decking-design/decking-design';
import { DeckingUserSettingsService } from './../../../services/decking-user-settings/user-settings.service';
import { CommonCodeListService } from 'src/decking/services/external/common-code-list.service';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { CommonUserSettingsService } from '../../../services/external/common-user-settings.service';

export interface ISaveAsTemplateComponentInput {
    designTemplateDocument: IDesignTemplateDocument;
    thumbnailId?: string;
    onTemplateSaved: (templateId: string) => void;
}

@Component({
    selector: 'app-save-as-decking-template',
    templateUrl: './save-as-decking-template.component.html',
    styleUrls: ['./save-as-decking-template.component.scss']
})
export class SaveAsDeckingTemplateComponent implements OnInit {
    public submitted: boolean;
    public name: string;
    public nameValid = false;

    public requiredValidator = Validators.required;
    public nameMaxLength = 250;

    private deckingDesign: DeckingDesign;

    constructor(
        public localization: LocalizationService,
        private designTemplate: DesignTemplateService,
        private activeModal: NgbActiveModal,
        private userSettingsService: CommonUserSettingsService,
        private deckingUserSettingsService: DeckingUserSettingsService,
        private userService: UserService,
        private routingService: RoutingService,
        private deckingDesignService: DeckingDesignService,
        private commonCodeListService: CommonCodeListService,
    ) { }

    public get titleKey() {
        return 'Agito.Hilti.Profis3.SaveAsTemplate.AddTitle';
    }

    public get formValid() {
        return this.name && this.name.trim() != '';
    }

    ngOnInit(): void {
        setTimeout(() => this.nameTextBoxFocus());
        this.deckingDesign = this.deckingDesignService.getCurrentDesign();
    }

    public async save() {
        if (this.submitted || !this.formValid) {
            return;
        }
        this.submitted = true;
        try {
            const newTemplate = await this.deckingDesignService.createDesignTemplate(this.deckingDesign.id, true, this.name);

            const designTemplateDocument = this.getDesignTemplateDocument(newTemplate.id);

            const templateId = await this.designTemplate.create(designTemplateDocument, this.deckingDesign.documentId);
            newTemplate.documentId = templateId;
            await this.deckingDesignService.saveDesign(newTemplate);
        } catch (error) {
            if (error instanceof Error) {
                console.error(error);
            }
            this.submitted = false;
        }
        this.close();

        if (this.submitted) {
            this.onTemplateSaved();
        }
    }

    public close() {
        this.activeModal.close();
    }

    private onTemplateSaved() {
        this.userService.projectAndDesignView = ProjectAndDesignView.templates;

        this.routingService.navigateToUrl(UrlPath.projectAndDesign);
    }

    private nameTextBoxFocus() {
        document.querySelector<HTMLElement>('.modal-body #create-template-name-wrapper')
            ?.shadowRoot?.querySelector<HTMLElement>('#create-template-name')
            ?.focus();
    }

    private getDesignTemplateDocument(id: string): IDesignTemplateDocument {
        return {
            templateName: this.name,
            designTypeId: DesignTypeId.DiaphragmDesign,
            designStandardId: 0,
            regionId: this.getRegionByCountryCode(this.deckingUserSettingsService.deckingSettings.region.countryCode).id,
            anchorName: undefined,
            approvalNumber: '',
            projectDesign: `{ "designId": "${id}" }`
        };
    }

    private getRegionByCountryCode(countryCode: string) {
        const regionCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(region => region.countryCode == countryCode);
    }
}
