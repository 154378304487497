import { Component } from '@angular/core';
import { AuthenticationService } from './../../../services/external/authentication.service';
import { ModalService } from './../../../services/external/modal.service';
import { OfflineService } from './../../../services/external/offline.service';

@Component({
  selector: 'app-top-bar-menu',
  templateUrl: './top-bar-menu.component.html',
  styleUrls: ['./top-bar-menu.component.scss'],
})
export class TopBarMenuComponent {

  constructor(
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private offlineService: OfflineService,
  ) { }

  public logout(): void {
    this.authenticationService.logout();
  }

  public openShortcutIconPopup(): void {
    this.modalService.openShortcutIcon();
  }

  public openManageHiltiAccount(): void {
    this.offlineService.nativeExternalLinkOpen('');
}

  public openUserSettings(): void {
    this.modalService.openUserSettings();
  }

}
