import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DeckingCommonAreasWrapperComponent } from 'src/decking/components/decking-main-left/decking-areas-common/decking-common-areas-wrapper.component';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { DeckingTabsWrapperService } from 'src/decking/services/decking-tabs-wrapper/decking-tabs-wrapper.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { AreaSummaryModel } from '../../../../entities/decking-design/area-model';
import { DeckingSubstitutionAreaFactoryService } from '../../../../services/decking-areas/factory/decking-substitution-area-factory.service';
import { DeckingSpecificationCalculationService } from '../../../../services/decking-design/calculation/specification/decking-specification-calculation.service';
import { SubstitutionAreaModel } from './../../../../entities/decking-substitution/substitution-area';

@Component({
  selector: 'substitution-areas-wrapper',
  templateUrl: './substitution-areas-wrapper.component.html',
  styleUrls: ['./substitution-areas-wrapper.component.scss']
})
export class SubstitutionAreasWrapperComponent extends DeckingCommonAreasWrapperComponent implements OnInit, OnDestroy {

  public currentSubstitutionAreas$: Observable<SubstitutionAreaModel>;

  constructor(
    public override localization: LocalizationService,
    public override deckingTabsWrapperService: DeckingTabsWrapperService,
    private deckingSubstitutionService: DeckingSubstitutionService,
    private areasFactoryService: DeckingSubstitutionAreaFactoryService,
    private deckingSpecificationService: DeckingSpecificationCalculationService
  ) { 
    super(localization,deckingTabsWrapperService);
  }

  ngOnInit(): void {
    this.saving$ = this.deckingSpecificationService.saving$;
    this.configureTooltips();
    this.setObservables(this.deckingSubstitutionService.currentAreasSummary$);
  }

  public override setObservables(currentAreasSummary$: Observable<AreaSummaryModel[]>): void {
    this.currentSubstitutionAreas$ = this.deckingSubstitutionService.currentArea$;
    super.setObservables(currentAreasSummary$);
  }

  public override addArea() {
    super.addArea();
    this.deckingSubstitutionService.addNewArea(this.defaultArea());
  }

  private defaultArea(): SubstitutionAreaModel {
    return this.areasFactoryService.buildDefaultDeckingArea(this.currentAreasSummary?.length, this.deckingSubstitutionService.getCurrentSubstitution().settings);
  }
}
