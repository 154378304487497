import { combineLatest, distinctUntilChanged, map, Observable, shareReplay } from 'rxjs';
import { OnInit, Component, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { LocalizationService } from './../../../../services/external/localization.service';
import { DeckGaugeListItem } from './../../../../entities/decking-code-list/code-list/deck-gauge-list-item';
import { FrameFastenerListItem } from './../../../../entities/decking-code-list/code-list/frame-fastener-list-item';
import { PatternListItem } from './../../../../entities/decking-code-list/code-list/pattern-list-item';
import { SidelapConnectorListItem } from './../../../../entities/decking-code-list/code-list/sidelap-connector-list-item';
import { ZoneTemplateModel } from './../../../../entities/decking-design/zone-template-model';
import { DesignSettings } from './../../../../entities/settings/design-settings';
import { DeckingCodeListService } from './../../../../services/decking-code-list/decking-code-list.service';
import { DeckingDesignService } from './../../../../services/decking-design/decking-design.service';
import { UserService } from './../../../../services/external/user.service';
import { DeckingUnitsHelperService } from './../../../..//services/decking-units-helper/decking-units-helper.service';
import { ZoneTemplateService } from './../../../../services/decking-zoneTemplates/decking-zoneTemplates.service';
import { ModalService } from './../../../../services/external/modal.service';
import { ZoneModel } from './../../../../entities/decking-design/zone-model';
import { AreaModel } from './../../../../entities/decking-design/area-model';
import { DefinitionOfSidelapConnectors } from './../../../../entities/decking-code-list/enums/definition-sidelap-connectors';
import { includeSprites } from '../.././../../sprites';

@Component({
    selector: 'app-zone-templates',
    templateUrl: './zone-templates.component.html',
    styleUrls: ['./zone-templates.component.scss']
})
export class ZoneTemplatesComponent implements OnInit  {

    @Input() dropdownZoneTemplate = 'dropdownZoneTemplate';

    public currentArea$: Observable<AreaModel>;
    public sidelapSpacingUnit$: Observable<Unit>;
    public isBySpacing$: Observable<boolean>;
    public lengthUnit$: Observable<Unit>;
    public currentZones: ZoneModel[];

    public currentSettings$: Observable<DesignSettings>;
    public isImperialUnit$: Observable<boolean>;

    // Dropdowns
    public deckGaugeItems$: Observable<DropdownItem<DeckGaugeListItem>[]>;
    public patternItems$: Observable<DropdownItem<PatternListItem>[]>;
    public frameItems$: Observable<DropdownItem<FrameFastenerListItem>[]>;
    public sidelapItems$: Observable<DropdownItem<SidelapConnectorListItem>[]>;

    // Active CodeListService
    public currentDeckGauge: DropdownItem<DeckGaugeListItem>[];
    public currentPatternListItem: DropdownItem<PatternListItem>[];
    public currentFrameItems: DropdownItem<FrameFastenerListItem>[];
    public currentSidelapItems: DropdownItem<SidelapConnectorListItem>[];

    // Templates dropdown
    public selectedTemplate: Partial<ZoneTemplateModel>;
    public templates: DropdownProps<Partial<ZoneTemplateModel>>;
    public selectedZoneIndexes: Set<number> = new Set();
    public isCreatingNewTemplate: boolean;
    public isSubmitting: boolean;
    public requiredValidator = Validators.required;
    public newTemplateName: string;
    public duplicatedTemplateName :boolean;

    // currentTemplate
    public emptyZoneTemplate: Partial<ZoneTemplateModel> = {
        name: null,
        deckGauge: null,
        pattern: null,
        frameFastener: null,
        sidelapConnector: null,
        side: null
    };

    public currentTemplate: Partial<ZoneTemplateModel>;
    public currentZoneModel: Partial<ZoneTemplateModel>;
    public hasChangesNotSaved: boolean;

    public DECK_GAUGE_UNIT: string;

    constructor(
        public elementRef: ElementRef<HTMLElement>,
        public activeModal: NgbActiveModal,
        private deckingDesignService: DeckingDesignService,
        public zoneTemplateService: ZoneTemplateService,
        private deckingCodeListService: DeckingCodeListService,
        private deckingUnitsHelperService: DeckingUnitsHelperService,
        public localization: LocalizationService,
        private modal: ModalService,
        protected userService: UserService,
        protected cdr: ChangeDetectorRef
    ) {
        this.DECK_GAUGE_UNIT = this.deckingUnitsHelperService.DECK_GAUGE_UNIT;
    } 

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,            
            'sprite-export-design',
            'sprite-duplicate-design',            
            'sprite-delete-active'
          );
        this.currentArea$ = this.deckingDesignService.currentArea$;
        this.currentArea$.subscribe(currentArea => { this.currentZones = currentArea.zones; });
        this.lengthUnit$ = this.deckingDesignService.currentSettings$.pipe(
            map(s => s.length.id),
            distinctUntilChanged(),
        );
        this.isBySpacing$ = this.deckingDesignService.currentSettings$.pipe(
            map(s => s.definitionOfSidelapConnectors.id === DefinitionOfSidelapConnectors.ByConnectionSpacing),
            distinctUntilChanged()
        );

        this.sidelapSpacingUnit$ = combineLatest([
            this.lengthUnit$,
            this.isBySpacing$,
        ]).pipe(
            map(([lengthUnit, isBySpacing]) =>
                isBySpacing ? lengthUnit : Unit.None
            )
        );
        this.currentSettings$ = this.deckingDesignService.currentSettings$;
        this.isImperialUnit$ = this.currentSettings$.pipe(map(s => s.length.id),
            distinctUntilChanged(),
            map((unitValue: Unit) => {
                return !this.deckingUnitsHelperService.isInternationalSystemUnit(unitValue);
            }));

        this.initDropDowns();
        this.initTemplatesDropdown();
        this.initCurrentCodeList();        
        this.currentTemplate = this.emptyZoneTemplate;                
    }     

    private initCurrentCodeList(): void {
        this.deckGaugeItems$.subscribe(deckingGauge => {
            this.currentDeckGauge = deckingGauge;
        });

        this.patternItems$.subscribe(patternListItem => {
            this.currentPatternListItem = patternListItem;
        });

        this.frameItems$.subscribe(frameItem => {
            this.currentFrameItems = frameItem;
        });

        this.sidelapItems$.subscribe(sidelapItem => {
            this.currentSidelapItems = sidelapItem;
        });
    } 

    customDropdownsStyles(): void {        
        const customStyle = 'background-color: inherit; width: 32px; background-image: url(cdn/pe-ui-common/c08c8fb27269ff90.png); background-position: -74px -90px; height: 32px;';
        const items: Element | null = document.querySelector('.dropdownZoneTemplate')?.shadowRoot?.querySelector('.dropdown-wrapper .control-container .dropdown-items');
        if (!items) return;    
        for (let i = 0; i < items?.children?.length; i++) {
            const itemTagElement = items.children[i].querySelector('.item > .tag');    
            if (itemTagElement) {            
                itemTagElement.setAttribute('style',customStyle);
            }
        }
    }

    private initDropDowns(): void {
        const panelId$ = this.currentArea$.pipe(
            map((a) => a.deckPanel.id),
            distinctUntilChanged()
        );
        const panelType$ = this.currentArea$.pipe(
            map((a) => a.panelType.id),
            distinctUntilChanged()
        );
        const panelWidth$ = this.currentArea$.pipe(
            map((a) => a.panelWidth.value),
            distinctUntilChanged()
        );

        this.deckGaugeItems$ = panelId$.pipe(
            map((panelId) =>
                this.deckingCodeListService.GetDeckGaugesDropdownItems(panelId)
            ),
            shareReplay(1)
        );
        this.patternItems$ = combineLatest([
            panelId$,
            panelWidth$,
            this.isImperialUnit$,
        ]).pipe(
            map(([panelId, panelWidth, isImperialUnit]) =>
                this.deckingCodeListService.GetPatternsDropdownItems(
                    panelId,
                    panelWidth,
                    isImperialUnit
                )
            ),
            shareReplay(1)
        );
        this.frameItems$ = panelId$.pipe(
            map((panelId) =>
                this.deckingCodeListService.GetFrameFastenersDropdownItems(
                    panelId
                )
            ),
            shareReplay(1)
        );
        this.sidelapItems$ = combineLatest([panelId$, panelType$]).pipe(
            map(([panelId, panelType]) =>
                this.deckingCodeListService.GetSidelapConnectorsDropdownItems(
                    panelId,
                    panelType
                )
            ),
            shareReplay(1)
        );
    }

    async initTemplatesDropdown(): Promise<void> {
        this.templates = {
            id: 'zone-templates-dropdown',
            title: 'Zone templates',
            items: [
                {
                    value: null,
                    text: 'Create new template',
                    isSpecial: true,
                }
            ],
            selectedValue: null,
        };
        const userId =  this.userService?.authentication?.userId?.replace('|', '');
        const zoneTemplates = await this.zoneTemplateService.getZoneTemplatesByUserId(userId);
        if (!(zoneTemplates?.length)) return ; 
        const mappedTemplates = zoneTemplates.filter(template => template?.name)
        .map(template => ({            // Create a new object with the updated properties
            id: template.id,
            text: template.name,
            disabled: this.isNotValidZoneTemplate(template),                                                                       
            ...(this.isNotValidZoneTemplate(template) ? { tags : [{tooltip:this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.AreaName.Tooltip.NotValidZoneTemplate'),text:''}], } : {}),
            value: {
                ...template,
            }
        }));
        this.templates.items.push(...mappedTemplates);        
    }

    isNotValidZoneTemplate(template: ZoneTemplateModel) {
        if (!template) { return false; }
        const isTemplateInCurrentDeckGauge: boolean = this.hasIdInCollection(this.currentDeckGauge, template?.deckGauge?.id);
        const isTemplateInCurrentPatternList: boolean = this.hasIdInCollection(this.currentPatternListItem, template?.pattern?.id);
        const isTemplateInCurrentFrameItems: boolean = this.hasIdInCollection(this.currentFrameItems, template?.frameFastener?.id);
        const isTemplateInCurrentSidelapItems: boolean = this.hasIdInCollection(this.currentSidelapItems, template?.sidelapConnector?.id);
        if (
            isTemplateInCurrentDeckGauge &&
            isTemplateInCurrentPatternList &&
            isTemplateInCurrentFrameItems &&
            isTemplateInCurrentSidelapItems
        ) { return false; }
        return true;
    }

    private hasIdInCollection(array: Array<{ value: { id: unknown } }>, id: unknown): boolean {    
        return array?.some(item => item?.value?.id === id);
    }

    public setNameZoneTemplate(currentName: string): boolean {     
        this.duplicatedTemplateName = this.templates?.items?.some(item  => item?.value?.name === currentName);
        this.newTemplateName = this.duplicatedTemplateName ? this.newTemplateName : currentName;
        return  this.duplicatedTemplateName;
    }   

    get areInputsDisabled(): boolean {
        return !this.currentTemplate.id && !this.hasChangesNotSaved;
    }

    public templateSelected(event: Partial<ZoneTemplateModel> | null): void {        
        if (event) {
            this.currentTemplate = event;
        } else {
            this.isCreatingNewTemplate = true;
            this.newTemplateName = '';
        }
    }

    newTemplateNameSumitted() {
        this.isCreatingNewTemplate = false;
        this.resetDropdownValues();
        this.currentTemplate = this.emptyZoneTemplate;
        this.currentTemplate.name = this.newTemplateName;
        this.selectedTemplate = null;
        this.hasChangesNotSaved = true;
    }

    newTemplateCancel(): void {
        this.newTemplateName = '';
        this.isCreatingNewTemplate = false;
    }

    selectallZones(event: Event, zones: Array<ZoneModel>) {
        const checkbox = event.target as HTMLInputElement;
        if (checkbox.checked) {
            zones.forEach((_, index) => this.selectedZoneIndexes.add(index));
        } else {
            this.selectedZoneIndexes.clear();
        }
    }

    isZoneSelected(index: number): boolean {
        return this.selectedZoneIndexes.has(index);
    }

    selectZone(event: Event, zoneIndex: number): void {
        const checkbox = event.target as HTMLInputElement;
        if (checkbox.checked) {
            this.selectedZoneIndexes.add(zoneIndex);
        } else {
            this.selectedZoneIndexes.delete(zoneIndex);
        }
    }

    close(): void {
        this.activeModal.close();
    }

    get canBeSubmitted(): boolean {
        return !this.isSubmitting &&
            this.selectedZoneIndexes.size !== 0 &&
            (this.currentTemplate?.id ?? null) !== null;
    }

    async saveZoneTemplate(): Promise<void> {
        this.currentZoneModel = this.currentTemplate;     
        const zoneTemplate = this.currentTemplate.id ? await this.zoneTemplateService.updateZoneTemplate(this.currentZoneModel as ZoneTemplateModel) : await this.zoneTemplateService.createZoneTemplate(this.currentZoneModel as ZoneTemplateModel);
        this.currentTemplate = zoneTemplate;
        this.hasChangesNotSaved = false;
        await this.initTemplatesDropdown();
        this.selectedTemplate = this.templates.items.find(item => item.id === this.currentTemplate.id)?.value;
    }

    async copyZoneTemplate(): Promise<void> {
        this.currentTemplate.name = `${this.currentTemplate.name}-Copy`;
        if (!this.currentTemplate.id) return;
        delete this.currentTemplate.id;
        delete this.currentTemplate.etag;
        this.hasChangesNotSaved = true;
    }

    async deleteZoneTemplate(): Promise<void> {
        if (!this.currentTemplate.id) return;
        this.modal.openConfirmChange({
            id: 'confirm-delete-template',
            title: this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.Templates.Delete.Title') + ' ' + this.currentTemplate.name,
            message: this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.Templates.Delete.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.Templates.Delete.Yes'),
            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.Templates.Delete.No'),
            onCancel: (modal) => {
                modal.close();
            },
            onConfirm: async (modal) => {
                modal.close();
                await this.zoneTemplateService.deleteZoneTemplate(this.currentTemplate as ZoneTemplateModel);
                this.resetZoneTemplates();
            }
        });
    }

    resetZoneTemplates() {
        this.resetDropdownValues();
        this.currentTemplate = this.emptyZoneTemplate;
        this.currentTemplate.name = '';
        this.initTemplatesDropdown();
        this.isCreatingNewTemplate = false;
        this.hasChangesNotSaved = false;
        this.detectChanges();
    }

    resetDropdownValues() {
        this.currentTemplate.deckGauge = null;
        this.currentTemplate.pattern = null;
        this.currentTemplate.frameFastener = null;
        this.currentTemplate.sidelapConnector = null;
        this.currentTemplate.side = null;
    }

    applyTemplateToZones() {
        this.isSubmitting = true;
        this.updateCurrentAreaByTemplate(this?.currentTemplate, this?.selectedZoneIndexes);
        this.activeModal?.close();
    }

    updateCurrentAreaByTemplate(pTemplate: Partial<ZoneTemplateModel>, selectedZones: Set<number>): void {
        const zonesCount = this.currentZones.length;
        for (let i = 0; i < zonesCount; i++) {
            if (selectedZones.has(i)) {
                this.currentZones[i].deckGauge = pTemplate?.deckGauge;
                this.currentZones[i].pattern = pTemplate?.pattern;
                this.currentZones[i].frameFastener = pTemplate?.frameFastener;
                this.currentZones[i].sidelapConnector = pTemplate?.sidelapConnector;
                this.currentZones[i].side = pTemplate?.side;
                this.currentZones[i].isDirty = true;
            }
        }
        this.deckingDesignService.updateZones(this.currentZones);
    }

    protected detectChanges() {
        this.cdr.detach();
        this.cdr.detectChanges();
        this.cdr.reattach();
    }
}