<div id="decking_report_action_buttons">
    <button id="reportModal-footer-closeButton" type="button" class="modal-button button button-default" [disabled]="isCancelButtonDisabled"
        l10n="Agito.Hilti.Profis3.Decking.Report.ActionButtons.Cancel" (click)="close()">
    </button>
    <button id="reportModal-footer-saveButton" type="submit" class="modal-button button button-CTA" [disabled]="isSaveButtonDisabled" (click)="save()">
        <pe-loading-button [loading]="isLoading">
            <span class="text" l10n="Agito.Hilti.Profis3.Decking.Report.ActionButtons.SaveAndCreate"></span>
        </pe-loading-button>
    </button>
</div>
