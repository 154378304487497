import { Component, ElementRef, Input } from '@angular/core';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { LocalizationService } from './../../../services/external/localization.service';
import { environment } from 'src/environments/environmentDecking';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { includeSprites } from '../../../sprites';

@Component({
  selector: 'app-diaphragm-strength',
  templateUrl: './diaphragm-strength.component.html',
  styleUrls: ['./diaphragm-strength.component.scss']
})
export class DiaphragmStrengthComponent {

  @Input()
  public modalInstance!: ModalInstance;

  public additionalInformation: string;
  public format = format;

  constructor(public localization: LocalizationService, private elementRef: ElementRef<HTMLElement>) {
    includeSprites(this.elementRef.nativeElement,
        'sprite-frame-diaphragm-strengthTop',
        'sprite-frame-diaphragm-strengthBottom'
      );
    this.additionalInformation = this.localization.getString('Agito.Hilti.Profis3.Decking.DiaphragmStrength.AdditionalInformation').replace('{productTechnicalGuideLink}', environment.deckingProductTechnicalGuideLink);
  }

  public close(): void {
    this.modalInstance.close();
  }
}
