<tr id="section_header">
    <th colspan="2" scope="colgroup">
        <!-- Area name -->
        <span
            id="zoneProps-headerArea-areaName"
            [ngbTooltip]="itemTooltipTemplateAreaName"
        >
            {{
                format(
                    "Agito.Hilti.Profis3.Decking.Substitution.Zones.Header.Area"
                        | l10n: localization.selectedLanguage,
                    areaName
                )
            }}
        </span>
        <ng-template #itemTooltipTemplateAreaName>
            <pe-content-tooltip
                [tooltip]="{
                    title:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.AreaName.Tooltip.Title'
                        | l10n: localization.selectedLanguage,
                    content: format(
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.AreaName.Tooltip.Text'
                            | l10n: localization.selectedLanguage,
                        [areaName]
                    )
                }"
            ></pe-content-tooltip>
        </ng-template>
    </th>
    <th scope="colgroup">        
    </th>
    <th scope="colgroup">
        <span
            id="loadsType-headerArea-areaName"
            l10n="Agito.Hilti.Profis3.Loads.Type"
            [ngbTooltip]="itemTooltipTemplateLoadType"
        ></span>
        <ng-template #itemTooltipTemplateLoadType>
            <pe-content-tooltip
                [tooltip]="{
                    title:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.Type.Tooltip.Title'
                        | l10n: localization.selectedLanguage,
                    content:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.Type.Tooltip.Text'
                        | l10n: localization.selectedLanguage
                }"
            ></pe-content-tooltip>
        </ng-template>
    </th>
    <th *ngIf="zoneSpacing" [attr.colspan]="1" scope="colgroup">
        <span l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Header.Dimensions"></span>
    </th>
    <th colspan="5" scope="colgroup">
        <span
            id="zones-headerArea-areaName"
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Header.Diaphragm"
            [ngbTooltip]="itemTooltipTemplateZone"
        ></span>
        <ng-template #itemTooltipTemplateZone>
            <pe-content-tooltip
                [tooltip]="{
                    title:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.DiaphragmDetails.Tooltip.Title'
                        | l10n: localization.selectedLanguage,
                    content:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.DiaphragmDetails.Tooltip.Text'
                        | l10n: localization.selectedLanguage
                }"
            ></pe-content-tooltip>
        </ng-template>
    </th>
    <th scope="colgroup">        
    </th>
    <th [attr.colspan]="loadsColumnsCount$ | async" scope="colgroup">
        <span  [ngbTooltip]="itemTooltipTemplateZonesLoads">{{
            loadsHeader$ | async

        }}</span>
        <ng-template #itemTooltipTemplateZonesLoads>
            <pe-content-tooltip
                [tooltip]="{
                    title:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.Loads.Tooltip.Title'
                        | l10n: localization.selectedLanguage,
                    content: format(
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.Loads.Tooltip.Text'
                            | l10n: localization.selectedLanguage,
                        [loadsTooltipType]
                    )
                }"
            ></pe-content-tooltip>
        </ng-template>
    </th>
    <th scope="colgroup"></th>
</tr>
<tr id="titles_header">
    <!-- Selection -->
    <th scope="col"></th>
    <!-- Zone name-->
    <th scope="col">
        <span
            id="zonesSubHeader-content"
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.Zone"
            [ngbTooltip]="itemTooltipTemplateZoneSubheader"
        ></span>
        <ng-template #itemTooltipTemplateZoneSubheader>
            <pe-content-tooltip
                [tooltip]="{
                    title:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.Zone.Tooltip.Title'
                        | l10n: localization.selectedLanguage,
                    content:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.Zone.Tooltip.Text'
                        | l10n: localization.selectedLanguage
                }"
            ></pe-content-tooltip>
        </ng-template>
    </th>
    <!-- Zone Type -->
    <th scope="col"></th>
    <!-- Type -->
    <th id="type_header">
        <div id="type_buttons_container">
            <button
                id="load-combinations-all-load-type-static-button"
                type="button"
                class="type-button btn btn-default"
                [disabled]="(isSubstitutionRelevantLoadAtZoneLevelSetting$ | async) == false"
                (click)="setZoneType(relevantLoads.wind)"
                [ngbTooltip]="
                    'Agito.Hilti.Profis3.Decking.Substitution.Zones.WindLoad.Tooltip.Title'
                        | l10n: localization.selectedLanguage
                "
            >
                <span class="decking-sprite decking-sprite-wind-icon"></span>
            </button>
            <button
                id="load-combinations-all-load-type-seismic-button"
                type="button"
                class="type-button btn btn-default"
                [disabled]="(isSubstitutionRelevantLoadAtZoneLevelSetting$ | async) == false"
                (click)="setZoneType(relevantLoads.seismic)"
                [ngbTooltip]="
                    'Agito.Hilti.Profis3.Decking.Substitution.Zones.SeismicLoad.Tooltip.Title'
                        | l10n: localization.selectedLanguage
                "
            >
                <span class="decking-sprite decking-sprite-seismic-icon"></span>
            </button>
        </div>
    </th>
    <!-- S -->
    <th *ngIf="zoneSpacing" scope="col">
        <span l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.S"></span>
    </th>    
    <!-- Deck gauge -->
    <th scope="col">
        <span
            id="deckGauge-subheader"
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.DeckGauge"
            [ngbTooltip]="itemTooltipTemplateDeckGauge"
        ></span>
        <ng-template #itemTooltipTemplateDeckGauge>
            <pe-content-tooltip
                [tooltip]="{
                    title:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.DeckGauge.Tooltip.Title'
                        | l10n: localization.selectedLanguage,
                    content:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.DeckGauge.Tooltip.Text'
                        | l10n: localization.selectedLanguage
                }"
            ></pe-content-tooltip>
        </ng-template>
    </th>
    <!-- Pattern -->
    <th scope="col">
        <span
            id="pattern-subheader"
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.Pattern"
            [ngbTooltip]="itemTooltipTemplatePattern"
        ></span>
        <ng-template #itemTooltipTemplatePattern>
            <pe-content-tooltip
                [tooltip]="{
                    title:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.Pattern.Tooltip.Title'
                        | l10n: localization.selectedLanguage,
                    content:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.Pattern.Tooltip.Text'
                        | l10n: localization.selectedLanguage
                }"
            ></pe-content-tooltip>
        </ng-template>
    </th>
    <!-- Frame fastener -->
    <th scope="col">
        <div class="row-header-info">
            <span
                id="framefastener-subheader"
                l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.FrameFastener"
                [ngbTooltip]="itemTooltipTemplateFrameFastener"
            ></span>
            <ng-template #itemTooltipTemplateFrameFastener>
                <pe-content-tooltip
                    [tooltip]="{
                        title:
                            'Agito.Hilti.Profis3.Decking.Substitution.Zones.FrameFastener.Tooltip.Title'
                            | l10n: localization.selectedLanguage,
                        content:
                            'Agito.Hilti.Profis3.Decking.Substitution.Zones.FrameFastener.Tooltip.Text'
                            | l10n: localization.selectedLanguage
                    }"
                ></pe-content-tooltip>
            </ng-template>
            <pe-control-title [infoPopup]="fastenerSelectionInfoPopup"></pe-control-title>
        </div>
    </th>
    <!-- Sidelap connector -->
    <th scope="col">
        <span
            id="sidelap-connector-subheader"
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.SidelapConnector"
            [ngbTooltip]="itemTooltipTemplateSidelapConnector"
        ></span>
        <ng-template #itemTooltipTemplateSidelapConnector>
            <pe-content-tooltip
                [tooltip]="{
                    title:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.SidelapConnection.Tooltip.Title'
                        | l10n: localization.selectedLanguage,
                    content:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.SidelapConnection.Tooltip.Text'
                        | l10n: localization.selectedLanguage
                }"
            ></pe-content-tooltip>
        </ng-template>
    </th>
    <!-- Side -->
    <th scope="col">
        <span
            id="side-subheader"
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.Side"
            [ngbTooltip]="itemTooltipTemplateSide"
        ></span>
        <ng-template #itemTooltipTemplateSide>
            <pe-content-tooltip
                [tooltip]="{
                    title: sidelapConnectorTooltip$ | async | l10n: localization.selectedLanguage,
                    content: ''
                }"
            ></pe-content-tooltip>
        </ng-template>
    </th>
    <!-- Enable loads -->
    <th scope="col" class="no-padding">
        <div class="row-header-info">
            <span class="info-popup-icon decking-sprite decking-sprite-info" [ngbTooltip]="itemTooltipCheckBox"></span>
            <ng-template #itemTooltipCheckBox>
                <pe-content-tooltip
                    [tooltip]="{
                        title:
                            '',
                        content:
                            'Agito.Hilti.Profis3.Decking.Substitution.Zones.Checkbox.Tooltip.Text'
                            | l10n: localization.selectedLanguage
                    }"
                ></pe-content-tooltip>
            </ng-template>
        </div>
    </th>
    <!-- Q -->
    <th scope="col">
        <span
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.Q"
            [ngbTooltip]="itemTooltipTemplateQ"
        ></span>
        <ng-template #itemTooltipTemplateQ>
            <pe-content-tooltip
                [tooltip]="{
                    title:
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.Q.Tooltip.Title'
                        | l10n: localization.selectedLanguage,
                    content: format(
                        'Agito.Hilti.Profis3.Decking.Substitution.Zones.Q.Tooltip.Text'
                            | l10n: localization.selectedLanguage,
                        [loadsTooltipType]
                    )
                }"
            ></pe-content-tooltip>
        </ng-template>
        <span [textContent]="forcePerLengthUnit"></span>
    </th>
    <!-- W -->
    <th scope="col" *ngIf="isSubstitutionRequiredUpliftSubmittalSetting$ | async">
        <span
        id="W-loads"
        l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.W"
        [ngbTooltip]="itemTooltipTemplateW"
    ></span>
    <ng-template #itemTooltipTemplateW>
        <pe-content-tooltip
            [tooltip]="{
                title:
                    'Agito.Hilti.Profis3.Decking.Substitution.Zones.W.Tooltip.Title'
                    | l10n: localization.selectedLanguage,
                content: format(
                    'Agito.Hilti.Profis3.Decking.Substitution.Zones.W.Tooltip.Text'
                        | l10n: localization.selectedLanguage,
                    [loadsTooltipType]
                )
            }"
        ></pe-content-tooltip>
    </ng-template>
    <span [textContent]="stressUnit"></span>
    </th>
    <!-- G -->
    <th scope="col" *ngIf="isSubstitutionRequiredShearStiffnessSetting$ | async">
        <span
        id="G-loads"
        l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Subheader.G"
        [ngbTooltip]="itemTooltipTemplateG"
    ></span>
    <ng-template #itemTooltipTemplateG>
        <pe-content-tooltip
            [tooltip]="{
                title:
                    'Agito.Hilti.Profis3.Decking.Substitution.Zones.G.Tooltip.Title'
                    | l10n: localization.selectedLanguage,
                content:
                    'Agito.Hilti.Profis3.Decking.Substitution.Zones.G.Tooltip.Text'
                    | l10n: localization.selectedLanguage
            }"
        ></pe-content-tooltip>
    </ng-template>
    <span [textContent]="shearStiffnessUnit"></span>
    </th>
    <!-- Buttons -->
    <th scope="col"></th>
</tr>