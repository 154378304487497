<div class="modal-content base-modal">
    <div class="modal-header">
        <h3 class="modal-title" l10n="Agito.Hilti.Profis3.DesignSectionExport.Title"></h3>

        <button type="button" class="close" (click)="close()">
            <span>&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <!-- PROFIS file to device -->
        <div class="tooltip-wrapper">
            <button class="profis-file export-button" (click)="exportDesignToFile()" type="button"
                [disabled]="loading">
                <div class="report-wrapper">
                    <div class="export-button-image">
                        <span class="decking-sprite decking-sprite-profis-file"></span>
                    </div>
                    <div class="export-button-message">
                        <span class="export-button-message-name"
                            l10n="Agito.Hilti.Profis3.DesignSectionExport.ProfisFile"></span>
                        <span class="export-button-message-description"
                            l10n="Agito.Hilti.Profis3.DesignSectionExport.ProfisFile.Description"></span>
                    </div>
                    <div class="export-button-caret">
                        <span class="decking-sprite decking-sprite-arrow-right"></span>
                    </div>
                </div>
            </button>
        </div>

        <!-- Specification Text -->
        <div class="tooltip-wrapper">
            <button class="specification-text export-button" (click)="openSpecificationTextModal()" type="button">
                <div class="report-wrapper">
                    <div class="export-button-image">
                        <span class="decking-sprite decking-sprite-specification-text"></span>
                    </div>
                    <div class="export-button-message">
                        <span class="export-button-message-name"
                            l10n="Agito.Hilti.Profis3.DesignSectionExport.SpecificationText"></span>
                        <span class="export-button-message-description"
                            l10n="Agito.Hilti.Profis3.SpecificationText.Tooltip"></span>
                    </div>
                    <div class="export-button-caret">
                        <span class="decking-sprite decking-sprite-arrow-right"></span>
                    </div>
                </div>
            </button>
        </div>
    </div>
</div>