import { NgForm } from '@angular/forms';
import { PaperSize } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, Observable, from, catchError, of, timeout, first, forkJoin } from 'rxjs';
import cloneDeep from 'lodash-es/cloneDeep';

import { AreaSummaryModel } from './../../../entities/decking-design/area-model';
import { DeckingUnitsHelperService } from './../../../services/decking-units-helper/decking-units-helper.service';
import { DeckingReport } from './../../../entities/decking-design/decking-report-info';
import { DeckingDesignService } from './../../../services/decking-design/decking-design.service';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { DeckingSubstitutionSignalrService } from 'src/decking/services/decking-design-signalr/decking-substitution-signalr.service';
import { DeckingReportService } from './../../../services/decking-report/decking-report.service';
import { LocalizationService } from './../../../services/external/localization.service';
import { DeckingTrackingService } from './../../../services/decking-tracking/decking-tracking.service';
import { DeckingCodeListService } from './../../../services/decking-code-list/decking-code-list.service';
import { DeckingDesign } from './../../../entities/decking-design/decking-design';
import { IDeckingDocument } from './../../../entities/decking-design/decking-document';
import { PreviewDialogComponent } from './decking-report-preview-dialog/preview-dialog.component';
import { DeckingDesignSignalrService } from './../../../services/decking-design-signalr/decking-design-signalr.service';
import { environment } from 'src/environments/environmentDecking';
import { LoggerService } from './../../../services/external/logger.service';
import { includeSprites } from '../.././../sprites';
import { DeckingMainService } from 'src/decking/services/decking-main/decking-main.service';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';
import { BaseDesign } from '../../../entities/decking-design/base-design';
import { DeckingSubstitutionTrackingService } from '../../../services/decking-tracking/decking-substitution-tracking.service';
import { ReportTemplateService } from '../../../services/decking-report-template/decking-report-template.service';
// Modal to enter the data for generating the decking report
@Component({
    selector: 'decking-report-generator',
    templateUrl: './decking-report-generator.component.html',
    styleUrls: ['./decking-report-generator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeckingReportGeneratorComponent implements OnInit, OnDestroy {
    reportInfo: DeckingReport;
    currentReportData: Subscription;
    currentSettings: Subscription;
    designSaved: Subscription;
    useSIUnits: boolean;
    isLayoutValid: boolean;
    isLoading = false;
    isSubstitution = false;
    areas: Observable<AreaSummaryModel[]>;
    currentDesign: BaseDesign;

    isReportDataTemplateLoaded = false;
    private timeoutInMilliseconds = 240000;

    @ViewChild('form')
    form: NgForm;

    constructor(
        public localization: LocalizationService,
        public elementRef: ElementRef<HTMLElement>,
        private activeModal: NgbActiveModal,
        private deckingDesignService: DeckingDesignService,
        public deckingReportService: DeckingReportService,
        private deckingUnitsHelperService: DeckingUnitsHelperService,
        private designTrackingService: DeckingTrackingService,
        private substitutionTrackingService: DeckingSubstitutionTrackingService,
        private codeListService: DeckingCodeListService,
        public deckingSignalRService: DeckingDesignSignalrService,
        public modalService: NgbModal,
        private loggerService: LoggerService,
        private deckingSubstitutionService: DeckingSubstitutionService,
        public deckingMainService: DeckingMainService,
        public deckingSubstitutionSignalRService: DeckingSubstitutionSignalrService,
        private reportTemplatesService: ReportTemplateService
    ) { }

    public get isValidReportForm(): boolean {
        // add extra form validations here
        const isReportTypeSelected = !!this.reportInfo?.reportType?.value;
        const hasSelectedAreas = this.reportInfo.selectedAreas.length > 0;
        return isReportTypeSelected && hasSelectedAreas && this.form?.valid && !this.isLoading
            && this.isReportDataTemplateLoaded;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,
            'sprite-lines',
            'sprite-lines-expanded');

        this.currentSettings = this.getCurrentData().currentSettings;
        this.useSIUnits = this.getCurrentData().useSIUnits;
        this.currentReportData = this.getCurrentData().currentReportData;
        this.areas = this.getCurrentData().areas;
    }

    ngOnDestroy(): void {
        this.currentReportData.unsubscribe();
        this.currentSettings.unsubscribe();
        if (this.designSaved) {
            this.designSaved.unsubscribe();
        }
    }

    async save() {
        switch (this.deckingMainService.getSelectedModeType()) {
            case DeckingDesignModeType.DesignMode: {
                this.saveAndGenerateDesignPreview();
                break;
            }
            case DeckingDesignModeType.SubstitutionBuilderMode: {             
                this.saveAndGenerateSubstitutionPreview();
                break;
            }
        }
    }

    private async saveAndGenerateDesignPreview() {
        this.isLoading = true;
        const deckingDesign = this.deckingDesignService.getCurrentDesign();
        await this.deckingSignalRService.checkConnection(deckingDesign.id, 'SubscribeToDesign');
        const reportContent = this.deckingReportService.generateReportContent(deckingDesign.id, deckingDesign.eTag, {
            reportData: this.reportInfo,
            reportType: this.reportInfo.reportType.value,
            useSiUnits: this.useSIUnits,
            patternImages: this.getPatternImages(deckingDesign)
        });

        const designUpdated = this.deckingSignalRService.designSaved$.pipe(first());

        this.designSaved = forkJoin([from(reportContent), designUpdated]).pipe(
            timeout(this.timeoutInMilliseconds),
            catchError(() => {
                this.loggerService.logServiceError('reached timeout for report preview', environment.deckingReportProcessorServiceUrl, 'SaveAndCreateReportContent');
                this.isLoading = false;
                return of(null);
            })
        ).subscribe((result: [string, DeckingDesign]) => {
            this.isLoading = false;
            this.designTrackingService.setTrackReportType(this.reportInfo.reportType.value);
            this.close();
            this.getPreview(result[0], deckingDesign);
        });
    }

    close() {
        this.activeModal.close();
    }

    private getPatternImages(design: DeckingDesign): IDeckingDocument<number, string>[] {
        const patternImages = [] as IDeckingDocument<number, string>[];
        design.areas.forEach(area => {
            area.zones.forEach(zone => {
                if (!patternImages.some(patternImage => patternImage.id == zone.pattern.id)) {
                    patternImages.push({ id: zone.pattern.id, index: 0, value: this.codeListService.GetPatternImage(area.deckPanel.id, zone.pattern.id) } as IDeckingDocument<number, string>);
                }
            });
        });

        return patternImages;
    }

    public getSubstitutionPreview(reportContent: string, deckingsubstitution: DeckingSubstitution) {
        const modalRef = this.modalService.open(PreviewDialogComponent, { size: 'xl' });
        modalRef.componentInstance.reportContent = reportContent;
        modalRef.componentInstance.substitutionDesign = deckingsubstitution;
    }

    public getPreview(reportContent: string, deckingDesign: DeckingDesign) {
        const modalRef = this.modalService.open(PreviewDialogComponent, { size: 'xl' });
        modalRef.componentInstance.reportContent = reportContent;
        modalRef.componentInstance.deckingDesign = deckingDesign;
    }

    private getCurrentData() {
        let currentSettings: any;
        let useSIUnits: any;
        let currentReportData: any;
        let areas: any;

        if (this.deckingMainService.getSelectedModeType() == DeckingDesignModeType.DesignMode) {
            currentSettings = this.deckingDesignService.currentSettings$.subscribe(settings => {
                useSIUnits = this.deckingUnitsHelperService.isInternationalSystemUnit(settings.length.id);
            });
            currentReportData = this.getReportData(this.deckingDesignService.currentReportData$, useSIUnits);
            areas = this.deckingDesignService.currentAreasSummary$;
        } else if (this.deckingMainService.getSelectedModeType() == DeckingDesignModeType.SubstitutionBuilderMode) {
            currentSettings = this.deckingSubstitutionService.currentSettings$.subscribe(settings => {
                useSIUnits = this.deckingUnitsHelperService.isInternationalSystemUnit(settings.length.id);
            });
            currentReportData = this.getReportData(this.deckingSubstitutionService.currentReportData$, useSIUnits);
            areas = this.deckingSubstitutionService.currentAreasSummary$;
        } else {
            this.loggerService.logServiceError(`Invalid Project Type : ${this.deckingMainService.baseDesign.projectType}`, 'DeckingReportGeneratorComponent', 'GetCurrentData');
        }

        return { currentSettings, useSIUnits, currentReportData, areas };
    }

    private getReportData(deckingReport: Observable<DeckingReport>, useSIUnits: boolean) {
        return deckingReport.subscribe(reportData => {
            // Clone object to work locally before saving
            this.reportInfo = cloneDeep(reportData);

            this.reportInfo.paperSize = reportData.paperSize ? reportData.paperSize : { value: PaperSize.Letter };
            this.reportInfo.reportType = reportData.reportType ? reportData.reportType : { value: 2 };
            this.reportInfo.useUnits = useSIUnits.valueOf() ? { value: true } : { value: false };
        });
    }
    async saveAndGenerateSubstitutionPreview() {
        this.isLoading = true;
        const deckingDesign = this.deckingSubstitutionService.getCurrentSubstitution();
        await this.deckingSubstitutionSignalRService.checkConnection(deckingDesign.id, 'SubscribeToSubstitution');
        const reportContent = this.deckingReportService.generateSubstitutionReportContent(deckingDesign.id, deckingDesign.eTag, {
            reportData: this.reportInfo,
            reportType: this.reportInfo.reportType.value,
            useSiUnits: this.useSIUnits,
            patternImages: this.getSubstitutionPatternImages(deckingDesign)
        });

        const designUpdated = this.deckingSubstitutionSignalRService.substitutionDesignSaved$.pipe(first());

        this.designSaved = forkJoin([from(reportContent), designUpdated]).pipe(
            timeout(this.timeoutInMilliseconds),
            catchError(() => {
                this.loggerService.logServiceError('reached timeout for report preview', environment.deckingReportProcessorServiceUrl, 'SaveAndCreateSubstitutionReportContent');
                this.isLoading = false;
                return of(null);
            })
        ).subscribe((result: [string, DeckingSubstitution]) => {
            this.isLoading = false;
            this.close();
            this.getSubstitutionPreview(result[0], deckingDesign);
            
            this.handleSubstitutionTrackingActivity();
        });
    }

    private async handleSubstitutionTrackingActivity() {
          // Tracking methods
          this.substitutionTrackingService.setLastReportSelectedLanguage(this.reportInfo.reportLanguageId?.value);
          this.substitutionTrackingService.setLastReportSelectedUnitType(this.useSIUnits ? this.localization.getString('Agito.Hilti.Profis3.Decking.Report.Layout.SIUnits') : this.localization.getString('Agito.Hilti.Profis3.Decking.Report.Layout.ImperialUnits'));
          const defaultTemplate = (await this.reportTemplatesService.getTemplates())?.find(template => template.Name === 'Default');
          this.reportInfo.templateIdSelected?.value === defaultTemplate?.Id ? 
              this.substitutionTrackingService.addReportDefaultTemplate() :
              this.substitutionTrackingService.addReportCustomTemplate();
          await this.substitutionTrackingService.trackSubstitutionActivity(this.deckingSubstitutionService.getCurrentSubstitution());
    }

    private getSubstitutionPatternImages(substitution: DeckingSubstitution): IDeckingDocument<number, string>[] {
        const patternImages = [] as IDeckingDocument<number, string>[];
        substitution.areas.forEach(area => {
            area.zones.forEach(zone => {
                const patternId = zone.zoneSpecified.pattern?.id;
                if (!patternImages.some(patternImage => patternImage.id === patternId)) {
                    patternImages.push({
                        id: patternId,
                        index: 0,
                        value: this.codeListService.GetPatternImage(area.deckPanel.id, patternId)
                    });
                }
                const substitutedPatternId = zone.zoneSubstituted.pattern.id;
                if (!patternImages.some(patternImage => patternImage.id === substitutedPatternId)) {
                    patternImages.push({
                        id: substitutedPatternId,
                        index: 0,
                        value: this.codeListService.GetPatternImage(area.deckPanel.id, substitutedPatternId)
                    });
                }
            });
        });

        return patternImages;
    }
}
