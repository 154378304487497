import { Component, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { ngbTooltipTemplate } from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import { InfoPopup } from '@profis-engineering/pe-ui-common/components/control-title/control-title.common';
import { DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { RadioButtonItem, RadioButtonProps, RadioLook } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { TextBoxDisplay } from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Observable, Subject, combineLatest, distinctUntilChanged, map } from 'rxjs';
import { CompressiveStrengthListItem } from 'src/decking/entities/decking-code-list/code-list/compressive-strength-list-item';
import { DeckFillListItem } from 'src/decking/entities/decking-code-list/code-list/deck-fill-list-item';
import { DeckPanelListItem } from 'src/decking/entities/decking-code-list/code-list/deck-panel-list-item';
import { DeckTypeListItem } from 'src/decking/entities/decking-code-list/code-list/deck-type-list-item';
import { PanelTypeListItem } from 'src/decking/entities/decking-code-list/code-list/panel-type-list-item';
import { PanelWidthListItem } from 'src/decking/entities/decking-code-list/code-list/panel-width-list-item';
import { SupportConstructionsListItem } from 'src/decking/entities/decking-code-list/code-list/support-constructions-list-item';
import { DeckType } from 'src/decking/entities/decking-code-list/enums/deck-type';
import { BaseAreaModel } from 'src/decking/entities/decking-design/area-model';
import { IDeckingDocument } from 'src/decking/entities/decking-design/decking-document';
import { ModalInfo } from 'src/decking/entities/decking-design/modal-info';
import { JOIST_SPACING_TYPE } from 'src/decking/entities/enums/joist-spacing-type';
import { DesignSettings } from 'src/decking/entities/settings/design-settings';
import { DeckingCodeListService } from 'src/decking/services/decking-code-list/decking-code-list.service';
import { DeckingUnitsHelperService } from 'src/decking/services/decking-units-helper/decking-units-helper.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { ModalService } from 'src/decking/services/external/modal.service';
import { UnitService } from 'src/decking/services/external/unit.service';
import { DeckingDropdownComponent } from '../../common/decking-dropdown/decking-dropdown.component';
import { DeckingRadioButtonComponent } from '../../common/decking-radio-button/decking-radio-button.component';
@Component({
    template: ''
})
export class DeckingCommonAreaPropertiesComponent  implements OnDestroy{

    @Input()
    public index: number;

    @Input()
    saving: boolean;

    @ViewChildren(DeckingDropdownComponent)
    deckingDropdownCollection: QueryList<DeckingDropdownComponent<number, string>>;

    @ViewChildren(DeckingRadioButtonComponent)
    deckingRadioButtonCollection: QueryList<DeckingRadioButtonComponent<number, string>>;

    public ngbTooltipTemplate = ngbTooltipTemplate;
    public readonly DeckPanelDropId: string = 'areaOpt-deckProps-deckPanelDrop-';
    public readonly PanelTypeDropId: string = 'areaOpt-deckProps-panelTypeDrop-';
    public numericTextBoxDisplay = TextBoxDisplay.Normal;
    public deckTypeChangedModalInfo:ModalInfo;

    public deckTypes = DeckType;
    public panelTypeInfoPopup: InfoPopup;
    public zoneOrientationInfoPopup: InfoPopup;
    public diaphragmStrengthInfoPopup: InfoPopup;

    public deckTypeItems: RadioButtonItem<DeckTypeListItem>[];
    public deckFillItems: DropdownItem<DeckFillListItem>[];
    public compressiveStrengthItems: DropdownItem<CompressiveStrengthListItem>[] = [];
    public deckPanelItems$: Observable<DropdownItem<DeckPanelListItem>[]>;
    public panelTypeItems$: Observable<DropdownItem<PanelTypeListItem>[]>;
    public panelWidthItems$: Observable<DropdownItem<PanelWidthListItem>[]>;
    public supportConstructionItems: DropdownItem<SupportConstructionsListItem>[];
    public joistSpacingControl: RadioButtonProps<JOIST_SPACING_TYPE>;
    public deckTypeControl: RadioButtonProps<DeckTypeListItem>;

    public unsubscribe$ = new Subject<void>();
    public lengthGeneralUnit$: Observable<Unit>;
    public joistBeamThicknessUnit$: Observable<Unit>;
    public joistBeamSpacingUnit$: Observable<Unit>;
    public panelWidthUnit$: Observable<Unit>;
    public currentDesignSettings$: Observable<DesignSettings>;

    

    constructor(
        public localization: LocalizationService,
        public deckingUnitsHelperService: DeckingUnitsHelperService,
        public unitService: UnitService,
        public modalService: ModalService,
        public deckingCodeListService: DeckingCodeListService,
    ) {

        this.deckTypeChangedModalInfo = {
            id: 'confirm-edit-area-deckType',
            title: this.localization.getString('Agito.Hilti.Profis3.Decking.DeckTypeChangeConfirmationModal.Title'),
            message: this.localization.getString('Agito.Hilti.Profis3.Decking.DeckTypeChangeConfirmationModal.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.DeckTypeChangeConfirmationModal.ConfirmButtonText'),
            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.DeckTypeChangeConfirmationModal.CancelButtonText'),
          } as ModalInfo;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public initDeckFillDropdownItems(): void {
        this.deckFillItems = this.deckingCodeListService.GetDeckFillDropdownItems();
    }

    public initSupportConstructionDropdownItems(): void {
        this.supportConstructionItems = this.deckingCodeListService.GetSupportConstructionDropdownItems();
    }

    public initDeckTypeRadioButtons(deckType: IDeckingDocument<DeckType, string>): void {
        this.deckTypeItems = this.deckingCodeListService.GetDeckTypeRadioItems();
        this.deckTypeControl = {
            id: 'areaOpt-deckProps-deckType-' + this.index,
            title: this.localization.getString('Agito.Hilti.Profis3.Decking.Areas.DeckProperties.DeckType'),
            look: RadioLook.Normal,
            items: this.deckTypeItems,
            selectedValue: deckType
        };
    }

    public initInfoPopUp() {
        this.panelTypeInfoPopup = {
            content: { componentName: 'decking-panel-type-info'},
            options: {
            size: 'lg'
            }
        };

        this.zoneOrientationInfoPopup = {
            content: { componentName : 'decking-zone-orientation'},
            options: {
                size: 'lg'
            }
        };

        this.diaphragmStrengthInfoPopup = {
            content: { componentName: 'decking-diaphragm-strength'},
            options: {
            size: 'lg'
            }
        };
    }

    public initLengthUnits(): void {
        this.lengthGeneralUnit$ = this.currentDesignSettings$.pipe(map(settings => settings.length.id), distinctUntilChanged());

        this.joistBeamSpacingUnit$ = this.lengthGeneralUnit$.pipe(map(lengthUnit => {
            return this.deckingUnitsHelperService.getBeamSpacingUnit(lengthUnit);
        }));

        this.joistBeamThicknessUnit$ = this.lengthGeneralUnit$.pipe(map(lengthGeneralUnit => {
            return this.deckingUnitsHelperService.getStandardLengthUnit(lengthGeneralUnit);
        }));

        this.panelWidthUnit$ = this.lengthGeneralUnit$.pipe(map(lengthGeneralUnit => {
            return this.deckingUnitsHelperService.getStandardLengthUnit(lengthGeneralUnit);
        }));
    }

    openConfirmModal(modalInfo: ModalInfo, acceptedFunc: (param?: any) => void, canceledFunc: (paramCanceled?: any) => void, param?: any, paramCanceled?: any) {
        const openedConfirmModal = this.modalService.openConfirmChange({
          id: modalInfo.id,
          title: modalInfo.title,
          message: modalInfo.message,
          confirmButtonText: modalInfo.confirmButtonText,
          cancelButtonText: modalInfo.cancelButtonText,
          onConfirm: (modal) => {
            modal.close(true);
          },
          onCancel: (modal) => {
            modal.close(false);
          }
        }).result;
    
        openedConfirmModal.then((accepted: any) => {
          // Modal closed
          if (accepted) {
            acceptedFunc(param);
          }
          else {
            canceledFunc(paramCanceled);
          }
        }).catch(() => {
            // Modal dismissed
            canceledFunc(paramCanceled);
          });
      }

    public initRadioButtonItems(value: boolean): void {
        this.joistSpacingControl = {
            title: this.localization.getString('Agito.Hilti.Profis3.Decking.Areas.JoistBeamProperties.JoistSpacing'),
            look: RadioLook.Normal,
            id: 'areaOpt-beamProps-joistSpacing-' + this.index,
            items: [
            {
                text: this.localization.getString('Agito.Hilti.Profis3.Decking.Areas.JoistBeamProperties.Area'),
                value: JOIST_SPACING_TYPE.Area,
                disabled: false,
                id: 'areaOpt-beamProps-areaSpacingRadio-' + this.index
            },
            {
                text: this.localization.getString('Agito.Hilti.Profis3.Decking.Areas.JoistBeamProperties.Zone'),
                value: JOIST_SPACING_TYPE.Zone,
                disabled: false,
                id: 'areaOpt-beamProps-zoneSpacingRadio-' + this.index
            }
            ],
            selectedValue: value ? JOIST_SPACING_TYPE.Zone : JOIST_SPACING_TYPE.Area
        };
    }

    public initPanelWidthDropDownItems(currentArea$: Observable<BaseAreaModel>): void {
        const panelId$ = currentArea$.pipe(map(currentArea => currentArea.deckPanel.id), distinctUntilChanged());
        this.panelWidthItems$ = combineLatest([panelId$, this.panelWidthUnit$]).pipe(
            map(([panelId, panelWidthUnit]) => {
            const panelWidthItems = this.deckingCodeListService.GetPanelWidthDropdownItems(panelId, panelWidthUnit);
            return panelWidthItems.map<DropdownItem<PanelWidthListItem>>(i => {
                return {
                text: `${i.text} ${this.unitService.formatUnit(panelWidthUnit)}`,
                value: i.value,
                };
            });
            })
        );
    }

    public initDeckPanelDropdownItems(currentArea$: Observable<BaseAreaModel>): void {
        this.deckPanelItems$ = currentArea$.pipe(
            map(currentArea => currentArea.deckType.id),
            distinctUntilChanged(),
            map(deckTypeId => this.deckingCodeListService.GetDeckPanelDropdownItems(deckTypeId))
        );
    }
    
    public initPanelTypeDropdownItems(currentArea$: Observable<BaseAreaModel>): void {
        this.panelTypeItems$ = currentArea$.pipe(
            map(currentArea => currentArea.deckPanel.id),
            distinctUntilChanged(),
            map(deckPanelId => this.deckingCodeListService.GetPanelTypeDropdownItems(deckPanelId))
        );
    }
    
}