import { Injectable } from '@angular/core';
import { LicenseServiceInjected } from '@profis-engineering/pe-ui-common/services/license.common';

@Injectable({
    providedIn: 'root'
  })
  export class LicenseService extends LicenseServiceInjected { 
    
    public isPremium() {
      return this.getLicense() === 'PREMIUM';
    }
  }