import { DesignMethod } from '../../enums/design-method';
export class DesignMethodConverter {
    static getDesignMethodFromString(value: string): DesignMethod {
        switch (value) {
            case 'LRFD':
                return DesignMethod.lrfd;
            case 'ASD':
                return DesignMethod.asd;
            case 'LSD CAN':
                return DesignMethod.ldsCan;
        }
        return DesignMethod.asd;
    }
}
