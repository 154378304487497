import { Component, Input } from '@angular/core';

@Component({
    selector: 'decking-report-template-display',
    templateUrl: './decking-report-template-display.component.html',
    styleUrls: []
})
export class DeckingReportTemplateDisplayComponent  {
    @Input()
    reportTemplateType: number;
}
