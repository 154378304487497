<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title" id="report-modal-header-title" l10n="Agito.Hilti.Profis3.Decking.Report.Title.GenerateReport"></h3>
        <button id="report-modal-header-closeButton" type="button" [disabled]="isLoading" class="close" (click)="close()">
            <span>&times;</span>
        </button>
    </div>

    <div class="modal-body" id="report-generation-options-content">
        <div>
            <form #form="ngForm">
                <!--Section for the summary data of the current design-->
                <decking-report-generation-options-summary>
                </decking-report-generation-options-summary>

                <!--Section for the details of the report to be generated-->
                <decking-report-generation-options-details [reportInfo]="reportInfo">
                </decking-report-generation-options-details>

                <!--Section to select the areas to be included in the report-->
                <decking-report-generation-options-area-selection [reportInfo]="reportInfo" [areas]="areas | async">
                </decking-report-generation-options-area-selection>

                <!--Section to specify parameters for the report's layout-->
                <decking-report-generation-options-layout 
                    [reportInfo]="reportInfo"
                    [useSIUnits]="useSIUnits" 
                    (useSIUnitsChange)="useSIUnits=$event"
                    (reportTemplateDataLoaded)="isReportDataTemplateLoaded=true">
                </decking-report-generation-options-layout>
            </form>
        </div>
    </div>

    <div class="modal-footer">
        <!--Buttons actions for the screen-->
        <decking-report-generation-options-action-buttons
            [isSaveButtonDisabled]="!isValidReportForm"
            [isCancelButtonDisabled]="isLoading"
            [isLoading]="isLoading || !isReportDataTemplateLoaded"
            (saveClick)="save()"
            (closeClick)="close()">
        </decking-report-generation-options-action-buttons>
    </div>
</div>


