import { Component, Input } from '@angular/core';

@Component({
  selector: 'decking-utilization-result',
  templateUrl: './decking-utilization-result.component.html',
  styleUrls: ['./decking-utilization-result.component.scss']
})
export class DeckingUtilizationResultComponent {

  @Input()
  public percentage: number;

  @Input()
  public title: string;

  @Input()
  public description: string;

  @Input()
  public spriteTitle: string;

  @Input()
  public spriteDescription: string;

}
