import { CalculatingIndicatorService } from './indicator/calculating-indicator.service';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { exhaustMap, filter, finalize, take, tap } from 'rxjs/operators';
import { DeckingDesignService } from '../../decking-design.service';
import { DeckingDesign } from '../../../../entities/decking-design/decking-design';
import { DeckingDesignSignalrService } from '../../../decking-design-signalr/decking-design-signalr.service';

/**
 * Abstract class to handle the calculation events. Class template for the calculation services
 */
@Injectable({
  providedIn: 'root'
})
export abstract class DeckingDesignCalculationService<T> {
    saving$: Observable<boolean>;

    private saveDesignSubscription: Subscription;

    constructor(
        protected deckingSignalRService: DeckingDesignSignalrService,
        protected deckingDesignService: DeckingDesignService,
        private calculatingIndicatorService: CalculatingIndicatorService
    ) {
        this.saving$ = this.calculatingIndicatorService.calculating$;
    }

    init() {
        if (this.saveDesignSubscription) {
            this.dispose();
        }
        const calculationTrigger$ = this.buildTriggerCalculationObservable();
        this.saveDesignSubscription = calculationTrigger$.pipe(
            exhaustMap((calculationInput: T) => this.calculatedDesignObservable(calculationInput)),
        ).subscribe((design) => {
            this.updateCurrentDesign(design);
        });

    }

    dispose() {
        this.saveDesignSubscription.unsubscribe();
    }

    /** Observable that triggers the calculation event */
    protected abstract buildTriggerCalculationObservable(): Observable<T>;

    /** Action to be called when the calculation is triggered */
    protected abstract callCalculation(calculationInput: T): void;

    protected abstract getDesignId(calculationInput: T): string;

    private calculatedDesignObservable(calculationInput: T): Observable<DeckingDesign> {
        this.calculatingIndicatorService.setCalculating(true);
        this.callCalculation(calculationInput);

        return this.buildSavedDesignObservable(calculationInput);
    }

    /** Observable that triggers when the calculated design was saved. It dispatches the latest design and saves it */
    private buildSavedDesignObservable(calculationInput: T): Observable<DeckingDesign> {
        return this.deckingSignalRService.designCalculated$.pipe(
            filter((designSaved: DeckingDesign) => designSaved && this.getDesignId(calculationInput) === designSaved.id),
            tap((design: DeckingDesign) => {
                design.saved = true;

            }),
            finalize(() => {
                this.calculatingIndicatorService.setCalculating(false);
            }),
            take(1));
    }

    private updateCurrentDesign(design: DeckingDesign) {
        design.saved = true;
        this.deckingDesignService.setDesign(design);
    }
}
