import {
    Component,
    OnInit,
    OnChanges,
    SimpleChanges,
    Input,
} from '@angular/core';
import { DeckingDesignService } from './../../../../services/decking-design/decking-design.service';
import { DeckingScopeChecksService } from './../../../../services/decking-scope-checks/decking-scope-checks.service';
import { DeckingCommonZonesRowComponent } from '../../decking-zones-common/decking-common-zones-row.component';
import { LocalizationService } from './../../../../services/external/localization.service';
import { distinctUntilChanged, map, shareReplay } from 'rxjs';
import { RelevantLoads } from './../../../../entities/decking-code-list/enums/relevant-loads';
import { DeckingFieldState, IDeckingFieldStateValue } from './../../../../entities/decking-design/decking-field-state';
import { ZoneModel } from './../../../../entities/decking-design/zone-model';
import { FieldState } from './../../../../entities/enums/field-state';
import { CalculationState } from './../../../../entities/enums/calculation-state';
import { ScopeCheckSeverity } from './../../../../entities/decking-design/scope-check-result';
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[decking-zones-row]',
    templateUrl: './decking-zones-row.component.html',
    styleUrls: ['./decking-zones-row.component.scss'],
})
export class DeckingZonesRowComponent
    extends DeckingCommonZonesRowComponent
    implements OnInit, OnChanges
{

    public placeholderText: string;

    @Input()
    public zone: ZoneModel;

    constructor(
        public deckingDesignService: DeckingDesignService,
        public deckingScopeChecksService: DeckingScopeChecksService,
        public localizationService: LocalizationService
    ) {
        super();
        this.placeholderText = this.localizationService.getString(
            'Agito.Hilti.Profis3.Decking.Zones.Buttons.Optimize'
        );
    }

    public ngOnInit(): void {
        this.isSelected$ = this.deckingDesignService.currentZone$.pipe(map((zone) => zone.id === this.zone.id));
        this.isRequiredShearStiffnessSetting$ = this.deckingDesignService.isRequiredShearStiffnessSetting$;
        this.isRequiredUpliftSubmittalSetting$ = this.deckingDesignService.isRequiredUpliftSubmittalSetting$;
        this.isRelevantLoadAtZoneLevelSetting$ = this.deckingDesignService.isRelevantLoadAtZoneLevelSetting$;
    }

    public override ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        this.hasCriticalScopeChecks = this.deckingScopeChecksService.zoneHasCriticalScopeChecks(this.zone);
        this.isDeckFilled$ = this.deckingDesignService.currentArea$.pipe(map(currentArea => currentArea.deckFill.value ==='Agito.Hilti.PE.Decking.CalculationService.DeckFill.NoFill'), distinctUntilChanged(), shareReplay(1));
    }

    public getClassByFieldState<TId, TValue>(item: DeckingFieldState<TId, TValue>): string {
        if (item == null && this.zone.calculationState != CalculationState.NotSolution) {
            return 'not-selected-design';
        }
        if (item?.fieldState == FieldState.Optimized
            && (this.zone.calculationState === CalculationState.Optimized || this.zone.calculationState === CalculationState.PartialOptimized)) {
            return 'optimized-design';
        }
        if (this.zone.calculationState === CalculationState.Failed || this.isFailedStatusOptimized() || this.zone.calculationState == CalculationState.NotSolution) {
            return 'failed-design';
        }
        return '';
    }

    public getClassByCalculationState(): string {
        if (this.zone.calculationState == CalculationState.Optimized) {
            return 'optimized-design';
        }
        if (this.zone.calculationState == CalculationState.Failed || this.isFailedStatusOptimized() || this.zone.calculationState == CalculationState.NotSolution) {
            return 'failed-design';
        }
        if (this.zone.calculationState == CalculationState.Verified || this.zone.calculationState == CalculationState.PartialOptimized) {
            return 'verified-or-partialOptimized-design';
        }
        return '';
    }

    private isFailedStatusOptimized(){
        return this.zone.calculationState == CalculationState.NotSolution && this.zone.result.failedScopeChecks.some(sChk => sChk.severity == ScopeCheckSeverity.Critical);
    }

    public valueSelected<TId, TValue>(item: DeckingFieldState<TId, TValue> | IDeckingFieldStateValue<TValue>) {
        if (item != null) {
            item.fieldState = FieldState.Selected;
        }
        this.zone.resetAlternatives = false;
        this.valueChanged();
    }

    public valueChanged(isDirty = true): void {
        this.deckingDesignService.updateZone(this.zone, this.index, isDirty);
    }

    public selectCurrentZone(): void {
        this.deckingDesignService.setCurrentZone(this.index);
    }

    public setZoneType(relevantLoads: RelevantLoads): void {
        this.zone.relevantLoads = {
            value: relevantLoads
        };
        this.deckingDesignService.updateZone(this.zone, this.index);
    }
}
