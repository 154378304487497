
import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AreaSummaryModel } from 'src/decking/entities/decking-design/area-model';
import { DeckingTabsWrapperService } from 'src/decking/services/decking-tabs-wrapper/decking-tabs-wrapper.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';

@Component({
    template: ''
})
export class DeckingCommonAreaComponent{

    @ViewChildren('areaNameInput') inputs: QueryList<ElementRef>;
    @Input()
    public index: number;
  
    @Input()
    saving: boolean;
  
    @Input()
    showDeleteOption: boolean;
    
    @Input()
    currentArea: AreaSummaryModel;

    isSelected: boolean;
    modalRef: NgbModalRef;

    constructor(
        public modalService: NgbModal, 
        public localization: LocalizationService, 
        public deckingTabsWrapperService: DeckingTabsWrapperService
    ) { }

    public openDeleteModal(content: any): void {
        this.modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    }

    public close(): void {
        this.modalRef.close();
    }

    public deleteArea(): void {
        this.modalRef.close();
        this.deckingTabsWrapperService.allAreasCollapsed = true;
    }
}