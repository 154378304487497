import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ScopeCheckParameter, ScopeCheckResult } from 'src/decking/entities/decking-design/scope-check-result';
import { DeckingUnitsHelperService } from 'src/decking/services/decking-units-helper/decking-units-helper.service';
import { UnitService } from 'src/decking/services/external/unit.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { DefinitionOfSidelapConnectors } from 'src/decking/entities/decking-code-list/enums/definition-sidelap-connectors';

export class BaseScopeChecksService {

    protected constructor(
        protected unit: UnitService,
        protected deckingUnitsHelperService: DeckingUnitsHelperService,
        protected localization: LocalizationService
    ) {}
    
    protected substituteTextProperties(translationText: string, scopeCheck: ScopeCheckResult, settingsLengthUnit: Unit, areaName: string, zoneName: string): string {
        const standardLengthUnit = this.deckingUnitsHelperService.getStandardLengthUnit(settingsLengthUnit);
        if (scopeCheck.parameters && scopeCheck.parameters.length > 0) {
            scopeCheck.parameters.forEach(parameter => {
                switch (parameter.name) {
                    case 'SupportSpan':
                        translationText = this.replaceSupportSpanValue(parameter, translationText);
                        break;
                    case 'SupportThickness':
                    case 'SidelapSpacing':
                        translationText = this.replaceSupportThicknessValue(parameter, translationText, 'Millimetre', Unit.mm, standardLengthUnit);
                        break;
                    case 'MinSupportThickness':
                    case 'MaxSupportThickness':
                    case 'MinSidelapSpacing':
                        translationText = this.replaceSupportThicknessValue(parameter, translationText, 'Inch', Unit.inch, standardLengthUnit);
                        break;
                    case 'MaxSidelapsPerSpan':
                        translationText = this.replaceMaxSidelapsPerSpan(parameter, translationText);
                        break;
                    default:
                        translationText = translationText.replace('{' + parameter.name + '}', parameter.value.toString());
                        break;
                }
            });
        }

        return `${translationText} ${areaName} - ${zoneName}`;
    }

    protected replaceMaxSidelapsPerSpan(parameter: ScopeCheckParameter, translationText: string) {
        const roundedValue = Math.round(+parameter.value);
        return translationText.replace('{' + parameter.name + '}', roundedValue.toString());
    }

    protected replaceSupportSpanValue(parameter: ScopeCheckParameter, translationText: string) {
        const supportSpanValue: number = +parameter.value.toString().replace('Millimetre', '');
        const convertedValue = this.unit.convertUnitValueArgsToUnit(supportSpanValue, Unit.mm, Unit.inch);
        translationText = translationText.replace('{' + parameter.name + '}', convertedValue.toString() + ' Inch');
        return translationText;
    }

    protected replaceSupportThicknessValue(parameter: ScopeCheckParameter, translationText: string, unitFromText: string, unitFrom: Unit, unitTo: Unit) {
        const supportThicknessValue: number = +parameter.value.toString().replace(unitFromText, '');
        const convertedValue = this.unit.convertUnitValueArgsToUnit(supportThicknessValue, unitFrom, unitTo);
        const formattedValue = this.unit.formatUnitValueArgs(convertedValue, unitTo, 4);
        translationText = translationText.replace('{' + parameter.name + '}', formattedValue);
        return translationText;
    }

    protected getKey(scopeCheckId: string, definitionOfSidelapConnectors: DefinitionOfSidelapConnectors, frameFastener: number, currentSettingsLengthUnit: Unit): string {
        if (scopeCheckId == 'MinSidelapSpacing') {
            if (definitionOfSidelapConnectors == DefinitionOfSidelapConnectors.ByConnectionSpacing) {
                return `Agito.Hilti.Profis3.Decking.ScopeCheckId.${scopeCheckId}BySpacing`;
            }
            return `Agito.Hilti.Profis3.Decking.ScopeCheckId.${scopeCheckId}ByNumber`;
        }
        if (scopeCheckId == 'FrameFastenerSupportThickness') {
            if (frameFastener == 1) {
                return `Agito.Hilti.Profis3.Decking.ScopeCheckId.${scopeCheckId}Enp19${this.isImperial(currentSettingsLengthUnit) ? '' : 'Metric'}`;
            }
        }
        return `Agito.Hilti.Profis3.Decking.ScopeCheckId.${scopeCheckId}`;
    }

    protected isImperial(currentSettingsLengthUnit: Unit) {
        return currentSettingsLengthUnit === Unit.ft || currentSettingsLengthUnit === Unit.inch || currentSettingsLengthUnit === Unit.mi;
    }

    protected translationText(key: string): string {
        return this.localization.getString(key);
    }
}