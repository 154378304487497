import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { LocalizationService } from './../../../services/external/localization.service';
import { ModalService } from './../../../services/external/modal.service';
import { includeSprites } from '../.././../sprites';

@Component({
    template: ''
})
export abstract class DeckingCommonZonesRowActionButtonsComponent<T> implements OnInit {

    @Input()
    public zone: T;

    @Input()
    public index: number;

    @Input()
    public saving: boolean;

    @Input()
    canDeleteZone: boolean;

    constructor(
        public localization: LocalizationService,
        public modalService: ModalService,
        public elementRef: ElementRef<HTMLElement>
    ) { }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,
            'sprite-optimize-icon',
            'sprite-optimize-icon-inactive',
            'sprite-delete-active',
            'sprite-delete-inactive'
        );
    }

    public openConfirmDeleteModal(): void {
        this.modalService.openConfirmChange({
            id: 'confirm_delete_zone_modal',
            title: this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.Delete.Warning'),
            message: this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.Delete.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.Delete.Yes'),
            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.Delete.No'),
            onConfirm: (modal) => {
                this.deleteZone();
                modal.close();
            },
            onCancel: (modal) => {
                modal.close(0);
            }
        });
    }

    protected abstract deleteZone(): void;
}
