import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DropdownItem
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
  RadioButtonItem
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';

import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import range from 'lodash-es/range';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { DeckingDefaultFactoryService } from '../decking-design/factory/decking-default-factory.service';
import { IDeckingListItems } from '../../entities/decking-code-list/interfaces/decking-list-items';
import { IFastenerPatterns } from '../../entities/decking-code-list/interfaces/fastener-patterns';
import { IPanelCrossSectionListItem } from '../../entities/decking-code-list/code-list/panel-cross-section-list-item';
import { IAreaDropdownsItems } from '../../entities/decking-code-list/interfaces/area-dropdowns-items';
import { IZoneDropdownItems } from '../../entities/decking-code-list/interfaces/zone-dropdown-items';
import { LocalizationService } from '../external/localization.service';
import { ApiService } from '../external/api.service';
import { UnitService } from '../external/unit.service';
import { environment } from '../../../environments/environmentDecking';
import { DeckTypeListItem } from '../../entities/decking-code-list/code-list/deck-type-list-item';
import { DeckFillListItem } from '../../entities/decking-code-list/code-list/deck-fill-list-item';
import { DeckFill } from '../../entities/decking-code-list/enums/deck-fill';
import { CompressiveStrengthListItem } from '../../entities/decking-code-list/code-list/compressive-strength-list-item';
import { DeckType } from '../../entities/decking-code-list/enums/deck-type';
import { DeckPanelListItem } from '../../entities/decking-code-list/code-list/deck-panel-list-item';
import { PanelTypeListItem } from '../../entities/decking-code-list/code-list/panel-type-list-item';
import { PanelWidthListItem } from '../../entities/decking-code-list/code-list/panel-width-list-item';
import { SupportConstructionsListItem } from '../../entities/decking-code-list/code-list/support-constructions-list-item';
import { DeckGaugeListItem, SubstitutionDeckGaugeListItem } from '../../entities/decking-code-list/code-list/deck-gauge-list-item';
import { PatternListItem } from '../../entities/decking-code-list/code-list/pattern-list-item';
import { FrameFastenerListItem } from '../../entities/decking-code-list/code-list/frame-fastener-list-item';
import { IDeckingDocument } from '../../entities/decking-design/decking-document';
import { PanelType } from '../../entities/decking-code-list/enums/panel-type';
import { SidelapConnectorListItem } from '../../entities/decking-code-list/code-list/sidelap-connector-list-item';
import { SidelapConnectorSpacingListItem } from '../../entities/decking-code-list/code-list/sidelap-connector-spacing-list-item';
import { FieldState } from '../../entities/enums/field-state';
import { DeckingFieldState } from '../../entities/decking-design/decking-field-state';
import { Region } from '../../entities/decking-code-list/enums/region';
import { DeckingMainService } from '../decking-main/decking-main.service';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';

@Injectable({
  providedIn: 'root'
})
export class DeckingCodeListService {

  responsePromise: Promise<HttpResponse<IDeckingListItems>>;
  responsePromiseFastener: Promise<HttpResponse<IFastenerPatterns[]>>;
  responsePromisePanel: Promise<HttpResponse<IPanelCrossSectionListItem[]>>;

  public fastenersItems: IFastenerPatterns[];
  public panelDefinitions: IPanelCrossSectionListItem[];
  private data: IDeckingListItems;
  private areaItems: IAreaDropdownsItems;
  private zoneItems: IZoneDropdownItems;
  private defaultPanelCrossSections = [3, 10, 25, 26];

  private currentLanguage: string;

  constructor(
    private localizationService: LocalizationService,
    private apiService: ApiService,
    private unitService: UnitService,
    private deckingMainService: DeckingMainService
  ) { }

  /**
   * Doing the HTTP Request and saving the promise to be awaited.
   */
  public async init(): Promise<void> {
    const url = `${environment.deckingCalculationServiceUrl}api/designCodeListQuery`;
    const urlFasteners = `${environment.deckingCalculationServiceUrl}api/FastenerPattern`;
    const urlPanelDefinitions = `${environment.deckingCalculationServiceUrl}api/FastenerPattern/GetPanelProfile`;

    if (!this.data) {
      this.responsePromise = this.apiService.request<IDeckingListItems>(new HttpRequest('GET', url));
      this.data = (await this.responsePromise).body;
      this.areaItems = this.data.areaItems;
      this.zoneItems = this.data.zoneItems;
    }

    if (!this.fastenersItems) {
      this.responsePromiseFastener = this.apiService.request<IFastenerPatterns[]>(new HttpRequest('GET', urlFasteners));
      this.fastenersItems = (await this.responsePromiseFastener).body;
    }

    if (!this.panelDefinitions) {
      this.responsePromisePanel = this.apiService.request<IPanelCrossSectionListItem[]>(new HttpRequest('GET', urlPanelDefinitions));
      this.panelDefinitions = (await this.responsePromisePanel).body;
    }

    this.currentLanguage = this.localizationService.selectedLanguage.toUpperCase();

  }

  /*** DropdownItems for Area Properties Dropdowns ***/

  /**
   *
   * @returns RadioButtonItem for DeckType Radio Buttons on Area Properties
   */
  public GetDeckTypeRadioItems(): RadioButtonItem<DeckTypeListItem>[] {
    const deckTypes = this.areaItems.deckTypes;

    deckTypes.sort((x, y) => x.index - y.index);

    return deckTypes.map<RadioButtonItem<DeckTypeListItem>>(x => ({
      text: this.localizationService.getString(x.value),
      value: x,
      disabled: false,
      id: 'areaOpt-deckProps-deckTypeRadio-' + x.index
    }));
  }

  /**
   *
   * @returns DropdownItems for DeckFill Dropdown on Area Properties
   */
  public GetDeckFillDropdownItems(): DropdownItem<DeckFillListItem>[] {

    const deckFills: DeckFillListItem[] = this.areaItems.deckFills;

    deckFills.sort((x, y) => x.index - y.index);

    return deckFills.map<DropdownItem<DeckFillListItem>>(x => ({
      text: this.localizationService.getString(x.value),
      value: x,
    }));
  }

  /**
   *
   * @param deckFill Field to partition elements according DeckFill dependency
   * @param unit Unit to convert (PSI or N/mm2). Default unit from codelist repository is PSI
   * @returns DropdownItems for CompressiveStrength Dropdown on Area Properties
   */
  public GetCompressiveStrengthDropdownItems(deckFill: DeckFill, unit: Unit): DropdownItem<CompressiveStrengthListItem>[] {
    const compressiveStrengths = this.areaItems.compressiveStrengths.filter(x => x.deckFills.includes(deckFill));
    compressiveStrengths.sort((x: CompressiveStrengthListItem, y: CompressiveStrengthListItem) => x.index - y.index);

    const unitDisplay = this.unitService.formatUnit(unit);

    return compressiveStrengths.map<DropdownItem<CompressiveStrengthListItem>>(x => {
      const PSIConversion = unit != Unit.PSI ? this.unitService.convertUnitValueArgsToUnit(x.value, Unit.PSI, unit) : x.value;
      const finalText = Number.isInteger(PSIConversion) ? PSIConversion : PSIConversion.toFixed(1);
      return {
        text: `${finalText} ${unitDisplay}`,
        value: x
      };
    });
  }

  /**
   *
   * @param deckType Field to partition elements according DeckType dependency
   * @returns DropdownItems for DeckPanel Dropdown on Area Properties
   */
  public GetDeckPanelDropdownItems(deckType: DeckType): DropdownItem<DeckPanelListItem>[] {
    const deckPanels = this.areaItems.deckPanels.filter(x => x.deckTypes.includes(deckType));
    deckPanels.sort((x, y) => x.index - y.index);
    return deckPanels.map<DropdownItem<DeckPanelListItem>>(x => ({
      text: x.translations[this.currentLanguage] ? x.translations[this.currentLanguage] : x.value.toString(),
      value: x
    }));
  }

  /**
   *
   * @returns DropdownItems for PanelType Dropdown on Area Properties
   */
  public GetPanelTypeDropdownItems(deckPanelId: number): DropdownItem<PanelTypeListItem>[] {
    const deckPanel = this.areaItems.deckPanels.find(item => item.id === deckPanelId);
    const panelTypes = this.areaItems.panelTypes.filter(item => deckPanel.panelTypes.includes(item.id));
    panelTypes.sort((x, y) => x.index - y.index);
    return panelTypes.map<DropdownItem<PanelTypeListItem>>(x => ({
      text: this.localizationService.getString(x.value),
      value: x,
    }));
  }

  /**
   *
   * @returns DropdownItems for PanelWidth Dropdown on Area Properties
   */
  public GetPanelWidthDropdownItems(panelId: number, unit: Unit): DropdownItem<PanelWidthListItem>[] {
    const panelWidths = [...new Map(this.zoneItems.patterns.filter(p => p.panelId == panelId).map(item => [item['panelWidth'], item])).values()].sort((x, y) => x.panelWidthSI - y.panelWidthSI);
    const isImperial = unit === Unit.ft || unit === Unit.inch || unit === Unit.mi;
    return panelWidths.map<DropdownItem<PanelWidthListItem>>(width => ({
      text: (isImperial ? width.panelWidth : width.panelWidthSI).toString(),
      value: {
        id: width.panelWidthSI,
        index: width.panelWidthSI,
        value: width.panelWidthSI
      },
    }));
  }

  /**
   *
   * @returns Available Panel Widths for panel
   */
  public GetPanelWidths(panelId: number): number[][] {
    return [...new Map(this.zoneItems.patterns.filter(p => p.panelId == panelId).map(item => [item['panelWidth'], [item.panelWidth, item.panelWidthSI]])).values()];
  }

  /**
   *
   * @returns DropdownItems for SupportConstruction Dropdown on Area Properties
   */
  public GetSupportConstructionDropdownItems(): DropdownItem<SupportConstructionsListItem>[] {
    const supportConstructions = this.areaItems.supportConstructions;
    supportConstructions.sort((x, y) => x.index - y.index);
    return supportConstructions.map<DropdownItem<SupportConstructionsListItem>>(x => ({
      text: this.localizationService.getString(x.value),
      value: x,
    }));
  }

  /*** DropdownItems for Zone Dropdowns ***/

  /**
   *
   * @returns DropdownItems for deckGauges Dropdown on Zone Properties
   */
  public GetDeckGaugesDropdownItems(panelId: number): DropdownItem<DeckGaugeListItem>[] {
    const deckGauges = this.zoneItems.deckGauges.filter(p => p.panels.includes(panelId));
    const unitDisplay = 'ga';
    deckGauges.sort((x, y) => x.index - y.index);
    return [this.buildNotSelectedItem<DeckGaugeListItem>(), ...deckGauges.map<DropdownItem<DeckGaugeListItem>>(x => ({
      text: `${x.value.toString()} ${unitDisplay}`,
      value: x,
    }))];
  }
  /**
   *
   * @returns Frame Patterns on Zone Properties
   */
  public GetPatterns(panelId: number, width: number): PatternListItem[] {
    return this.zoneItems.patterns
      .filter(p => p.panelId === panelId && (p.panelWidth === width || p.panelWidthSI === width))
      .sort((x, y) => x.index - y.index);
  }

  /**
   *
   * @returns DropdownItems for patterns Dropdown on Zone Properties
   */
  public GetPatternsDropdownItems(panelId: number, width: number, isLengthImperial?: boolean): DropdownItem<PatternListItem>[] {
    const patterns = this.GetPatterns(panelId, width);
    return [this.buildNotSelectedItem<PatternListItem>(), ...patterns.map(item => ({
      image: this.SetBase64Image(this.fastenersItems.filter(p => p.panelId === panelId && p.fastenerPatternId === item.id)[0]?.image),
      text: isLengthImperial ? item.pattern : item.patternSI,
      value: { ...item, value: isLengthImperial ? item.patternSI : item.pattern }
    }))];
  }

  public GetPatternImage(panelId: number, fastenerPatternId: number): string {
    const patterns = this.fastenersItems.filter(p => p.panelId === panelId && p.fastenerPatternId === fastenerPatternId);
    return patterns && patterns.length > 0 ? patterns[0].image : '';
  }

  /**
   *
   * @param base64image image as string format
   * @returns convert a base64 image to IIconStyle interface
   */
  public SetBase64Image(base64image: string): IIconStyle {
    if (base64image == undefined) {
      return {
        'display': 'none'
      };
    }
    else {
      return {
        'background-image': `url("data:image/png;base64,${base64image}")`,
        'height': '20px',
        'width': '290px',
        'background-size': 'contain',
        'background-repeat': 'no-repeat',
        'background-position': 'center center'
      };
    }
  }

  /**
   *
   * @returns DropdownItems for frameFasteners Dropdown on Zone Properties in Design Mode
   */
  public GetFrameFastenersDropdownItems(panelId: number): DropdownItem<FrameFastenerListItem>[] {
    const frameFasteners = this.zoneItems.frameFasteners.filter(f => f.useForDesign && !f.restrictedPanels.includes(panelId));
    frameFasteners.sort((x, y) => x.index - y.index);
    return [this.buildNotSelectedItem<FrameFastenerListItem>(), ...frameFasteners.map<DropdownItem<IDeckingDocument<number, string>>>(x => ({
      text: x.translations[this.currentLanguage] ? x.translations[this.currentLanguage] : x.fastenerName,
      value: { id: x.id, index: x.index, value: x.shortFastenerName, shortFastenerName: x.shortFastenerName, isHILTIProduct: x.isHILTIProduct, translations: x.translations, hiltiOnlinePageUrl: x.hiltiOnlinePageUrl, pictureFileUrl: x.pictureFileUrl, useForDesign: x.useForDesign, useForSpecified: x.useForSpecified, useForSubstituted: x.useForSubstituted },
    }))];
  }

  /**
   *
   * @returns DropdownItems for specified frameFasteners Dropdown on Zone Properties in Substitution mode
   */
  public GetSpecifiedFrameFastenersDropdownItems(panelId: number): DropdownItem<FrameFastenerListItem>[] {
    const frameFasteners = this.zoneItems.frameFasteners.filter(f => f.useForSpecified && !f.restrictedPanels.includes(panelId));
    frameFasteners.sort((x, y) => x.index - y.index);
    return [this.buildNotSelectedItem<FrameFastenerListItem>(), ...frameFasteners.map<DropdownItem<IDeckingDocument<number, string>>>(x => ({
      text: x.translations[this.currentLanguage] ? x.translations[this.currentLanguage] : x.fastenerName,
      value: { id: x.id, index: x.index, value: x.shortFastenerName, shortFastenerName: x.shortFastenerName, isHILTIProduct: x.isHILTIProduct, translations: x.translations, hiltiOnlinePageUrl: x.hiltiOnlinePageUrl, pictureFileUrl: x.pictureFileUrl, useForDesign: x.useForDesign, useForSpecified: x.useForSpecified, useForSubstituted: x.useForSubstituted },
    }))];
  }

  /**
   *
   * @returns DropdownItems for substituted frameFasteners Dropdown on Zone Properties in Substitution mode
   */
  public GetSubstitutedFrameFastenersDropdownItems(panelId: number): DropdownItem<FrameFastenerListItem>[] {
    const frameFasteners = this.zoneItems.frameFasteners.filter(f => f.useForSubstituted && !f.restrictedPanels.includes(panelId));
    frameFasteners.sort((x, y) => x.index - y.index);
    return [this.buildNotSelectedItem<FrameFastenerListItem>(), ...frameFasteners.map<DropdownItem<IDeckingDocument<number, string>>>(x => ({
      text: x.translations[this.currentLanguage] ? x.translations[this.currentLanguage] : x.fastenerName,
      value: { id: x.id, index: x.index, value: x.shortFastenerName, shortFastenerName: x.shortFastenerName, isHILTIProduct: x.isHILTIProduct, translations: x.translations, hiltiOnlinePageUrl: x.hiltiOnlinePageUrl, pictureFileUrl: x.pictureFileUrl, useForDesign: x.useForDesign, useForSpecified: x.useForSpecified, useForSubstituted: x.useForSubstituted },
    }))];
  }

  /**
   * @param fastenerId id of te fastener
   * @returns translated name for a fastener
   */
  public getFastenerTranslatedName(fastenerId: number): string {
    const frameFasteners = this.zoneItems.frameFasteners.filter(f => f.id == fastenerId);
    if (frameFasteners.length == 0) {
      return '';
    }
    return frameFasteners[0].translations[this.currentLanguage] ? frameFasteners[0].translations[this.currentLanguage] : frameFasteners[0].fastenerName;
  }

  /**
   *
   * @returns DropdownItems for sidelapConnectors Dropdown on Zone Properties
   */
  public GetSidelapConnectorsDropdownItems(panelId: number, panelType: PanelType): DropdownItem<SidelapConnectorListItem>[] {
    const sidelapConnectors = this.zoneItems.sidelapConnectors.filter(s => !s.restrictedPanels.includes(panelId) && s.panelTypes.includes(panelType));
    sidelapConnectors.sort((x, y) => x.index - y.index);
    return [this.buildNotSelectedItem<SidelapConnectorListItem>(), ...sidelapConnectors.map<DropdownItem<SidelapConnectorListItem>>(x => ({
      text: x.translations[this.currentLanguage] ? x.translations[this.currentLanguage] : x.fastenerName,
      value: { ...x, value: x.fastenerName, isHILTIProduct: x.isHILTIProduct },
    }))];
  }

  /**
 * @param sidelapId id of the sidelap
 * @returns translated name for a sidelap
 */
  public getSidelapTranslatedName(sidelapId: number): string {
    const sidelapConnectors = this.zoneItems.sidelapConnectors.filter(f => f.id == sidelapId);
    if (sidelapConnectors.length == 0) {
      return '';
    }
    return sidelapConnectors[0].translations[this.currentLanguage] ? sidelapConnectors[0].translations[this.currentLanguage] : sidelapConnectors[0].fastenerName;
  }

  /**
   *
   * @returns DropdownItems for sidelapConnectorSpacings Dropdown on Zone Properties
   */
  public GetSidelapConnectorSpacingsDropdownItems(start: number, end: number, increment: number): DropdownItem<SidelapConnectorSpacingListItem>[] {
    const sidelapConnectorSpacings = range(start, end + 1, increment);
    return [this.buildNotSelectedItem<SidelapConnectorSpacingListItem>(), ...sidelapConnectorSpacings.map<DropdownItem<SidelapConnectorSpacingListItem>>((x, i) => ({
      text: x.toString(),
      value: {
        id: x,
        value: x,
        index: i,
        fieldState: FieldState.NotSelected
      },
    }))];
  }

  /**
   * @returns Default DeckType
   */
  public GetDefaultDeckTypeRadioItem(): DeckTypeListItem {
    return this.areaItems.deckTypes[0];
  }

  /**
   * @returns Default DeckFill
   */
  public GetDefaultDeckFillDropdownItem(): DeckFillListItem {
    return this.areaItems.deckFills[0];
  }

  /**
   * @returns Default CompressiveStrength
   */
  public GetDefaultCompressiveStrengthDropdownItem(deckFill: DeckFill): CompressiveStrengthListItem {
    return this.areaItems.compressiveStrengths.find(x => x.deckFills.includes(deckFill));
  }

  /**
   * @returns Default DeckPanel
   */
  public GetDefaultDeckPanelDropdownItem(deckType: DeckType): DeckPanelListItem {
    let deckPanelItem: DeckPanelListItem;
    if (deckType === DeckType.SteelroofDeck) {
      deckPanelItem = this.areaItems.deckPanels.find(x => x.id === DeckingDefaultFactoryService.DEFAULT_ROOF_DECKPANEL_ID);
    } else {
      deckPanelItem = this.areaItems.deckPanels.find(x => x.deckTypes.includes(deckType));
    }

    return deckPanelItem || this.areaItems.deckPanels[0];
  }

  /**
   * @returns Default PanelType
   */
  public GetDefaultPanelTypeDropdownItem(panelId: number): PanelTypeListItem {
    const panelTypeOptions = this.GetPanelTypeDropdownItems(panelId);
    return panelTypeOptions.length > 0 ? panelTypeOptions[0].value : null;
  }

  /**
   * @returns Default PanelWidth
   */
  public GetDefaultPanelWidthDropdownItem(panelId: number): PanelWidthListItem {
    const panelWidthsOptions = this.GetPanelWidthDropdownItems(panelId, Unit.inch);
    return panelWidthsOptions[panelWidthsOptions.length - 1].value; // Returns the last element (the highest)
  }

  /**
   * @returns Default SupportConstruction
   */
  public GetDefaultSupportConstructionDropdownItem(): SupportConstructionsListItem {
    return this.areaItems.supportConstructions[0];
  }

  /**
   * @returns Default DeckGauge
   */
  public GetDefaultDeckGaugeDropdownItem(): DeckGaugeListItem {
    return null;
  }

  /**
   * @returns Default DeckGauge
   */
  public GetDefaultSubstitutionDeckGaugeDropdownItem(): SubstitutionDeckGaugeListItem {
    return null;
  }

  /**
   * @returns Default Pattern
   */
  public GetDefaultPatternDropdownItem(): DeckingFieldState<number, string> {
    return null;
  }

  /**
   * @returns Default FrameFastener
   */
  public GetDefaultFrameFastenerDropdownItem(): DeckingFieldState<number, string> {
    return null;
  }

  /**
   * @returns Default SidelapConnector
   */
  public GetDefaultSidelapConnectorDropdownItem(): DeckingFieldState<number, string> {
    return null;
  }

  /**
   * @returns Default Joist/Beam Spacing
   */
  public GetDefaultJoistBeamSpacing(region: Region): number {
    if (region === Region.CA) {
      return DeckingDefaultFactoryService.DEFAULT_JOIST_SPACING_IN_MM_CA;
    }
    return DeckingDefaultFactoryService.DEFAULT_JOIST_SPACING_IN_MM_US;

  }

  /**
   * @returns Default Joist/Beam Thickness
   */
  public GetDefaultJoistBeamThickness(region: Region): number {
    if (region === Region.CA) {
      return this.deckingMainService.getSelectedModeType() == DeckingDesignModeType.DesignMode ? DeckingDefaultFactoryService.DEFAULT_JOIST_THICKNESS_IN_MM_CA : DeckingDefaultFactoryService.SUBSTITUTION_DEFAULT_JOIST_THICKNESS_IN_MM_CA;
    }
    return this.deckingMainService.getSelectedModeType() == DeckingDesignModeType.DesignMode ? DeckingDefaultFactoryService.DEFAULT_JOIST_THICKNESS_IN_MM_US : DeckingDefaultFactoryService.SUBSTITUTION_DEFAULT_JOIST_THICKNESS_IN_MM_CA;
  }

  /**
   * @return Default Concrete Fill Thickness
   */
  public GetDefaultConcreteFillThickness(region: Region): number {
    if (region === Region.CA) {
      return DeckingDefaultFactoryService.DEFAULT_CONCRETE_FILL_THICKNESS_IN_MM_CA;
    }
    return DeckingDefaultFactoryService.DEFAULT_CONCRETE_FILL_THICKNESS_IN_MM_US;
  }

  public GetFrameFastenerItem(frameFastenerId: number): FrameFastenerListItem {
    return this.zoneItems.frameFasteners.find(s => s.id === frameFastenerId);
  }

  public GetSidelapItem(sidelapId: number): SidelapConnectorListItem {
    return this.zoneItems.sidelapConnectors.find(s => s.id === sidelapId);
  }

  public GetPanelCrossSection(panelId: number): IPanelCrossSectionListItem {
    let panelCrossSection = this.panelDefinitions.find(pcs => pcs.id === panelId && pcs.panelCrossSections && pcs.panelCrossSections.length > 0);
    if (!panelCrossSection) {
      for (const id of this.defaultPanelCrossSections) {
        panelCrossSection = this.panelDefinitions.find(pcs => pcs.id === id && pcs.panelCrossSections && pcs.panelCrossSections.length > 0);
        if (panelCrossSection) {
          break;
        }
      }
      return panelCrossSection ?? this.panelDefinitions[0];
    }
    return panelCrossSection;
  }

  public GetSidelapConnectorShortName(sidelapId: number): string {
    const sidelapConnector = this.zoneItems.sidelapConnectors.filter(s => s.id == sidelapId);
    return sidelapConnector.length > 0 ? sidelapConnector[0].shortFastenerName : '';
  }

  /**
   *
   * @returns An empty item to be used as not selected item
   */
  private buildNotSelectedItem<T>(): DropdownItem<T> {
    return {
      text: ' ', // invisible U+00A0 character to be presented in the same way as the others items
      value: null,
    };
  }

  public GetFastenerItems(): IFastenerPatterns[] {
    return this.fastenersItems;
  }
}
