import { HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LocalizationService } from  './../../services/external/localization.service';
import { ModalService } from  './../../services/external/modal.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  constructor(
    private modalService: ModalService,
    private localizationService: LocalizationService
  ) { }

  public showDesignErrorModal(title?: string, message?: string, response?: HttpResponseBase, url?: string, requestData?: object): void {
    this.modalService
      .openAlertError(
        title ?? this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.DesignDoesNotExistAnymore.Title'),
        message ?? this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.DesignDoesNotExistAnymore.Message'),
        {
          response,
          endPointUrl: url,
          requestPayload: requestData
        }
      );
  }
}