import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tooltip, ngbTooltipTemplate } from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import { InfoPopup } from '@profis-engineering/pe-ui-common/components/control-title/control-title.common';
import { RadioButtonItem } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { randomString } from '@profis-engineering/pe-ui-common/helpers/random';

@Component({
  selector: 'decking-button-group',
  templateUrl: './decking-button-group.component.html',
  styleUrls: ['./decking-button-group.component.scss']
})
export class DeckingButtonGroupComponent<TValue> {

  @Input()
    public id = randomString(8);

    @Input()
    public title: string;

    @Input()
    public selectedValue: TValue;

    @Output()
    public selectedValueChange = new EventEmitter<TValue>();

    @Input()
    public items: RadioButtonItem<TValue>[];

    @Input()
    public tooltip: Tooltip;

    @Input()
    public infoPopup: InfoPopup;

    @Input()
    public infoPopupTooltip: Tooltip;

    public onSelected(item: RadioButtonItem<TValue>) {
        this.selectedValue = item.value;
        this.selectedValueChange.emit(item.value);
    }

    public ngbTooltipTemplate = ngbTooltipTemplate;

}
