import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { LocalizationService } from './../../../services/external/localization.service';
import { includeSprites } from 'src/decking/sprites';


@Component({
    selector: 'app-square-icon-button',
    templateUrl: './square-icon-button.component.html',
    styleUrls: ['./square-icon-button.component.scss'],
})
export class SquareIconButtonComponent {
    @Input() disabled: boolean;

    @Input() iconSprite: string;

    @Input() tooltipTranslationKey: string;

    @Output() buttonClick = new EventEmitter();

    @Input() width: string;

    @Input() height: string;

    constructor(
        public localizationService: LocalizationService,
        private elementRef: ElementRef<HTMLElement>
    ) {
        includeSprites(
            this.elementRef.nativeElement,
            'sprite-search',
            'sprite-center',
            'sprite-view',
            'sprite-arrow-left-medium',
            'sprite-arrow-right-medium',
            'sprite-undo',
            'sprite-redo'
        );
    }

    public get Tooltip(): string {
        return this.localizationService.getString(this.tooltipTranslationKey);
    }

    public get widthWithDefault(): string {
        return this.width ? this.width : '32px';
    }

    public get heightWithDefault(): string {
        return this.height ? this.height : '32px';
    }
}
