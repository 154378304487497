import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeckingDesignSettingsService {

  private _saveSignal = new BehaviorSubject<string>(null);
  saveSignal$ = this._saveSignal.asObservable();

  sendDeckingSettingsSaveSignal(projectId: string) {
    this._saveSignal.next(projectId);
  }
}
