<decking-numeric-text-box
    [id]="id"
    [cssClass]="cssClass"
    [title]="title"
    [value]="internalValue"
    [showStepper]="false"
    (valueChange)="onValueChanged($any($event).detail)"
    [unit]="unit$ | async"
    [appendUnit]="isBySpacing$ | async"
    [maxValue]="maxValue$ | async"
    [minValue]="minValue$ | async"
    [precision]="(isBySpacing$ | async) ? 2 : 0"
    [disabled]="disabled || saving"
    SelectTextOnClick>
</decking-numeric-text-box> 
