import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef,  OnDestroy, OnInit } from '@angular/core';
import { DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { DesignSettings } from './../../../entities/settings/design-settings';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { RadioButtonItem } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { DeckGaugeListItem } from './../../../entities/decking-code-list/code-list/deck-gauge-list-item';
import { PatternListItem } from './../../../entities/decking-code-list/code-list/pattern-list-item';
import { FrameFastenerListItem } from './../../../entities/decking-code-list/code-list/frame-fastener-list-item';
import { SidelapConnectorListItem } from './../../../entities/decking-code-list/code-list/sidelap-connector-list-item';
import { ZoneModel } from './../../../entities/decking-design/zone-model';
import { DeckingCodeListService } from './../../../services/decking-code-list/decking-code-list.service';
import { DeckingUnitsHelperService } from './../../../services/decking-units-helper/decking-units-helper.service';
import { map, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { includeSprites } from '../.././../sprites';
import { PanelType } from './../../../entities/decking-code-list/enums/panel-type';
import { SubstitutionZoneModel } from './../../../entities/decking-substitution/substitution-zone';

@Component({
    template: '',
    changeDetection: ChangeDetectionStrategy.Default //added to prevent object design disconnection
})
export class DeckingCommonZonesComponent implements OnInit, OnDestroy {
    public saving$: Observable<boolean>;
    public canDeleteZone$: Observable<boolean>;
    public areResultsExpanded$: Observable<boolean>;
    public currentSettings$: Observable<DesignSettings>;
    public isImperialUnit$: Observable<boolean>;
    public savingSubscription!: Subscription;
    public currentAreaSubscription!: Subscription;

    public panelId$: Observable<number>;
    public panelWidth$: Observable<number>;
    public panelType$: Observable<PanelType>;

    // Dropdowns
    public deckGaugeItems$: Observable<DropdownItem<DeckGaugeListItem>[]>;
    public patternItems$: Observable<DropdownItem<PatternListItem>[]>;
    public frameItems$: Observable<DropdownItem<FrameFastenerListItem>[]>;
    public specifiedFrameItems$: Observable<DropdownItem<FrameFastenerListItem>[]>;
    public substitutedFrameItems$: Observable<DropdownItem<FrameFastenerListItem>[]>;
    public sidelapItems$: Observable<DropdownItem<SidelapConnectorListItem>[]>;
    public selectedLoadItems$: Observable<RadioButtonItem<boolean>[]>;

    // Units
    public lengthUnit$: Observable<Unit>;
    public stressUnit$: Observable<Unit>;
    public forcePerLengthUnit$: Observable<Unit>;
    public shearStiffnessUnit$: Observable<Unit>;
    public forceUnit$: Observable<Unit>;

    public isRequiredShearStiffnessSetting$: Observable<boolean>;    
    public isRequiredUpliftSubmittalSetting$: Observable<boolean>;
    public isSubstitutionRequiredShearStiffnessSetting$: Observable<boolean>;    
    public isSubstitutionRequiredUpliftSubmittalSetting$: Observable<boolean>;

    constructor(
        public deckingCodeListService: DeckingCodeListService,
        public deckingUnitsHelperService: DeckingUnitsHelperService,
        public elementRef: ElementRef<HTMLElement>,        
        public cdr: ChangeDetectorRef                       
    ) { }
    

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,
            'sprite-seismic-icon',
            'sprite-wind-icon',
            'sprite-arrow-left-medium',
            'sprite-arrow-right-medium'
        );
        this.isImperialUnit$ = this.currentSettings$?.pipe(map(s => s.length.id), distinctUntilChanged(),
        map((unitValue: Unit) => {
            return !this.deckingUnitsHelperService.isInternationalSystemUnit(unitValue);
        }));                 
        this.initDropDowns();
        this.initUnits();        
    }
    ngOnDestroy(): void {
        this.savingSubscription?.unsubscribe();
        this.currentAreaSubscription?.unsubscribe();
    }

    public detectChanges() {
        this.cdr.detach();
        this.cdr.detectChanges();
        this.cdr.reattach();
    }

    public trackByFn(_: number, zone: ZoneModel | SubstitutionZoneModel): string {
        return zone.id;
    }

    public initDropDowns(): void {
        this.deckGaugeItems$ = this.panelId$.pipe(map(panelId => this.deckingCodeListService.GetDeckGaugesDropdownItems(panelId)), shareReplay(1));
        this.patternItems$ = combineLatest([this.panelId$, this.panelWidth$, this.isImperialUnit$]).pipe(map(([panelId, panelWidth, isImperialUnit]) => this.deckingCodeListService.GetPatternsDropdownItems(panelId, panelWidth, isImperialUnit)), shareReplay(1));
        this.frameItems$ = this.panelId$.pipe(map(panelId => this.deckingCodeListService.GetFrameFastenersDropdownItems(panelId)), shareReplay(1));
        this.sidelapItems$ = combineLatest([this.panelId$, this.panelType$]).pipe(map(([panelId, panelType]) => this.deckingCodeListService.GetSidelapConnectorsDropdownItems(panelId, panelType)), shareReplay(1));
        this.specifiedFrameItems$ = this.panelId$.pipe(map(panelId => this.deckingCodeListService.GetSpecifiedFrameFastenersDropdownItems(panelId)), shareReplay(1));
        this.substitutedFrameItems$ = this.panelId$.pipe(map(panelId => this.deckingCodeListService.GetSubstitutedFrameFastenersDropdownItems(panelId)), shareReplay(1));
    }

    private initUnits(): void {
        this.lengthUnit$ = this.isImperialUnit$?.pipe(map(isImperialUnit => isImperialUnit ? Unit.ft : Unit.m));
        this.forcePerLengthUnit$ = this.currentSettings$?.pipe(map(s => s.forcePerLength?.id), distinctUntilChanged());
        this.shearStiffnessUnit$ = this.currentSettings$?.pipe(map(s => s.shearStiffness?.id), distinctUntilChanged());
        this.stressUnit$ = this.currentSettings$?.pipe(map(s => s.stress?.id), distinctUntilChanged());
        this.forceUnit$ = this.currentSettings$?.pipe(map(s => s.force?.id), distinctUntilChanged());
    } 
}
