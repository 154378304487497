import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { DesignType as DesignTypeCommon } from '@profis-engineering/pe-ui-common/entities/code-lists/design-type';
import { DocumentIntegrationType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import { SafeFunctionInvokerHelper } from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import {
    AppSettings, IAbpInfo, IDesignInfo, IDesignListInfo, IFavoritesInfo, IImportDesignProvider,
    IIntegrationsInfo, IQuickStartApplication, IServiceNameMapping, IVirtualTour, IUserSettingsInfo
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
/**
 * Service used to consolidate data obtained from various modules (e.g. pe-ui-pe, pe-ui-c2c).
 */
@Injectable({
    providedIn: 'root'
})
export class ModulesService {
    private quickStartModulesAddedSubject = new Subject<void>();
    public quickStartModulesAdded = this.quickStartModulesAddedSubject.asObservable();

    private designListInfoProvidedSubject = new Subject<void>();
    public designListInfoProvided = this.designListInfoProvidedSubject.asObservable();

    private designInfoProvidedSubject = new Subject<void>();
    public designInfoProvided = this.designInfoProvidedSubject.asObservable();

    private authenticationRequiredServices: string[] = [];
    private serviceNameMapping: IServiceNameMapping = {};
    private appSettingsData: AppSettings[] = [];
    private quickStartApplications: IQuickStartApplication[] = [];
    private virtualTours: IVirtualTour[] = [];
    private designInfo: IDesignInfo[] = [];
    private designListInfo: IDesignListInfo[] = [];
    private importDesignProviders: IImportDesignProvider[] = [];
    private importFileExtensions: string[] = [];
    private abpData: IAbpInfo[] = [];
    private integrationsData: IIntegrationsInfo[] = [];
    private designTypesInternal: DesignTypeCommon[] = [];
    private favoritesInfo: IFavoritesInfo[] = [];
    private userSettingsInfo: IUserSettingsInfo[] = [];
    private initialDataLoadedEvents: (() => Promise<void> | void)[] = [];

    public get designTypes() {
        return this.designTypesInternal;
    }

    public async downloadDesign(name: string, designId: string, designTypeId: number) {
        const designInfo = this.designListInfo.find(x => x.designTypeId == designTypeId && x.downloadDesign != null);
        if (designInfo != null) {
            await SafeFunctionInvokerHelper.safeInvoke(() => designInfo.downloadDesign(name, designId));
        }
    }

    // Authentication required services
    public addAuthenticationRequiredServices(services: string[]) {
        if (services.length) {
            this.authenticationRequiredServices = this.authenticationRequiredServices.concat(services);
        }
    }

    public serviceRequiresAuthentication(url: string) {
        return this.authenticationRequiredServices.some((prefix) => url.startsWith(prefix));
    }


    // Service name mappings
    public addServiceNameMappings(mapping: IServiceNameMapping) {
        if (!mapping) {
            return;
        }

        for (const key in mapping) {
            this.serviceNameMapping[key] = mapping[key];
        }
    }

    public getServiceName(url: string): string {
        for (const mappingUrl in this.serviceNameMapping) {
            if (url.startsWith(mappingUrl)) {
                return this.serviceNameMapping[mappingUrl];
            }
        }

        return undefined;
    }


    public addAppSettingsData(appSettings: AppSettings[]) {
        if (appSettings.length) {
            this.appSettingsData = this.appSettingsData.concat(appSettings);
        }
    }

    public getAppSettingsData(): AppSettings[] {
        return this.appSettingsData;
    }


    public addQuickStartApplications(quickStartApplications: IQuickStartApplication[]) {
        if (quickStartApplications.length) {
            this.quickStartApplications = this.quickStartApplications.concat(quickStartApplications);

            this.quickStartModulesAddedSubject.next();
        }
    }

    public getQuickStartApplications(): IQuickStartApplication[] {
        return this.quickStartApplications;
    }


    public addVirtualTours(virtualTours: IVirtualTour[]) {
        if (virtualTours.length) {
            this.virtualTours = this.virtualTours.concat(virtualTours);
        }
    }

    public getVirtualTours(): IVirtualTour[] {
        return this.virtualTours;
    }


    public addDesignInfo(designInfo: IDesignInfo[]) {
        if (designInfo.length) {
            this.designInfo = this.designInfo.concat(designInfo);

            this.designInfoProvidedSubject.next();
        }
    }

    public getDesignInfo(): IDesignInfo[] {
        return this.designInfo;
    }

    // this code should moved to specific module after modularization
    public getDesignInfoForDesignType(designTypeId: number, regionId: number, connectionType?: number) {
        return this.getDesignInfo().find(x => x.designTypeId == designTypeId
            && (!('connectionType' in x) || (x.connectionType as Array<number>).includes(connectionType) || connectionType == null)
            && x.isAvailable(regionId));
    }


    public addDesignListInfo(designListInfo: IDesignListInfo[]) {
        if (designListInfo.length) {
            this.designListInfo = this.designListInfo.concat(designListInfo);

            this.designListInfoProvidedSubject.next();
        }
    }

    public getDesignListInfo(): IDesignListInfo[] {
        return this.designListInfo;
    }

    public getDesignListInfoByDesignType(designTypeId: number) {
        return this.designListInfo.find((design) => design.designTypeId == designTypeId);
    }


    public addImportDesignProvider(importDesignProvider: IImportDesignProvider) {
        if (importDesignProvider) {
            this.importDesignProviders.push(importDesignProvider);
        }
    }

    public getImportDesignProviders(): IImportDesignProvider[] {
        return this.importDesignProviders;
    }

    public addImportFileExtensions(importFileExtensions: string[]) {
        if (importFileExtensions.length) {
            this.importFileExtensions = this.importFileExtensions.concat(importFileExtensions);
        }
    }

    public getImportFileExtensions(): string[] {
        return this.importFileExtensions;
    }


    public addAbpInfo(abpInfo: IAbpInfo) {
        if (abpInfo) {
            this.abpData.push(abpInfo);
        }
    }

    public isAdvancedCalculationPossible(designType: number, designStandard: number, region: number) {
        const data = this.abpData.filter(x => x.isAdvancedCalculationPossible != null);
        for (const d of data) {
            const retVal = SafeFunctionInvokerHelper.safeInvoke(() => d.isAdvancedCalculationPossible(designType, designStandard, region), false);
            if (retVal) {
                return true;
            }
        }

        return false;
    }

    public isRigidityCheckPossible(designStandard: number, region: number) {
        const data = this.abpData.filter(x => x.isRigidityCheckPossible != null);
        for (const d of data) {
            const retVal = SafeFunctionInvokerHelper.safeInvoke(() => d.isRigidityCheckPossible(designStandard, region), false);
            if (retVal) {
                return true;
            }
        }

        return false;
    }


    public addIntegrationsInfo(integrationsInfo: IIntegrationsInfo) {
        if (integrationsInfo) {
            this.integrationsData.push(integrationsInfo);
        }
    }

    public getQuickStartApplicationForIntegration(integrationType: DocumentIntegrationType): IQuickStartApplication | undefined {
        const data = this.integrationsData.filter(x => x.getQuickStartApplicationForIntegration != null);
        for (const d of data) {
            const retVal = SafeFunctionInvokerHelper.safeInvoke(() => d.getQuickStartApplicationForIntegration(integrationType), undefined);
            if (retVal != null) {
                return retVal;
            }
        }

        return undefined;
    }


    public addDesignTypes(designTypes?: DesignTypeCommon[]) {
        if (designTypes?.length) {
            this.designTypesInternal.push(...designTypes);
            this.designTypesInternal.sort((a, b) => a.id - b.id);
        }
    }


    public addFavoritesInfo(favoritesInfo?: IFavoritesInfo) {
        if (favoritesInfo) {
            this.favoritesInfo.push(favoritesInfo);
        }
    }

    public getFavoritesInfo(): IFavoritesInfo[] {
        return this.favoritesInfo;
    }

    public addUserSettingsInfo(userSettingsInfo?: IUserSettingsInfo) {
        if (userSettingsInfo) {
            this.userSettingsInfo.push(userSettingsInfo);
        }
    }

    public getUserSettingsInfo(): IUserSettingsInfo[] {
        return this.userSettingsInfo;
    }

    public addInitialDataLoadedEvent(initialDataLoadedEvent?: () => Promise<void> | void) {
        if (initialDataLoadedEvent) {
            this.initialDataLoadedEvents.push(initialDataLoadedEvent);
        }
    }

    public async initialDataLoaded() {
        const promises: Promise<void>[] = [];

        for (const initialDataLoadedEvent of this.initialDataLoadedEvents) {
            promises.push(Promise.resolve(initialDataLoadedEvent()).catch(error => console.error(error)));
        }

        await Promise.all(promises);
    }
}
