import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { LocalizationService } from './../../../services/external/localization.service';
import { includeSprites } from '../.././../sprites';
import { environment } from 'src/environments/environmentDecking';

@Component({
  templateUrl: './fastener-selection.component.html',
  styleUrls: ['./fastener-selection.component.scss'],
})
export class FastenerSelectionComponent implements OnInit {

  @Input()
  public modalInstance!: ModalInstance;
  
  public additionalInformation: string;
  public format = format;

  constructor(
    public localization: LocalizationService,
    private elementRef: ElementRef<HTMLElement>) {

    this.additionalInformation = this.localization.getString('Agito.Hilti.Profis3.Decking.FastenerSelection.AdditionalInformation').replace('{productTechnicalGuideLink}', environment.deckingProductTechnicalGuideLink);
  }

  public ngOnInit(): void {
    includeSprites(this.elementRef.nativeElement,
        'sprite-bar-joist',
        'sprite-X-HSN24',
        'sprite-S-RT5-M9',
        'sprite-S-MD12-24x1-5-8M-HWH5',
        'sprite-structural-steel',
        'sprite-X-ENP-19L15',
        'sprite-S-SLC01M-HWH',
        'sprite-S-SLCO2-MHWH',
        'sprite-Hilti10HWHScrew'
    );
  }

  public close(): void {
    this.modalInstance.close();
  }
}
