import { Component, Input, OnInit } from '@angular/core';
import { TextBoxDisplay } from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import { SubstitutionZoneModel } from '../../../../../entities/decking-substitution/substitution-zone';
import { TextBoxAlign } from '../../../../common/text-box/text-box.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { DeckingSubstitutionService } from '../../../../../services/decking-design/decking-substitution.service';
import { DeckingUnitsHelperService } from '../../../../../services/decking-units-helper/decking-units-helper.service';
import { DeckingSpecificationCalculationService } from '../../../../../services/decking-design/calculation/specification/decking-specification-calculation.service';
import { DesignSettings } from '../../../../../entities/settings/design-settings';
import { SubstitutionAreaModel } from '../../../../../entities/decking-substitution/substitution-area';

@Component({
  selector: '[app-substitution-fastener-estimation-row]',
  templateUrl: './substitution-fastener-estimation-row.component.html',
  styleUrls: ['./substitution-fastener-estimation-row.component.scss']
})
export class SubstitutionFastenerEstimationRowComponent implements OnInit {
  @Input()
  public currentArea: SubstitutionAreaModel;

  @Input()
  public deckingZone: SubstitutionZoneModel;

  @Input()
  public index: number;

  constructor(
    private deckingSubstitutionService: DeckingSubstitutionService,
    private deckingUnitsHelperService: DeckingUnitsHelperService,
    private deckingSpecificationCalculationService: DeckingSpecificationCalculationService
  ) { 
    this.initializeStreams();
  }

  ngOnInit(): void {
    this.setUnits();
    this.setAvarageJoistSpacing();
  }

  public numericTextBoxDisplay = TextBoxDisplay.Normal;
  public numericTextBoxAlign = TextBoxAlign.End;

  public saving$: Observable<boolean>;
  public currentLengthSetting$: Observable<Unit>;
  public shownLengthUnit$: Observable<Unit>;
  public shownAreaUnit$: Observable<Unit>;
  public shownSpacingUnit$: Observable<Unit>;

  private initializeStreams() {
    this.currentLengthSetting$ = this.deckingSubstitutionService.currentSettings$.pipe(map((settings: DesignSettings) => settings.length.id), distinctUntilChanged());
    this.saving$ = this.deckingSpecificationCalculationService.saving$;
  }

  private setAvarageJoistSpacing() {
    if (!this.deckingZone.fastenerEstimation.averageJoistSpacing?.value) {
      this.deckingZone.fastenerEstimation.averageJoistSpacing = { value: this.currentArea.zoneSpacing?.value ? this.deckingZone.s.value : this.currentArea.beam.spacing.value };
    }
  }

  private setUnits() {
    this.shownLengthUnit$ = this.currentLengthSetting$.pipe(
      map((unit: Unit) => this.getLengthUnit(unit))
    );
    this.shownAreaUnit$ = this.currentLengthSetting$.pipe(
      map((unit: Unit) => this.getAreaUnit(unit))
    );
    this.shownSpacingUnit$ = this.currentLengthSetting$.pipe(
      map((unit: Unit) => this.getSpacingUnit(unit))
    );
  }

  private getLengthUnit(lengthUnit: Unit): Unit {
    return this.deckingUnitsHelperService.getStandardLengthUnit(lengthUnit);
  }

  private getAreaUnit(lengthUnit: Unit): Unit {
    return this.deckingUnitsHelperService.getFastenerEstimationAreaUnit(lengthUnit);
  }

  private getSpacingUnit(lengthUnit: Unit): Unit {
    return this.deckingUnitsHelperService.getBeamSpacingUnit(lengthUnit);
  }

  public updateZone() {
    this.deckingSubstitutionService.updateZone(this.deckingZone, this.index, true, true);
  }
}
