import { Injectable } from '@angular/core';
import { DeckingCodeListService } from  './../../../services/decking-code-list/decking-code-list.service';
import { AreaModel } from './../../../entities/decking-design/area-model';
import { DeflectionCalculation } from './../../../entities/decking-design/deflection-calculation';
import { DesignSettings } from './../../../entities/settings/design-settings';
import { DeckingTabsWrapperService } from  './../../../services/decking-tabs-wrapper/decking-tabs-wrapper.service';
import { DeckingZonesService } from  './../../../services/decking-zones/decking-zones.service';
import { BaseAreaFactory } from './base-area-factory';

@Injectable({
  providedIn: 'root'
})
export class DeckingDesignAreaFactoryService extends BaseAreaFactory {

    constructor(
        protected override deckingTabsWrapperService: DeckingTabsWrapperService,
        protected override deckingCodeListService: DeckingCodeListService,
        private deckingZonesService: DeckingZonesService
      ) {
        super(deckingTabsWrapperService, deckingCodeListService);
      }
    
      buildDefaultDeckingArea(currentAreasCount: number, designSettings: DesignSettings): AreaModel {
        const defaultArea = this.getDefaultBaseArea(currentAreasCount, designSettings) as AreaModel;
        defaultArea.zones = [ this.deckingZonesService.getDefaultZone(designSettings) ];
        defaultArea.deflectionCalculation = new DeflectionCalculation();
        defaultArea.isDeflectionCalculationUpdate = false;
        return defaultArea;
      }
}
