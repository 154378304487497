import { Injectable } from '@angular/core';
import { IImportDesignProvider, IQuickStartApplication } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { LocalizationService } from '../external/localization.service';
import { FeatureVisibilityService } from '../external/feature-visibility.service';
import { DesignTypeId } from 'src/decking/entities/enums/design-types';
import { getSpriteAsIconStyle } from 'src/decking/sprites';
import { DeckingUserSettingsService } from '../decking-user-settings/user-settings.service';
import { CommonUserSettingsService } from '../external/common-user-settings.service';
import { ModalService } from '../external/modal.service';
import { ISaveDesign, ISaveDesignResult } from '@profis-engineering/pe-ui-common/entities/save-design';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';
import { DeckingSubstitutionService } from '../decking-design/decking-substitution.service';
import { DeckingDesignService } from '../decking-design/decking-design.service';
import { DeckingUrlPath } from 'src/decking/entities/module-constants';
import { RegionHub } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { CommonCodeListService } from '../external/common-code-list.service';
import { CantOpenDesignBecauseLockedByOtherUser, IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { DesignTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { DeckingDocumentService } from '../decking-document/decking-document.service';
import { RoutingService } from '../external/routing.service';
import { UrlPath as urlPathCommon } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { ICalculationResult } from '@profis-engineering/pe-ui-common/services/calculation.common';
import { IAddEditDesignComponentInput } from 'src/decking/entities/decking-design/add-edit-design';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { DisplayDesignType, IDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { DesignTemplateService } from '../external/design-template.service';
import { CodeListService } from '../external/code-list.service';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { NumberService } from '../external/number.service';
import { getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { DesignType } from '@profis-engineering/pe-ui-common/entities/code-lists/design-type';
import { ModulesService } from '../external/modules.service';
import { DesignStandard } from 'src/decking/entities/code-lists/design-standards';
import { DesignMethodGroup } from 'src/decking/entities/code-lists/design-method-group';
import { CommonRegion, CommonRegion as Region } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { DeckingMainService } from '../decking-main/decking-main.service';
import { DeckingImportProvider } from '../decking-import/decking-import.provider';
import { DeckingSubstitutionImportService } from '../decking-import/decking-substitution-import.service';
import { DeckingDesignImportService } from '../decking-import/decking-design-import.service';
import { BrowserService } from '../external/browser.service';
import { DeckingDesign } from 'src/decking/entities/decking-design/decking-design';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';
import { IDeckingDesignListInfo } from 'src/decking/entities/decking-design-list-info';
import { DeckingDesignVerificationService } from '../decking-design/calculation/verification/decking-design-verification.service';
import { DeckingDesignSignalrService } from '../decking-design-signalr/decking-design-signalr.service';
import { environment } from '../../../environments/environmentDecking';
import { DeckingDesignSettingsService } from '../decking-design/settings/decking-design-settings.service';
import { Decking3dSettingsService } from '../decking-3d-settings/decking-3d-settings.service';
import { DeckingSubstitutionSignalrService } from '../decking-design-signalr/decking-substitution-signalr.service';
import { DeckingSpecificationCalculationService } from '../decking-design/calculation/specification/decking-specification-calculation.service';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UserService } from '../external/user.service';
import { BaseDesign } from '../../entities/decking-design/base-design';

export class URLPath extends urlPathCommon {
    public static readonly mainDecking = '/decking/';
    public static readonly trimbleCallback = '/tc_auth_callback';
}

export enum DeckingRegion {
    US = 1,
    CA = 2,
}

export enum ProjectCodeList {
    Region,
    DesignStandard,
    DesignType,
    EdgeDistanceDisplayType,
    DesignMethodGroup,
    SteelGuidelines,
    ConcreteInCompressionMethod,
    ConcreteSafetyFactorGammaC,
    AdvancedCalculationType,
    BrickGroup
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationProviderService {
    private regions: { [id: string]: CommonRegion };
    private designTypes: { [id: string]: DesignType };
    private designStandards: { [id: string]: DesignStandard };
    private openedTemplateSettingsFromDashboard = false;

    constructor(
        // Common Services
        private localizationService: LocalizationService,
        private featureVisibilityService: FeatureVisibilityService,
        private deckingUserSettingsService: DeckingUserSettingsService,
        private decking3dSettingsService: Decking3dSettingsService,
        private commonUserSettingsService: CommonUserSettingsService,
        private modalService: ModalService,
        private deckingSubstitutionService: DeckingSubstitutionService,
        private deckingSubstitutionImportService: DeckingSubstitutionImportService,
        private deckingDesignService: DeckingDesignService,
        private deckingDesignImportService: DeckingDesignImportService,
        private documentService: DeckingDocumentService,
        private routingService: RoutingService,
        private codeListCommon: CommonCodeListService,
        private designTemplate: DesignTemplateService,
        private codeList: CodeListService,
        private numberService: NumberService,
        private modulesService: ModulesService,
        private deckingMainService: DeckingMainService,
        private deckingDesignVerificationService: DeckingDesignVerificationService,
        private deckingSignalrService: DeckingDesignSignalrService,
        private browser: BrowserService,
        private deckingDesignSettingsService: DeckingDesignSettingsService,
        private deckingSubstitutionSignalRService: DeckingSubstitutionSignalrService,
        private deckingSpecificationCalculationService: DeckingSpecificationCalculationService,
        private userService: UserService
    ) { }

    private get isDeckingEnabled(): boolean {
        return this.featureVisibilityService.isFeatureEnabled('Decking_Global') || environment.deckingEnabled;
    }

    public get getDesignTypes(): DesignType[] {
        const diaphragmDesignType = new DesignType({
            id: 106,
            displayKey: 'DiaphragmDesign',
            image: 'sprite-diaphragm-design',
            nameResourceKey: 'Agito.Hilti.Profis3.CodeList.DesignTypeEntity.DiaphragmDesign',
            allowedDesignStandardRegions: [{ RegionId: 6, DesignStandards: [] }, { RegionId: 80, DesignStandards: [] }],
            tooltipDisabledType: 0,
            tooltipType: 0
        });
        return [diaphragmDesignType];
    }

    public getDesignListInfo(): IDeckingDesignListInfo[] {
        return [
            {
                order: 1 * 1000 + 0,
                designTypeId: DesignTypeId.DiaphragmDesign,
                designTypeName: this.translate('Agito.Hilti.Profis3.Decking.ProjectAndDesign.Main.QuickStart.DesignType'),
                designTypeImage: getSpriteAsIconStyle('sprite-decking'),

                isEnabled: () => true,
                checkDesign: undefined,

                openFromDocumentDesign: (documentDesign: IDesignListItem) => this.LoadAndVerifyIfProjectIsLocked(documentDesign),
                copyDesign: (designid: string, designName: string, projectId: string) => this.copyDeckingDesign(designid, designName, projectId),
                openDesignSettings: (designId: string, _designName: string, _regionId: number, onDesignSettingsClosed?: () => void) => this.openDiaphragmSettings(designId, _designName, _regionId, onDesignSettingsClosed),
                openTemplate: (templateId: string) => this.loadDeckingDesignFromTemplate(templateId),
                openTemplateSettings: (templateId: string, onDesignSettingsClosed?: () => void) => this.openDeckingTemplate(templateId, onDesignSettingsClosed),
                newDesignFromTemplate: (designId: string, projectId?: string, designName?: string) => this.loadDeckingTemplate(designId, projectId, designName),
                getDesign: undefined,
                toDisplayDesign: (design: IDesignListItem, getDesignThumbnail?: (designId: string) => string) => this.toDisplayDesign(design, getDesignThumbnail),
                toDisplayDesignTemplate: (template: DesignTemplateEntity, getDesignThumbnail?: (templateId: string) => string) => this.getDeckingDesignTemplate(template, getDesignThumbnail),
                deleteDesign: (documentId: string, isTemplate = false) => this.deleteDesign(documentId, isTemplate),
                handleDeckingMainGuard: (designId: string) => this.handleDeckingMainGuard(designId),
                importDFDesign: (projectDesign: File, projectId: string) => this.importDFDesign(projectDesign, projectId),
                updateDeckignSettings: (projectId: string) => this.updateDeckingSettings(projectId),
                reloadUserSettings: (language: string) => this.realoadUserSettings(language)
            }
        ];
    }

    public getQuickStartApplications(): IQuickStartApplication[] {
        const isNewHomePageEnabled = this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
        const isDeckingEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_Global');

        return [
            {
                // Decking Design (not splitted)
                order: 1 * 1000 + 0,
                showInQuickStart: isDeckingEnabled,
                showInCollapsedState: true,
                collapsedTextTranslationKey: 'Agito.Hilti.Profis3.Decking.ProjectAndDesign.Main.QuickStart.DesignType',
                designType: DesignTypeId.DiaphragmDesign,
                idQuickStart: 'quick-start-button-decking',
                imageStyle: isNewHomePageEnabled ? getSpriteAsIconStyle('sprite-decking-design') : getSpriteAsIconStyle('sprite-decking'),
                actionTranslationKey: 'Agito.Hilti.Profis3.Decking.ProjectAndDesign.Main.QuickStart.DesignType',
                designTypeTranslationKey: 'Agito.Hilti.Profis3.Decking.ProjectAndDesign.Main.QuickStart.DesignType',
                newLabelTranslationKey: 'Agito.Hilti.Profis3.Decking.QuickStart.NewStatus',
                isEnabled: () => isDeckingEnabled && this.isHNA(),
                isDesignTypeDisabled: () => false,
                getButtonTooltip: () => undefined,
                createRegionDesignStandardApprovalNumber: () => this.getDeckingRegionDesignMethod(),
                showNewLabel: () => this.featureVisibilityService.isFeatureEnabled('Decking_NewLabel'),
                getNewDesignName: () => this.localizationService.getString('Agito.Hilti.Profis3.Decking.QuickStart.DesignType') + ' - ' + this.localizationService.moment(new Date()).format('ll'),
                createNewDesign: saveDesign => this.createNewQuickstartDesign(saveDesign)
            }
        ];
    }

    public getDeckingImportProvider(): IImportDesignProvider {
        return new DeckingImportProvider(this.deckingDesignImportService, this.deckingSubstitutionImportService, this.deckingDesignService, this.deckingMainService);
    }

    public async initializeSettings() {
        if (this.isDeckingEnabled) {
            await this.deckingUserSettingsService.initialize();
            this.decking3dSettingsService.initialize();
        }
    }

    private updateDeckingSettings(projectId: string) {
        this.deckingDesignSettingsService.sendDeckingSettingsSaveSignal(projectId);
    }

    private realoadUserSettings(language: string) {
        this.decking3dSettingsService.reloadUserSettings(language);
    }

    private getDeckingDesignTemplate(template: DesignTemplateEntity, getDesignThumbnail?: (templateId: string) => string): IDisplayDesign {
        if (template == null) {
            return undefined;
        }

        this.regions = this.normalizeArray(this.codeListCommon.commonCodeLists[CommonCodeList.Region] as CommonRegion[], 'id');
        const region = this.regions[template.RegionId];
        const designStandard = template.DesignStandardId;
        const regionDesignStandardApprovalNumber = this.getDeckingRegionName(template.RegionId);

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        this.designTypes = this.normalizeArray(this.modulesService.designTypes, 'id');

        const designType = (this.codeList.projectCodeLists[ProjectCodeList.DesignType] as DesignType[])
            .find(x => x.id == template.DesignTypeId);

        return {
            id: template.DesignTemplateDocumentId,
            name: template.DesignTemplateName,
            created: template.DateCreate,
            createdDisplay: () => this.localizationService.moment(template.DateCreate).format('LL'),
            // projectName: designTypeCodeListItem.getTranslatedNameText(codeListDeps) ?? '',
            projectName: designType?.getTranslatedNameText(codeListDeps) ?? '',
            productName: template.AnchorName,
            designType: template.DesignTypeId,
            approvalNumber: template.ApprovalNumber,
            region: region,
            designStandardC2C: designStandard,
            regionDesignStandardApprovalNumber,
            image: getSpriteAsIconStyle('sprite-decking'),
            thumbnail: getDesignThumbnail != undefined ? getDesignThumbnail(template.DesignTemplateDocumentId) : undefined,
            displayDesignType: DisplayDesignType.template,
            regionText: this.createRegionText(region),
            isSharedByMe: template.isSharedByMe ?? false
            // designStandardTextApprovalNumberText
        } as unknown as IDisplayDesign;
    }

    private toDisplayDesign(design: IDesignListItem, getDesignThumbnail?: (designId: string) => string): IDisplayDesign {
        if (design == null) {
            return undefined;
        }
        this.designStandards = this.normalizeArray(this.codeList.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[], 'id');
        let region: CommonRegion = undefined;
        if (design.metaData?.region != undefined) {
            this.regions = this.normalizeArray(this.codeListCommon.commonCodeLists[CommonCodeList.Region] as CommonRegion[], 'id');
            region = this.regions[design.metaData.region];
        }

        let designMethodGroup: DesignMethodGroup = undefined;
        if (design.metaData?.designMethod != undefined) {
            const designMethodGroups = this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroup[];
            designMethodGroup = designMethodGroups.find(dMeth => dMeth.id == design.metaData.designMethod);
        }

        const productName = design.metaData?.productName;
        const designType = design.metaData?.designType;
        const approvalNumber = design.metaData?.approvalNumber;
        const parentProject = this.documentService.findProjectById(design.projectId);
        const utcTime  = this.localizationService.moment(design.createDate).utc(true).toDate();
        const localTime = this.localizationService.moment(utcTime).format('MMM D, YYYY h:mm A');

        let regionDesignStandardApprovalNumber = '';
        let designStandardTextApprovalNumberText = '';
        let designStandard: DesignStandard = undefined;

        if (design.metaData?.standard != undefined) {
            designStandard = this.designStandards[design.metaData.standard];
            designStandardTextApprovalNumberText = this.createDesignStandardApprovalNumberText(designStandard, approvalNumber, design.metaData.designType);
            regionDesignStandardApprovalNumber = this.getDeckingRegionName(design.metaData.region);
        }

        return {
            id: design.id,
            name: design.designName,
            created: design.createDate,
            createdDisplay: () => this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage') ? localTime : this.localizationService.moment(design.createDate).format('LL'),
            rawProject: parentProject,
            projectId: design.projectId,
            productName,
            designType,
            approvalNumber,
            region,
            designStandard,
            designMethodGroup,
            regionDesignStandardApprovalNumber,
            image: getSpriteAsIconStyle('sprite-decking'),
            thumbnail: getDesignThumbnail != undefined ? getDesignThumbnail(design.id) : undefined,
            displayDesignType: DisplayDesignType.design,
            regionText: this.createRegionText(region),
            designStandardTextApprovalNumberText,
            integrationDocument: design.integrationDocument,
            isSharedByMe: design.isSharedByMe ?? false,
            isFavorite: design.isFavorite ?? false
        } as unknown as IDisplayDesign;
    }

    private createDesignStandardApprovalNumberText(designStandard: DesignStandard, approvalNumber: string, designTypeId?: number) {
        this.designTypes = this.normalizeArray(this.modulesService.designTypes, 'id');
        const designType = designTypeId != null ? this.designTypes[designTypeId] : null;

        const text = [
            designStandard != null ? this.localizationService.getString(this.getDesignTypeSpecificKey(designStandard.nameResourceKey, designType)) : null,
            approvalNumber
        ].filter((value) => value != null && value != '').join(', ');

        if (text == null || text == '') {
            return this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown');
        }

        return text;
    }

    private getDesignTypeSpecificKey(key: string, designType: DesignType) {
        if (designType == null) {
            return key;
        }

        const newKey = `${key}.${designType.displayKey}`;
        return this.localizationService.getKeyExists(newKey) ? newKey : key;
    }

    private getDeckingRegionName(regionId: number): string {
        switch (regionId) {
            case DeckingRegion.US:
                return 'United States';
            case DeckingRegion.CA:
                return 'Canada';
            default:
                return '';
        }
    }

    private createRegionText(region: CommonRegion) {
        if (region != null && region.id == SpecialRegion.Default) {
            region = this.getRegionById(this.commonUserSettingsService.settings.application.general.regionId.value);
        }

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        const text = region != null ? region.getTranslatedNameText(codeListDeps) : null;

        if (text == null || text == '') {
            return this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown');
        }

        return text;
    }


    public async loadDeckingTemplate(templateId: string, projId?: string, designName?: string) {
        this.deckingMainService.setTemplateId(templateId);
        const projectId = projId ?? this.documentService.draftsProject.id;
        const template = await this.designTemplate.getById(templateId);
        const designId = JSON.parse(template.ProjectDesign).designId;
        const deckingDesign = await this.deckingDesignService.createDesignTemplate(designId, false, designName, projectId);
        const newDeckingDesign = await this.deckingDesignService.createNewDesignFromTemplate(projectId, deckingDesign);
        this.deckingDesignService.launchedFromDashboard = true;
        this.routingService.navigateToUrl(URLPath.mainDecking + newDeckingDesign.id);
        return {} as ISaveDesignResult;
    }

    public async loadDeckingDesignFromTemplate(templateId: string): Promise<ISaveDesignResult> {
        const template = await this.designTemplate.getById(templateId);
        const projectDesign = JSON.parse(template.ProjectDesign);
        const deckingDesign = await this.deckingDesignService.getDeckingDesignById(projectDesign.designId);
        this.loadDeckingDesign(deckingDesign.documentId, projectDesign.designId, true, deckingDesign);

        return {} as ISaveDesignResult;
    }

    private async openDeckingTemplate(templateId: string, onDesignSettingsClosed?: () => void) {
        this.openedTemplateSettingsFromDashboard = true;
        const template = await this.designTemplate.getById(templateId);
        const projectDesign = JSON.parse(template.ProjectDesign);
        const deckingDesign = await this.deckingDesignService.getDeckingDesignById(projectDesign.designId);
        await this.deckingDesignService.loadDeckingDesign(projectDesign.designId, deckingDesign.documentId);

        const mockData: IAddEditDesignComponentInput = { addEditType: AddEditType.edit, selectedModuleDesignInfo: undefined };
        mockData.designType = DesignTypeId.DiaphragmDesign;
        mockData.unitLength = Unit.ft;
        mockData.addEditType = AddEditType.edit;

        this.regions = this.normalizeArray(this.codeListCommon.commonCodeLists[CommonCodeList.Region] as CommonRegion[], 'id');
        const region = this.regions[template.RegionId];
        mockData.design = {
            id: template.DesignTemplateDocumentId,
            name: template.DesignTemplateName,
            projectId: '',
            projectName: '',
            region: region,
            designType: DesignTypeId.DiaphragmDesign,
            displayDesignType: DisplayDesignType.template,
            unitLength: null,
            unitLengthLarge: null,
            unitArea: null,
            unitStress: null,
            unitStressSmall: null,
            unitForce: null,
            unitMoment: null,
            unitTemperature: null,
            unitForcePerLength: null,
            unitMomentPerLength: null,
            unitDensity: null,
            unitAreaPerLength: null,
            designTemplateDocumentId: template?.DesignTemplateDocumentId
        };

        this.modalService.openAddEditDesignFromModule(mockData).closed.then(async () => {
            setTimeout(() => {
                onDesignSettingsClosed();
            }, 2000);
        });
    }

    private async openDiaphragmSettings(documentId: string, designName: string, regionId: number, onDesignSettingsClosed?: () => void): Promise<void> {
        this.deckingSignalrService.init();
        this.deckingDesignVerificationService.init();

        this.deckingSubstitutionSignalRService.init();
        this.deckingSpecificationCalculationService.init();

        const modalData: IAddEditDesignComponentInput = { addEditType: AddEditType.edit, selectedModuleDesignInfo: undefined };
        modalData.designType = DesignTypeId.DiaphragmDesign;
        modalData.unitLength = Unit.ft;

        const documentDesign = this.documentService.findDesignById(documentId);
        const deckingDesignId = await this.deckingDesignService.getDeckingIdFromDocumentId(documentId);
        if (deckingDesignId) await this.loadDeckingDesign(documentDesign.id, deckingDesignId, false);

        const findRegion = documentDesign?.metaData?.region ?? regionId;
        const region = (this.codeListCommon.commonCodeLists[CommonCodeList.Region] as CommonRegion[]).find((row) => row.id == findRegion);

        modalData.design = {
            id: documentId,
            name: designName,
            projectId: documentDesign?.projectId,
            projectName: documentDesign?.projectName,
            region: region,
            designType: DesignTypeId.DiaphragmDesign,
            displayDesignType: DisplayDesignType.design,
            unitLength: null,
            unitLengthLarge: null,
            unitArea: null,
            unitStress: null,
            unitStressSmall: null,
            unitForce: null,
            unitMoment: null,
            unitTemperature: null,
            unitForcePerLength: null,
            unitMomentPerLength: null,
            unitDensity: null,
            unitAreaPerLength: null,
            isDeckingType: true
        };
        this.modalService.openAddEditDesignFromModule(modalData).result.then(() => onDesignSettingsClosed());
    }

    public async loadDeckingDesign(documentDesignId: string, deckingProjectId?: string, openDesign = true, design?: BaseDesign) {
        const baseDesign = design ?? (await this.getDeckingDesign(documentDesignId));
        if (this.isSubstitutionBuilder(baseDesign)) {
            await this.deckingSubstitutionService.loadDeckingSubstitution(baseDesign.id, documentDesignId);
            this.deckingSubstitutionService.launchedFromDashboard = true;
        } else {
            await this.deckingDesignService.loadDeckingDesign(baseDesign.id, documentDesignId);
            this.deckingDesignService.launchedFromDashboard = true;
        }
        this.deckingMainService.setSelectedModeType(baseDesign.projectType);
        if (openDesign) this.routingService.navigateToUrl(URLPath.mainDecking + baseDesign.id);

        return {} as ICalculationResult;
    }

    private async LoadAndVerifyIfProjectIsLocked(documentDesign: IDesignListItem) {
        const promiseCatch = (response: any) => {
            if (response instanceof CantOpenDesignBecauseLockedByOtherUser) {
                this.modalService.openAlertWarning(
                    this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Title'),
                    formatKeyValue(
                        this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Description'),
                        {
                            user: response.username ?? ''
                        }
                    )
                );
            }
            throw response;
        };

        await this.documentService.openDesignExclusiveDecking(documentDesign).then(async () => { await this.loadDeckingDesign(documentDesign.id); }).catch((response) => { promiseCatch(response); });

        return {} as ICalculationResult;
    }

    public async copyDeckingDesign(documentDesignId: string, designName: string, projectId: string) {
        const baseDesign = await this.getDeckingDesign(documentDesignId);
        if (this.isSubstitutionBuilder(baseDesign)) {
            await this.deckingSubstitutionService.copySubstitution(baseDesign.id, designName, projectId);
        } else {
            await this.deckingDesignService.copyDesign(baseDesign.id, designName, projectId);
        }
        return {} as IDesignListItem;
    }

    public async deleteDesign(documentId: string, isTemplate: boolean) {
        if (isTemplate) {
            await this.deckingDesignService.deleteDesign(documentId);
            return;
        }

        const baseDesign = await this.getDeckingDesign(documentId);
        if (this.isSubstitutionBuilder(baseDesign)) {
            await this.deckingSubstitutionService.deleteSubstitution(baseDesign.id);
        } else {
            await this.deckingDesignService.deleteDesign(baseDesign.id);
        }
    }

    private async handleDeckingMainGuard(designId: string) {
        if (!this.isDeckingEnabled) {
            return false;
        }

        let deckingDesign = null;
        let deckingSubstitution = null;
        // if the design is not launched from Dashboard, it means it was reloaded or opened in a new window
        if (!this.deckingDesignService.launchedFromDashboard && !this.deckingSubstitutionService.launchedFromDashboard) {
            const projectType = await this.deckingDesignService.getProjectType(designId);
            this.deckingMainService.setSelectedModeType(projectType);

            if (projectType === DeckingDesignModeType.SubstitutionBuilderMode && !this.deckingSubstitutionService.launchedFromDashboard) {
                deckingSubstitution = await this.deckingSubstitutionService.getDeckingSubstitutionById(designId);
                this.changeHomePageSelectedProject(deckingSubstitution.documentId);
                return await this.reloadSubstitution(deckingSubstitution);
            } else if (!this.deckingDesignService.launchedFromDashboard) {
                deckingDesign = await this.deckingDesignService.getDeckingDesignById(designId);
                this.changeHomePageSelectedProject(deckingDesign.documentId);
                return await this.reloadDesign(deckingDesign);
            }
        }

        // Resetting launchedFromDashboard flags.
        this.deckingDesignService.launchedFromDashboard = false;
        this.deckingSubstitutionService.launchedFromDashboard = false;
        return true;
    }

    private changeHomePageSelectedProject(documentId: string): void {
        this.userService.changeDesign(this.documentService.findProjectByDesignId(documentId));
    }

    private async reloadDesign(deckingDesign: DeckingDesign): Promise<boolean> {
        try {
            const documentDesign = await this.openDocumentBaseDesign(deckingDesign?.documentId);
            if (documentDesign == null)
                return false;

            await this.deckingDesignService.reloadDesign(deckingDesign, documentDesign);
            return true;
        } catch {
            return false;
        }
    }

    private async reloadSubstitution(deckingSubstitution: DeckingSubstitution): Promise<boolean> {
        try {
            const documentDesign = await this.openDocumentBaseDesign(deckingSubstitution?.documentId);
            if (documentDesign == null)
                return false;

            await this.deckingSubstitutionService.reloadSubstitution(deckingSubstitution, documentDesign);
            return true;
        } catch {
            return false;
        }
    }

    private async importDFDesign(projectDesign: File, projectId: string): Promise<void> {
        return await this.deckingDesignImportService.importDFDesign(projectDesign, projectId);
    }

    private async openDocumentBaseDesign(documentId: string): Promise<IDesignListItem> {
        const documentDesign = documentId ? this.documentService.findDesignById(documentId) : null;
        if (documentDesign) await this.documentService.openDesignExclusiveDecking(documentDesign);
        return documentDesign;
    }

    private async getDeckingDesign(documentId: string): Promise<BaseDesign> {
        const deckingContent = await this.documentService.getDeckingDesignContent(documentId, this.documentService.openNewSessionForDesign(documentId), '', false);
        return JSON.parse(this.browser.fromBase64(deckingContent.body.filecontent)) as BaseDesign;
    }

    private isSubstitutionBuilder(baseDesign: BaseDesign) {
        return baseDesign.projectType === DeckingDesignModeType.SubstitutionBuilderMode;
    }

    public getDeckingRegionDesignMethod() {
        const designMethod = this.deckingUserSettingsService?.deckingSettings?.designMethod?.value;
        const countryCode = this.deckingUserSettingsService?.deckingSettings?.region?.countryCode;
        const region = this.getRegionByCountryCode(countryCode);
        const translatedRegionName = region
            ? this.localizationService.getString(region.nameResourceKey)
            : this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown');

        return `${translatedRegionName}, ${designMethod}`;
    }

    private async createNewQuickstartDesign(saveDesign: ISaveDesign): Promise<ISaveDesignResult> {
        const isSubstitutionBuilderEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_SubstitutionBuilder');
        let designModeType: DeckingDesignModeType;
        if (isSubstitutionBuilderEnabled) {
            designModeType = this.deckingUserSettingsService.deckingSettings?.designMode?.value === DeckingDesignModeType.AlwaysAskMode
                ? await this.modalService.openWebComponentModal('decking-mode-selector', { size: 'md' }, {}).result
                : this.deckingUserSettingsService.deckingSettings?.designMode?.value;
        } else {
            designModeType = DeckingDesignModeType.DesignMode;
        }

        if (designModeType === null || designModeType === undefined) {
            return undefined;
        }

        this.deckingMainService.setSelectedModeType(designModeType);

        let deckingDesign = null;
        if (designModeType === DeckingDesignModeType.SubstitutionBuilderMode) {
            deckingDesign = await this.deckingSubstitutionService.createNewSubstitution(saveDesign.projectId);
            this.deckingSubstitutionService.launchedFromDashboard = true;
        } else {
            deckingDesign = await this.deckingDesignService.createNewDesign(saveDesign.projectId, saveDesign.designName);
            this.deckingDesignService.launchedFromDashboard = true;
        }

        return {
            path: DeckingUrlPath.mainDecking,
            design: undefined,
            success: true,
            designId: deckingDesign.id
        };
    }

    private getRegionById(regionId: number) {
        const regionCodeList = this.codeListCommon.commonCodeLists[CommonCodeList.Region] as Region[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private getRegionByCountryCode(countryCode: string) {
        const regionCodeList = this.codeListCommon.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(region => region.countryCode == countryCode);
    }

    public isHNA() {
        const regionId = this.commonUserSettingsService.settings.application.general.regionId.value;
        const region = this.getRegionById(regionId);
        return region?.hubId == RegionHub.W1_HNA;
    }

    private translate(key: string) {
        return this.localizationService.getString(key);
    }

    private normalizeArray<T>(array: T[], indexKey: keyof T) {
        const normalizedObject: any = {};
        array.forEach(element => {
            const key = element[indexKey];
            normalizedObject[key] = element;
        });

        return normalizedObject as { [key: string]: T };
    }
}
