import { Component, OnDestroy } from '@angular/core';
import { Tooltip, ngbTooltipTemplate } from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AreaSummaryModel } from 'src/decking/entities/decking-design/area-model';
import { DeckingTabsWrapperService } from 'src/decking/services/decking-tabs-wrapper/decking-tabs-wrapper.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';

@Component({
    template: ''
})
export class DeckingCommonAreasWrapperComponent implements OnDestroy{

    public currentAreasSummary: AreaSummaryModel[];

    public ngbTooltipTemplate = ngbTooltipTemplate;
    public areaOptAddPanelTitleTooltip: Tooltip;
    public areaOptAddPanelTitleTooltipEnabled: Tooltip;
    public areaOptAddPanelTitleTooltipDisabled: Tooltip;

    saving$: Observable<boolean>;
    public unsubscribe$ = new Subject<void>();

    constructor(
        public localization: LocalizationService,
        public deckingTabsWrapperService: DeckingTabsWrapperService
    ) { }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.deckingTabsWrapperService.allAreasCollapsed = false;
    }

    trackByFn(_: number, area: AreaSummaryModel): string {
        return area.id;
    }

    public configureTooltips(): void {
        this.areaOptAddPanelTitleTooltipEnabled = {
            title: this.localization.getString('Agito.Hilti.Profis3.Decking.Substitution.AreaManagement.AddNewArea.Tooltip.Title'),
            content: this.localization.getString('Agito.Hilti.Profis3.Decking.Substitution.AreaManagement.AddNewArea.Tooltip.Text1')
        };

        this.areaOptAddPanelTitleTooltipDisabled = {
            title: this.localization.getString('Agito.Hilti.Profis3.Decking.Substitution.AreaManagement.AddNewArea.Tooltip.Title'),
            content: this.localization.getString('Agito.Hilti.Profis3.Decking.Substitution.AreaManagement.AddNewArea.Tooltip.Text2')
        };

        this.areaOptAddPanelTitleTooltip = this.areaOptAddPanelTitleTooltipEnabled;
    }

    public setObservables(currentAreasSummary$: Observable<AreaSummaryModel[]>) {
        
        currentAreasSummary$.pipe(takeUntil(this.unsubscribe$))
          .subscribe((areas: AreaSummaryModel[]) => {
            this.currentAreasSummary = areas;
            if (this.currentAreasSummary == undefined) {
              this.currentAreasSummary = [];
            }
      
            // default area
            if (this.currentAreasSummary.length === 0) {
              this.addArea();
            }
          });
      }

    public showDeleteOption(): boolean {
        return this.deckingTabsWrapperService.showDeleteOption(this.currentAreasSummary?.length);
    }

    public addArea() {
        if (this.currentAreasSummary.length == 10) {
            this.areaOptAddPanelTitleTooltip = this.areaOptAddPanelTitleTooltipDisabled;
        }
        else {
            this.areaOptAddPanelTitleTooltip = this.areaOptAddPanelTitleTooltipEnabled;
        }
    }
}