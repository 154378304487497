<div class="main-content-left">
    <div class="main-content-left-container">
        <div class="menu-container">
            <div class="react-menu">
                <div [ngClass]="styleTab">
                    <div class="tabs-menu-scroll">
                        <div class="tooltip-wrapper" data-place="right" currentitem="false">
                            <decking-main-left-row [leftBarRow]="homeRow" (click)="navigateToHome()" [expanded]="expanded"> </decking-main-left-row>
                        </div>
                        <div *ngFor="let row of mainLeftService.leftBarRows" class="tooltip-wrapper" data-place="right" currentitem="false">
                            <decking-main-left-row [leftBarRow]="row" (click)="updateTabSelected(row.rowId)" [expanded]="expanded"> </decking-main-left-row>
                        </div>
                        <div class="tooltip-wrapper tab-expand-wrapper" data-tip="" data-place="right" currentitem="false">
                            <button class="tab-button tab-expand " type="button" id="tab-expand" (click)="onToggleExpanded()">
                                <div class="tab-button-content">
                                    <div class="tab-button-image">
                                        <div class="image">
                                            <span *ngIf="!expanded" class="sprite sprite-arrow-right-white"></span>
                                            <span *ngIf="expanded" class="sprite sprite-arrow-left-white"></span>
                                        </div>
                                    </div>
                                    <div class="tab-description"></div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div [hidden]="!designerLayoutService.leftColumnVisible" class="tabs-wrapper">
                    <div class="tabs-scroll">
                        <div class="tabs">
                            <ng-content></ng-content>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
