import { Injectable } from '@angular/core';
import { Design, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { IDetailedDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { DesignExternalMetaData, DocumentAccessMode, IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { BaseDesign } from '../../entities/decking-design/base-design';
import { Project } from '../../entities/project';
import { DeckingDocumentBaseService } from './decking-document-base.service';

@Injectable({
  providedIn: 'root'
})
export class DeckingDocumentService extends DeckingDocumentBaseService {
  baseService!: DeckingDocumentBaseService;

  public setBaseService(baseService: DeckingDocumentBaseService) {
    this.baseService = baseService;

    this.addDesign = baseService.addDesign.bind(baseService);
    this.findProjectById = baseService.findProjectById.bind(baseService);
    this.getDeckingDesignContent = baseService.getDeckingDesignContent.bind(baseService);
    this.openNewSessionForDesign = baseService.openNewSessionForDesign.bind(baseService);
    this.updateDocumentDesignContent = baseService.updateDocumentDesignContent.bind(baseService);
    this.updateDesignThumbnailImage = baseService.updateDesignThumbnailImage.bind(baseService);
    this.findDesignById = baseService.findDesignById.bind(baseService);
    this.openDesignExclusiveDecking = baseService.openDesignExclusiveDecking.bind(baseService);
    this.updateDesignWithNewContent = baseService.updateDesignWithNewContent.bind(baseService);
    this.getDocumentThumbnails = baseService.getDocumentThumbnails.bind(baseService);
    this.getSessionKeyForDesign = baseService.getSessionKeyForDesign.bind(baseService);
    this.uploadDesignImage = baseService.uploadDesignImage.bind(baseService);
    this.findProjectByDesignId = baseService.findProjectByDesignId.bind(baseService);
  }

  public get draftsProject() {
    return this.baseService.draftsProject;
  }

  public get projects(): Record<string, Project> {
    return this.baseService.projects;
  }

  public get designsFlat(): Record<string, IDesignListItem> {
    return this.baseService.designsFlat;
  }

  public createUniqueName(oldName: string, usedNames: string[]): string {
    let newName = oldName;
    let index = 0;

    while (usedNames.find((item) => item == newName) != null) {
      index++;
      newName = oldName + ' (' + index + ')';
    }

    return newName;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  public findProjectById(projectId: string): Project {
    return null;
  }

  public findDesignById(designId: string): IDesignListItem {
    return {} as IDesignListItem;
  }

  public async addDesign(projectId: string, design: Design, canGenerateUniqueName: boolean, ignoreConflict: boolean, useDeckingDesign: boolean, deckingProject: BaseDesign): Promise<IDesignListItem> {
    return {} as Promise<IDesignListItem>;
  }

  public async getDeckingDesignContent(designId: string, sessionId: string, documentId: string, isLock: boolean): Promise<any> {
    return {} as Promise<any>;
  }

  public openNewSessionForDesign(designId: string): string {
    return null;
  }

  public getSessionKeyForDesign(designId: string): string {
    return null;
  }

  public async updateDocumentDesignContent(document: IDesignListItem, base64Content: string, unlock: boolean, exclusiveLock: boolean, documentAccessMode: DocumentAccessMode): Promise<void> {
    return;
  }

  public async updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
    return {} as Promise<void>;
  }

  public getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>> {
    return {} as Promise<Record<string, string>>;
  }

  public uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void> {
    return {} as Promise<void>;
  }

  public openDesignExclusiveDecking(project: IBaseDesign): Promise<any> {
    return {} as Promise<any>;
  }

  public updateDesignWithNewContent(designId: string, projectId: string, designName: string, contentOverride: any, metadataOverride: DesignExternalMetaData, displayDesign: IDetailedDisplayDesign, unlock?: boolean, exclusiveLock?: boolean, documentAccessMode?: DocumentAccessMode): Promise<void> {
    return {} as Promise<void>;
  }

  public findProjectByDesignId(designId: string): Project {
    return { } as Project;
  }
}
