import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'decking-report-button',
  templateUrl: './decking-report-button.component.html',
  styleUrls: ['./decking-report-button.component.scss']
})
export class DeckingReportButtonComponent {
  @Input() disabled: boolean;
  @Input() isSubstitution = false;
  @Output() buttonClick = new EventEmitter();
}
