import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


import { HttpRequest } from '@angular/common/http';

import cloneDeep from 'lodash-es/cloneDeep';
import { IDecking3DSettings } from '../../entities/settings/decking-3D-settings';
import { DeckingUserSettingsService } from '../decking-user-settings/user-settings.service';
import { UserService } from '../external/user.service';
import { ApiService } from '../external/api.service';
import { LoggerService } from '../external/logger.service';
import { environment } from '../../../environments/environmentDecking';
import { IDeckingSettingsCollection, IDeckingUserSettings } from '../../entities/settings/decking-user-settings';

@Injectable({
    providedIn: 'root',
})
export class Decking3dSettingsService {
    private readonly _default3dSettings: IDecking3DSettings = {
        transparentConcreteFill: true,
        sidelapDimensions: true,
        loads: true,
        inputs: true,
        deckProperties: true
    };

    private internalSettings3d: BehaviorSubject<IDecking3DSettings>;
    public settings3d$: Observable<IDecking3DSettings>;

    constructor(
        private userSettingsService: DeckingUserSettingsService,
        protected userService: UserService,
        private apiService: ApiService,
        protected loggerService: LoggerService
    ) {
    }

    public initialize(): void {
        const userSettings = this.userSettingsService.deckingSettings.settings3d;
        this.internalSettings3d = new BehaviorSubject(userSettings || this._default3dSettings);
        this.settings3d$ = this.internalSettings3d.asObservable();
    }

    public set settings3d(settings: IDecking3DSettings) {
        this.internalSettings3d.next(settings);
    }

    public get settings3d(): IDecking3DSettings {
        return this.internalSettings3d.value;
    }

    public async setSettings3d(settings: IDecking3DSettings): Promise<void> {
        this.settings3d = settings;
        await this.updateUserSettings(settings);
    }

    public patchSettings3d(partialSettings: Partial<IDecking3DSettings>) {
        const currentSettings = this.settings3d;
        for (const key in partialSettings) {
            if (
                Object.hasOwn(partialSettings, key) &&
                Object.hasOwn(currentSettings, key)
            ) {
                currentSettings[key as keyof typeof partialSettings] = partialSettings[key as keyof typeof partialSettings];
            }
        }
        this.settings3d = currentSettings;
    }

    public async updateUserSettings(settings: IDecking3DSettings) {
        const tempSettings = cloneDeep(this.userSettingsService.deckingSettings);
        tempSettings.settings3d = settings;
        try {
            const urlToSave = `${environment.deckingUserSettingsServiceUrl}api/UserSettingCommand`;
            const postData: any = tempSettings;
            postData.userId = this.userService.authentication.userId;
            const response = await this.apiService.request<IDeckingUserSettings>(new HttpRequest('POST', urlToSave, postData));

            this.userSettingsService.deckingSettings = response.body;
            this.loggerService.logServiceResponse('user-settings-service', 'Decking3dSettings', response);
        }
        catch (e: any) {
            this.loggerService.logServiceError(e, 'user-settings-service', 'Decking3dSettings');
            console.log(e);
        }
    }

    public async reloadUserSettings(languageCode: string) {
        try {
            const urlDeckingSettingsCollection = `${environment.deckingUserSettingsServiceUrl}api/settingsquery/${languageCode ?? 'en-US'}`;
            const deckingSettingsCollectionEntity = (await this.apiService.request<IDeckingSettingsCollection>(new HttpRequest('GET', urlDeckingSettingsCollection))).body;

            this.userSettingsService.deckingSettings.definitionOfSidelapConnectors.value = deckingSettingsCollectionEntity.definitionOfSidelapConnectors.find(definition => definition.index == this.userSettingsService.deckingSettings.definitionOfSidelapConnectors.index).value;
            this.userSettingsService.deckingSettings.relevantLoads.value = deckingSettingsCollectionEntity.relevantLoads.find(definition => definition.index == this.userSettingsService.deckingSettings.relevantLoads.index).value;
            this.userSettingsService.deckingSettingsCollection = deckingSettingsCollectionEntity;
        }
        catch (e: any) {
            this.loggerService.logServiceError(e, 'user-settings-service', 'Decking3dSettings');
            console.log(e);
        }
    }
}
