import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Project } from '../entities/project';
import { DeckingDocumentService } from '../services/decking-document/decking-document.service';

export function designNameValidator(
    project: Project,
    oldProject: Project,
    oldName: string,
    documentService: DeckingDocumentService,
    errorMessage: string
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const designName = control.value ?? '';
        let valid = true;

        if (
            project == null
            || document == null
            ||
            (
                oldName != null
                && designName.toLowerCase().trim() == oldName.toLowerCase().trim()
                && project == oldProject
            )
        ) {
            return null;
        }

        const parentProject = project.parentId
            ? documentService.findProjectById(project.parentId)
            : project;

        valid = !Object.values(documentService.designsFlat).some((d) => d.projectId == parentProject.id && d.designName.toLowerCase().trim() == designName.toLowerCase().trim());
        if (valid) {
            for (const subProjectid in parentProject.subProjects) {
                if (Object.values(documentService.designsFlat).some((d) => d.projectId == subProjectid && d.designName.toLowerCase().trim() == designName.toLowerCase().trim())) {
                    valid = false;
                    break;
                }
            }
        }

        if (!valid) {
            return {
                name: errorMessage
            };
        }

        return null;
    };
}