import { Component, Input, OnInit, } from '@angular/core';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { map, Observable, distinctUntilChanged } from 'rxjs';

import { ZoneModel } from './../../../../entities/decking-design/zone-model';
import { DeckingUnitsHelperService } from './../../../../services/decking-units-helper/decking-units-helper.service';
import { DeckingDesignVerificationService } from './../../../../services/decking-design/calculation/verification/decking-design-verification.service';
import { DeckingDesignService } from './../../../../services/decking-design/decking-design.service';
import { DesignSettings } from './../../../../entities/settings/design-settings';
import { TextBoxDisplay } from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import { TextBoxAlign } from './../../../..//components/common/text-box/text-box.common';

@Component({
  selector: 'app-fastener-estimation-row',
  templateUrl: './fastener-estimation-row.component.html',
  styleUrls: ['./fastener-estimation-row.component.scss']
})
export class FastenerEstimationRowComponent implements OnInit {

  public saving$: Observable<boolean>;
  public currentLengthSetting$: Observable<Unit>;
  public shownLengthUnit$: Observable<Unit>;
  public shownAreaUnit$: Observable<Unit>;

  @Input()
  public deckingZone: ZoneModel;

  @Input()
  public index: number;

  public numericTextBoxDisplay = TextBoxDisplay.Normal;
  public numericTextBoxAlign = TextBoxAlign.End;

  constructor(
    private deckingUnitsHelperService: DeckingUnitsHelperService,
    private verificationService: DeckingDesignVerificationService,
    private deckingDesignService: DeckingDesignService
  ) {
    this.initializeStreams();
  }

  ngOnInit() {
    this.setUnits();
  }

  private initializeStreams() {
    this.currentLengthSetting$ = this.deckingDesignService.currentSettings$.pipe(map((settings: DesignSettings) => settings.length.id), distinctUntilChanged());
    this.saving$ = this.verificationService.saving$;
  }

  private setUnits() {
    this.shownLengthUnit$ = this.currentLengthSetting$.pipe(
      map((unit: Unit) => this.getLengthUnit(unit)));

    this.shownAreaUnit$ = this.currentLengthSetting$.pipe(
      map((unit: Unit) => this.getAreaUnit(unit)));
  }

  private getLengthUnit(lengthUnit: Unit): Unit {
    return this.deckingUnitsHelperService.getStandardLengthUnit(lengthUnit);
  }

  private getAreaUnit(lengthUnit: Unit): Unit {
    return this.deckingUnitsHelperService.getFastenerEstimationAreaUnit(lengthUnit);
  }

  public updateZone() {
    const currentZoneIndex = this.deckingDesignService.getZoneCurrentIndex(this.deckingZone);
    this.deckingDesignService.updateZone(this.deckingZone, currentZoneIndex, true, true);
  }
}
