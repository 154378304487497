import { Component, Input, OnInit } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import {
  TextBoxLook, TextBoxProps
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { DeckingDesign } from '../../../entities/decking-design/decking-design';
import { Project } from '../../../entities/project';
import { DeckingDesignService } from '../../../services/decking-design/decking-design.service';
import { DeckingDocumentService } from '../../../services/decking-document/decking-document.service';
import { LocalizationService } from '../../../services/external/localization.service';
import { OfflineService } from '../../../services/external/offline.service';
import { designNameValidator } from '../../../validators/design-name.validators';

export interface IDeckingRenameDesignComponentInput {
  documentId: string;
  dropProject: Project;

  onDesignRenamed: (project: Project) => void;
}

@Component({
  templateUrl: './rename-design.component.html',
  styleUrls: ['./rename-design.component.scss']
})
export class RenameDesignComponent implements OnInit {
  @Input()
  public modalInstance!: ModalInstance<IDeckingRenameDesignComponentInput>;

  public submitted: boolean;
  public designStandardId: number;
  public designMethodGroupId: number;
  public newProject: string;

  public nameTextBox: TextBoxProps = {};

  private pendingSave: boolean;
  private design: DeckingDesign;

  constructor(
    public localization: LocalizationService,
    private documentService: DeckingDocumentService,
    private offline: OfflineService,
    private deckingDesignService: DeckingDesignService
  ) {
  }

  public get formValid() {
    return this.nameTextBox?.isValid;
  }

  private get project() {
    return this.modalInstance.input?.dropProject;
  }

  private get documentId() {
    return this.modalInstance.input?.documentId;
  }

  private get onDesignRenamed() {
    return this.modalInstance.input?.onDesignRenamed;
  }

  async ngOnInit() {
    this.design = await this.deckingDesignService.getDeckingDesignById(await this.deckingDesignService.getDeckingIdFromDocumentId(this.documentId, false));
    this.modalInstance.setOnClosing(() => {
      if (this.pendingSave) {
        return false;
      }
      return true;
    });

    this.nameTextBox = {
      id: 'rename-design-name',
      look: TextBoxLook.Large,
      title: this.localization.getString('Agito.Hilti.Profis3.RenameDesign.DesignLabel.Text'),
      maxLength: 250,
      validators: [Validators.required],
      validationData: {
        showValidationErrors: true
      },
      value: this.design?.name ?? ''
    };

    if (!this.offline.isOffline) {
      this.nameTextBox.validators.push(
        designNameValidator(
          this.project, null, null, this.documentService,
          this.localization.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Message')
        )
      );
    }

    setTimeout(() => this.nameTextBoxFocus());
  }

  public async save(form: NgForm) {
    if (this.submitted || !this.formValid || (form.enabled && !form.valid) || this.pendingSave) {
      return;
    }

    this.submitted = true;
    this.pendingSave = true;

    await this.deckingDesignService.updateDesignNameProject(this.nameTextBox.value, this.project.id, this.documentService.findDesignById(this.design.documentId), this.design.id);

    this.pendingSave = false;
    this.submitted = false;

    if (this.onDesignRenamed != null) {
      this.onDesignRenamed(this.project);
    }
    this.close();
  }

  public close() {
    this.modalInstance.close();
  }

  private nameTextBoxFocus() {
    const elt = document.querySelector<HTMLElement>('.modal-body #rename-design-name-wrapper')
      ?.shadowRoot?.querySelector<HTMLElement>('#rename-design-name');

    // Focus and blur (to show validation error)
    elt?.focus();
    elt?.blur();

    // Final focus
    elt?.focus();
  }
}