import { Injectable } from '@angular/core';
import { ImportServiceInjected } from '@profis-engineering/pe-ui-common/services/import.common';

@Injectable({
  providedIn: 'root'
})
export class ImportService extends ImportServiceInjected { 
  // Used to know if import comes from HomePage or opened Design, will turn on when import is comming from main-right-options.component (Design and Substitution) and reset after finishing the import process.
  public importedFromDesign = false;
}
