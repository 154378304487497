<decking-collapsable-up [defaultExpanded]="true">
    <div class="collapsable-title" style="display: flex; align-items: center;">
        <span class="decking-sprite decking-sprite-decking-areas"></span>
        <span
            class="messages-title"
            [ngbTooltip]="this.currentArea?.name.value">
            {{areaSummaryLabel}}
        </span>
    </div>
    <ng-template appCollapsableContent>
        <div class="anchor-loads anchor-loads-table">
            <table>
                <caption style="display: none;">Decking Results</caption>
                <tbody>
                    <tr>
                        <th scope="col">{{'Agito.Hilti.Profis3.Decking.Zones.Subheader.Zone' | l10n:localization.selectedLanguage}}</th>
                        <th scope="col">{{'Agito.Hilti.Profis3.Decking.Zones.Subheader.Q' | l10n:localization.selectedLanguage}}</th>
                        <th scope="col" *ngIf="isRequiredUpliftSubmittalSetting$ | async">{{'Agito.Hilti.Profis3.Decking.Zones.Subheader.W' | l10n:localization.selectedLanguage}}</th>
                        <th scope="col" *ngIf="isRequiredShearStiffnessSetting$ | async">{{'Agito.Hilti.Profis3.Decking.Zones.Subheader.G' | l10n:localization.selectedLanguage}}</th>
                    </tr>
                    <tr *ngFor="let zone of currentArea?.zones; ;let i=index;"
                        [class.success]="zone.result.isDesignCorrect?.value"
                        [class.danger]="zone.result.isDesignCorrect != null && !zone.result.isDesignCorrect.value">
                        <td>{{i+1}}</td>
                        <td><span *ngIf="!(zonesWithCriticalScopeChecks$| async).includes(zone.id); else placeholder">{{zone.result.q.value | percent:'1.0-2'}}</span></td>
                        <td *ngIf="isRequiredUpliftSubmittalSetting$ | async">
                            <span *ngIf="!(zonesWithCriticalScopeChecks$| async).includes(zone.id); else placeholder">{{zone.result.w.value | percent:'1.0-2'}}</span></td>
                        <td *ngIf="isRequiredShearStiffnessSetting$ | async">
                            <span *ngIf="!(zonesWithCriticalScopeChecks$| async).includes(zone.id); else placeholder"><decking-unit-value-display [value]="zone.result.g.value" [unit]="shearStiffnessUnit$ | async" [appendUnit]="true"></decking-unit-value-display></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-template>
</decking-collapsable-up>

<!-- Failing Scope Check placeholder -->
<ng-template #placeholder>
    <span>-</span>
</ng-template>