import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-basic',
  templateUrl: './message-basic.component.html',
  styleUrls: ['./message-basic.component.scss']
})
export class MessageBasicComponent {

  @Input() Title: string;

  @Input() Text: string;

  @Input() MessageSprite = 'sprite-info';

  @Input() MessageStyle = '';
}
