import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { InfoPopup } from '@profis-engineering/pe-ui-common/components/control-title/control-title.common';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { RelevantLoads } from './../../../entities/decking-code-list/enums/relevant-loads';
import { LocalizationService } from './../../../services/external/localization.service';
import { combineLatest } from 'rxjs';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';

@Component({
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeckingCommonZonesHeaderComponent implements OnInit {
    @Input()
    areaName: string;

    @Input()
    zoneSpacing: boolean;

    public format = format;

    public loadsTooltipType: string;
    public loadsHeader$: Observable<string>;
    public designMethod$: Observable<string>;
    public designStandard$: Observable<string>;
    public isRequiredShearStiffnessSetting$: Observable<boolean>;
    public isRequiredUpliftSubmittalSetting$: Observable<boolean>;
    public isSubstitutionRequiredShearStiffnessSetting$: Observable<boolean>;
    public isSubstitutionRequiredUpliftSubmittalSetting$: Observable<boolean>;
    public isSubstitutionRelevantLoadAtZoneLevelSetting$: Observable<boolean>;
    public isRelevantLoadAtZoneLevelSetting$: Observable<boolean>;
    public loadsColumnsCount$: Observable<number>;
    public resultsColumnsCount$: Observable<number>;
    public relevantLoads = RelevantLoads;
    public fastenerSelectionInfoPopup: InfoPopup;
    public isRequiredShearStiffnessSelected: boolean;
    public isRequiredUpliftSubmittalSelected: boolean;
    public isSubstitutionRequiredShearStiffnessSelected: boolean;
    public isSubstitutionRequiredUpliftSubmittalSelected: boolean;
    public selectedDesignModeMode: number;
    public deckingDesignModeType = DeckingDesignModeType;

    constructor(
        public localization: LocalizationService
    ) { }

    public ngOnInit(): void {
        this.configureToolTips();
        const combined$ = combineLatest([
            this.isRequiredShearStiffnessSetting$,
            this.isRequiredUpliftSubmittalSetting$,
            this.isSubstitutionRequiredShearStiffnessSetting$,
            this.isSubstitutionRequiredUpliftSubmittalSetting$
        ]).pipe(
        map(([isRequiredShearStiffnessSetting,
            isRequiredUpliftSubmittalSetting,
            isSubstitutionRequiredShearStiffnessSetting,
            isSubstitutionRequiredUpliftSubmittalSetting]) => {
           
                if (this.selectedDesignModeMode === this.deckingDesignModeType.DesignMode) {
                    return this.getZoneColumnCount(isRequiredShearStiffnessSetting, isRequiredUpliftSubmittalSetting);
                } else {
                    return this.getZoneColumnCount(isSubstitutionRequiredShearStiffnessSetting, isSubstitutionRequiredUpliftSubmittalSetting);
                }
            })
        );

        this.loadsColumnsCount$ = this.resultsColumnsCount$ = combined$;
        this.initInfoPopUp();
    }

    private configureToolTips(): void {
        this.loadsTooltipType = '';
        let loadsHeader = '';
        this.loadsHeader$ = this.designMethod$.pipe(
            map((designMethod) => {
                switch (designMethod) {
                    case 'ASD':
                        this.loadsTooltipType = this.localization.getString(
                            'Agito.Hilti.Profis3.Decking.Substitution.Zones.DesignMethodASD.Tooltip.Text'
                        );
                        loadsHeader = this.localization.getString(
                            'Agito.Hilti.Profis3.Decking.Substitution.Zones.Header.Loads.DesignMethodASD'
                        );
                        break;
                    case 'LRFD':
                        this.loadsTooltipType = this.localization.getString(
                            'Agito.Hilti.Profis3.Decking.Substitution.Zones.DesignMethodLRFD.Tooltip.Text'
                        );
                        loadsHeader = this.localization.getString(
                            'Agito.Hilti.Profis3.Decking.Substitution.Zones.Header.Loads.DesignMethodLRFD'
                        );
                        break;
                    case 'LSD CAN':
                        this.loadsTooltipType = this.localization.getString(
                            'Agito.Hilti.Profis3.Decking.Substitution.Zones.DesignMethodLSD.Tooltip.Text'
                        );
                        loadsHeader = this.localization.getString(
                            'Agito.Hilti.Profis3.Decking.Substitution.Zones.Header.Loads.DesignMethodLSD'
                        );
                        break;
                }
                return this.localization.getString('Agito.Hilti.Profis3.Decking.Substitution.Zones.Header.Loads') +
                    ' ' +
                    loadsHeader;
            })
        );
    }

    private initInfoPopUp() {
        this.fastenerSelectionInfoPopup = {
            content: { componentName: 'decking-fastener-selection' },
            options: {
                size: 'lg'
            }
        };
    }

    private getZoneColumnCount(isRequiredShearStiffnessSetting: boolean, isRequiredUpliftSubmittalSetting: boolean): number {
        switch (true)
        {
            case (!(isRequiredShearStiffnessSetting ?? false) && !(isRequiredUpliftSubmittalSetting ?? false)):
                return 1;
            case ((isRequiredShearStiffnessSetting ?? false) && (isRequiredUpliftSubmittalSetting ?? false)):
                return 3;
            case ((isRequiredShearStiffnessSetting ?? false) || (isRequiredUpliftSubmittalSetting ?? false)):
                return 2;
            default:
                return 3;
        }
    }
}
