import { Component, Input, OnInit } from '@angular/core';
import { AreaSummaryModel } from './../../../../entities/decking-design/area-model';
import { DeckingReport } from './../../../../entities/decking-design/decking-report-info';

@Component({
    selector: 'decking-report-generation-options-area-selection',
    templateUrl: './decking-report-generation-options-area-selection.component.html',
    styleUrls: ['./decking-report-generation-options-area-selection.component.scss']
})
export class DeckingReportGenerationOptionsAreaSelectionComponent implements OnInit {
    @Input()
    areas: AreaSummaryModel[];

    @Input()
    reportInfo: DeckingReport;

    public ngOnInit(): void{
        // autoselect all areas
        this.reportInfo.selectedAreas = this.areas.map(area => area.id);
    }

    selectedArea(areaId: string) {
        return this.reportInfo.selectedAreas.some(id => id === areaId);
    }

    onAreaSelected(selected: boolean, areaId: string) {
        if (selected) {
            this.reportInfo.selectedAreas.push(areaId);
        } else {
            const indexOfArea = this.reportInfo.selectedAreas.indexOf(areaId);
            this.reportInfo.selectedAreas.splice(indexOfArea, 1);
        }
    }
}
