import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeckingUrlPath } from './../../../entities/module-constants';
import { DeckingDocumentService } from './../../../services/decking-document/decking-document.service';
import { ImportService } from './../../../services/external/import.service';
import { LocalizationService } from './../../../services/external/localization.service';
import { DeckingDesignService } from './../../../services/decking-design/decking-design.service';
import { DeckingDefaultFactoryService } from './../../../services/decking-design/factory/decking-default-factory.service';
import { ExportDesignComponent } from './../../../components/decking-popup/export-design/export-design.component';
import { BaseDeckingMainRightOptionsComponent } from './base-decking-main-right-options-component';

@Component({
  selector: 'decking-main-right-options',
  templateUrl: './decking-main-right-options.component.html',
  styleUrls: ['./decking-main-right-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeckingMainRightOptionsComponent extends BaseDeckingMainRightOptionsComponent {

  @Input() saving: boolean;
  @Input() isTemplate: boolean;

  constructor(
    private modalService: NgbModal,
    private importService: ImportService,
    private documentService: DeckingDocumentService,
    private deckingDesignService: DeckingDesignService,
    protected override localizationService: LocalizationService,
    private deckingDefaultFactoryService: DeckingDefaultFactoryService,
    protected override elementRef: ElementRef<HTMLElement>) {
      super(localizationService, elementRef);
  }

  public openExportModal() {
    const modalRef = this.modalService.open(ExportDesignComponent, { size: 'md' });
    modalRef.componentInstance.name = 'exportDesignModal';
  }

  public async duplicateDesign() {
    const designId = this.deckingDesignService.getCurrentDesign().id;
    const projectId = this.deckingDesignService.document.projectId;
    const designName = this.deckingDefaultFactoryService.createDeckingDesignName(projectId);
    const res = await this.deckingDesignService.copyDesign(designId, designName, projectId);
    const url = DeckingUrlPath.mainDecking + res.designId;
    window.open(url, '_blank');
  }
    
  protected override async import(projectDesign: File | Blob, name?: string) {
    const currentProject = Object.values(this.documentService.projects).find((proj) => proj.id == this.deckingDesignService.document.projectId);
    this.importService.importedFromDesign = true;
    try {
      await this.importService.import(currentProject, undefined, projectDesign, name, true);
    } finally {
      this.importService.importedFromDesign = false;
    }
  }
}
