<pe-control-title
    [focusControlId]="id"
    [title]="title"
    [tooltip]="tooltip"
    [infoPopup]="infoPopup"
    [infoPopupTooltip]="infoPopupTooltip">
</pe-control-title>
<div class="toggle-button-container">
    <ng-container *ngFor="let item of items">
        <ng-template #itemTooltipTemplate>
            <pe-content-tooltip [tooltip]="item.tooltip"></pe-content-tooltip>
        </ng-template>
        <button
            [id]="'toggle-btn-' + item.id"
            [ngbTooltip]="ngbTooltipTemplate(item.tooltip, itemTooltipTemplate)"
            [ngClass]="{ active: selectedValue == item.value }"
            (click)="onSelected(item)"
            type="button"
            class="button button-default">
            {{ item.text }}
        </button>
    </ng-container>
</div>