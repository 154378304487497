import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from './../../../../environments/environmentDecking';

@Injectable({
    providedIn: 'root'
})
export class DeckingReportHubConnectionFactoryService {
    public buildHubConnection(): signalR.HubConnection {
        // connection options
        const options = {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
        };

        return new signalR.HubConnectionBuilder()
            .withUrl(`${environment.deckingReportProcessorServiceSignalRUrl}deckingReportHub`, options)
            .configureLogging(signalR.LogLevel.Warning)
            .build();
    }
}
