<div class="navigation-container box-section" *ngIf="deckingEnabled">
    <pe-section
        id="appSettings-deckTab"
        [look]="collapseSectionBoxLook"
        [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Menu' | l10n:localization.selectedLanguage"
        [collapseTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n:localization.selectedLanguage"
        [collapsed]="collapsed"
        (collapsedChange)="onSectionCollapsedChange()">
        <div class="navigation-container-buttons">
            <button id="deckDesign-diaphragm" class="navigation-view-button"
                (click)="selectRowDiaphragm.emit()"
                [ngClass]="{ 'selected': rowDiaphragmSelected }" type="button">
                <div class="wrapper">
                    <span class="selector"></span>
                    <span class="text"
                        l10n="Agito.Hilti.Profis3.Decking.ApplicationSettings.Menu.DiaphragmConfiguration"></span>
                </div>
            </button>
        </div>
    </pe-section>
</div>