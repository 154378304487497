<div class="modal-content">
    <div class="modal-header" *ngIf="currentArea$ | async as currentArea">
        <h3 id="zne-templates-headerModal-panelTitle" class="modal-title">
            {{'Agito.Hilti.Profis3.Decking.Zones.ZoneTemplate.Title' | l10n:localization.selectedLanguage}} {{currentArea.name.value}}
        </h3>
        <button
            id="zone-templates-headerModal-closeButton"
            type="button"
            class="close"
            (click)="close()">
            <span>&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row mb-3">
            <div class="col-6">
                <div class="title">
                    <strong l10n="Agito.Hilti.Profis3.Decking.Zones.ZoneTemplate.SelectTemplate"></strong>
                </div>
            </div>
            <div class="col new-project-container">
                <ng-container *ngIf="!isCreatingNewTemplate; else newTemplate">
                    <div class="title" (click)="customDropdownsStyles()">                       
                        <pe-dropdown
                            [id]="'TemplateDropdown'"
                            [ngClass]="dropdownZoneTemplate"
                            [selectedValue]="selectedTemplate"                            
                            (selectedValueChange)="templateSelected($any($event).detail)"
                            [items]="templates?.items">
                        </pe-dropdown>
                    </div>
                </ng-container>
                <ng-template #newTemplate>
                    <br>
                    <div class="input-container">
                        <div class="new-template">
                            <pe-text-box
                                [value]="newTemplateName"
                                (valueChange)="setNameZoneTemplate($any($event).detail);"
                                [validators]="[requiredValidator]"
                                [maxLength]="30"
                                [preventFormSubmit]="false">
                            </pe-text-box>
                            <div class="section-row" >
                                <span *ngIf="duplicatedTemplateName;" class="text-danger decking-sprite-duplicate-design" l10n="Agito.Hilti.Profis3.Decking.Zones.Buttons.DuplicateZoneTemplateName"></span>
                            </div>
                        </div>
                        <button type="button" class="ok-button" [disabled]="!!!newTemplateName || duplicatedTemplateName" (click)="newTemplateNameSumitted()">
                            <span class="image sprite sprite-ok"></span>
                        </button>
                        <button type="button" class="cancel-button" (click)="newTemplateCancel()">
                            <span class="image sprite sprite-x"></span>
                        </button>
                    </div>
                </ng-template>
            </div>
            <div class="col">
                <div id="action_buttons" class="title">
                    <!-- Save -->
                    <app-square-icon-button
                        [attr.id]="'button-save-template'"
                        enableTooltip
                        iconSprite="decking-sprite-export-design"
                        (click)="saveZoneTemplate();"
                        height="10px"
                        [disabled]="!!!currentTemplate.name"
                        [ngbTooltip]="'Agito.Hilti.Profis3.Decking.Zones.ZoneTemplate.SaveTemplate.Tooltip.Text'
                        | l10n: localization.selectedLanguage">
                    </app-square-icon-button>
                    <!-- Copy -->
                    <app-square-icon-button
                        [attr.id]="'button-copy-template'"
                        enableTooltip
                        iconSprite="decking-sprite-duplicate-design"
                        height="10px"
                        (click)="copyZoneTemplate();"
                        [disabled]="!!!currentTemplate.name"
                        [ngbTooltip]="'Agito.Hilti.Profis3.Decking.Zones.ZoneTemplate.CopyTemplate.Tooltip.Text'
                        | l10n: localization.selectedLanguage">
                    </app-square-icon-button>
                    <!-- Delete -->
                    <app-square-icon-button
                        [attr.id]="'button-save-template'"
                        enableTooltip
                        iconSprite="decking-sprite-delete-active"
                        height="10px"
                        (click)="deleteZoneTemplate();"
                        [disabled]="!!!currentTemplate.name"
                        [ngbTooltip]="'Agito.Hilti.Profis3.Decking.Zones.ZoneTemplate.DeleteTemplate.Tooltip.Text'
                        | l10n: localization.selectedLanguage">
                    </app-square-icon-button>
                </div>
            </div>
        </div>
        <table class="table-archive" aria-label="Template edition table">
            <thead>
                <tr class="table-archive-row-header">
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.ZoneTemplate.TemplateName"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.DeckGauge"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.Pattern"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.FrameFastener"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.SidelapFastener"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.Side"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <!-- Name -->
                    <td class="input-cell" SelectTextOnClick>
                        <input [disabled]="areInputsDisabled" type="text" [(ngModel)]="$any(currentTemplate).name">
                    </td>
                    <!-- Gauge -->
                    <td class="input-cell">
                        <decking-bootstrap-dropdown
                            [id]="'deckgauge-dropdown-'"
                            [items]="deckGaugeItems$ | async"
                            [disabled]="areInputsDisabled"
                            [container]="'body'"
                            [placement]="'bottom'"
                            [(selectedValue)]=" $any(currentTemplate).deckGauge">
                        </decking-bootstrap-dropdown>
                    </td>
                    <!-- Pattern -->
                    <td class="details input-cell">
                        <decking-bootstrap-dropdown
                            [id]="'pattern-dropdown'"
                            [items]="$any(patternItems$ | async)"
                            [disabled]="areInputsDisabled"
                            [container]="'body'"
                            [placement]="'bottom'"
                            [(selectedValue)]=" $any(currentTemplate).pattern">
                        </decking-bootstrap-dropdown>
                    </td>
                    <!-- Frame Fastener -->
                    <td class="details input-cell">
                        <decking-bootstrap-dropdown
                            [id]="'framefastener-dropdown'"
                            [disabled]="areInputsDisabled"
                            [items]="frameItems$ | async"
                            [container]="'body'"
                            [placement]="'bottom'"
                            [(selectedValue)]=" $any(currentTemplate).frameFastener">
                        </decking-bootstrap-dropdown>
                    </td>
                    <!-- Sidelap Connector -->
                    <td class="details input-cell">
                        <decking-bootstrap-dropdown
                            [id]="'sidelapconnector-dropdown'"
                            [items]="$any(sidelapItems$ | async) "
                            [disabled]="areInputsDisabled"
                            [container]="'body'"
                            [placement]="'bottom'"
                            [(selectedValue)]=" $any(currentTemplate).sidelapConnector">
                        </decking-bootstrap-dropdown>
                    </td>
                    <!-- Side spacing -->
                    <td class="details input-cell">
                        <design-zone-side
                            [id]="'spacing-dropdown'"
                            [currentZone]="$any(currentTemplate)"
                            [disabled]="areInputsDisabled">
                        </design-zone-side>    
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="mt-1" *ngIf="hasChangesNotSaved" l10n="Agito.Hilti.Profis3.Decking.Zones.ZoneTemplate.ChangesNotSaved"></p>
        <div class="title">
            <strong l10n="Agito.Hilti.Profis3.Decking.Zones.ZoneTemplate.AssignTo"></strong>
        </div>
        <table *ngIf="currentArea$ | async as currentArea" class="table-archive" aria-label="Current Zones table">
            <thead>
                <tr class="table-archive-row-header">
                    <th>
                        <label class="checkbox">
                            <span class="checkbox-wrapper">
                                <input type="checkbox" class="checkbox-input"
                                    [checked]="selectedZoneIndexes.size === currentArea.zones.length"
                                    (change)="selectallZones($event, currentArea.zones)"/>
                                <span class="checkbox-button"></span>
                                <span class="checkbox-label" l10n="Agito.Hilti.Profis3.Decking.Zones.ZoneTemplate.SelectAllZones"></span>
                            </span>
                        </label>
                    </th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.DeckGauge"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.Pattern"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.FrameFastener"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.SidelapFastener"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.Side"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let zone of currentArea.zones; index as i" class="table-archive-row">
                    <td class="checkbox-padding">
                        <label class="checkbox">
                            <span class="checkbox-wrapper">
                                <input type="checkbox" class="checkbox-input"
                                    [checked]="isZoneSelected(i)"
                                    (change)="selectZone($event, i)"/>
                                <span class="checkbox-button"></span>
                                <span class="checkbox-label">{{zone.name.value}}</span>
                            </span>
                        </label>
                    </td>
                    <td>{{ (zone.deckGauge?.value ?? '-') + ' ' + DECK_GAUGE_UNIT }}</td>
                    <td>{{ zone.pattern?.value ?? '-' }}</td>
                    <td>{{ zone.frameFastener?.value ?? '-' }}</td>
                    <td>{{ zone.sidelapConnector?.value ?? '-' }}</td>
                    <td>
                        <decking-unit-value-display
                            [value]="zone.side?.value"
                            [precision]="0"
                            [unit]="sidelapSpacingUnit$ | async"
                            [appendUnit]="isBySpacing$ | async">
                        </decking-unit-value-display>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            id="zone-templates-closeButton"
            class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.Decking.Zones.ZoneTemplate.CloseModal"
            (click)="close()">
        </button>
        <button
            type="submit"
            id="zone-templates-save-button"
            class="modal-button button button-CTA"
            (click)="applyTemplateToZones()"
            [disabled]="!canBeSubmitted">
            <pe-loading-button
                [loading]="isSubmitting"
                l10n="Agito.Hilti.Profis3.Decking.Zones.ZoneTemplate.ApplyTemplate">
            </pe-loading-button>
        </button>
    </div>
</div>
