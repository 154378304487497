import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { DeckingDesignService } from './../../../../services/decking-design/decking-design.service';
import { LocalizationService } from './../../../../services/external/localization.service';
import { DeckingCommonZonesHeaderComponent } from '../../decking-zones-common/decking-common-zones-header.component';
import { RelevantLoads } from 'src/decking/entities/decking-code-list/enums/relevant-loads';
import { distinctUntilChanged, map } from 'rxjs';
import { DeckingMainService } from 'src/decking/services/decking-main/decking-main.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[decking-zones-header]',
    templateUrl: './decking-zones-header.component.html',
    styleUrls: ['./decking-zones-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeckingZonesHeaderComponent extends DeckingCommonZonesHeaderComponent implements OnInit {

    @Input()
    public areResultsExpanded: boolean;

    @Output()
    public expandResults = new EventEmitter();

    @Output()
    public collapseResults = new EventEmitter();

    constructor(
        public override localization: LocalizationService,
        public deckingDesignService: DeckingDesignService,
        public deckingMainService: DeckingMainService
    ) { 
        super(localization);
    }
    
    public override ngOnInit(): void {
        this.isRequiredShearStiffnessSetting$ = this.deckingDesignService.isRequiredShearStiffnessSetting$;
        this.isRequiredUpliftSubmittalSetting$ = this.deckingDesignService.isRequiredUpliftSubmittalSetting$;
        this.isSubstitutionRequiredShearStiffnessSetting$ = this.deckingDesignService.isSubstitutionRequiredShearStiffnessSetting$;
        this.isSubstitutionRequiredUpliftSubmittalSetting$ = this.deckingDesignService.isSubstitutionRequiredUpliftSubmittalSetting$;
        this.isRelevantLoadAtZoneLevelSetting$ = this.deckingDesignService.isRelevantLoadAtZoneLevelSetting$;
        this.designMethod$ = this.deckingDesignService.currentSettings$.pipe(
            map((settings) => {
                return settings.designMethod.value;
            }),
            distinctUntilChanged()
        );
        this.selectedDesignModeMode = this.deckingMainService.getSelectedModeType();
        super.ngOnInit();
    }

    public setZoneType(relevantLoads: RelevantLoads): void {
        this.deckingDesignService.updateZoneTypeForZones(relevantLoads);
    }

    public getFrameFastenerHeader(): string {
        return this.areResultsExpanded && this.zoneSpacing ? this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.Subheader.FrameFastener').split(' ')[1] : this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.Subheader.FrameFastener');
    }

    
}
