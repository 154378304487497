<div class="modal-content">
    <div class="modal-header">
        <h3 id="previewModal-header-panelTitle" class="modal-title" l10n="Agito.Hilti.Profis3.Decking.Report.ActionButtons.ReportPreview"></h3>

        <button id="previewModal-header-closeButton" type="button" class="close" (click)="close()">
            <span>&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <iframe #previewIframe name="previewModal-body-previewFrame" id="previewModal-body-previewFrame" title="report preview" [attr.srcDoc]="safeContent"></iframe>
    </div>

    <div class="modal-footer">
        <button id="previewModal-footer-closeButton" [disabled]="isIframeLoading" type="submit" class="modal-button button mr-1" (click)="close()">
            <pe-loading-button [loading]="isIframeLoading" l10n="Agito.Hilti.Profis3.Decking.Report.ActionButtons.Cancel"></pe-loading-button>
        </button>
        <button id="reportModal-footer-generateButton"  [disabled]="isIframeLoading" type="submit" class="modal-button button button-CTA" (click)="generate()">
            <pe-loading-button [loading]="isIframeLoading" l10n="Agito.Hilti.Profis3.Decking.Report.ActionButtons.GeneratePdf">
            </pe-loading-button>
        </button>
    </div>
</div>
