<div id="action_buttons">

    <!-- Optimize -->
    <button
        [attr.id]="'zone-row-cost-button-' + index"
        type="button"
        [disabled]="saving"
        (click)="optimize()"
    >
        <span
            class="decking-sprite"
            [class.decking-sprite-optimize-icon]="!saving"
            [class.decking-sprite-optimize-icon-inactive]="saving"
        ></span>
    </button>

    <!-- Delete -->
    <button
        [attr.id]="'zone-row-delete-button-' + index"
        type="button"
        [disabled]="!canDeleteZone "
        (click)="openConfirmDeleteModal()"
    >
        <span
            class="decking-sprite"
            [class.decking-sprite-delete-active]="canDeleteZone"
            [class.decking-sprite-delete-inactive]="!canDeleteZone"
        ></span>
    </button>
</div>
