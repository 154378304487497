import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Observable, distinctUntilChanged, map, combineLatest } from 'rxjs';
import { DeckingCommonZonesComponent } from 'src/decking/components/decking-main-bottom/decking-zones-common/decking-common-zones.component';
import { SubstitutionAreaModel } from 'src/decking/entities/decking-substitution/substitution-area';
import { SubstitutionZoneModel } from 'src/decking/entities/decking-substitution/substitution-zone';
import { DeckingCodeListService } from 'src/decking/services/decking-code-list/decking-code-list.service';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { DeckingUnitsHelperService } from 'src/decking/services/decking-units-helper/decking-units-helper.service';
import { DeckingZonesResultsService } from 'src/decking/services/decking-zones/results/decking-zones-results.service';
import { DeckingSpecificationCalculationService } from '../../../../services/decking-design/calculation/specification/decking-specification-calculation.service';
import { includeSprites } from 'src/decking/sprites';
import { DeckingMainService } from 'src/decking/services/decking-main/decking-main.service';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';

@Component({
    selector: 'substitution-zones',
    templateUrl: './decking-substitution-zones.component.html',
    styleUrls: ['./decking-substitution-zones.component.scss']
})
export class DeckingSubstitutionZonesComponent extends DeckingCommonZonesComponent implements OnInit {

    public currentSubstitutionArea$: Observable<SubstitutionAreaModel>;
    public zoneSelected$: Observable<SubstitutionZoneModel>;
    public currentSubstitutionAreas$: Observable<Array<SubstitutionAreaModel>>;
    public deckingDesignModeType = DeckingDesignModeType;

    constructor(
        public deckingSubstitutionService: DeckingSubstitutionService,
        public deckingSpecificationCalculation: DeckingSpecificationCalculationService,
        private deckingZonesResultsService: DeckingZonesResultsService,
        public override deckingCodeListService: DeckingCodeListService,
        public override deckingUnitsHelperService: DeckingUnitsHelperService,
        public override elementRef: ElementRef<HTMLElement>,
        public override cdr: ChangeDetectorRef,
        protected deckingMainService: DeckingMainService
    ) { 
        super(deckingCodeListService, deckingUnitsHelperService, elementRef, cdr);
    }

    public override ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,
            'sprite-seismic-icon',
            'sprite-wind-icon',
        );

        this.currentSubstitutionArea$ = this.deckingSubstitutionService.currentArea$;
        this.zoneSelected$ = this.deckingSubstitutionService.currentZone$;
        this.currentSettings$ = this.deckingSubstitutionService.currentSettings$;
        this.areResultsExpanded$ = this.deckingZonesResultsService.areResultsExpanded$;
        this.saving$ = this.deckingSpecificationCalculation.saving$; 
        super.ngOnInit();
        this.canDeleteZone$ = combineLatest([this.saving$, this.deckingSubstitutionService.canDeleteZone$]).pipe(
            map(([saving, canDeleteZone]) => canDeleteZone && !saving)
        );
        this.isSubstitutionRequiredShearStiffnessSetting$ = this.deckingSubstitutionService.isSubstitutionRequiredShearStiffnessSetting$;
        this.isSubstitutionRequiredUpliftSubmittalSetting$ = this.deckingSubstitutionService.isSubstitutionRequiredUpliftSubmittalSetting$;
        this.currentAreaSubscription = this.deckingSubstitutionService.currentArea$.subscribe(() => {
            this.detectChanges();
        });
        this.savingSubscription = this.saving$.subscribe(() => {
            this.detectChanges();
        });       
    }

    public override initDropDowns(): void {
        this.panelId$ = this.currentSubstitutionArea$.pipe(map(a => a.deckPanel.id), distinctUntilChanged());
        this.panelType$ = this.currentSubstitutionArea$.pipe(map(a => a.panelType.id), distinctUntilChanged());
        this.panelWidth$ = this.currentSubstitutionArea$.pipe(map(a => a.panelWidth.value), distinctUntilChanged());
        super.initDropDowns();
    }
}
