import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { DeckingCodeListService } from  './../../services/decking-code-list/decking-code-list.service';
import { ApiService } from  './../../services/external/api.service';
import { LocalizationService } from  './../../services/external/localization.service';
import { BrowserService } from  './../../services/external/browser.service';
import { DesignTemplateService } from  './../../services/external/design-template.service';
import { DeckingDocumentService } from  './../../services/decking-document/decking-document.service';

// Service in charge to keep the State of a Substitution/Design project using using RxJs BehaviorSubject
// Both Design and Subject service inherit these common properties to avoid duplicated code and share common functionalities
export class BaseProjectService {
    protected deckingDocumentId: string;
    protected isLaunchedFromDashboard = false;

    /**
     * @private Observable to watch if there are changes to undo.
     */
    public readonly canUndo$: Observable<boolean>;

    /**
     * @private Observable to watch if there are changes to redo.
     */
    public readonly canRedo$: Observable<boolean>;

    public readonly currentDocument$: Observable<IDesignListItem>;


    public areasZonesNames: Map<string, Set<string>>;

    protected _document: BehaviorSubject<IDesignListItem>;

    /**
     * @private Sets the maximum stack size of the undo and redo Stack.
     */
    protected readonly Max_UndoRedo_Stack_Size: number = 200;

    /**
     * @private Sets the suffix of the zone name in case of duplicates.
     */
    protected readonly DUPLICATE_AREA_ZONE_NAME_SUFFIX: string = '(1)';

    protected canUndo = new BehaviorSubject<boolean>(false);
    protected canRedo = new BehaviorSubject<boolean>(false);

    protected readonly maximumNumberAreas = 10;


    constructor(
        protected deckingCodeListService: DeckingCodeListService,
        protected apiService: ApiService,
        protected documentService: DeckingDocumentService,
        protected localizationService: LocalizationService,
        protected browserService: BrowserService,
        protected designTemplateService: DesignTemplateService
    ) {
        this._document = new BehaviorSubject({} as IDesignListItem);
        this.currentDocument$ = this._document.asObservable();

        this.canUndo$ = this.canUndo.asObservable();
        this.canRedo$ = this.canRedo.asObservable();
    }

    public get documentId(): string {
        return this.deckingDocumentId;
    }

    public set documentId(documentId: string) {
        this.deckingDocumentId = documentId;
    }

    public get launchedFromDashboard(): boolean {
        return this.isLaunchedFromDashboard;
    }

    public set launchedFromDashboard(launchedFromDashboard: boolean) {
        this.isLaunchedFromDashboard = launchedFromDashboard;
    }

    /**
     * return current document
     */
    public get document() {
        return this._document.value;
    }

    public async initDeckingDesignCodeList(): Promise<void> {
        await this.deckingCodeListService.init();
    }
}
