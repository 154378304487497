import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Observable, combineLatest, distinctUntilChanged, map } from 'rxjs';
import { DefinitionOfSidelapConnectors } from 'src/decking/entities/decking-code-list/enums/definition-sidelap-connectors';
import { DesignSettings } from 'src/decking/entities/settings/design-settings';

@Component({
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeckingCommonZonesSideComponent {
    @Input()
    public id: string;

    @Input()
    public title: string;

    @Input()
    public cssClass: string;

    @Input()
    public saving: boolean;

    @Input()
    public disabled: boolean;

    @Output()
    public valueChanged = new EventEmitter();


    public unit$: Observable<UnitType>;
    public isBySpacing$: Observable<boolean>;
    public maxValue$: Observable<number>;
    public minValue$: Observable<number>;
    public lengthUnit$: Observable<UnitType>;
    public currentSettings$: Observable<DesignSettings>;

    public internalValue: number;

    initCommonObservables(): void {
        
        this.isBySpacing$ = this.currentSettings$.pipe(
            map(s => s.definitionOfSidelapConnectors.id === DefinitionOfSidelapConnectors.ByConnectionSpacing),
            distinctUntilChanged()
        );

        this.maxValue$ = this.isBySpacing$.pipe(map(isBySpacing => isBySpacing ? 25400 : 50));
        this.minValue$ = this.isBySpacing$.pipe(map(isBySpacing => isBySpacing ? 25.4 : 1));

        this.lengthUnit$ = this.currentSettings$.pipe(
            map(s => s.length.id),
            distinctUntilChanged(),
        );

        this.unit$ = combineLatest([this.lengthUnit$, this.isBySpacing$]).pipe(
            map(([lengthUnit, isBySpacing]) => isBySpacing ? lengthUnit : UnitType.None)
        );
    }

}
