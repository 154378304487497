import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { combineLatest, distinctUntilChanged, map, takeUntil } from 'rxjs';
import { DeckingCommonAreaPropertiesComponent } from 'src/decking/components/decking-main-left/decking-areas-common/decking-common-area-properties.component';
import { ModalInfo } from 'src/decking/entities/decking-design/modal-info';
import { JOIST_SPACING_TYPE } from 'src/decking/entities/enums/joist-spacing-type';
import { DeckingCodeListService } from 'src/decking/services/decking-code-list/decking-code-list.service';
import { DeckingUnitsHelperService } from 'src/decking/services/decking-units-helper/decking-units-helper.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { ModalService } from 'src/decking/services/external/modal.service';
import { UnitService } from 'src/decking/services/external/unit.service';
import { SubstitutionZoneModel } from '../../../../entities/decking-substitution/substitution-zone';
import { CalculationState } from '../../../../entities/enums/calculation-state';
import { DeckingSubstitutionService } from '../../../../services/decking-design/decking-substitution.service';
import { DeckingSubstitutionZonesService } from '../../../../services/decking-zones/substitution-zones.service';
import { DeckPanelListItem } from './../../../../entities/decking-code-list/code-list/deck-panel-list-item';
import { DeckTypeListItem } from './../../../../entities/decking-code-list/code-list/deck-type-list-item';
import { DeckFill } from './../../../../entities/decking-code-list/enums/deck-fill';
import { SubstitutionAreaModel } from './../../../../entities/decking-substitution/substitution-area';
import { PanelTypeListItem } from 'src/decking/entities/decking-code-list/code-list/panel-type-list-item';

@Component({
  selector: 'substitution-area-properties',
  templateUrl: './substitution-area-properties.component.html',
  styleUrls: ['./substitution-area-properties.component.scss']
})
export class SubstitutionAreaPropertiesComponent extends DeckingCommonAreaPropertiesComponent implements OnInit, OnChanges {

  @Input()
  substitutionArea: SubstitutionAreaModel;

  constructor(
    public override localization: LocalizationService,
    public override deckingUnitsHelperService: DeckingUnitsHelperService,
    public override unitService: UnitService,
    public override modalService: ModalService,
    public override deckingCodeListService: DeckingCodeListService,
    private deckingSubstitutionService: DeckingSubstitutionService,
    private deckingZoneService: DeckingSubstitutionZonesService
  ) {
    super(localization, deckingUnitsHelperService, unitService, modalService, deckingCodeListService);
  }

  public get isDeckFillNoneSelected() {
    return this.substitutionArea.deckFill.id == DeckFill.NoFill;
  }

  public get isInsulatingConcreteSelected() {
    return this.substitutionArea.deckFill.id == DeckFill.InsulatingConcreteWithoutBoard || this.substitutionArea.deckFill.id == DeckFill.InsulatingConcreteWithBoard;
  }

  public fillThicknessMinValue(): number {
    return this.isDeckFillNoneSelected ? 0 : 50.8;
  }

  async ngOnInit(): Promise<void> {
    this.currentDesignSettings$ = this.deckingSubstitutionService.currentSettings$;
    this.initLengthUnits();
    this.initDropDownItems();
    this.initRadioButtonItems(this.substitutionArea?.zoneSpacing.value);
    this.initInfoPopUp();
  }

  ngOnChanges() {
    this.initRadioButtonItems(this.substitutionArea?.zoneSpacing.value);
  }

  private initDropDownItems(): void {
    this.initDeckFillDropdownItems();
    this.initCompressiveStrengthItems();
    this.initDeckPanelDropdownItems(this.deckingSubstitutionService.currentArea$);
    this.initPanelTypeDropdownItems(this.deckingSubstitutionService.currentArea$);
    this.initPanelWidthDropDownItems(this.deckingSubstitutionService.currentArea$);
    this.initSupportConstructionDropdownItems();
    this.initDeckTypeRadioButtons(this.substitutionArea.deckType);
  }

  public initCompressiveStrengthItems(): void {
    combineLatest([
      this.currentDesignSettings$.pipe(
        map(settings => settings.stress.id),
        distinctUntilChanged()
      ),
      this.deckingSubstitutionService.currentArea$.pipe(
        map(currentArea => currentArea.deckFill.id),
        distinctUntilChanged()
      )
    ]).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(([stressId, deckFillId]) => {
      this.populateCompressiveStrengthDropdownItems(stressId, deckFillId);
    });
  }

  public populateCompressiveStrengthDropdownItems(stressId?: Unit, deckFillId?: DeckFill): void {
    const settingsStressUnit = stressId ?? this.deckingSubstitutionService.getCurrentSubstitution().settings.stress.id;
    const compressiveStrengthUnit = this.deckingUnitsHelperService.getCompressiveStrengthUnit(settingsStressUnit);
    const deckFill = deckFillId ?? this.deckingSubstitutionService.currentArea.deckFill.id;
    this.compressiveStrengthItems = this.deckingCodeListService.GetCompressiveStrengthDropdownItems(deckFill, compressiveStrengthUnit);
  }

  public emitChanges(isCalculationDirty = true): void {
    if(!this.substitutionArea.name.value){
      this.substitutionArea.name.value = this.localization.getString('Agito.Hilti.Profis3.Decking.Areas.JoistBeamProperties.Area'+this.deckingSubstitutionService.setCurrentArea(this.index));
    }
    this.updateAreas(isCalculationDirty);
  }

  public emitChangesDeckFill(): void {
    // new default value
    this.substitutionArea.compressiveStrength = (this.deckingCodeListService.GetDefaultCompressiveStrengthDropdownItem(this.substitutionArea.deckFill.id));

    // fill thickness default value by region
    const region = this.deckingSubstitutionService.getCurrentSubstitution().settings.region;
    this.substitutionArea.fillThickness = {
      value: this.isDeckFillNoneSelected ? 0 : this.deckingCodeListService.GetDefaultConcreteFillThickness(region.index)
    };
    this.updateAreas(true);

  }

  public onDeckTypeChanged(deckTypeRadioItem: DeckTypeListItem): void {
    const previousDeckTypeValue = this.substitutionArea.deckType;
    this.substitutionArea.deckType = deckTypeRadioItem;
    // Not show the modal if all zones values are the default values
    if (this.checkZoneHasDefaultValues()) {
      this.applyDeckTypeSelected();
      return;
    }

    this.openConfirmModal(this.deckTypeChangedModalInfo,
      this.applyDeckTypeSelected.bind(this),
      this.resetDeckTypeSelected.bind(this),
      deckTypeRadioItem,
      previousDeckTypeValue
    );
  }

  public onDeckPanelSelected(deckPanelDropItem: DeckPanelListItem): void {
    // Not show the modal if don't change the DeckPanel
    if (this.substitutionArea.deckPanel.id == deckPanelDropItem.id) {
      return;
    }
    
    const previousDeckPanelValue = this.substitutionArea.deckPanel;
    this.substitutionArea.deckPanel = deckPanelDropItem;
    // Not show the modal if all zones values are the default values
    if (this.checkZoneHasDefaultValues()) {
      this.applyDeckPanelSelected(deckPanelDropItem);
      return;
    }

    this.openConfirmModal({
      id: 'confirm-edit-area-deckPanel',
      title: this.localization.getString('Agito.Hilti.Profis3.Decking.DeckPanelChangeConfirmationModal.Title'),
      message: this.localization.getString('Agito.Hilti.Profis3.Decking.DeckPanelChangeConfirmationModal.Message'),
      confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.DeckPanelChangeConfirmationModal.ConfirmButtonText'),
      cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.DeckPanelChangeConfirmationModal.CancelButtonText'),
    } as ModalInfo,
      this.applyDeckPanelSelected.bind(this),
      this.resetDeckPanelSelected.bind(this),
      deckPanelDropItem,
      previousDeckPanelValue
    );
  }

  checkZoneHasDefaultValues(): boolean {
    const currentSettings = this.deckingSubstitutionService.getCurrentSubstitution().settings;
    const zoneDefault: SubstitutionZoneModel = this.deckingZoneService.getDefaultZone(currentSettings);
    let defaultValues = true;

    this.substitutionArea.zones.forEach(zone => {
      if (zone.zoneSpecified.deckGauge != zoneDefault.zoneSpecified.deckGauge ||
        zone.zoneSpecified.pattern != zoneDefault.zoneSpecified.pattern ||
        zone.zoneSpecified.frameFastener != zoneDefault.zoneSpecified.frameFastener ||
        zone.zoneSpecified.sidelapConnector != zoneDefault.zoneSpecified.sidelapConnector ||
        zone.zoneSpecified.side != zoneDefault.zoneSpecified.side ||       
        zone.zoneSubstituted.deckGauge != zoneDefault.zoneSubstituted.deckGauge ||
        zone.zoneSubstituted.pattern != zoneDefault.zoneSubstituted.pattern ||
        zone.zoneSubstituted.frameFastener != zoneDefault.zoneSubstituted.frameFastener ||
        zone.zoneSubstituted.sidelapConnector != zoneDefault.zoneSubstituted.sidelapConnector ||
        zone.zoneSubstituted.side != zoneDefault.zoneSubstituted.side
      ) {
        defaultValues = false;
      }
    });

    return defaultValues;
  }

  applyDeckTypeSelected() {
    // new default value
    const defaltDeckPanelDropdownItem = (this.deckingCodeListService.GetDefaultDeckPanelDropdownItem(this.substitutionArea.deckType.id));
    this.substitutionArea.deckPanel = defaltDeckPanelDropdownItem;
    this.substitutionArea.fu = { value: defaltDeckPanelDropdownItem.fu };
    this.substitutionArea.fy = { value: defaltDeckPanelDropdownItem.fy };

    // resetting zones
    this.resetZones();

    // logic for an area after its deck panel changed.
    this.deckingSubstitutionService.updatePanel(this.substitutionArea);
  }

  resetDeckTypeSelected(previousDeckTypeValue: DeckTypeListItem) {
    this.substitutionArea.deckType = previousDeckTypeValue;
  }

  applyDeckPanelSelected(deckPanelDropItem: DeckPanelListItem) {
    // setting the new deckPanel value after user confirmation
    this.substitutionArea.fy = { value: deckPanelDropItem.fy };
    this.substitutionArea.fu = { value: deckPanelDropItem.fu };

    // resetting zones
    this.resetZones();

    // logic for an area after its deck panel changed.
    this.deckingSubstitutionService.updatePanel(this.substitutionArea);
  }

  resetDeckPanelSelected(previousDeckPanelValue: DeckPanelListItem) {
    this.substitutionArea.deckPanel = previousDeckPanelValue;
    const deckingDropdown = this.deckingDropdownCollection.find((component) => {
      return component.id === this.DeckPanelDropId + this.index;
    });
    deckingDropdown.selectedValue = this.substitutionArea.deckPanel;
    deckingDropdown.ngOnChanges(null);
  }

  onPanelTypeSelected(panelTypeItem: PanelTypeListItem) {
    const previouspanelTypeValue = this.substitutionArea.panelType;
    this.substitutionArea.panelType = panelTypeItem;
    // Not show the modal if all zones values are the default values
    if (this.checkZoneHasDefaultValues()) {
      this.applyPanelTypeSelected(panelTypeItem);
      return;
    }

    this.openConfirmModal({
      id: 'confirm-edit-area-paneltype',
      title: this.localization.getString('Agito.Hilti.Profis3.Decking.PanelTypeChangeConfirmationModal.Title'),
      message: this.localization.getString('Agito.Hilti.Profis3.Decking.PanelTypeChangeConfirmationModal.Message'),
      confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.PanelTypeChangeConfirmationModal.ConfirmButtonText'),
      cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Decking.PanelTypeChangeConfirmationModal.CancelButtonText'),
    } as ModalInfo,
      this.applyPanelTypeSelected.bind(this),
      this.resetPanelTypeSelected.bind(this),
      panelTypeItem,
      previouspanelTypeValue
    );
  }
  applyPanelTypeSelected(deckPanelDropItem: PanelTypeListItem) {
    // setting the new PanelType value after user confirmation
    this.substitutionArea.panelType = deckPanelDropItem;

    // resetting zones
    this.resetZones();

    // logic for an area after its panel type changed.
    this.deckingSubstitutionService.updatePanelType(this.substitutionArea);
  }

  resetPanelTypeSelected(previousDeckPanelValue: PanelTypeListItem) {
    this.substitutionArea.panelType = previousDeckPanelValue;
    const deckingDropdown = this.deckingDropdownCollection.find((component) => {
      return component.id === this.PanelTypeDropId + this.index;
    });
    deckingDropdown.selectedValue = this.substitutionArea.panelType;
    deckingDropdown.ngOnChanges(null);
  }

  onPanelWidthSelected() {
    this.deckingSubstitutionService.updatePanelWidth(this.substitutionArea);
  }

  public joistSpacingControlSelectedValueChange(selectedValue: JOIST_SPACING_TYPE) {
    this.substitutionArea.zoneSpacing = { value: selectedValue === JOIST_SPACING_TYPE.Zone };
    this.updateAreas(true);
  }

  private updateAreas(isCalculationDirty: boolean) {
    this.deckingSubstitutionService.updateCurrentArea(this.substitutionArea, isCalculationDirty);
  }

  private resetZones(): void {
    const zonesCount = this.substitutionArea.zones.length;
    const defaultZone = this.deckingZoneService.getDefaultZone(this.deckingSubstitutionService.getCurrentSubstitution().settings);
    for (let i = 0; i < zonesCount; i++) {
      this.substitutionArea.zones[i].zoneSpecified.deckGauge = defaultZone.zoneSpecified.deckGauge;
      this.substitutionArea.zones[i].zoneSpecified.frameFastener = defaultZone.zoneSpecified.frameFastener;
      this.substitutionArea.zones[i].zoneSpecified.sidelapConnector = defaultZone.zoneSpecified.sidelapConnector;
      this.substitutionArea.zones[i].zoneSpecified.pattern = defaultZone.zoneSpecified.pattern;
      this.substitutionArea.zones[i].zoneSpecified.side = defaultZone.zoneSpecified.side;
      this.substitutionArea.zones[i].zoneSpecified.g = defaultZone.zoneSpecified.g;
      this.substitutionArea.zones[i].zoneSpecified.w = defaultZone.zoneSpecified.w;
      this.substitutionArea.zones[i].zoneSpecified.q = defaultZone.zoneSpecified.q;
      this.substitutionArea.zones[i].zoneSpecified.result = defaultZone.zoneSpecified.result;
      this.substitutionArea.zones[i].zoneSpecified.calculationState = CalculationState.Empty;
      this.substitutionArea.zones[i].zoneSpecified.enableLoad = defaultZone.zoneSpecified.enableLoad;
      
      this.substitutionArea.zones[i].zoneSubstituted.deckGauge = defaultZone.zoneSubstituted.deckGauge;
      this.substitutionArea.zones[i].zoneSubstituted.frameFastener = defaultZone.zoneSubstituted.frameFastener;
      this.substitutionArea.zones[i].zoneSubstituted.sidelapConnector = defaultZone.zoneSubstituted.sidelapConnector;
      this.substitutionArea.zones[i].zoneSubstituted.pattern = defaultZone.zoneSubstituted.pattern;
      this.substitutionArea.zones[i].zoneSubstituted.side = defaultZone.zoneSubstituted.side;
      this.substitutionArea.zones[i].zoneSubstituted.g = defaultZone.zoneSubstituted.g;
      this.substitutionArea.zones[i].zoneSubstituted.w = defaultZone.zoneSubstituted.w;
      this.substitutionArea.zones[i].zoneSubstituted.q = defaultZone.zoneSubstituted.q;
      this.substitutionArea.zones[i].zoneSubstituted.result = defaultZone.zoneSubstituted.result;
      this.substitutionArea.zones[i].zoneSubstituted.calculationState = CalculationState.Empty;
      this.substitutionArea.zones[i].alternatives = [];
    }
  }
}
