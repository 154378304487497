import { ZoneResult } from './../../entities/decking-design/decking-result';

export class BaseZones {
    protected getDefaultResult(): ZoneResult {
        return {
            q: { value: 0 },
            w: { value: 0 },
            g: { value: 0 },
            qLoad: { value: 0 },
            wLoad: { value: 0 },
            qwLoad: null,
            gLoad: { value: 0 },
            cost: { value: 0 },
            diaphragmShearPercentage: { value: 0 },
            shearStiffness: { value: 0 },
            upliftPercentage: { value: 0 },
            alongPanelEdge: { value: 0 },
            alongPanelEnd: { value: 0 },
            isDesignCorrect: null,
            failedScopeChecks: [],
            numberOfEdgeSupportConnections: { value: 0 }
          };
    }
}