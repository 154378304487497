import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { Tooltip } from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import { DropdownItem, DropdownLook, DropdownTag } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { InfoPopup } from '@profis-engineering/pe-ui-common/components/control-title/control-title.common';
import { IValidationAdditionalData } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { IDeckingDocument } from './../../../entities/decking-design/decking-document';

@Component({
    selector: 'decking-dropdown',
    templateUrl: './decking-dropdown.component.html'
})
// Wrapper component of app drop down component
// This component extends the functionally of the dropdown component allowing using
// complex objects as values of the items
export class DeckingDropdownComponent<TId, TValue> implements OnChanges {
    @Input()
    public id: string;

    @Input()
    public title: string;

    @Input()
    public items: DropdownItem<IDeckingDocument<TId, TValue>>[];

    @Input()
    public disabled = false;

    @Input()
    public selectedValue: IDeckingDocument<TId, TValue>;

    @Output()
    public selectedValueChange = new EventEmitter<IDeckingDocument<TId, TValue>>();

    @Input()
    public notSelectedText: string;

    @Input()
    public tooltip: Tooltip;

    @Input()
    public infoPopup: InfoPopup;

    @Input()
    public infoPopupTooltip: Tooltip;

    @Input()
    public submitted = false;

    @Output()
    public submittedChange = new EventEmitter<boolean>();

    // Look
    @Input()
    public look = DropdownLook.Normal;

    @Input()
    public openUp = false;

    @Input()
    public tags: DropdownTag[];

    // Validation
    @Input()
    public validators: ValidatorFn[];

    @Input()
    public validationData: IValidationAdditionalData;

    @Output()
    public isValid = new EventEmitter<boolean>();

    @Input()
    public trackBy: (i: DropdownItem<IDeckingDocument<TId, TValue>>) => boolean = (i => i.value?.id === this.selectedValue?.id);

    ngOnChanges(_: SimpleChanges): void {
        this.selectedValue = this.items?.find(this.trackBy)?.value;
        if (!this.selectedValue && this.items.length === 1) {
            this.selectedValue = this.items[0].value;
        }     
    }

}
