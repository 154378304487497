<div class="modal-content" *ngIf="(currentArea$ | async); let currentArea;">
    <div class="modal-header">
        <h3 id="deflectionCalculation-headerModal-panelTitle" class="modal-title" l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Title"></h3>

        <button id="deflectionCalculation-headerModal-closeButton" type="button" class="close" (click)="close()" [disabled]="saving$ | async">
            <span>&times;</span>
        </button>
    </div>

    <div class="modal-body">

        <div class="section-row">
            <div class="section-column">
                <p class="header">
                    <span class="deflections-title" l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.AreaProperties"></span>
                </p>
            </div>
        </div>

        <div class="section-row">

            <div class="section-column">
                <label class="label" l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Length"></label>
                <decking-numeric-text-box
                    SelectTextOnClick
                    [id]="'deflectionCalculation-lengthInput'"
                    [defaultStepperValue]="1"
                    [value]="currentArea.deflectionCalculation.length.value"
                    (valueChange)="currentArea.deflectionCalculation.length.value=$any($event).detail; updateAreaDeflection(currentArea);"
                    [minValue]="304.8"
                    [maxValue]="609600"
                    [textAlign]="numericTextBoxAlign"
                    [display]="numericTextBoxDisplay"
                    [unit]="shownLengthUnit$ | async"
                    [disabled]="saving$ | async"
                    [required]="true"
                    [precision]="2">
                </decking-numeric-text-box>
            </div>

            <div class="section-column">
                <label class="label" l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Width"></label>
                <decking-numeric-text-box
                    SelectTextOnClick
                    [id]="'deflectionCalculation-widthInput'"
                    [defaultStepperValue]="1"
                    [value]="currentArea.deflectionCalculation.width.value"
                    (valueChange)="currentArea.deflectionCalculation.width.value=$any($event).detail; updateAreaDeflection(currentArea);"
                    [minValue]="304.8"
                    [maxValue]="609600"
                    [textAlign]="numericTextBoxAlign"
                    [display]="numericTextBoxDisplay"
                    [unit]="shownLengthUnit$ | async"
                    [disabled]="saving$ | async"
                    [required]="true"
                    [precision]="2">
                </decking-numeric-text-box>
            </div>
        </div>


        <div class="section-row margin-top">
            <div class="section-column">
                <p class="header">
                    <span class="deflections-title" l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Section"></span>
                </p>
            </div>
        </div>

        <div class="section-row">
            <div class="section-column">
                <label class="label" l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.AngleArea"></label>
                <label class="label"> &nbsp;{{currentArea?.name.value}}</label>
                <decking-numeric-text-box                    
                    SelectTextOnClick
                    [value]="currentArea?.deflectionCalculation.angleAreaA.value"
                    (valueChange)="currentArea?.deflectionCalculation.angleAreaA.value=$any($event).detail; updateAreaDeflection(currentArea);"
                    [attr.id]="'deflectionCalculation-AngleAreaA'"
                    [unit]="shownAreaUnit$ | async"
                    [attr.name]="'inputAngleAreaA'"
                    [required]="true"
                    [minValue]="0"
                    [textAlign]="numericTextBoxAlign"
                    [display]="numericTextBoxDisplay"
                    [disabled]="saving$ | async"
                    [showStepper]="false">
                </decking-numeric-text-box>
            </div>
            <div class="section-column">
                <label class="label" l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.MomentOfInertiaI"></label>
                <p class="result">
                    <span
                        *ngIf="currentArea?.deflectionCalculation.momentOfInertiaI; else nullBlock"
                        [innerHTML]="shownMomentOfInertiaValue$ | async">
                    </span>
                </p>
            </div>
            <div class="section-column"></div>
            <div class="section-column"></div>
        </div>

        <div class="section-row">
            <div class="section-column">
                <label class="label">W<sub><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Wind"></span></sub></label>
                <decking-numeric-text-box                    
                    SelectTextOnClick
                    [value]="currentArea?.deflectionCalculation.wWind.value"
                    (valueChange)="currentArea?.deflectionCalculation.wWind.value=$any($event).detail; updateAreaDeflection(currentArea);"
                    [unit]="shownForcePerLengthUnit$ | async"
                    [minValue]="0"
                    [textAlign]="numericTextBoxAlign"
                    [display]="numericTextBoxDisplay"
                    [attr.id]="'deflectionCalculation-WWind'"
                    [attr.name]="'inputWWind'"
                    [disabled]="saving$ | async"
                    [required]="true"                    
                    [showStepper]="false">
                </decking-numeric-text-box>
            </div>
            <div class="section-column">
                <label class="label"><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.FlangeDeflection"></span> &#8710;f<sub><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Wind"></span></sub></label>
                <p class="result">
                    <decking-unit-value-display 
                        *ngIf="currentArea?.deflectionCalculation.flangeDeflectionWind; else nullBlock" 
                        [value]="currentArea?.deflectionCalculation.flangeDeflectionWind.value"
                        [unit]="shownLengthUnit$ | async">
                    </decking-unit-value-display>
                </p>
            </div>
            <div class="section-column">
                <label class="label"><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.WebDeflection"></span> &#8710;W<sub><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Wind"></span></sub></label>
                <p class="result">
                    <decking-unit-value-display 
                        *ngIf="currentArea?.deflectionCalculation.webDeflectionWind; else nullBlock" 
                        [value]="currentArea?.deflectionCalculation.webDeflectionWind.value"
                        [unit]="shownLengthUnit$ | async">
                    </decking-unit-value-display>
                </p>
            </div>
            <div class="section-column">
                <label class="label"><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.TotalDeflection"></span> &#8710;<sub><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Wind"></span></sub></label>
                <p class="result">
                    <decking-unit-value-display 
                        *ngIf="currentArea?.deflectionCalculation.totalDeflectionWind; else nullBlock" 
                        [value]="currentArea?.deflectionCalculation.totalDeflectionWind.value"
                        [unit]="shownLengthUnit$ | async">
                    </decking-unit-value-display>
                </p>
            </div>
        </div>

        <div class="section-row">
            <div class="section-column">
                <label class="label">W<sub><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Seismic"></span></sub></label>
                <decking-numeric-text-box
                    SelectTextOnClick
                    [value]="currentArea?.deflectionCalculation.wSeismic.value"
                    (valueChange)="currentArea?.deflectionCalculation.wSeismic.value=$any($event).detail; updateAreaDeflection(currentArea);"
                    [unit]="shownForcePerLengthUnit$ | async"
                    [attr.id]="'deflectionCalculation-WSeismic'"
                    [minValue]="0"
                    [textAlign]="numericTextBoxAlign"
                    [display]="numericTextBoxDisplay"
                    [attr.name]="'inputWSeismic'"
                    [disabled]="saving$ | async"
                    [required]="true"                    
                    [showStepper]="false">
                </decking-numeric-text-box>
            </div>
            <div class="section-column">
                <label class="label"><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.FlangeDeflection"></span> &#8710;f<sub><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Seismic"></span></sub></label>
                <p class="result">
                    <decking-unit-value-display 
                        *ngIf="currentArea?.deflectionCalculation.flangeDeflectionSeismic; else nullBlock" 
                        [value]="currentArea?.deflectionCalculation.flangeDeflectionSeismic.value"
                        [unit]="shownLengthUnit$ | async">
                    </decking-unit-value-display>
                </p>
            </div>
            <div class="section-column">
                <label class="label"><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.WebDeflection"></span> &#8710;W<sub><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Seismic"></span></sub></label>
                <p class="result">
                    <decking-unit-value-display 
                        *ngIf="currentArea?.deflectionCalculation.webDeflectionSeismic; else nullBlock" 
                        [value]="currentArea?.deflectionCalculation.webDeflectionSeismic.value"
                        [unit]="shownLengthUnit$ | async">
                    </decking-unit-value-display>
                </p>
            </div>
            <div class="section-column">
                <label class="label"><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.TotalDeflection"></span> &#8710;<sub><span l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Seismic"></span></sub></label>
                <p class="result">
                    <decking-unit-value-display
                        *ngIf="currentArea?.deflectionCalculation.totalDeflectionSeismic; else nullBlock" 
                        [value]="currentArea?.deflectionCalculation.totalDeflectionSeismic.value"
                        [unit]="shownLengthUnit$ | async">
                    </decking-unit-value-display>
                </p>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" 
            id="deflectionCalculation-footerPanel-closeButton" 
            class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.Decking.DeflectionCalculation.Close"
            (click)="close()" 
            [disabled]="saving$ | async">
        </button>
    </div>
</div>

<ng-template #nullBlock>-</ng-template>
