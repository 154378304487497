import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeckingDesignSignalrService } from 'src/decking/services/decking-design-signalr/decking-design-signalr.service';
import { DeckingDesignOptimizationService } from 'src/decking/services/decking-design/calculation/optimization/decking-design-optimization.service';
import { DeckingDesignSavedService } from 'src/decking/services/decking-design/calculation/saving/decking-design-saved.service';
import { DeckingDesignVerificationService } from 'src/decking/services/decking-design/calculation/verification/decking-design-verification.service';
import { DeckingDesignService } from 'src/decking/services/decking-design/decking-design.service';

@Component({
  selector: 'decking-main',
  templateUrl: './decking-main.component.html'
})
export class DeckingMainComponent implements OnInit, OnDestroy{

    isDesign = true;

    constructor(
        private designSavedService: DeckingDesignSavedService,
        private designVerificationService: DeckingDesignVerificationService,
        private designOptimizationService: DeckingDesignOptimizationService,
        private deckingDesignService: DeckingDesignService,
        private deckingSignalrService: DeckingDesignSignalrService
    ) {
    }

    ngOnInit(): void {
        this.isDesign = !!(this.deckingDesignService.documentId);
        if(this.isDesign){
            this.deckingSignalrService.init();
            this.designVerificationService.init();
            this.designOptimizationService.init();
            this.designSavedService.init();
        }
    }

    ngOnDestroy(): void {
        if(this.isDesign){
            this.designVerificationService.dispose();
            this.designOptimizationService.dispose();
            this.designSavedService.dispose();
            this.deckingDesignService.dispose();
            this.deckingSignalrService.disconnect();
        }
    }
}
