import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, of } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitService } from 'src/decking/services/external/unit.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { DeckType } from 'src/decking/entities/decking-code-list/enums/deck-type';
import { MessageType } from 'src/decking/entities/enums/message-types';
import { CalculationState } from 'src/decking/entities/enums/calculation-state';
import { IDeckingMessage } from 'src/decking/entities/decking-design/decking-message';
import { DefinitionOfSidelapConnectors } from 'src/decking/entities/decking-code-list/enums/definition-sidelap-connectors';
import { PanelType } from 'src/decking/entities/decking-code-list/enums/panel-type';
import { DeckingUserSettingsService } from 'src/decking/services/decking-user-settings/user-settings.service';
import { includeSprites } from 'src/decking/sprites';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { SubstitutionAreaModel } from 'src/decking/entities/decking-substitution/substitution-area';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';
import { SubstitutionZoneModel } from 'src/decking/entities/decking-substitution/substitution-zone';
import { SubstitutionSettings } from 'src/decking/entities/settings/substitution-settings';
import { DeckingSubstitutionScopeChecksService } from 'src/decking/services/decking-scope-checks/decking-substitution-scope-checks.service';
import { BaseDeckingMainRightComponent } from '../../decking-main-right/base-decking-main-right-component';
import { DeckingReportGeneratorComponent } from '../../decking-popup/decking-report-generator/decking-report-generator.component';
import { DeckingSpecificationCalculationService } from '../../../services/decking-design/calculation/specification/decking-specification-calculation.service';
import { DeckingSubstitutionTrackingService } from '../../../services/decking-tracking/decking-substitution-tracking.service';

@Component({
    selector: 'substitution-main-right',
    templateUrl: './substitution-main-right.component.html',
    styleUrls: ['./substitution-main-right.component.scss']
})
export class SubstitutionMainRightComponent extends BaseDeckingMainRightComponent implements OnInit, OnDestroy {
    public currentArea$: Observable<SubstitutionAreaModel>;
    public currentDeckingDesign$: Observable<DeckingSubstitution>;

    public currentZone$: Observable<SubstitutionZoneModel>;
    public currentZoneLabel$: Observable<string>;

    private currentSettingsLengthUnit$: Observable<Unit>;
    private definitionOfSidelapConnectors$: Observable<DefinitionOfSidelapConnectors>;

    public currentDeckingDesignAreas: SubstitutionAreaModel[];
    public deckTypes = DeckType;
    public isTemplate: boolean;
    public currentSettings$: Observable<SubstitutionSettings>;
    public shearStiffnessUnit$: Observable<Unit>;

    lengthUnit: Unit;
    forcePerLengthUnit: Unit;
    shearStiffnessUnit: Unit;
    lengthStringUnit: string;

    constructor(
        protected override localization: LocalizationService,
        protected override cdr: ChangeDetectorRef,
        public elementRef: ElementRef<HTMLElement>,
        private modalService: NgbModal,
        private deckingSubstitutionService: DeckingSubstitutionService,
        private deckingSpecificationService: DeckingSpecificationCalculationService,
        private userSettingsService: DeckingUserSettingsService,
        private unitService: UnitService,
        private deckingSubstitutionScopeChecksService: DeckingSubstitutionScopeChecksService,
        private substitutionTrackingService: DeckingSubstitutionTrackingService
    ) {
        super(localization, cdr);
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,
            'sprite-info',
            'sprite-warning',
            'sprite-lines',
            'sprite-lines-expanded',
            'sprite-export-design',
            'sprite-duplicate-design',
            'sprite-openfile-d-light',
            'sprite-decking-shear',
            'sprite-decking-diaphragm-shear',
            'sprite-decking-shear-stiffness',
            'sprite-decking-tension',
            'sprite-decking-uplift',
            'sprite-decking-perimeter-spacing',
            'sprite-long-arrow-right-white',
            'sprite-decking-areas',
            'sprite-profis-file',
            'sprite-arrow-right',
            'sprite-specification-text'
        );

        this.currentArea$ = this.deckingSubstitutionService.currentArea$;
        this.currentSettingsLengthUnit$ = this.deckingSubstitutionService.currentSettings$.pipe(map(settings => settings?.length?.id),distinctUntilChanged());
        this.definitionOfSidelapConnectors$ = this.deckingSubstitutionService.currentSettings$.pipe(map(settings => settings?.definitionOfSidelapConnectors?.id),distinctUntilChanged());
        this.currentDeckingDesign$ = this.deckingSubstitutionService.currentDeckingSubstitution$;
        this.saving$ = this.deckingSpecificationService.saving$;
        this.currentZoneLabel$ = this.deckingSubstitutionService.currentZone$.pipe(map(currentZone => {
            return format(this.localization.getString('Agito.Hilti.Profis3.Decking.Results.ZoneDesign.Title'), currentZone?.name.value);
        }));
        this.currentZone$ = this.deckingSubstitutionService.currentZone$;
        this.forcePerLengthUnit = this.getForcePerLengthUnit();
        this.lengthUnit = this.getLengthUnit();
        this.lengthStringUnit = this.unitService.getUnitStrings(this.lengthUnit)[0];
        this.currentSettings$ = this.deckingSubstitutionService.currentSettings$;
        this.shearStiffnessUnit$ = this.currentSettings$.pipe(map(s => s.shearStiffness.id), distinctUntilChanged());

        //Return all area Ids
        this.areas$ = this.deckingSubstitutionService.currentAreasSummary$.pipe(map(areas => {
            return new Set(areas?.map(area => area.id));
        }), distinctUntilChanged());
        //Add areas that contains zones with Incorrect design
        this.messages$ = combineLatest([this.areas$, this.currentArea$, this.currentDeckingDesign$, this.currentSettingsLengthUnit$, this.definitionOfSidelapConnectors$, this.currentZone$])
            .pipe(map(([designAreas, currentArea, currentDeckingDesign, currentSettingsLengthUnit, definitionOfSidelapConnectors, currentZone]) => {
                //Review if any area has been deleted and removed of incorrect areas array
                this.incorrectAreas.forEach((_areaName, areaId) => {
                    if (!designAreas.has(areaId)) {
                        this.incorrectAreas.delete(areaId);
                    }
                });

                if (this.incorrectAreas.has(currentArea?.id)) {
                    this.incorrectAreas.delete(currentArea.id);
                    this.incorrectDeckTypeArea.delete(currentArea.id);
                }

                if (this.deckingSubstitutionScopeChecksService.hasRequiredInformationAreaValidation(currentArea)) {
                    this.incorrectAreas.set(currentArea.id, currentArea.name.value);
                }
                this.failingScopeCheckAreas.clear();
                this.incorrectDeckTypeArea.clear();
                currentDeckingDesign?.areas?.forEach(area => {
                    if (this.deckingSubstitutionScopeChecksService.areaHasFailingScopeChecks(area)) {
                        this.failingScopeCheckAreas.set(area.id, area.name.value);
                    }
                    if (this.hasValidDeckType(area)) {
                        this.incorrectDeckTypeArea.set(area.id, area.name.value);
                    }
                });

                const scopeCheckResponses = Array.from(this.failingScopeCheckAreas).map(([_key]) => {
                    return this.deckingSubstitutionScopeChecksService.getAreaFailingScopeChecksMessages(currentDeckingDesign.areas.find(area => area.id === _key), currentSettingsLengthUnit, definitionOfSidelapConnectors, currentZone.zoneSpecified.frameFastener ? currentZone.zoneSpecified.frameFastener.id : 0);
                });

                let scopeCheckMessages: IDeckingMessage[] = [];

                let criticalMessages = Array.from(this.incorrectAreas).map(([_key, areaName]) => {
                    return {
                        Type: MessageType.Warning,
                        Title: this.getAreasMessage(areaName),
                        Text: this.getAreasMessage(areaName),
                        ButtonText: null,
                        ButtonClick: null,
                        disabled$: of(false)
                    } as IDeckingMessage;
                });

                const deckCheckMessages = Array.from(this.incorrectDeckTypeArea).map(([_key, areaName]) => {
                    return {
                        Type: MessageType.Warning,
                        Title: this.getAreasMessage(areaName),
                        Text: this.getDeckTypeMessage(areaName),
                        ButtonText: null,
                        ButtonClick: null,
                        disabled$: of(false)
                    } as IDeckingMessage;
                });

                scopeCheckResponses.forEach(scopeChecksResponse => {
                    scopeCheckMessages = scopeCheckMessages.concat(scopeChecksResponse.scopeCheckMessages);
                    criticalMessages = criticalMessages.concat(scopeChecksResponse.criticalMessages);
                });

                const notSolutionFoundMessage = this.AddNotFoundSolutionMessage(currentArea);
                if (notSolutionFoundMessage) {
                    criticalMessages = criticalMessages.concat(notSolutionFoundMessage);
                }
                const noSpecifiedDeckGaugeMessage = this.AddNoSpecifiedDeckGaugeMessage(currentArea);
                if (noSpecifiedDeckGaugeMessage) {
                    criticalMessages = criticalMessages.concat(noSpecifiedDeckGaugeMessage);
                }

                return { criticalMessages: criticalMessages, scopeCheckMessages: scopeCheckMessages, deckCheckMessages: deckCheckMessages };
            }));

        this.disable$ = combineLatest([this.messages$.pipe(map(message => message.criticalMessages.length > 0)), this.saving$]).pipe(map(([disable, saving]) => disable || saving || this.isTemplate));

        this.savingSubscription = this.saving$.subscribe(() => {
            this.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.savingSubscription.unsubscribe();
    }

    public async generateReport() {
        this.substitutionTrackingService.addReportPreviewed();
        const activeModal: NgbModalRef = this.modalService.open(DeckingReportGeneratorComponent, {
            size: 'lg',
            backdrop: 'static',
        });
        
        activeModal.componentInstance.isSubstitution = true;
        await this.substitutionTrackingService.trackSubstitutionActivity(this.deckingSubstitutionService.getCurrentSubstitution());
    }

    private getForcePerLengthUnit(): Unit {
        return this.userSettingsService.deckingSettings.forcePerLength.unitValue;
    }

    private getLengthUnit(): Unit {
        return this.userSettingsService.deckingSettings.length.unitValue;
    }

    private AddNotFoundSolutionMessage(area: SubstitutionAreaModel): any {
        const notSolutionZones = area?.zones.filter(zone => zone.zoneSpecified?.calculationState === CalculationState.NotSolution || zone.zoneSubstituted?.calculationState === CalculationState.NotSolution || this.deckingSubstitutionScopeChecksService.compareZoneResultsValidation(zone) === -1);
        if (notSolutionZones) {
            const notFoundMessage = notSolutionZones
                .map(zone => zone.name.value)
                .join(', ');
            if (notFoundMessage) {
                return {
                    Type: MessageType.Warning,
                    Title: '',
                    Text: format(this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.NotSolutionFound'), area.name.value, notFoundMessage),
                    ButtonText: null,
                    ButtonClick: null
                };
            }
        }
    }

    private AddNoSpecifiedDeckGaugeMessage(area: SubstitutionAreaModel): any {
        const noDeckGaugeValidation = area?.zones.filter(zone => this.deckingSubstitutionScopeChecksService.hasDeckGaugeUnselectedValidation(zone));
        if (noDeckGaugeValidation) {
            const noDeckGaugeMessage = noDeckGaugeValidation
                .map(zone => zone.name.value)
                .join(', ');
            if (noDeckGaugeMessage) {
                return {
                    Type: MessageType.Warning,
                    Title: '',
                    Text: format(this.localization.getString('Agito.Hilti.Profis3.Decking.Substitution.Zones.NoSpecifiedDeckGauge'), noDeckGaugeMessage),
                    ButtonText: null,
                    ButtonClick: null
                };
            }
        }
        return null;
    }

    private hasValidDeckType(area: SubstitutionAreaModel): boolean {
        let deckTypeCheck = false;
        const deckTypeSettings = {
            velcroPLP: 11,
            pattern: [70, 76, 75, 68, 67, 81],
            fastenerENP: 1
        };
        if (area.deckPanel.id === deckTypeSettings.velcroPLP && area.panelType.id === PanelType.Interlocking) {
            deckTypeCheck = area?.zones.filter(zone => deckTypeSettings.pattern.includes(zone?.zoneSpecified?.pattern?.id) && zone?.zoneSpecified?.frameFastener?.id === deckTypeSettings.fastenerENP).length > 0;
        }
        return deckTypeCheck;
    }
}
