import { Pipe, PipeTransform } from '@angular/core';
import { ZoneModel } from './../../decking/entities/decking-design/zone-model';

@Pipe({
    name: 'filterVerifiedZones',
    pure: false
})
export class FilterVerifiedZones implements PipeTransform {
    transform(items: ZoneModel[]): any {
        if (!items) {
            return items;
        }
        // The zones that have been already verified, will have a value in isDesignCorrect.
        return items.filter(item => item.result.isDesignCorrect !== null && item.result.isDesignCorrect !== undefined);
    }
}
