import { Component, ElementRef, Input } from '@angular/core';
import { LocalizationService } from './../../../services/external/localization.service';
import { environment } from 'src/environments/environmentDecking';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { includeSprites } from 'src/decking/sprites';

@Component({
  selector: 'app-panel-type-info',
  templateUrl: './panel-type-info.component.html',
  styleUrls: ['./panel-type-info.component.scss']
})
export class PanelTypeInfoComponent{

  @Input()
  public modalInstance!: ModalInstance;

  constructor(
    public localization: LocalizationService,
    private elementRef: ElementRef<HTMLElement>
  ) { 
    includeSprites(
        this.elementRef.nativeElement,
        'sprite-nestable',
        'sprite-interlocking',
    );
  }

  public get AdditionalInformation(): string {
    return this.localization.getString('Agito.Hilti.Profis3.Decking.NestableInterlocking.AdditionalInformation').replace('{productTechnicalGuideLink}', environment.deckingProductTechnicalGuideLink);
  }

  public close(): void {
    this.modalInstance.close();
  }
}
