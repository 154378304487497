import { Injectable } from '@angular/core';
import { BaseDesign, BaseTemplate } from '../../entities/decking-design/base-design';
import { DeckingDesignModeType } from '../../entities/enums/decking-design-mode-type';

@Injectable({
    providedIn: 'root'
})
export class DeckingMainService {
    baseDesign = new BaseDesign();
    baseTemplate = new BaseTemplate();

    public getSelectedModeType(): DeckingDesignModeType {
        return this.baseDesign.projectType;
    }

    public setSelectedModeType(modeType: DeckingDesignModeType) {
        this.baseDesign.projectType = modeType;
    }

    public getTemplateId(): string {
        return this.baseTemplate.templateId;
    }

    public setTemplateId(templateId: string) {
        this.baseTemplate.templateId  = templateId;
    }
}