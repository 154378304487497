import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AreaModel } from 'src/decking/entities/decking-design/area-model';
import { DeckingDesignService } from 'src/decking/services/decking-design/decking-design.service';
import { DeckingTabsWrapperService } from 'src/decking/services/decking-tabs-wrapper/decking-tabs-wrapper.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { DeckingCommonAreaComponent } from '../decking-areas-common/decking-common-area.component';
import { includeSprites } from 'src/decking/sprites';

@Component({
  selector: 'decking-design-area',
  templateUrl: './decking-design-area.component.html',
  styleUrls: ['./decking-design-area.component.scss']
})
export class DeckingDesignAreaComponent extends DeckingCommonAreaComponent implements OnChanges {

  @Input()
  areaSelected: AreaModel;

  constructor(
    public override modalService: NgbModal, 
    public override localization: LocalizationService, 
    public override deckingTabsWrapperService: DeckingTabsWrapperService,
    private deckingDesignService: DeckingDesignService,
    private elementRef: ElementRef<HTMLElement>
  ) {
    super(modalService, localization, deckingTabsWrapperService);
    includeSprites(elementRef.nativeElement, 'sprite-dots');
  }

  ngOnChanges(_: SimpleChanges): void {
    this.isSelected = this.deckingTabsWrapperService.allAreasCollapsed ? false : this.deckingDesignService.isAreaSelected(this.index);
  }

  public toggleClicked(): void {
    this.deckingDesignService.setCurrentArea(this.index);
    this.deckingTabsWrapperService.allAreasCollapsed = false;
  }

  public override deleteArea(): void {
    this.deckingDesignService.deleteArea(this.index);
    super.deleteArea();
  }

  public duplicateArea(): void {
    this.deckingDesignService.duplicateArea(this.index);
  }

}
