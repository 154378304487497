import { Injectable } from '@angular/core';
import { DeckingSubstitution } from './../../../../entities/decking-substitution/decking-substitution';
import { SubstitutionAreaModel } from './../../../../entities/decking-substitution/substitution-area';
import { SubstitutionZoneModel } from './../../../../entities/decking-substitution/substitution-zone';
import { SubstitutionSettings } from './../../../../entities/settings/substitution-settings';

@Injectable({
    providedIn: 'root'
})
export class SubstitutionZoneInputsSettingsService {

    public checkDesignZoneInputSettings(design: DeckingSubstitution, isRelevantLoadsDifferent: boolean) {
        const settings = design.settings;
        this.checkRelevantLoadsAtZoneLevel(settings, design, isRelevantLoadsDifferent);
        this.checkRequiredShearStiffnessAndUpliftSubmittalValue(settings, design);
    }

    private checkRelevantLoadsAtZoneLevel(settings: SubstitutionSettings, design: DeckingSubstitution, isRelevantLoadsDifferent: boolean) {
        if (isRelevantLoadsDifferent) {
            const relevantLoad = settings.relevantLoads.id;
            design.areas.forEach((area: SubstitutionAreaModel) => {
                area.zones.forEach((zone: SubstitutionZoneModel) => {
                    zone.relevantLoads = { value: relevantLoad };
                });
            });
        }
    }

    private checkRequiredShearStiffnessAndUpliftSubmittalValue(settings: SubstitutionSettings, design: DeckingSubstitution) {
        if (!settings.substitutionRequiredShearStiffness.value) {
            design.areas.forEach((area: SubstitutionAreaModel) => {
                area.zones.forEach((zone: SubstitutionZoneModel) => {
                    zone.zoneSpecified.g = { value: 0 };
                    zone.zoneSubstituted.g = { value: 0 };
                });
            });
        }

        if (!settings.substitutionRequiredUpliftSubmittal.value) {
            design.areas.forEach((area: SubstitutionAreaModel) => {
                area.zones.forEach((zone: SubstitutionZoneModel) => {
                    zone.zoneSpecified.w = { value: 0 };
                    zone.zoneSubstituted.w = { value: 0 };
                });
            });
        }
    }
}
