<div id="buttons-group">
    <app-square-icon-button tooltipTranslationKey="Agito.Hilti.Profis3.Main.Undo" iconSprite="decking-sprite-undo"
        (buttonClick)="undoButtonClick.emit()" [disabled]="undoDisabled" width="40" class="z-index">
    </app-square-icon-button>

    <span>&nbsp;</span>

    <app-square-icon-button tooltipTranslationKey="Agito.Hilti.Profis3.Main.Redo" iconSprite="decking-sprite-redo"
        (buttonClick)="redoButtonClick.emit()" [disabled]="redoDisabled" width="40" class="z-index">
    </app-square-icon-button>
</div>