<div id="zones-action-buttons">
    <button
        id="zonePropsEnabled-addButton"
        type="button"
        [disabled]="currentArea?.zones.length >= maxZonesAllowed || saving"
        [class.disabled]="currentArea?.zones.length >= maxZonesAllowed || saving"
        class="button button-default"
        l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Buttons.AddZone"
        (click)="addZone()"
    ></button>
    <button
        id="zoneProps-optimizeButton"
        type="button"
        [disabled]="saving || disableOptimizeAll"
        [class.disabled]="saving"
        class="button button-default"
        (click)="substituteAll()"
    >
        <span
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Buttons.OptimizeAllZones"
        ></span>
    </button>
    <button
        id="zoneProps-alternativesButton"
        type="button"
        [disabled]="disableAlternatives$ | async"
        [class.disabled]="disableAlternatives$ | async"
        class="button button-default"
        (click)="compareAlternatives()"
    >
        <span
            l10n="Agito.Hilti.Profis3.Decking.Substitution.Zones.Buttons.CompareAlternatives"
        ></span>
    </button>
</div>
