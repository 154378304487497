<div>
    <div class="areas-management">
        <div class="areas-management-title">
            <span id="areaOpt-panelTitle" l10n="Agito.Hilti.Profis3.Decking.AreaManagement.Areas"
            [ngbTooltip]="ngbTooltipTemplate({title:'Agito.Hilti.Profis3.Decking.Substitution.AreaManagement.Areas.Tooltip.Title' | l10n:localization.selectedLanguage, content:'Agito.Hilti.Profis3.Decking.Substitution.AreaManagement.Areas.Tooltip.Text' | l10n:localization.selectedLanguage}, itemTooltipTemplateTitle)"></span>
            <ng-template #itemTooltipTemplateTitle>
                <pe-content-tooltip [tooltip]="{title:'Agito.Hilti.Profis3.Decking.Substitution.AreaManagement.Areas.Tooltip.Title' | l10n:localization.selectedLanguage, content:'Agito.Hilti.Profis3.Decking.Substitution.AreaManagement.Areas.Tooltip.Text' | l10n:localization.selectedLanguage}"></pe-content-tooltip>
            </ng-template>
        </div>
        <div class="areas-management-add">
            <button enableTooltip id="areaOpt-add" type="button" class="button-default button button-icon centered" style="border: none" (click)="addArea()"
            [class.disabled]="saving$ | async"
            [disabled]="saving$ | async"
            [ngbTooltip]="ngbTooltipTemplate(areaOptAddPanelTitleTooltip, itemTooltipTemplateButton)">
                <span class="sprite sprite-plus"></span>
            </button>
            <ng-template #itemTooltipTemplateButton>
                <pe-content-tooltip [tooltip]="areaOptAddPanelTitleTooltip"></pe-content-tooltip>
            </ng-template>
        </div>
    </div>

    <ng-container *ngFor="let substitutionArea of currentAreasSummary; trackBy: trackByFn; let i=index">
        <substitution-area [currentArea]="substitutionArea" [substitutionAreaSelected]="currentSubstitutionAreas$ | async" [saving]="saving$ | async" [index]="i" [showDeleteOption]="showDeleteOption()"></substitution-area>
    </ng-container>
</div>
