import { Component, ElementRef, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserService } from './../../../services/external/browser.service';
import { LocalizationService } from './../../../services/external/localization.service';
import { DeckingDesignService } from './../../../services/decking-design/decking-design.service';
import { DeckingTrackingService } from './../../../services/decking-tracking/decking-tracking.service';
import { SpecificationTextComponent } from './../../../components/decking-popup/specification-text/specification-text.component';
import { includeSprites } from '../.././../sprites';
import { BaseExportComponent } from './base-export-component';

@Component({
  selector: 'app-export-design',
  templateUrl: './export-design.component.html',
  styleUrls: ['./export-design.component.scss']
})
export class ExportDesignComponent extends BaseExportComponent implements OnInit {

  constructor(
    protected override activeModal: NgbActiveModal,
    protected override browser: BrowserService,
    protected override localization: LocalizationService,
    private deckingDesignService: DeckingDesignService,
    private modalService: NgbModal,
    private trackingService: DeckingTrackingService,
    public elementRef: ElementRef<HTMLElement>
  ) { 
    super(activeModal, browser, localization);
  }

  public ngOnInit(): void {
    includeSprites(this.elementRef.nativeElement,
      'sprite-profis-file',
      'sprite-arrow-right',
      'sprite-specification-text'
    );
  }

  public exportDesignToFile() {
    this.loading = true;
    const path = '/';
    const currentDesign = this.deckingDesignService.getCurrentDesign();
    this.DownloadDeckingFile(path, currentDesign);
    this.trackingService.addDesignExported();
    this.trackingService.trackDeckingActivity(this.deckingDesignService.getCurrentDesign());
  }

  public openSpecificationTextModal() {
    const modalRef = this.modalService.open(SpecificationTextComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'specificationTextModal';
    this.activeModal.close();
    this.trackingService.addSpecificationTextUsed();
    this.trackingService.trackDeckingActivity(this.deckingDesignService.getCurrentDesign());
  }
}
