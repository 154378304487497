import { Component, OnInit } from '@angular/core';
import { takeUntil,Subject} from 'rxjs';
import { DeckingDesignService } from './../../../../services/decking-design/decking-design.service';
import { LocalizationService } from './../../../../services/external/localization.service';
import { RelevantLoads } from './../../../../entities/decking-code-list/enums/relevant-loads';
import { DeckingDesign } from './../../../../entities/decking-design/decking-design';
import { ZoneModel } from './../../../../entities/decking-design/zone-model';
import { AreaModel } from './../../../../entities/decking-design/area-model';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { SubstitutionAreaModel } from 'src/decking/entities/decking-substitution/substitution-area';
import { SubstitutionZoneModel } from 'src/decking/entities/decking-substitution/substitution-zone';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';
import { DeckingMainService } from 'src/decking/services/decking-main/decking-main.service';
import { LoggerService } from 'src/decking/services/external/logger.service';
import { BaseDesign } from '../../../../entities/decking-design/base-design';

@Component({
    selector: 'decking-report-generation-options-summary',
    templateUrl: './decking-report-generation-options-summary.component.html',
    styleUrls: ['./decking-report-generation-options-summary.component.scss']
})
export class DeckingReportGenerationOptionsSummaryComponent implements OnInit {
    
    deckType:string;
    relevantLoadsType:string;
    designMethod:string;
    designStandard:string;

    public currentDesign: BaseDesign;
    private unsubscribe$ = new Subject<void>();
    public relevantLoads = RelevantLoads;

    constructor (
        private deckingDesignService: DeckingDesignService,
        public localizationService: LocalizationService,
        private deckingSubstitutionService: DeckingSubstitutionService,
        public deckingMainService: DeckingMainService,
        private loggerService: LoggerService,
     ){}

    ngOnInit(): void {
        this.initializeData();
    }

    private initializeData() {
        if(this.deckingMainService.getSelectedModeType() == DeckingDesignModeType.DesignMode) {
            this.currentDesign = this.currentDesign as DeckingDesign;
            this.deckingDesignService.currentDeckingDesign$.pipe(
                takeUntil(this.unsubscribe$)
                ).subscribe((data: DeckingDesign) => {
                    this.currentDesign = data;
                });
        } else if(this.deckingMainService.getSelectedModeType() == DeckingDesignModeType.SubstitutionBuilderMode) {
            this.currentDesign = this.currentDesign as DeckingSubstitution;
            this.deckingSubstitutionService.currentDeckingSubstitution$.pipe(
                takeUntil(this.unsubscribe$)
                ).subscribe((data: DeckingSubstitution) => {
                    this.currentDesign = data;
            });
        } else {
            console.error();
            this.loggerService.logServiceError(`Invalid Project Type : ${this.deckingMainService.baseDesign.projectType}`, 'DeckingReportGenerationOptionsSummaryComponent', 'InitializeData');
        }

        this.deckType=this.getSummaryData().summaryDeckType;
        this.relevantLoadsType=this.getSummaryData().summaryLoad;
        this.designMethod=this.getSummaryData().designMethod;
        this.designStandard=this.getSummaryData().designStandard;
    }

    private getSummaryData(): { summaryDeckType: string; summaryLoad: string; designMethod: string; designStandard: string } {
        let designMethod: any;
        let designStandard: any;
        let summaryDeckType: any;
        let summaryLoad: any;

        if(this.currentDesign.projectType == DeckingDesignModeType.DesignMode) {
            const deckingDesign = this.currentDesign as DeckingDesign;

            designMethod = deckingDesign.settings.designMethod.value;
            designStandard = deckingDesign.settings.designStandard.value;
            summaryDeckType = this.capitalizeFirstLetter([...new Set(deckingDesign.areas.map((area:AreaModel)=>this.translationText(area.deckType.value)))].join(` ${this.translationText('Agito.Hilti.Profis3.Decking.Report.Layout.And')} `));
            summaryLoad = this.capitalizeFirstLetter([...new Set(deckingDesign.areas.map((area:AreaModel)=>area.zones.map((zone:ZoneModel)=>this.getLoadName(RelevantLoads,zone.relevantLoads.value))).flat())].join(` ${this.translationText('Agito.Hilti.Profis3.Decking.Report.Layout.And')} `));
        } else if(this.currentDesign.projectType == DeckingDesignModeType.SubstitutionBuilderMode) {
            const deckingSubstitution = this.currentDesign as DeckingSubstitution;

            designMethod = deckingSubstitution.settings.designMethod.value;
            designStandard = deckingSubstitution.settings.designStandard.value;
            summaryDeckType = this.capitalizeFirstLetter([...new Set(deckingSubstitution.areas.map((area:SubstitutionAreaModel)=>this.translationText(area.deckType.value)))].join(` ${this.translationText('Agito.Hilti.Profis3.Decking.Report.Layout.And')} `));
            summaryLoad = this.capitalizeFirstLetter([...new Set(deckingSubstitution.areas.map((area:SubstitutionAreaModel)=>area.zones.map((zone:SubstitutionZoneModel)=>this.getLoadName(RelevantLoads,zone.relevantLoads.value))).flat())].join(` ${this.translationText('Agito.Hilti.Profis3.Decking.Report.Layout.And')} `));
        } else {
            this.loggerService.logServiceError(`Invalid Project Type : ${this.deckingMainService.baseDesign.projectType}`, 'DeckingReportGenerationOptionsSummaryComponent', 'GetSummaryData');
        }

        return {summaryDeckType,summaryLoad,designMethod,designStandard};
    }

    private capitalizeFirstLetter(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    private getLoadName(relevanLoads: any, value: number): string | undefined {
        const keys = Object.keys(relevanLoads).filter((key) => isNaN(Number(key)));
        for (const key of keys) {
            if (relevanLoads[key] === value) {
                return key;
            }
        }
        return undefined;
    }

    private translationText(key: string): string {
        return this.localizationService.getString(key);
    }
}
