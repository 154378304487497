<app-top-bar *ngIf="!isDeckingNewHeaderEnabled" [showSaveAsTemplateButton]="false" designTitle="{{(substitutionDesignTitle$ | async)}}"
    (settingsButtonClick)="openDiaphragmSubstitutionDesign()"
    (saveAsTemplateButtonClick)="substitutionTopBarSaveAsTemplateClick()">
    <app-top-bar-menu menu></app-top-bar-menu>
</app-top-bar>

<pe-main-header *ngIf="isDeckingNewHeaderEnabled" class="main-header"
    designName= "{{(substitutionDesignTitle$ | async)}}"
    [designRegion]="regionId"
    [beforeLogout]="beforeSubstitutionLogout"
    [openDesignSettings]="openDiaphragmSubstitutionDesign"
    [openSaveAsTemplate]="null"
    [startTour]="startTourFromSubstitution"
    [openGeneralNotes]="openGeneralNotesFromSubstitution"
    [displayLDFlags]="displayLDFlags"
    [useNewHomePage]="isNewHomePage"
    [setIsInternalLicenseSwitchAvailable] = "false"
    (menuOpened)="menuOpenedFromSubstitution()"
    (hiltiDataPrivacyUrlOpened)="hiltiDataPrivacyUrlOpenedFromSubstitution()"
    (regionLinkOpened)="regionLinkOpenedFromSubstitution()">
</pe-main-header>
