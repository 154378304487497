import { Component, Input } from '@angular/core';
import { DeckingReport } from './../../../../entities/decking-design/decking-report-info';

@Component({
    selector: 'decking-report-generation-options-details',
    templateUrl: './decking-report-generation-options-details.component.html',
    styleUrls: ['./decking-report-generation-options-details.component.scss']
})
export class DeckingReportGenerationOptionsDetailsComponent {
    @Input()
    reportInfo: DeckingReport;
}
