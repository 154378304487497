import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeckingDesign } from './../../../../entities/decking-design/decking-design';
import { DeckingDesignService } from  './../../../../services/decking-design/decking-design.service';
import { DeckingDesignSignalrService } from  './../../../../services/decking-design-signalr/decking-design-signalr.service';

@Injectable({
    providedIn: 'root'
})
export class DeckingDesignSavedService {
    private subscription: Subscription;

    constructor(
        private deckingSignalRService: DeckingDesignSignalrService,
        private deckingDesignService: DeckingDesignService
    ) { }

    init(): void {
        this.subscription = this.deckingSignalRService.designSaved$.subscribe((design: DeckingDesign) => {
            design.saved = true;
            this.deckingDesignService.setDesign(design);
        });
    }

    dispose(): void {
        this.subscription.unsubscribe();
    }
}
