export function interpolateFillThickness(value: number): number {
    const min3DUnit = 1.7;
    const max3DUnit = 3.3;
    const minFillThickness = 50.8;
    const maxFillThickness = 152.4;
    return interpolate3DUnit(value, minFillThickness, maxFillThickness, min3DUnit, max3DUnit);
}

// Interpolate the joist spacing value to a 3D unit, it uses a logarithmic function which values approximate
// to the minimum, maximum and default display value for the joist spacing
export function interpolateJoistSpacing(value: number): number {
    const logFactor = 290;
    const xStartPosition = 300;
    const yStartPosition = -2600;

    return interpolateLogarithmic3DUnit(value, logFactor, xStartPosition, yStartPosition);
}

// this function help us to interpolate a user interface value to the 3D model, this interpolation process use the equation of the line
// first need to calculate the min and max values from the value input and from the 3D model
// Axi x is gonna be the interface values and Axi y is gonna be the 3D model value, P1 (Xmin, Ymin) and P2 (Xmax, Ymax), P1}
// calculate m = (y2 - y1) / (x2-x1), Axi x min 50.8 mm max 152.4 mm Axi y min 1.7 max 3.3,
// calculate b = y intersection from this line b = y - mx;
// calculate y from the new input, for this use y = mx + b
export function interpolate3DUnit(value: number, minUnitValue: number, maxUnitValue: number, min3DUnitValue: number, max3dUnitValue: number): number {
    const gradient = (max3dUnitValue - min3DUnitValue) / (maxUnitValue - minUnitValue);
    const yIntercept = min3DUnitValue - gradient * minUnitValue;
    value = Math.max(value, minUnitValue);
    value = Math.min(value, maxUnitValue);
    return gradient * (value) + yIntercept;
}

// This function interpolates an absolute value from the UI to a unit value in the 3D Model, this uses a logarithmic function base 2 in the form of
// a log(x - b) + c to interpolate the units
export function interpolateLogarithmic3DUnit(value: number, logTermFactor: number, x: number, y: number) {
    return logTermFactor * Math.log2(value + x) + y;

}
