import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { BaseProductSelectorComponent } from 'src/decking/components/common/product-selector/base-product-selector.component';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';
import { SubstitutionAreaModel } from 'src/decking/entities/decking-substitution/substitution-area';
import { SubstitutionZoneModel } from 'src/decking/entities/decking-substitution/substitution-zone';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { includeSprites, includeSpritesForProductImages } from 'src/decking/sprites';
import { PatternListItem } from './../../../../entities/decking-code-list/code-list/pattern-list-item';
import { DeckingFieldState } from './../../../../entities/decking-design/decking-field-state';
import { FieldState } from './../../../../entities/enums/field-state';
import { DeckingCodeListService } from './../../../../services/decking-code-list/decking-code-list.service';
import { DeckingUserSettingsService } from './../../../../services/decking-user-settings/user-settings.service';
import { ProductSelectorService } from './../../../../services/decking-zones/product-selector/product-selector.service';
import { LocalizationService } from './../../../../services/external/localization.service';
import { OfflineService } from './../../../../services/external/offline.service';
import { DeckingMainService } from 'src/decking/services/decking-main/decking-main.service';
import { DeckingSubstitutionTrackingService } from '../../../../services/decking-tracking/decking-substitution-tracking.service';
@Component({
    selector: 'decking-product-selector',
    templateUrl: './product-selector.component.html',
    styleUrls: ['./product-selector.component.scss'],
})
export class ProductSelectorComponent
    extends BaseProductSelectorComponent
    implements OnInit, OnChanges
{
    @Input()
    currentArea: SubstitutionAreaModel;
    @Input()
    currentDeckingZone: SubstitutionZoneModel;
    currentDesign: DeckingSubstitution;

    constructor(
        protected override deckingCodeListService: DeckingCodeListService,
        protected override productSelectorService: ProductSelectorService,
        protected override localization: LocalizationService,
        protected override offlineService: OfflineService,
        protected override deckingMainService: DeckingMainService,
        private deckingSubstitutionService: DeckingSubstitutionService,
        private userSettingsService: DeckingUserSettingsService,
        private elementRef: ElementRef<HTMLElement>,
        private substitutionTrackingService: DeckingSubstitutionTrackingService
    ) {
        super(
            deckingCodeListService,
            productSelectorService,
            localization,
            offlineService,
            deckingMainService
        );
    }

    ngOnInit(): void {
        includeSprites(
            this.elementRef.nativeElement,
            'sprite-arrow-right-medium',
            'sprite-arrow-left-medium',
            'sprite-info',
            'sprite-warning',
            'sprite-lines',
            'sprite-lines-expanded',
            'sprite-export-design',
            'sprite-duplicate-design',
            'sprite-openfile-d-light',
            'sprite-decking-shear',
            'sprite-decking-diaphragm-shear',
            'sprite-decking-shear-stiffness',
            'sprite-decking-tension',
            'sprite-decking-uplift',
            'sprite-decking-perimeter-spacing',
            'sprite-long-arrow-right-white',
            'sprite-decking-areas',
            'sprite-profis-file',
            'sprite-arrow-right',
            'sprite-specification-text'
        );
        includeSpritesForProductImages(
            this.elementRef.nativeElement,
            'sprite-no-image-available',
            'sprite-ped-0030002',
            'sprite-ped-058291',
            'sprite-ped-090944',
            'sprite-ped-090954',
            'sprite-ped-090969',
            'sprite-ped-135507',
            'sprite-ped-eaf430',
        );

        this.currentDesign = this.deckingSubstitutionService.getCurrentSubstitution();
        this.defaultExpanded = !this.currentDesign?.isNew;
        this.expanded$ = this.productSelectorService.expanded$;
        this.deckingSubstitutionService.currentSettings$.subscribe(
            (settings) => {
                // Get the settings from Design
                this.designSettings = settings;
                // If the design settings not exists then get the settings from user settings
                if (!this.designSettings) {
                    this.designSettings =
                        this.userSettingsService.deckingSettings;
                }
            }
        );
        this.initializeComponents(
            this.currentArea.deckPanel,
            this.currentArea.panelWidth,
            this.currentArea.panelType,
            this.currentDeckingZone.zoneSubstituted.pattern
        );
        this.deckingSubstitutionService.updateToExistingSubstitution();
    }

    public valueChanged(): void {
        this.deckingSubstitutionService.updateCurrentZone(
            this.currentDeckingZone
        );
    }

    public onChangeFramePattern(pattern: PatternListItem) {
        this.patternDropdown.selectedValue = pattern;
        this.currentDeckingZone.zoneSubstituted.pattern = {
            id: pattern.id,
            index: pattern.index,
            value: this.isLengthImperial ? pattern.patternSI : pattern.pattern,
            fieldState: FieldState.Selected,
        };
        this.valueChanged();
    }

    // Assign the frame fastener selected in Product Selector to the Current Zone
    public onChangeFrameFastener(
        frameFastener: DeckingFieldState<number, string>
    ) {
        this.currentDeckingZone.zoneSubstituted.frameFastener = frameFastener;
        this.valueChanged();
    }

    // Assign the sidelap connector selected in Product Selector to the Current Zone
    public onChangeSidelapConnector(
        sidelapConnector: DeckingFieldState<number, string>
    ) {
        this.currentDeckingZone.zoneSubstituted.sidelapConnector =
            sidelapConnector;
        this.valueChanged();
    }

    public async trackAndOpenUrlForFrameFastener(url: string) {
        this.openExternalUrl(url);
        this.substitutionTrackingService.setViewFrameFastenerUsed(true);
        await this.substitutionTrackingService.trackSubstitutionActivity(this.deckingSubstitutionService.getCurrentSubstitution());
    }

    public async trackAndOpenUrlForSidelapFastener(url: string) {
        this.openExternalUrl(url);
        this.substitutionTrackingService.setViewSidelapFastenerUsed(true);
        await this.substitutionTrackingService.trackSubstitutionActivity(this.deckingSubstitutionService.getCurrentSubstitution());
    }

    ngOnChanges(_: SimpleChanges): void {
        if (this.designSettings) {
            this.initializeComponents(
                this.currentArea.deckPanel,
                this.currentArea.panelWidth,
                this.currentArea.panelType,
                this.currentDeckingZone.zoneSubstituted.pattern
            );
        }

        this.frameFastenerOnlinePage = this.getFrameFastenerOnlinePage(
            this.currentDeckingZone?.zoneSubstituted.frameFastener
        );
        this.sideLapFastenersOnlinePage = this.getSideLapFastenersOnlinePage(
            this.currentDeckingZone?.zoneSubstituted.sidelapConnector
        );
    }
}
