import { Component, Input } from '@angular/core';
import { LeftBarRow } from './../../../entities/decking-design/left-bar-row';

@Component({
    selector: 'decking-main-left-row',
    templateUrl: './decking-main-left-row.component.html',
    styleUrls: ['./decking-main-left-row.component.scss'],
})
export class LeftBarRowComponent {
    @Input() leftBarRow!: LeftBarRow;

    @Input() expanded: boolean;
}
