import { Component, OnInit, ElementRef } from '@angular/core';
import { LocalizationService } from './../../services/external/localization.service';
import { RoutingService } from './../../services/external/routing.service';
import { LeftBarRow } from './../../entities/decking-design/left-bar-row';
import { DesignerLayoutService } from './../../services/designer-layout/designer-layout.service';
import { MainLeftService } from './../../services/decking-main-left/main-left.service';
import { includeSprites } from 'src/decking/sprites';

@Component({
  selector: 'decking-main-left',
  templateUrl: './decking-main-left.component.html',
  styleUrls: ['./decking-main-left.component.scss']
})
export class DeckingMainLeftComponent implements OnInit {

  public expanded: boolean;

  public homeRow: LeftBarRow;

  public styleTabCollapsed = 'tab-menu';
  public styleTabExpanded = 'tab-menu tabs-menu-expanded';
  public styleTab = 'tab-menu';

  constructor(
    private routingService: RoutingService,
    public mainLeftService: MainLeftService,
    public designerLayoutService: DesignerLayoutService,
    private localization: LocalizationService,
    public elementRef: ElementRef<HTMLElement>,
    ) { }

  ngOnInit(): void {

    includeSprites(this.elementRef.nativeElement,'sprite-loading-logo-small','sprite-decking-areas');

    this.homeRow = {
      rowId : 'tab-home', 
      spriteImage : 'decking-sprite decking-sprite-loading-logo-small', 
      description: this.localization.getString('Agito.Hilti.Profis3.Decking.Navigation.TabHome')
    };

    this.mainLeftService.initRows();

    this.mainLeftService.leftBarRows.push(
      { 
        rowId: 'tab-areas', 
        spriteImage: 'decking-sprite decking-sprite-decking-areas', 
        description: this.localization.getString('Agito.Hilti.Profis3.Decking.Navigation.TabAreas') 
      }
    );
  }

  updateTabSelected(tabId: string): void {
    this.mainLeftService.tabSelectedChange.next(tabId);
  }

  navigateToHome(): void {
    this.routingService.navigateToUrl('/');
  }

  onToggleExpanded(): void {
    this.expanded = !this.expanded;
    this.styleTab = this.expanded ? this.styleTabExpanded : this.styleTabCollapsed;
  }
}
