import { Injectable } from '@angular/core';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { UserSettingsServiceInjected } from '@profis-engineering/pe-ui-common/services/user-settings.common';

@Injectable({
  providedIn: 'root'
})
export class CommonUserSettingsService extends UserSettingsServiceInjected<any> { 
  /* eslint-disable @typescript-eslint/no-unused-vars */
  public getRegionByCountryCode(code: string): CommonRegion {
    return {} as CommonRegion;
  }
}
