import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { SidelapsNumberSettings } from './sidelaps/sidelaps-number-settings';
import { SidelapsSpacingSettings } from './sidelaps/sidelaps-spacing-settings';
import { DeckingDesignModeType } from '../enums/decking-design-mode-type';
import { IDecking3DSettings } from './decking-3D-settings';

export interface IUserSettingDocument {
    id: string;
    index: number;
    value: any;
}

export interface IUserUnitSettingDocument extends IUserSettingDocument{
    unitValue: Unit;
}


export interface IUserSettingRegion {
    id: string;
    index: number;
    value: string;
    countryCode: string;
}

export interface IUserSettingValue<TValue> {
    value: TValue;
}

/**
 * @description Definition of a decking user settings
 */
export interface IDeckingUserSettings {
    id: string;
    eTag: string;
    region: IUserSettingRegion;
    isImperial?: IUserSettingValue<boolean>;
    length: IUserUnitSettingDocument;
    area: IUserUnitSettingDocument;
    stress: IUserUnitSettingDocument;
    moment: IUserUnitSettingDocument;
    force: IUserUnitSettingDocument;
    forcePerLength: IUserUnitSettingDocument;
    momentPerLength: IUserUnitSettingDocument;
    designMethod: IUserSettingDocument;
    designStandard: IUserSettingDocument;
    relevantLoads: IUserSettingDocument;
    definitionOfSidelapConnectors: IUserSettingDocument;
    sidelapsSpacing: SidelapsSpacingSettings;
    sidelapsNumber: SidelapsNumberSettings;
    requiredShearStiffness: IUserSettingValue<boolean>;
    requiredUpliftSubmittal: IUserSettingValue<boolean>;
    substitutionRequiredShearStiffness: IUserSettingValue<boolean>;
    substitutionRequiredUpliftSubmittal: IUserSettingValue<boolean>;
    substitutionWindAndSeismicLoadsAtZoneLevel: IUserSettingValue<boolean>;
    calculateIntegration: IUserSettingValue<boolean>;
    windAndSeismicLoadsAtZoneLevel: IUserSettingValue<boolean>;
    shearStiffness: IUserUnitSettingDocument;
    settings3d?: IDecking3DSettings;
    designMode?: IUserSettingValue<DeckingDesignModeType>;
    wasteFactor?: IUserSettingValue<number>;
    isDirty?: boolean;
}

/**
 * @description Definition of all dropdown options for the decking user settings
 */
export interface IDeckingSettingsCollection {
    region: IUserSettingRegion[] | null;
    length: IUserUnitSettingDocument[] | null;
    area: IUserUnitSettingDocument[] | null;
    stress: IUserUnitSettingDocument[] | null;
    force: IUserUnitSettingDocument[] | null;
    moment: IUserUnitSettingDocument[] | null;
    forcePerLength: IUserUnitSettingDocument[] | null;
    momentPerLength: IUserUnitSettingDocument[] | null;
    designMethod: IUserSettingDocument[] | null;
    designStandard: IUserSettingDocument[] | null;
    definitionOfSidelapConnectors: IUserSettingDocument[] | null;
    sidelapConnector: IUserSettingDocument[] | null;
    sidelapConnectorSpacingIncrement: IUserSettingDocument[] | null;
    relevantLoads: IUserSettingDocument[] | null;
    regionDefault: IDeckingUserSettings[] | null;
    shearStiffness: IUserUnitSettingDocument[] | null;
}

/**
 * Class storing all the decking user settings
 */
export class DeckingUserSettings implements IDeckingUserSettings {
    id: string;
    eTag: string;
    region: IUserSettingRegion;
    length: IUserUnitSettingDocument;
    area: IUserUnitSettingDocument;
    stress: IUserUnitSettingDocument;
    moment: IUserUnitSettingDocument;
    force: IUserUnitSettingDocument;
    forcePerLength: IUserUnitSettingDocument;
    momentPerLength: IUserUnitSettingDocument;
    designMethod: IUserSettingDocument;
    designStandard: IUserSettingDocument;
    relevantLoads: IUserSettingDocument;
    definitionOfSidelapConnectors: IUserSettingDocument;
    sidelapsSpacing: SidelapsSpacingSettings;
    sidelapsNumber: SidelapsNumberSettings;
    requiredShearStiffness: IUserSettingValue<boolean>;
    requiredUpliftSubmittal: IUserSettingValue<boolean>;
    substitutionRequiredShearStiffness: IUserSettingValue<boolean>;
    substitutionRequiredUpliftSubmittal: IUserSettingValue<boolean>;
    substitutionWindAndSeismicLoadsAtZoneLevel: IUserSettingValue<boolean>;
    calculateIntegration: IUserSettingValue<boolean>;
    windAndSeismicLoadsAtZoneLevel: IUserSettingValue<boolean>;
    shearStiffness: IUserUnitSettingDocument;
    settings3d?: IDecking3DSettings;
    designMode?: IUserSettingValue<DeckingDesignModeType>;
    wasteFactor?: IUserSettingValue<number>;
    isDirty?: boolean;
}

/**
 * Enums of the possible Definitions of Sidelap Connectors
 */
export enum DefinitionOfSidelapConnectorTypes {
    connector1 = 1,
    connector2 = 2,
}

export interface UserSettingsInitialData {
    userSettings: IDeckingUserSettings;
    settingsCollection: IDeckingSettingsCollection;
}
