<decking-text-box
    class="input-textbox"
    [id]="textBoxId"
    [title]="textBoxTitle"
    [type]="type"
    [value]="displayValue"
    [cssClass]="cssClass"
    [displayBorder]="displayBorder"
    (valueChange)="displayValue = $any($event).detail"
    [placeholder]="displayPlaceholder()"
    [disabled]="disabled"
    [tooltip]="tooltip"
    [infoPopup]="infoPopup"
    [infoPopupTooltip]="infoPopupTooltip"
    (inputBlur)="onUserChange($any($event).detail)"
    (enterPressed)="onEnterPressed()"
    (isValid)="isValid.emit($any($event).detail)"
    [look]="look"
    [marginBottom]="'None'"
    [borderTop]="borderTop"
    [borderBottom]="borderBottom"
    [borderLeft]="borderLeft"
    [borderRight]="borderRight"
    [paddingRight]="paddingRight"
    [textAlign]="textAlign"
    [textBackground]="textBackground"
    [display]="display"
    [height]="height"
    [width]="width"
    [validators]="validators"
    [validationData]="validationData"
    [submitted]="submitted"
    (submittedChange)="submittedChange.emit($any($event).detail)"
    [updateOnBlur]="updateOnBlur">
</decking-text-box>

<div class="stepper" *ngIf="showStepper">
    <button class="stepper-button increment button" tabindex="-1" type="button" (mousedown)="debounce(0)"
        (mouseup)="stopDebounce()" (click)="increment()" placement="top" [ngbTooltip]="incrementTooltip()"
        [disabled]="disabled">
        +
    </button>
    <button class="stepper-button decrement button" tabindex="-1" type="button" (mousedown)="debounce(1)"
        (mouseup)="stopDebounce()" (click)="decrement()" placement="top" [ngbTooltip]="decrementTooltip()"
        [disabled]="disabled">
        -
    </button>
</div>
