import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeckingFieldState } from '../../../../entities/decking-design/decking-field-state';
import { FieldState } from '../../../../entities/enums/field-state';


export interface Product{
  id: number;
  name: string;
  image: string;
  isHiltiProduct: boolean;
  hiltiOnlinePageUrl: string;
}

@Component({
  selector: 'decking-product-selector-list',
  templateUrl: './product-selector-list.component.html',
  styleUrls: ['./product-selector-list.component.scss']
})
export class ProductSelectorListComponent{

  @Input()
  public title: string;

  @Input()
  public products: Product[];

  @Input()
    selectedValue: DeckingFieldState<number, string>;

  @Output()
    valueChanged = new EventEmitter<DeckingFieldState<number, string>>();

  public selectItem(product: Product){
    const item: DeckingFieldState<number, string> = {
      id: product.id,
      index: product.id,
      value: product.name,
      fieldState: FieldState.Selected
    };
    this.valueChanged.emit(item);
  }
}
