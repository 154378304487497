<div class="design-section-container design-management dropdown-active">
    <div class="collapsable-container">
        <decking-collapsable-up [defaultExpanded]="true">
            <div class="collapsable-title design-management-title">
                <span id="designOpt-panelTitle" l10n="Agito.Hilti.Profis3.Decking.AreaManagement.Design"></span>
            </div>
            <ng-template appCollapsableContent>
                <div class="collapsable-content">
                    <div class="area-content">
                        <div class="content-group">
                           <div class="content">
                              <div class="content-controls">
                                 <pe-dropdown
                                    id="diaphragmDesign-designMethod"
                                    class="control"
                                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.DesignMethod' | l10n:localization.selectedLanguage"
                                    [items]="designMethodItems"
                                    [selectedValue]="internalDeckingSettings.designMethod.index"
                                    (selectedValueChange)="getDesignMethod($any($event).detail)"
                                    [disabled]="isTemplate">
                                 </pe-dropdown>
                                 <pe-dropdown
                                    *ngIf="isMultiCodeEnabled"
                                    id="diaphragmDesign-designStandard"
                                    class="control"
                                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.DesignStandard' | l10n:localization.selectedLanguage"
                                    [items]="designStandardItems"
                                    [selectedValue]="internalDeckingSettings.designStandard.index"
                                    (selectedValueChange)="getDesignStandard($any($event).detail)"
                                    [disabled]="isTemplate">
                                </pe-dropdown>
                              </div>
                           </div>
                        </div>
                     </div>
                </div>
            </ng-template>
        </decking-collapsable-up>
    </div>
 </div>