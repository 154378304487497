import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RadioButtonItem } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { randomString } from '@profis-engineering/pe-ui-common/helpers/random';
import { DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Observable } from 'rxjs';
import { DeckType } from './../../../entities/decking-code-list/enums/deck-type';
import { DeckGaugeListItem } from './../../../entities/decking-code-list/code-list/deck-gauge-list-item';
import { SidelapConnectorListItem } from './../../../entities/decking-code-list/code-list/sidelap-connector-list-item';
import { FrameFastenerListItem } from './../../../entities/decking-code-list/code-list/frame-fastener-list-item';
import { PatternListItem } from './../../../entities/decking-code-list/code-list/pattern-list-item';
import { RelevantLoads } from './../../../entities/decking-code-list/enums/relevant-loads';


@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeckingCommonZonesRowComponent implements OnChanges {

    @Input()
    public deckType: DeckType;

    @Input()
    public index: number;

    @Input()
    public saving: boolean;

    @Input()
    public canDeleteZone: boolean;

    @Input()
    public zoneSpacing: boolean;

    @Input()
    public areResultsExpanded: boolean;

    // Dropdowns
    @Input()
    public deckGaugeItems: DropdownItem<DeckGaugeListItem>[];

    @Input()
    public patternItems: DropdownItem<PatternListItem>[];

    // This collection will be used in design mode
    @Input()
    public frameItems: DropdownItem<FrameFastenerListItem>[];

    // This collection will be used for specified zones in substitution mode
    @Input()
    public specifiedFrameItems: DropdownItem<FrameFastenerListItem>[];

    // This collection will be used for substituted zones in substitution mode
    @Input()
    public substitutedFrameItems: DropdownItem<FrameFastenerListItem>[];

    @Input()
    public sidelapItems: DropdownItem<SidelapConnectorListItem>[];

    @Input()
    public selectedLoadItems: RadioButtonItem<boolean>[];

    // Units
    @Input()
    public lengthUnit: Unit;

    @Input()
    public stressUnit: Unit;

    @Input()
    public forcePerLengthUnit: Unit;

    @Input()
    public shearStiffnessUnit: Unit;

    @Input()
    public forceUnit: Unit;

    public isSelected$: Observable<boolean>;
    public isRequiredShearStiffnessSetting$: Observable<boolean>;
    public isRequiredUpliftSubmittalSetting$: Observable<boolean>;
    public isSubstitutionRequiredShearStiffnessSetting$: Observable<boolean>;
    public isSubstitutionRequiredUpliftSubmittalSetting$: Observable<boolean>;
    public isSubstitutionRelevantLoadAtZoneLevelSetting$: Observable<boolean>;
    public isRelevantLoadAtZoneLevelSetting$: Observable<boolean>;
    public relevantLoads = RelevantLoads;
    public deckTypes = DeckType;
    public isDeckFilled$: Observable<boolean>;
    public disabledW$ : Observable<boolean>;
    public hasCriticalScopeChecks = false;
    public panelType$ : Observable<number>;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['index']) {
            this.selectedLoadItems = [
                {
                    id: randomString(8),
                    text: (this.index + 1).toString(),
                    value: true
                }
            ];
        }
        
    }

}
