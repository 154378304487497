import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalizationService } from './../../../services/external/localization.service';

@Component({
  selector: 'app-undo-redo',
  templateUrl: './undo-redo.component.html',
  styleUrls: ['./undo-redo.component.scss']
})
export class UndoRedoComponent {

  @Input() undoDisabled: boolean;

  @Input() redoDisabled: boolean;

  @Output() undoButtonClick = new EventEmitter();

  @Output() redoButtonClick = new EventEmitter();

  constructor(
    public localizationService: LocalizationService
  ) { }
}
