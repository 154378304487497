<div [ngClass]="isSelected ? 'area-container dropdown-active' : 'area-container'" >
    <div class="collapsable-container">
        <decking-collapsable-up [defaultExpanded]="isSelected" [expanded]="isSelected" (toggleClicked)="toggleClicked()" [inactiveStyle]="!isSelected">
            <div class="collapsable-title">
                <span [attr.id]="'areaOpt-areaTitle-' + index" class="text message-title title-wrapper">{{currentArea?.name?.value}}</span>
            </div>
            <ng-template appCollapsableContent>
                <div class="collapsable-content">
                    <substitution-area-properties [substitutionArea]="substitutionAreaSelected" [saving]="saving" [index]="index"></substitution-area-properties>
                </div>
            </ng-template>
        </decking-collapsable-up>
    </div>
    <div [ngClass]="isSelected ? 'dropdown-menu-container dropdown-active' : 'dropdown-menu-container dropdown-inactive'" ngbDropdown>
        <button type="button" [attr.id]="'areaOpt-moreActArea-' + index" class="dropdown-menu-button"
            ngbDropdownToggle>
            <span class="sprite sprite-dots"></span>
        </button>
        <div ngbDropdownMenu>
            <button [attr.id]="'areaOpt-moreAct-duplicate-' + index"
                class="dropdown-item-button settings" type="button"
                [disabled]="saving"
                [class.disabled]="saving"
                (click)="duplicateArea()"
                l10n="Agito.Hilti.Profis3.Decking.AreaManagement.DuplicateArea" ngbDropdownItem></button>
            <button [attr.id]="'areaOpt-moreAct-delete-' + index" *ngIf="showDeleteOption"
                [disabled]="saving"
                [class.disabled]="saving"
                class="dropdown-item-button manage-hilti-account" type="button"
                (click)="openDeleteModal(content)"
                l10n="Agito.Hilti.Profis3.Decking.AreaManagement.Delete" ngbDropdownItem></button>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h3 id="area-headerModal-panelTitle" class="modal-title" l10n="Agito.Hilti.Profis3.Decking.AreaManagement.Delete.Warning"></h3>

        <button id="area-headerModal-closeButton" type="button" class="close" (click)="close()">
            <span>&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="section">
            <div class="description-container">
                <span id="area-noteLabel" l10n="Agito.Hilti.Profis3.Decking.AreaManagement.Delete.Message"></span>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" id="area-footerPanel-closeButton" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.Decking.AreaManagement.Delete.No"
            (click)="close()">
        </button>

        <button type="button" id="area-footerPanel-saveButton" class="modal-button button button-CTA"
            l10n="Agito.Hilti.Profis3.Decking.AreaManagement.Delete.Yes"
            (click)="deleteArea()">
        </button>
    </div>

</ng-template>

