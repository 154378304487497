<div class="modal-content" >
    <div class="modal-header">
        <h3 id="zoneOrientation-headerModal-panelTitle" class="modal-title" l10n="Agito.Hilti.Profis3.Decking.ZoneOrientation.Title"></h3>

        <button id="zoneOrientation-headerModal-closeButton" type="button" class="close" (click)="close()">
            <span>&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="section-row">
            <div class="section-column">
                <div class="section-row">
                    <div class="description">
                        <span l10n="Agito.Hilti.Profis3.Decking.ZoneOrientation.Area1"></span>
                    </div>
                </div>
                <div class="section-row">
                    <div class="image-container">
                        <span class="image decking-sprite decking-sprite-responsive decking-sprite-zone-x"></span>
                    </div>
                </div>
            </div>
            <div class="section-column">
                <div class="section-row">
                    <div class="description">
                        <span l10n="Agito.Hilti.Profis3.Decking.ZoneOrientation.Area2"></span>
                    </div>
                </div>
                <div class="section-row">
                    <div class="image-container">
                        <span class="image decking-sprite decking-sprite-responsive decking-sprite-zone-y"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-row bottom-spacing-axis">
            <div class="image-left">
                <span class="image decking-sprite decking-sprite-responsive decking-sprite-axis"></span>
            </div>
        </div>
        <div class="section-row bottom-spacing">
            <span l10n="Agito.Hilti.Profis3.Decking.ZoneOrientation.Description"></span>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.Decking.ZoneOrientation.Button.Ok"
            (click)="close()">
        </button>
    </div>
</div>
