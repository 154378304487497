<span class="messages-title">{{title | uppercase }}</span>
<ng-template appEmbeddedTitle>
    <app-progress-bar style="width: 45%;" [percentage]="percentage"></app-progress-bar>
</ng-template>
<div class="box-section-content">
    <div class="utilizations">
        <div [ngClass]="percentage <= 100 ? 'utilization' : 'utilization invalid-value'">
            <div class="utilization-button">
                <div class="button-content">
                    <div class="progress-percent-container">
                        <div class="progress-percent" [style.width.%]="percentage"></div>
                    </div>
                    <div style="display: flex; flex-direction: row;">
                        <span class="decking-sprite {{spriteDescription}}"></span>
                        <div class="text-progress-container">
                            <p class="text" ng-reflect-key="Agito.Hilti.Profis3.Utilization">{{description}}</p>
                        </div>
                        <div [ngClass]="percentage <= 100 ? 'details-line' : 'details-line invalid-value'">
                            <div>
                                <p class="number notranslate">{{percentage | number:'1.0-2'}}%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-content select="[utilization]"></ng-content>
    </div>
</div>
