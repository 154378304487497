import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductSelectorService {
  expanded$: Observable<boolean>;
  private expanded = new BehaviorSubject<boolean>(true);

  constructor() {
    this.expanded$ = this.expanded.asObservable();
  }

  public expand(){
    this.expanded.next(true);
  }

  public collapse(){
    this.expanded.next(false);
  }

  public toggle(){
    this.expanded.next(!this.expanded.value);
  }

  public setExpanded(expanded: boolean){
    this.expanded.next(expanded);
  }
}
