import { NgZone } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { ApiService } from '../external/api.service';
import { FeatureVisibilityService } from '../external/feature-visibility.service';
import { ErrorHandlerService } from '../decking-error-handler/error-handler.service';
import { LocalizationService } from '../external/localization.service';
import { LoggerService } from '../external/logger.service';
import { environment } from '../../../environments/environmentDecking';
import { HttpRequest } from '@angular/common/http';
import { CommonUserSettingsService } from '../external/common-user-settings.service';

export class BaseSignalrService {
    protected connection: signalR.HubConnection;

    constructor(
        protected apiService: ApiService,
        protected zone: NgZone,
        protected featureVisibilityService: FeatureVisibilityService,
        protected ErrorHandlerService: ErrorHandlerService,
        protected localizationService: LocalizationService,
        protected userSettings: CommonUserSettingsService,
        protected loggerService: LoggerService
    ) {
    }

    protected get deckingEnabled() {
        return this.featureVisibilityService.isFeatureEnabled('Decking_Global') || environment.deckingEnabled;
    }

    public async checkConnection(designId: string, subscriptionType: string) {
        try {
            if (this.connection.state != signalR.HubConnectionState.Connected) {
                if (environment.accessToken == 'cookie') {
                    // request to get the needed cookie for authentication
                    await this.apiService.request(new HttpRequest('GET', environment.signalRCoreInitSessionUrl, { withCredentials: true, }));
                }

                await this.connection.start();
            }
            await this.connection.send(subscriptionType, designId);
        } catch (e) {
            let title = '';
            let message = '';
            [title, message] = this.getSignalRErrorFieldValues();
            this.ErrorHandlerService.showDesignErrorModal(title, message, null, subscriptionType, null);
            throw e;
        }
    }

    public async disconnect(): Promise<void> {
        if (this.connection.state == signalR.HubConnectionState.Connected) {
            await this.connection.stop();
        }
    }

    protected getSignalRErrorFieldValues(): [string, string] {

        const defaultTitle = this.localizationService.getString('Agito.Hilti.Profis3.ServerErrorAlert.Title', { defaultString: 'ERROR' });
        const defaultMessage = this.localizationService.getString('Agito.Hilti.Profis3.ServerErrorAlert.Message', { defaultString: 'Please try again later. If the problem persists contact us at' });

        const title = defaultTitle;
        let message: string;

        const serviceDescription = this.localizationService
            .getString('Agito.Hilti.Profis3.ServerErrorAlert.ServiceDescription')
            .replace('{service}', 'decking-design-service');
        message = (serviceDescription);

        const region = this.userSettings.getCommonRegionById(this.userSettings.settings.application.general.regionId.value);

        let contactUrl = null as string;
        if (region != null) {
            contactUrl = region.contactUrl;
        }
        contactUrl = (contactUrl == null || contactUrl == '')
            ? 'info@info'
            : contactUrl.replace('mailto:', '');

        message += ('\n\n' + defaultMessage) + ('\n' + (contactUrl));
        this.loggerService.logServiceError(message, environment.signalRServiceName, 'DeckingDesignChanges');
        return [title, message];
    }
}