import { Component } from '@angular/core';
import { DesignerLayoutService } from './../../../services/designer-layout/designer-layout.service';

@Component({
  selector: 'app-designer-layout',
  templateUrl: './designer-layout.component.html',
  styleUrls: ['./designer-layout.component.scss']
})
export class DesignerLayoutComponent {
  constructor(public designerLayoutService: DesignerLayoutService) { }
}
