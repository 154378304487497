import type schema from './environment.local.json';
import {
    EnvironmentHelper
} from '@profis-engineering/pe-ui-common/helpers/environment-helper';
import {
    QueryStringHelper
} from '@profis-engineering/pe-ui-common/helpers/query-string-helper';

export type Environment = typeof schema & {
    baseUrl: string; 
    debugGlModel: boolean;
    supportedFeatures: string[];
};

export const environment: Environment = getEnvironmentSettings();

const qsHelper = new QueryStringHelper(window.location.search);

environment.supportedFeatures = ['loadingInitialData'];

// setDeckingEnabled
if (environment.deckingQueryEnabled && !environment.deckingEnabled) {
    environment.deckingEnabled = qsHelper.getBoolean('deckingdemo');
}

function getEnvironmentSettings(): Environment {
    const env: Environment = (window as any).environmentDecking;
    const helper = new EnvironmentHelper(window.location.host);
    const [serviceVersion] = helper.getServiceVersions();

    if (serviceVersion != '') {
        env.deckingUserSettingsServiceUrl = helper.formatServiceVersion(env.deckingUserSettingsServiceUrl, serviceVersion);
        env.deckingDesignServiceUrl = helper.formatServiceVersion(env.deckingDesignServiceUrl, serviceVersion);
        env.deckingDesignServiceSignalRUrl = helper.formatServiceVersion(env.deckingDesignServiceSignalRUrl, serviceVersion);
        env.deckingCalculationServiceUrl = helper.formatServiceVersion(env.deckingCalculationServiceUrl, serviceVersion);
        env.deckingReportProcessorServiceUrl = helper.formatServiceVersion(env.deckingReportProcessorServiceUrl, serviceVersion);
        env.deckingReportProcessorServiceSignalRUrl = helper.formatServiceVersion(env.deckingReportProcessorServiceSignalRUrl, serviceVersion);
        env.deckingTrackingServiceUrl = helper.formatServiceVersion(env.deckingTrackingServiceUrl, serviceVersion);
    }

    return env ?? {} as Environment;
}
