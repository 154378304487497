import { Component, Input, } from '@angular/core';
import { LocalizationService } from './../../../services/external/localization.service';

@Component({
    selector: 'decking-collapsable-striped-title',
    templateUrl: './collapsable-striped-title.component.html',
    styleUrls: ['./collapsable-striped-title.component.scss']
})
export class CollapsableStripedTitleComponent {
    @Input()
    public isExpanded: boolean;

    @Input()
    buttonId?: string;

    constructor(
        public localization: LocalizationService
    ) { }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }
}
