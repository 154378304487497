import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CollapsableContentDirective } from './../../..//directives/collapsable-content.directive';
import { EmbeddedTitleDirective } from './../../..//directives/embedded-title.directive';
import { includeSprites } from 'src/decking/sprites';

@Component({
  selector: 'decking-collapsable-up',
  templateUrl: './collapsable-up.component.html',
  styleUrls: ['./collapsable-up.component.scss']
})
export class CollapsableUpComponent implements OnInit {

  @Input() public defaultExpanded: boolean;
  @Input() public expanded: boolean;
  @Input() public inactiveStyle = false;
  @Output() public toggleClicked = new EventEmitter<boolean>();
  @ContentChild(CollapsableContentDirective) content!: CollapsableContentDirective;
  @ContentChild(EmbeddedTitleDirective) embeddedTitle: EmbeddedTitleDirective;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
  ) {}

  ngOnInit(): void {
    includeSprites(this.elementRef.nativeElement,
      'sprite-lines',
      'sprite-lines-expanded'
    );
    this.expanded = this.defaultExpanded;
  }

  public toggleClick(): void {
    this.expanded = !this.expanded;
    this.toggleClicked.emit(this.expanded);
  }
}
