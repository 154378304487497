import { DeckingDesignCalculationService } from '../../calculation/common/decking-design-calculation.service';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DeckingDesign } from '../../../../entities/decking-design/decking-design';

@Injectable({
    providedIn: 'root',
})
export class DeckingDesignVerificationService extends DeckingDesignCalculationService<DeckingDesign> {
    protected getDesignId(calculationInput: DeckingDesign): string {
        return calculationInput.id;
    }

    protected buildTriggerCalculationObservable(): Observable<DeckingDesign> {
        return this.deckingDesignService.currentDeckingDesign$.pipe(
            filter((design => !design.saved)));
    }

    protected callCalculation(design: DeckingDesign): void {
        this.deckingSignalRService.VerifyDesign(design);
    }

}
