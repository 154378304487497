import { IDeckingDocument } from './../../../entities/decking-design/decking-document';
import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { RadioButtonItem, RadioLook } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { Tooltip } from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import { InfoPopup } from '@profis-engineering/pe-ui-common/components/control-title/control-title.common';

// Wrapper component of app radio button component
// This component extends the functionally of the radio button component allowing using
// complex objects as values of the items
@Component({
    selector: 'decking-radio-button',
    templateUrl: './decking-radio-button.component.html'
})
export class DeckingRadioButtonComponent<TId, TValue> implements OnChanges {
    @Input()
    public id: string;

    @Input()
    public title: string;

    @Input()
    public look = RadioLook.Normal;

    @Input()
    public selectedValue: IDeckingDocument<TId, TValue>;

    @Output()
    public selectedValueChange = new EventEmitter<IDeckingDocument<TId, TValue>>();

    @Input()
    public items: RadioButtonItem<IDeckingDocument<TId, TValue>>[];

    @Input()
    public disabled = false;

    @Input()
    public tooltip: Tooltip;

    @Input()
    public infoPopup: InfoPopup;

    @Input()
    public infoPopupTooltip: Tooltip;

    @Input()
    public trackBy: (i: RadioButtonItem<IDeckingDocument<TId, TValue>>) => boolean = (i => i.value.id === this.selectedValue.id);

    ngOnChanges(_: SimpleChanges): void {
        this.selectedValue = this.items.find(this.trackBy)?.value;
    }

}
