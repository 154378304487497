<div id="zones-action-buttons">
    <button id="zonePropsEnabled-addButton" type="button" [disabled]="
            this.currentArea?.zones.length >= this.maxZonesAllowed || saving
        " [class.disabled]="
            this.currentArea?.zones.length >= this.maxZonesAllowed || saving
        " class="button button-default" l10n="Agito.Hilti.Profis3.Decking.Zones.Buttons.AddZone"
        (click)="addZone()"></button>
    <button id="zoneProps-optimizeButton" type="button" [disabled]="saving" [class.disabled]="saving"
        class="button button-default" (click)="optimizeAll()">
        <span l10n="Agito.Hilti.Profis3.Decking.Zones.Buttons.OptimizeAllZones"></span>
    </button>
    <button id="zoneProps-alternativesButton" type="button" [disabled]="disableAlternatives$ | async"
        [class.disabled]="disableAlternatives$ | async" class="button button-default" (click)="compareAlternatives()">
        <span l10n="Agito.Hilti.Profis3.Decking.Zones.Buttons.CompareAlternatives"></span>
    </button>
    <button id="zoneProps-templatesButton" type="button" class="button button-default"
        (click)="zoneTemplates()" l10n="Agito.Hilti.Profis3.Decking.Zones.Buttons.UseZoneTemplate"
        [disabled]="saving || !showZoneTemplates"
        [class.disabled]="!showZoneTemplates"
        [ngbTooltip]="getZoneTemplatesDisabledTooltip()">
    </button>
</div>