import { Injectable } from '@angular/core';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

@Injectable({
  providedIn: 'root'
})

export class DeckingUnitsHelperService {

  public readonly DECK_GAUGE_UNIT = 'ga';

  private internationalSystemUnits = new Set<Unit>(
    [
      // Moment per length
      Unit.Nmm_mm,
      Unit.kNm_m,
      Unit.daNm_m,
      Unit.Nm_m,
      Unit.kgfm_m,
      Unit.kgfcm_cm,

      // Moment Unit
      Unit.Nmm,
      Unit.Nm,
      Unit.daNm,
      Unit.kNm,
      Unit.kgfm,
      Unit.kgfcm,

      // Area Unit
      Unit.mm2,
      Unit.cm2,
      Unit.m2,

      // Length unit
      Unit.mm,
      Unit.cm,
      Unit.m,

      // Force Per Length
      Unit.N_mm,
      Unit.N_m,
      Unit.kN_mm,
      Unit.kN_m,

      // Force unit
      Unit.N,
      Unit.daN,
      Unit.kN,
      Unit.kgf,

      // Stress unit
      Unit.Nmm2,
      Unit.kNm2,
      Unit.kgfcm2,

      // Temperature units
      Unit.C,

      // Density units
      Unit.kg_m3
    ]
  );

  public isInternationalSystemUnit(unit: Unit) {
    return this.internationalSystemUnits.has(unit);
  }

  public getBeamSpacingUnit(lengthUnit: Unit): Unit {
    if (this.isInternationalSystemUnit(lengthUnit)) {
      return lengthUnit;
    } else {
      return Unit.ft;
    }
  }

  public getStandardLengthUnit(lengthUnit: Unit) {
    if (this.isInternationalSystemUnit(lengthUnit)) {
      return Unit.mm;
    } else {
      return Unit.inch;
    }
  }

  public getPanelWidthUnit(lengthUnit: Unit): Unit {
    return this.getStandardLengthUnit(lengthUnit);
  }

  public getFastenerEstimationAreaUnit(lengthUnit: Unit): Unit {
    if (this.isInternationalSystemUnit(lengthUnit)) {
      return Unit.m2;
    }
    return Unit.ft2;
  }

  public getDeflectionCalculationAreaUnit(lengthUnit: Unit): Unit {
    if (this.isInternationalSystemUnit(lengthUnit)) {
      return Unit.mm2;
    }
    return Unit.inch2;
  }

  public getDeflectionCalculationForcePerLenghtUnit(forcePerLenghtUnit: Unit): Unit {
    if (this.isInternationalSystemUnit(forcePerLenghtUnit)) {
      return Unit.N_mm;
    }
    return Unit.lb_ft;
  }

  public getMomentOfInertiaValue(value: number, lengthUnit: Unit): string {
    if (this.isInternationalSystemUnit(lengthUnit)) {
      return `${value.toFixed(2)} mm<sup>4</sup>`;
    }
    return `${(value / 416231.426).toFixed(2)} in<sup>4</sup>`;
  }

  public getCompressiveStrengthUnit(stressUnit: Unit): Unit {
    if (this.isInternationalSystemUnit(stressUnit)) {
      return Unit.Nmm2;
    }
    return Unit.PSI;
  }
}
