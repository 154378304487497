import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DeckingCommonZonesSideComponent } from 'src/decking/components/decking-main-bottom/decking-zones-common/decking-common-zone-side.component';
import { IndividualZoneModel } from 'src/decking/entities/decking-substitution/substitution-zone';
import { FieldState } from 'src/decking/entities/enums/field-state';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';

@Component({
    selector: 'decking-substitution-zone-side',
    templateUrl: './side.component.html',
})
export class SubstitutionSideComponent extends DeckingCommonZonesSideComponent implements OnInit, OnChanges {

    @Input()
    public currentZone: IndividualZoneModel;

    constructor(
        private deckingSubstitutionService: DeckingSubstitutionService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.currentSettings$ = this.deckingSubstitutionService.currentSettings$;
        this.initCommonObservables();
    }

    public ngOnChanges(): void {
        this.updateInternalValue();
    }

    public onValueChanged(valueChanged: number): void {
        this.internalValue = valueChanged;
        if (this.internalValue?.toFixed(2) === this.currentZone.side?.value.toFixed(2))
            return;

        if (this.internalValue) {
            this.currentZone.side = {
                value: this.internalValue,
                fieldState: FieldState.Selected,
            };
        } else {
            this.currentZone.side = null;
        }

        this.valueChanged.emit();
    }

    private updateInternalValue() {
        if (!this.currentZone?.side) {
            this.internalValue = null;
        } else {
            this.internalValue = this.currentZone.side.value;
        }
    }
}
