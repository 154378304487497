import { Injectable } from '@angular/core';
import { AreaModel } from './../../../../entities/decking-design/area-model';
import { DeckingDesign } from './../../../../entities/decking-design/decking-design';
import { DesignSettings } from './../../../../entities/settings/design-settings';
import { ZoneModel } from './../../../../entities/decking-design/zone-model';

@Injectable({
    providedIn: 'root'
})
export class DeckingZoneInputsSettingsService {

    public checkDesignZoneInputSettings(design: DeckingDesign, isRelevantLoadsDifferent: boolean) {
        const settings = design.settings;
        this.checkRelevantLoadsAtZoneLevel(settings, design, isRelevantLoadsDifferent);
        this.checkRequiredShearStiffnessValue(settings, design);
        this.checkRequiredUpliftSubmittalValue(settings, design);
    }

    private checkRelevantLoadsAtZoneLevel(settings: DesignSettings, design: DeckingDesign, isRelevantLoadsDifferent: boolean) {
        if (isRelevantLoadsDifferent) {
            const relevantLoad = settings.relevantLoads.id;
            design.areas.forEach((area: AreaModel) => {
                area.zones.forEach((zone: ZoneModel) => {
                    zone.relevantLoads = { value: relevantLoad };
                });
            });
        }
    }

    private checkRequiredShearStiffnessValue(settings: DesignSettings, design: DeckingDesign) {
        if (!settings.requiredShearStiffness.value) {
            design.areas.forEach((area: AreaModel) => {
                area.zones.forEach((zone: ZoneModel) => {
                    zone.g = { value: 0 };
                });
            });
        }
    }

    private checkRequiredUpliftSubmittalValue(settings: DesignSettings, design: DeckingDesign) {
        if (!settings.requiredUpliftSubmittal.value) {
            design.areas.forEach((area: AreaModel) => {
                area.zones.forEach((zone: ZoneModel) => {
                    zone.w = { value: 0 };
                });
            });
        }
    }
}
