<div id="decking-main-right-options">
    <div class="option-item">
        <app-square-icon-button enableTooltip iconSprite="decking-sprite-export-design" width="100%" height="40px"
        [disabled]="saving || isTemplate" (buttonClick)="openExportModal()"
        [ngbTooltip]="'Agito.Hilti.Profis3.Main.Save' | l10n: localizationService.selectedLanguage" placement="top">
        </app-square-icon-button>
    </div>

    <div class="option-item">
        <app-square-icon-button enableTooltip iconSprite="decking-sprite-duplicate-design" width="100%" height="40px"
        [disabled]="saving || isTemplate" (buttonClick)="duplicateDesign()"
        [ngbTooltip]="'Agito.Hilti.Profis3.Main.DuplicateDesign' | l10n: localizationService.selectedLanguage" placement="top"/>
    </div>

    <div class="option-item">
        <app-square-icon-button iconSprite="decking-sprite-openfile-d-light" width="100%" height="40px"
        [disabled]="saving || isTemplate" (buttonClick)="selectOpenFile()"
        [ngbTooltip]="'Agito.Hilti.Profis3.Main.OpenDesign' | l10n: localizationService.selectedLanguage" placement="left"/>
        <input class="open-design-input" #openDesignInput (change)="importFileSelected()" name="project" type="file"
                accept=".pe,.pa2,.dia" (click)="$event.stopPropagation();" />
    </div>
</div>
