import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseDesign, Design } from '@profis-engineering/pe-ui-common/entities/design';
import { ApiService } from '../external/api.service';
import { RoutingService } from '../external/routing.service';
import { ChangesService } from '../external/changes.service';
import { UserService } from '../external/user.service';
import { LocalizationService } from '../external/localization.service';
import { LoggerService } from '../external/logger.service';
import { environment } from 'src/environments/environmentDecking';
import { DeckingSubstitutionService } from '../decking-design/decking-substitution.service';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';
import { DeckingUrlPath } from 'src/decking/entities/module-constants';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { DeckingDocumentService } from '../decking-document/decking-document.service';
import { DeckingImportBaseService } from './decking-import-base.service';
import { ImportService } from '../external/import.service';

@Injectable({
  providedIn: 'root'
})
export class DeckingSubstitutionImportService extends DeckingImportBaseService {

  constructor(
    apiService: ApiService,
    routingService: RoutingService,
    documentService: DeckingDocumentService,
    changesService: ChangesService,
    user: UserService,
    localization: LocalizationService,
    logger: LoggerService,
    importService: ImportService,
    private deckingSubstitutionService: DeckingSubstitutionService,
  ) {
    super(apiService, routingService, documentService, changesService, user, localization, logger, importService);
  }

  public async importDFDesign(projectDesign: File, projectId: string) {
    const fileContent = await this.readFileAsArrayBuffer(projectDesign);
    const base64String = this.arrayBufferToBase64(fileContent);
    const rData = { base64: base64String };
    const url = `${environment.deckingCalculationServiceUrl}api/TransformDesign`;
    this.apiService.request<{ value: DeckingSubstitution }>(new HttpRequest('POST', url, rData))
      .then(async (response) => {
        const newDeckingSubstitution = response.body.value;
        await this.deckingSubstitutionService.createNewSubstitutionFromExternalFile(projectId, newDeckingSubstitution);
        this.deckingSubstitutionService.launchedFromDashboard = true;
        this.routingService.navigateToUrl(DeckingUrlPath.mainDecking + newDeckingSubstitution.id);
      })
      .catch((response) => {
        this.logger.logServiceError(response, 'DeckingImportService', 'importDFDesign');
      });
  }

  public async createAndOpenFromProjectDesign(design: DeckingSubstitution, projectId: string) {
    await this.deckingSubstitutionService.createNewSubstitutionFromExternalFile(projectId, design, true, this.importService.importedFromDesign);
    this.openImportedProject(design);
  }

  public updateDesignFromExternalFile(oldDesign: IBaseDesign, projectSubstitution: DeckingSubstitution) {
    return this.documentService.openDesignExclusiveDecking(oldDesign)
      .then(async () => {
        const substitution = await this.deckingSubstitutionService.replaceExistingSubstitution(oldDesign as IDesignListItem, projectSubstitution, this.importService.importedFromDesign);
        this.openImportedProject(projectSubstitution);
        return new Promise<Design>(() => {
          return substitution;
        });
      });
  }

  private openImportedProject(projectSubstitution: DeckingSubstitution) {
    if (!this.importService.importedFromDesign) {
      this.deckingSubstitutionService.launchedFromDashboard = true;
      this.openImportSameWindow(projectSubstitution.id);
    } else {
      this.openImportNewWindow(projectSubstitution.id);
    }
  }
}
