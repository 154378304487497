import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    ViewChild,
    OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DeckingReportGenerationService } from './../../../../services/decking-report/decking-report-generation.service';
import { DeckingDesign } from './../../../../entities/decking-design/decking-design';
import { DeckingTrackingService } from 'src/decking/services/decking-tracking/decking-tracking.service';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';
import { DeckingMainService } from 'src/decking/services/decking-main/decking-main.service';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';
import { DeckingSubstitutionTrackingService } from '../../../../services/decking-tracking/decking-substitution-tracking.service';

@Component({
    selector: 'app-preview-dialog',
    templateUrl: './preview-dialog.component.html',
    styleUrls: ['./preview-dialog.component.scss'],
})
export class PreviewDialogComponent
    implements AfterViewInit, OnDestroy, OnInit
{
    @ViewChild('previewIframe') previewIframe: ElementRef<HTMLIFrameElement>;
    isIframeLoading = true;

    @Input()
    deckingDesign: DeckingDesign;

    @Input()
    substitutionDesign: DeckingSubstitution;

    @Input()
    reportContent: string;

    safeContent: SafeHtml;

    constructor(
        public activeModal: NgbActiveModal,
        private deckingReportGeneratorService: DeckingReportGenerationService,
        private sanitizer: DomSanitizer,
        private designTrackingService: DeckingTrackingService,
        private substitutionTrackingService: DeckingSubstitutionTrackingService,
        private deckingMainService:DeckingMainService
    ) {}

    ngOnInit(): void {
        this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.reportContent); //NOSONAR the report content is safely generated also CORS rules secures code injection. 
    }

    ngAfterViewInit() {
        this.isIframeLoading = true;
        this.previewIframe.nativeElement.addEventListener('load', this.handleLoad.bind(this));
    }

    ngOnDestroy() {
        this.previewIframe.nativeElement.removeEventListener('load', this.handleLoad);
    }

    handleLoad() {
        this.isIframeLoading = false;
    }

    close(): void {
        this.activeModal.close(false);
    }

    async generate(): Promise<void> {
        this.isIframeLoading = true;
        if (this.deckingMainService.getSelectedModeType() === DeckingDesignModeType.SubstitutionBuilderMode){
            await this.deckingReportGeneratorService.generateSubstitutionReport(
                this.substitutionDesign.id,
                this.substitutionDesign.eTag,
                this.substitutionDesign.report?.projectTitle?.value
            );
            this.substitutionTrackingService.addReportCreated();
            await this.substitutionTrackingService.trackSubstitutionActivity(this.substitutionDesign);
        } else {
            await this.deckingReportGeneratorService.generateReport(
                this.deckingDesign.id,
                this.deckingDesign.eTag,
                this.deckingDesign.report?.projectTitle?.value
            );
            this.designTrackingService.addReportCreated();
            await this.designTrackingService.trackDeckingActivity(this.deckingDesign);
        }
        this.isIframeLoading = false;
        this.activeModal.close(true);
    }
}
