<div class="main-header">
    <!-- HEADER LEFT -->
    <div class="header header-left">
        <span class="logo sprite sprite-hilti-logo"></span>
        <p class="application-name">
            <span class="main-application-name" l10n="Agito.Hilti.Profis3.Main.ApplicationName"></span>
            <span>&nbsp;</span>
            <span class="sub-application-name" l10n="Agito.Hilti.Profis3.Main.SubApplicationName"></span>
        </p>
        <div class="space"></div>

        <div class="border"></div>
    </div>

    <!-- HEADER CENTER -->
    <div class="header header-center">
        <div class="header-center-left">
            <span class="project-design-name" [ngbTooltip]="designTitle" placement="bottom">{{getDesignName()}}</span>
            <button
                *ngIf="!settingsButtonHidden"
                id="topbar-design-info-edit-button"
                class="document-design-button"
                type="button"
                (click)="settingsButtonClick.emit()"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.DesignOptions.Settings' | l10n: localizationService.selectedLanguage"
                placement="bottom">
                <span class="sprite sprite-settings"></span>
            </button>
            <button
                *ngIf="showSaveAsTemplateButton"
                id="topbar-design-save-as-template-button"
                class="document-design-button"
                [disabled]="!isPremiumUser"
                type="button"
                (click)="saveAsTemplateButtonClick.emit()"
                [ngbTooltip]="projectTemplateTooltipMessage | l10n: localizationService.selectedLanguage"
                placement="bottom">
                <span class="sprite sprite-new-document"></span>
            </button>
        </div>
    </div>

    <!-- HEADER RIGHT -->
    <div class="header header-right">
        <div class="border"></div>

        <span class="user-name">{{userName}}</span>

        <!-- decoupled menu -->
        <ng-content select="[menu]"></ng-content>
    </div>

</div>
