import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'decking-report-generation-options-action-buttons',
    templateUrl: './decking-report-generation-options-action-buttons.component.html',
    styleUrls: ['./decking-report-generation-options-action-buttons.component.scss']
})
export class DeckingReportGenerationOptionsActionButtonsComponent {

    @Input() isSaveButtonDisabled = false;
    @Input() isCancelButtonDisabled = false;
    @Input() isLoading = false;

    @Output()
    private closeClick = new EventEmitter();
    @Output()
    private saveClick = new EventEmitter();

    save() {
        this.saveClick.emit();
    }

    close() {
        this.closeClick.emit();
    }
}
