import { DeckingDesignAreasService } from '../../../../services/decking-areas/decking-design-areas.service';
import { DeckingDesignService } from '../../../../services/decking-design/decking-design.service';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { AreaModel } from '../../../../entities/decking-design/area-model';
import { DeckingDesignOptimizationService } from '../../../../services/decking-design/calculation/optimization/decking-design-optimization.service';
import { CalculationState } from '../../../../entities/enums/calculation-state';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { DeckingDesignVerificationService } from '../../../../services/decking-design/calculation/verification/decking-design-verification.service';
import { combineLatestWith } from 'rxjs/internal/operators/combineLatestWith';
import { ModalService } from './../../../../services/external/modal.service';
import { FeatureVisibilityService } from './../../../../services/external/feature-visibility.service';
import { ZoneTemplatesComponent } from '../zones-templates/zone-templates.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { includeSprites } from '../.././../../sprites';
import { LicenseService } from 'src/decking/services/external/license.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';

@Component({
    templateUrl: './decking-zones-action-buttons.component.html',
    styleUrls: ['./decking-zones-action-buttons.component.scss']
})
export class DeckingZonesActionButtonsComponent implements OnInit {
    @Input()
    public currentArea: AreaModel;

    @Input()
    public saving: boolean;

    public readonly maxZonesAllowed: number;
    public disableAlternatives$: Observable<boolean>;

    constructor(
        private deckingDesignOptimizationService: DeckingDesignOptimizationService,
        private designService: DeckingDesignService,
        private deckingVerificationService: DeckingDesignVerificationService,
        private modal: ModalService,
        private modalService: NgbModal,
        deckingAreaService: DeckingDesignAreasService,
        private launchDarklyService: FeatureVisibilityService,
        public elementRef: ElementRef<HTMLElement>,
        public licenseService: LicenseService,
        private localizationService: LocalizationService
    ) {
        this.maxZonesAllowed = deckingAreaService.maxZonesAllowed;
    }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,
            'sprite-optimize-icon'
        );
        this.disableAlternatives$ = this.designService.currentZone$.pipe(combineLatestWith(this.deckingVerificationService.saving$)).pipe(map(([currentZone, saving]) => {
            return saving || currentZone.calculationState == CalculationState.Empty || currentZone.calculationState == CalculationState.NotSolution;
        }));
    }

    public optimizeAll(): void {
        this.deckingDesignOptimizationService.optimizeAll();
    }

    public addZone(): void {
        this.designService.addNewZoneToCurrentArea();
    }

    public compareAlternatives() {
        this.modal.openWebComponentModal('decking-compare-zone-alternatives-modal', { size: 'xl' });
    }

    public get showZoneTemplates() {
        return this.launchDarklyService.isFeatureEnabled('Decking_ZoneTemplates') && this.licenseService.isPremium();
    }
    public zoneTemplates() {
        const modalRef = this.modalService.open(ZoneTemplatesComponent, { size: 'xl' });
        modalRef.componentInstance.name = 'zoneTemplatesModal';
    }

    public getZoneTemplatesDisabledTooltip() {
        return !this.showZoneTemplates ? this.localizationService.getString('Agito.Hilti.Profis3.Decking.Zones.Buttons.UseZoneTemplate.Disabled.Tooltip.Text') : '';
    }
}
