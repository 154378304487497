<div class="modal-content modal-content-diaphragm">
    <div class="modal-header">
        <h3 id="diaphragmStrength-headerModal-Title" class="modal-title"
            l10n="Agito.Hilti.Profis3.Decking.DiaphragmStrength.Title"></h3>

        <button id="diaphragmStrength-headerModal-closeButton" type="button" class="close" (click)="close()">
            <span>&times;</span>
        </button>
    </div>
    <div class="modal-body info">
        <div class="decking-sprite decking-sprite-responsive decking-sprite-frame-diaphragm-strengthTop" id="reportModal-summarySec-diaphragmStrengthTop"></div>
        <div class="decking-sprite decking-sprite-responsive decking-sprite-frame-diaphragm-strengthBottom" id="reportModal-summarySec-diaphragmStrengthButtom"></div>
        <div class="section-row">
            <span l10n="Agito.Hilti.Profis3.Decking.DiaphragmStrength.Table.Bottom"></span>
        </div>
        <div class="references-margin">
            <div class="section-row reference">
                <span l10n="Agito.Hilti.Profis3.Decking.DiaphragmStrength.Table.Reference1"></span>
            </div>
            <div class="section-row reference">
                <span l10n="Agito.Hilti.Profis3.Decking.DiaphragmStrength.Table.Reference2"></span>
            </div>
            <div class="section-row reference">
                <span l10n="Agito.Hilti.Profis3.Decking.DiaphragmStrength.Table.Reference3"></span>
            </div>
            <div class="section-row reference">
                <span l10n="Agito.Hilti.Profis3.Decking.DiaphragmStrength.Table.Reference4"></span>
            </div>
        </div>
        <div class="section-row">
            <span [innerHtml]="additionalInformation"></span>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.ControlTooltip.Ok" (click)="close()">
        </button>
    </div>
</div>
