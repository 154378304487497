<pe-modal-header 
    [modalInstance]="modalInstance" 
    [title]="'Agito.Hilti.Profis3.Decking.ModeSelectorModal.TitleText' | l10n:localizationService.selectedLanguage">
</pe-modal-header>

<pe-modal-body>
    <pe-radio-button
        class="radio-button-group control"
        [id]="modeSelectorOptions.id"
        [items]="modeSelectorOptions.items"
        [selectedValue]="modeSelected"
        (selectedValueChange)="modeSelectedValueChange($event)"
        >
    </pe-radio-button>
    <br>
    <p l10n="Agito.Hilti.Profis3.Decking.ModeSelectorModal.NoteText"></p>
</pe-modal-body>

<pe-modal-footer>
    <pe-button 
        l10n="Agito.Hilti.Profis3.Decking.ModeSelectorModal.CancelButtonText" 
        id="mode-selector-modal-cancel-button" 
        [look]="'Default'" 
        (buttonClick)="close()">
    </pe-button>
    <pe-button 
        l10n="Agito.Hilti.Profis3.Decking.ModeSelectorModal.ContinueButtonText"
        id="mode-selector-modal-cancel-button"
        [look]="'Cta'"
        (buttonClick)="continue()"
        [disabled]="isContinueDisabled">
    </pe-button>
</pe-modal-footer>
