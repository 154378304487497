import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { SidelapConnectorListItem } from '../entities/decking-code-list/code-list/sidelap-connector-list-item';
import { PanelType } from '../entities/decking-code-list/enums/panel-type';
import { SubstitutionType } from '../entities/decking-code-list/enums/substitution-type';

@Pipe({
    name: 'filterHiltiProducts',
    pure: false
})
export class FilterHiltiProducts implements PipeTransform {
    private substitutionTypeEnum = SubstitutionType;
    transform(items: DropdownItem<SidelapConnectorListItem>[], substitutionType: SubstitutionType, panelType: number, isHiltiProduct = false,): any {
        if (!items) {
            return items;
        }
        if(substitutionType === SubstitutionType.Specified) {
            return this.GetSpecifiedFilteredItems(items, panelType);
        } else if(substitutionType === SubstitutionType.Substituted) {
            return this.GetSubstitutedFilteredItems(items, panelType);
        }

        return items.filter(item => !item.value || item.value.isHILTIProduct === isHiltiProduct);
    }

    private GetSpecifiedFilteredItems(items: DropdownItem<SidelapConnectorListItem>[], panelType: number) {
        if(panelType === PanelType.Nestable) {
            return items.filter(item => !item.value || (item.value && item.value.allowForNestable && item.value.useForSpecifiedNestable));
        } else if(panelType === PanelType.Interlocking) {
            return items.filter(item => !item.value || (item.value.allowForInterlocking && item.value.useForSpecifiedInterlocking));
        }

        return items;
    }

    private GetSubstitutedFilteredItems(items: DropdownItem<SidelapConnectorListItem>[], panelType: number) {
        if(panelType === PanelType.Nestable) {
            return items.filter(item => !item.value || (item.value && item.value.allowForNestable && item.value.useForSubstitutedNestable));
        } else if(panelType === PanelType.Interlocking) {
            return items.filter(item => !item.value || (item.value.allowForInterlocking && item.value.useForSubstitutedInterlocking));
        }

        return items;
    }
}