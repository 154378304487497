import { Injectable, ElementRef } from '@angular/core';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';
import { Region } from 'src/decking/entities/decking-code-list/enums/region';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitService } from '../external/unit.service';
import { DeckingDesign } from 'src/decking/entities/decking-design/decking-design';

type Design = DeckingSubstitution | DeckingDesign;

@Injectable({
    providedIn: 'root'
})
export class SpecificationTextService {
  
    constructor(
        // Common Services
        private unitService: UnitService
    ) { }
    public getSpecificationRichTextData(specificationTextDiv: ElementRef): string {
        return `<html><head><style>table{border-collapse:collapse;border:2px solid #ccc;}th,td{border:1px solid #ccc;padding:8px;}.table-title{font-size:14px;font-weight:700;}</style></head><body>${specificationTextDiv.nativeElement.outerHTML}</body></html>`.replaceAll('\n', '<br/>');
    }

    public getSpecificationTextKeyForCurrentRegion(currentDesign: Design): string {
        if (currentDesign.settings.region.index === Region.CA) {
            return 'Agito.Hilti.Profis3.Decking.SpecificationTextModal.BodyText.InternationalSystem';
        } else {
            return 'Agito.Hilti.Profis3.Decking.SpecificationTextModal.BodyText.ImperialSystem';
        }
    }

    public getConvertedValue(value: number, unit: UnitType): string {
        const unitGroup = this.unitService.getUnitGroupFromUnit(unit);
        const internalUnit = this.unitService.getInternalUnit(unitGroup);
        const convertedValue = this.unitService.convertUnitValueToUnit({
          unit: internalUnit,
          value: value
        }, unit);
    
        return this.unitService.formatUnitValueArgs(convertedValue.value, unit);
    }
}
