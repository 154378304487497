import { DeckingDesignService } from './../../decking-design/decking-design.service';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AreaModel } from '../../../entities/decking-design/area-model';
import { ZoneModel } from '../../../entities/decking-design/zone-model';

@Injectable({
    providedIn: 'root'
})
export class DeckingZonesResultsService {
    public areResultsExpanded$: Observable<boolean>;

    private readonly areExpandedActionSubject: BehaviorSubject<boolean>;

    constructor(
        private deckingDesignService: DeckingDesignService
    ) {
        this.areExpandedActionSubject = new BehaviorSubject<boolean>(false);
        const zoneCalculated$: Observable<boolean> = this.deckingDesignService.currentArea$.pipe(
            map((a: AreaModel) => {
                return a.zones.find((z: ZoneModel) => z.result.isDesignCorrect !== null) !== undefined;
            }),
            distinctUntilChanged()
        );

        this.areResultsExpanded$ = merge(this.areExpandedActionSubject, zoneCalculated$);
    }

    public expandResults(): void {
        this.areExpandedActionSubject.next(true);
    }

    public collapseResults(): void {
        this.areExpandedActionSubject.next(false);
    }
}
