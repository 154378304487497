<ng-container *ngIf="isDesign; else isSubstitution">
    <app-designer-layout>

        <design-section-wrapper designer-layout-left-tabs-wrapper></design-section-wrapper>
        <decking-design-areas-wrapper designer-layout-left-tabs-wrapper></decking-design-areas-wrapper>

        <app-main-top designer-layout-top></app-main-top>

        <design-main-center designer-layout-center></design-main-center>

        <design-main-bottom designer-layout-bottom></design-main-bottom>
        <design-main-right designer-layout-right></design-main-right>
    </app-designer-layout>
</ng-container>
<ng-template #isSubstitution>
    <!-- substitution layout -->
    <app-designer-layout>
        <substitution-main-top designer-layout-top></substitution-main-top>
        <design-section-wrapper designer-layout-left-tabs-wrapper></design-section-wrapper>
        <substitution-areas-wrapper designer-layout-left-tabs-wrapper></substitution-areas-wrapper>
        <substitution-zones designer-layout-center></substitution-zones>
        <substitution-main-right designer-layout-right></substitution-main-right>
    </app-designer-layout>
</ng-template>
