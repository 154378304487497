import { RelevantLoads } from '../../enums/relevant-loads';
export class RelevantLoadsConverter {
    static getRelevantLoadFromString(index: number): RelevantLoads {
        switch (index) {
            case 0:
                return RelevantLoads.seismic;
            case 1:
                return RelevantLoads.wind;
            default:
                return RelevantLoads.other;
        }
    }
}
