import { Injectable } from '@angular/core';
import { NumberServiceBaseInjected } from '@profis-engineering/pe-ui-common/services/number.common';

export enum NumberType {
    integer,
    real
}

@Injectable({
    providedIn: 'root'
})
export class NumberService extends NumberServiceBaseInjected { }
