<div class="fastener-table-row">
    <div class="table-body-header"><div class="text-header">{{deckingZone.name.value}}</div></div>
    <div class="table-body-cell">
        <decking-numeric-text-box
            SelectTextOnClick
            [attr.id]="'zoneArea-' + index"
            class="name-input clip"
            [showStepper]="false"
            hideLabel="true"
            [unit]="shownAreaUnit$ | async"
            [minValue]="0"
            [maxValue]="92903040000"
            [display]="numericTextBoxDisplay"
            [textAlign]="numericTextBoxAlign"
            [value] = "deckingZone.fastenerEstimation.area.value"
            (valueChange)="deckingZone.fastenerEstimation.area.value=$any($event).detail; updateZone();"
            [disabled]="saving$ | async"
            [required]="true"
            [precision]="2">
        </decking-numeric-text-box>
    </div>
    <div class="table-body-cell">
        <decking-numeric-text-box
            SelectTextOnClick
            [attr.id]="'zonePerimeter-' + index"
            class="name-input clip"
            [showStepper]="false"
            [display]="numericTextBoxDisplay"
            [textAlign]="numericTextBoxAlign"
            hideLabel="true"
            [unit]="shownLengthUnit$ | async"
            [minValue]="0"
            [value] = "deckingZone.fastenerEstimation.perimeterSpacing.value"
            (valueChange)="deckingZone.fastenerEstimation.perimeterSpacing.value=$any($event).detail; updateZone();"
            [disabled]="saving$ | async"
            [required]="true">
        </decking-numeric-text-box>
    </div>
    <div class="table-body-cell"><div class="label-text">{{deckingZone.fastenerEstimation.frameFasteners.value}}</div></div>
    <div class="table-body-cell"><div class="label-text">{{deckingZone.fastenerEstimation.sidelapConnectors.value}}</div></div>
</div>