import { Injectable } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { DeckingSubstitution } from '../../../../entities/decking-substitution/decking-substitution';
import { DeckingBaseSubstitutionCalculationsService } from '../common/decking-base-substitution-calculations.service';

@Injectable({
  providedIn: 'root'
})
export class DeckingSpecificationCalculationService extends DeckingBaseSubstitutionCalculationsService<DeckingSubstitution> {
  protected getSubstitutionId(calculationInput: DeckingSubstitution): string {
    return calculationInput.id;
  }

  protected buildTriggerCalculationObservable(): Observable<DeckingSubstitution> {
    return this.deckingSubstitutionService.currentDeckingSubstitution$.pipe(
      filter((substitution => !substitution.saved))
    );
  }

  protected callCalculation(substitution: DeckingSubstitution): void {
    this.deckingSubstitutionSignalRService.specifyDesign(substitution);
  }
}
