<div>
    <div class="dropdown-menu-container" ngbDropdown>
        <button type="button" class="dropdown-menu-button" ngbDropdownToggle>
            <span class="decking-sprite decking-sprite-dots"></span>
        </button>
        <div ngbDropdownMenu>
            <button id="project-and-design-header-right-user-options-settings-button"
                class="dropdown-item-button settings" type="button" (click)="openUserSettings()"
                l10n="Agito.Hilti.Profis3.Main.UserOptions.MyProfile" ngbDropdownItem></button>
            <button id="project-and-design-header-right-user-options-manage-hilti-account-button"
                class="dropdown-item-button manage-hilti-account" type="button" (click)="openManageHiltiAccount()"
                l10n="Agito.Hilti.Profis3.Main.UserOptions.ManageHiltiAccount" ngbDropdownItem></button>
            <button id="project-and-design-header-right-user-options-create-desktop-shortcut"
                class="dropdown-item-button" type="button" (click)="openShortcutIconPopup()"
                l10n="Agito.Hilti.Profis3.Main.UserOptions.CreateDesktopShortcut" ngbDropdownItem></button>
            <button id="project-and-design-header-right-user-options-logout-button" 
                class="dropdown-item-button logout" type="button" (click)="logout()" 
                l10n="Agito.Hilti.Profis3.Main.UserOptions.Logout" ngbDropdownItem></button>
        </div>
    </div>
</div>
