import {
    Component
} from '@angular/core';
import { DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Observable } from 'rxjs';
import { PanelType } from 'src/decking/entities/decking-code-list/enums/panel-type';
import { IDeckingDocument } from 'src/decking/entities/decking-design/decking-document';
import { DesignSettings } from '../../../entities/settings/design-settings';
import { DeckingCodeListService } from '../../../services/decking-code-list/decking-code-list.service';
import { ProductSelectorService } from '../../../services/decking-zones/product-selector/product-selector.service';
import { LocalizationService } from '../../../services/external/localization.service';
import { PatternListItem } from './../../../../decking/entities/decking-code-list/code-list/pattern-list-item';
import { DeckingFieldState } from './../../../../decking/entities/decking-design/decking-field-state';
import { IDeckingUserSettings } from './../../../../decking/entities/settings/decking-user-settings';
import { OfflineService } from './../../../services/external/offline.service';
import { Product } from './product-selector-list/product-selector-list.component';
import { DeckingMainService } from 'src/decking/services/decking-main/decking-main.service';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';

const esr2776Url =
    'https://www.hilti.com/medias/sys_master/documents/hf0/h13/9989949587486/Approval-document-ASSET-DOC-LOC-38.pdf';
const esr2179Url =
    'https://www.hilti.com/medias/sys_master/documents/h91/h21/9989949980702/Approval-document-ASSET-DOC-LOC-52.pdf';
const esr3693Url =
    'https://www.hilti.com/medias/sys_master/documents/h02/h17/9989949653022/Approval-document-ASSET-DOC-LOC-4621933.pdf';

enum esrFrameFasteners {
    ENP19 = 1,
    HSN24 = 21,
    SMD = 20,
    SRT5 = 23,
}

enum esrSideLapConnectors {
    SLC01 = 13,
    SLC02 = 9,
    VSC2 = 20,
    HiltiScrew10 = 2,
    HiltiScrew12 = 3,
    HiltiScrew14 = 4,
    Screw10 = 17,
    Screw12 = 18,
    Screw14 = 19,
    ButtonPunch = 8,
}

@Component({
    template: ''
})
export class BaseProductSelectorComponent {
    public expanded$: Observable<boolean>;
    public defaultExpanded = true;
    public frameFasteners: Product[];
    public sideLapFasteners: Product[];
    public patternItems: PatternListItem[];
    public patternDropdown: DropdownProps<PatternListItem> = {};
    public isLengthImperial = false;
    public designSettings: DesignSettings | IDeckingUserSettings;
    public frameFastenerOnlinePage: string = null;
    public sideLapFastenersOnlinePage: string = null;

    constructor(
        protected deckingCodeListService: DeckingCodeListService,
        protected productSelectorService: ProductSelectorService,
        protected localization: LocalizationService,
        protected offlineService: OfflineService,
        protected deckingMainService: DeckingMainService
    ) {}

    public toggleClicked(expanded: boolean): void {
        this.productSelectorService.setExpanded(expanded);
    }

    public openExternalUrl(url: string): void {
        this.offlineService.nativeExternalLinkOpen(url);
    }

    public getFrameFastenerOnlinePage(
        frameFastener: DeckingFieldState<number, string>
    ): string {
        if (!frameFastener || !this?.frameFasteners) return null;

        const frameFastenerIndex = this.frameFasteners.findIndex(
            (x) => x.id === frameFastener.id
        );
        if (frameFastenerIndex >= 0)
            return this.frameFasteners[frameFastenerIndex].hiltiOnlinePageUrl;

        return null;
    }

    public getSideLapFastenersOnlinePage(
        sidelapConnector: DeckingFieldState<number, string>
    ): string {
        if (!sidelapConnector || !this?.sideLapFasteners) return null;

        const sideLapFastenerIndex = this.sideLapFasteners.findIndex(
            (x) => x.id === sidelapConnector.id
        );
        if (sideLapFastenerIndex >= 0)
            return this.sideLapFasteners[sideLapFastenerIndex]
                .hiltiOnlinePageUrl;

        return null;
    }

    public getESRDocumentUrl(
        frameFastener: DeckingFieldState<number, string>,
        sidelapConnector: DeckingFieldState<number, string>
    ): string {
        if (!frameFastener || !sidelapConnector) return esr3693Url;

        if (
            frameFastener.id === esrFrameFasteners.ENP19 ||
            frameFastener.id === esrFrameFasteners.HSN24
        ) {
            if (
                sidelapConnector.id === esrSideLapConnectors.SLC01 ||
                sidelapConnector.id === esrSideLapConnectors.SLC02 ||
                sidelapConnector.id === esrSideLapConnectors.VSC2
            )
                return esr2776Url;

            return esr2179Url;
        }
        return esr3693Url;
    }

    public IsViewESREnabled(
        frameFastener: DeckingFieldState<number, string>,
        sidelapConnector: DeckingFieldState<number, string>
    ): boolean {
        if (!frameFastener || !sidelapConnector) return false;

        const enableFrameFasteners = [
            esrFrameFasteners.ENP19,
            esrFrameFasteners.HSN24,
            esrFrameFasteners.SMD,
            esrFrameFasteners.SRT5,
        ];
        const enableSidelapFasteners = [
            esrSideLapConnectors.SLC01,
            esrSideLapConnectors.SLC02,
            esrSideLapConnectors.VSC2,
            esrSideLapConnectors.HiltiScrew10,
            esrSideLapConnectors.HiltiScrew12,
            esrSideLapConnectors.HiltiScrew14,
            esrSideLapConnectors.Screw10,
            esrSideLapConnectors.Screw12,
            esrSideLapConnectors.Screw14,
            esrSideLapConnectors.ButtonPunch,
        ];

        return (
            enableFrameFasteners.includes(frameFastener.id) &&
            enableSidelapFasteners.includes(sidelapConnector.id)
        );
    }

    /*
        Load Patterns Dropdown, Load and Mapping Frame Fastener List and Sidelap Connector List from Decking CodeList Service.
        In Frame Fastener and Sidelap Connector the images are hardcoded because the correct images are not available now.
    */
    public initializeComponents(
        currentAreaDeckPanel: IDeckingDocument<number, string>,
        currentAreaPanelWidth: IDeckingDocument<number, number>,
        currentAreaPanelType: IDeckingDocument<PanelType, string>,
        currentDeckingZonePattern: DeckingFieldState<number, string>
    ) {
        const lengthGeneralUnit = this.designSettings.length.id;
        this.isLengthImperial =
            lengthGeneralUnit === Unit.ft ||
            lengthGeneralUnit === Unit.inch ||
            lengthGeneralUnit === Unit.mi;
        this.patternItems = this.deckingCodeListService.GetPatterns(
            currentAreaDeckPanel.id,
            currentAreaPanelWidth.value
        );

        if (this.patternItems) {
            const fastenersItems =
                this.deckingCodeListService.GetFastenerItems();
            this.patternDropdown = {
                id: 'product-selector-frame-pattern-dropdown',
                items: this.patternItems.map((item) => ({
                    image: this.deckingCodeListService.SetBase64Image(
                        fastenersItems.filter(
                            (p) =>
                                p.panelId === currentAreaDeckPanel.id &&
                                p.fastenerPatternId === item.id
                        )[0]?.image
                    ),
                    text: this.isLengthImperial ? item.pattern : item.patternSI,
                    value: item,
                })),
                selectedValue: this.patternItems.find(
                    (p) => p.id == currentDeckingZonePattern?.id
                ),
            };

            if (this.deckingMainService.getSelectedModeType() == DeckingDesignModeType.DesignMode) {
                this.frameFasteners = this.mapFrameFastenerItems(this.deckingCodeListService, DeckingDesignModeType.DesignMode, currentAreaDeckPanel.id);
            } else if (this.deckingMainService.getSelectedModeType() == DeckingDesignModeType.SubstitutionBuilderMode) {
                this.frameFasteners = this.mapFrameFastenerItems(this.deckingCodeListService, DeckingDesignModeType.SubstitutionBuilderMode, currentAreaDeckPanel.id);
            }

            this.sideLapFasteners = this.deckingCodeListService
                .GetSidelapConnectorsDropdownItems(
                    currentAreaDeckPanel.id,
                    currentAreaPanelType.id
                )
                .filter((item) => item.value != null)
                .map(
                    (item) =>
                        ({
                            id: item.value.id,
                            name: item.value.shortFastenerName,
                            image: `decking-sprite-${
                                item.value.pictureFileUrl ??
                                'no-image-available'
                            }`,
                            selected: false,
                            isHiltiProduct: item.value.isHILTIProduct,
                            hiltiOnlinePageUrl: item.value.hiltiOnlinePageUrl,
                        } as Product)
                )
                .sort(
                    (a, b) =>
                        Number(b.isHiltiProduct) - Number(a.isHiltiProduct)
                );
        }
    }

    private mapFrameFastenerItems(service: DeckingCodeListService, modeType: DeckingDesignModeType, id: number){
        const dropdownItems = modeType === DeckingDesignModeType.DesignMode ?
            service.GetFrameFastenersDropdownItems(id) :
            service.GetSubstitutedFrameFastenersDropdownItems(id);
    
        return dropdownItems
            .filter((item) => item.value != null)
            .map((item) => ({
                id: item.value.id,
                name: item.value.shortFastenerName,
                image: `decking-sprite-${item.value.pictureFileUrl ?? 'no-image-available'}`,
                selected: false,
                isHiltiProduct: item.value.isHILTIProduct,
                hiltiOnlinePageUrl: item.value.hiltiOnlinePageUrl,
            }))
        .sort((a, b) => Number(b.isHiltiProduct) - Number(a.isHiltiProduct));
    }
}
