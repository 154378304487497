import { map, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OptimizeDesign } from '../../../../entities/calculation/optimization/optimize-design';
import { DeckingDesignCalculationService } from '../common/decking-design-calculation.service';

@Injectable({
    providedIn: 'root'
})
export class DeckingDesignOptimizationService extends DeckingDesignCalculationService<OptimizeDesign> {

    private optimizeAction = new Subject<number[]>();

    optimizeAll() {
        this.deckingDesignService.pushUndoStack(true);
        this.optimizeAction.next(this.getZoneIndexesToOptimize());
    }

    optimizeZone(zoneIndex: number) {
        this.deckingDesignService.pushUndoStack(true);
        this.optimizeAction.next([zoneIndex]);
    }

    protected buildTriggerCalculationObservable(): Observable<OptimizeDesign> {
        return this.optimizeAction.pipe(
            withLatestFrom(this.deckingDesignService.currentDeckingDesign$),
            map(([zoneIndex, currentDesign]) => new OptimizeDesign({
                areaIndex: this.deckingDesignService.getCurrentAreaIndex(),
                zoneIndex
            },
                currentDesign.id
            ))
        );
    }

    protected callCalculation(calculationInput: OptimizeDesign): void {
        this.deckingSignalRService.optimizeDesign(calculationInput);
    }

    protected getDesignId(calculationInput: OptimizeDesign): string {
        return calculationInput.designId;
    }

    private getZoneIndexesToOptimize(): number[]{
        const zoneIndexes: number[] = [];
        for (let i = 0; i < this.deckingDesignService.currentArea.zones.length; i++){
            if ( !this.deckingDesignService.currentArea.zones[i].isLocked.value ) {
                zoneIndexes.push(i);
            }
        }
        return zoneIndexes;
    }
}
