<div class="collapsible-container">
    <div [ngClass]="inactiveStyle ? 'app-collapsable-background background-inactive' : 'app-collapsable-background background-active'">
        <div style="flex-grow: 1;">
            <ng-content select=".collapsable-title"></ng-content>
        </div>
        <div *ngIf="!expanded && embeddedTitle">
            <ng-container [ngTemplateOutlet]="embeddedTitle.templateRef"></ng-container>
        </div>
        <div>
            <button class="toggle-button"
                type="button" (click)="toggleClick()">
                <span class="decking-sprite {{expanded ? 'decking-sprite-lines' : 'decking-sprite-lines-expanded'}}"></span>
            </button>
        </div>
    </div>
    <div *ngIf="expanded">
        <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
    </div>
</div>
