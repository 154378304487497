<div id="decking-main-right-container">
    <decking-report-button (buttonClick)="generateReport()" [disabled]="disable$ | async"></decking-report-button>

    <decking-main-right-options [saving]='saving$ | async' [isTemplate]='isTemplate'></decking-main-right-options>

    <decking-results-panel class="space" [currentArea]="currentArea$ | async"></decking-results-panel>

    <div class="zone-design-title space">
        <span id="areaOpt-panelTitle" class="zone-design-text">{{currentZoneLabel$ | async}}</span>
    </div>

    <ng-container *ngIf="currentZone$ | async; let currentZone">
        <decking-utilization-result class="space" 
            [title]="'Agito.Hilti.Profis3.Decking.Results.ZoneDesign.Shear.Title' | l10n:localization.selectedLanguage" 
            [description]="'Agito.Hilti.Profis3.Decking.Results.ZoneDesign.Shear.DiaphragmShear' | l10n:localization.selectedLanguage"
            [percentage]="currentZone?.result.diaphragmShearPercentage.value * 100" 
            [spriteTitle]="'decking-sprite-decking-shear'"
            [spriteDescription]="'decking-sprite-decking-diaphragm-shear'">
            <div utilization class="utilization-content">
                <span class="decking-sprite decking-sprite-decking-shear-stiffness"></span>
                <div class="utilization-description-container">
                    <p class="utilization-description-text" l10n="Agito.Hilti.Profis3.Decking.Results.ZoneDesign.Shear.ShearStiffness"></p>
                    <p>
                        <decking-unit-value-display
                            [value]="currentZone?.result.shearStiffness.value"
                            [unit]="shearStiffnessUnit$ | async"
                        ></decking-unit-value-display>
                    </p>
                </div>
            </div>
        </decking-utilization-result>

        <decking-utilization-result class="space" 
            [title]="'Agito.Hilti.Profis3.Decking.Results.ZoneDesign.Tension.Title' | l10n:localization.selectedLanguage" 
            [description]="'Agito.Hilti.Profis3.Decking.Results.ZoneDesign.Tension.Uplift'  | l10n:localization.selectedLanguage"
            [percentage]="currentZone?.result.upliftPercentage.value * 100" 
            [spriteTitle]="'decking-sprite-decking-tension'"
            [spriteDescription]="'decking-sprite-decking-uplift'">
        </decking-utilization-result>
        <ng-container *ngIf="currentArea$ | async; let currentArea">
            <decking-collapsable-up class="space" [defaultExpanded]="true" *ngIf="this.currentArea.deckType.id != deckTypes.SteelroofDeck">
                <div class="collapsable-title collapsable-perimeter-fastener-spacing">
                    <span class="decking-sprite decking-sprite-decking-perimeter-spacing"></span>
                    <span class="messages-title" l10n="Agito.Hilti.Profis3.Decking.Results.ZoneDesign.PerimeterFastenerSpacing.Title"></span>
                </div>
                <ng-template appCollapsableContent>
                    <div class="box-section-content">
                        <div class="box-section-content">
                            <div class="utilization-content">
                                <div class="utilization-description-container">
                                    <p class="utilization-description-text" l10n="Agito.Hilti.Profis3.Decking.Results.ZoneDesign.PerimeterFastenerSpacing.AlongPanelEdge"></p>
                                    <p>
                                        <decking-unit-value-display
                                            [value]="alongPanelEdge ?? 0"
                                            [unit]="lengthUnitFt"
                                            [appendUnit]="false"
                                            [precision]="2"
                                            [toFixed]="true">
                                        </decking-unit-value-display>/{{lengthStringUnitFt}}
                                    </p>
                                </div>
                            </div>
                            <div class="utilization-content">
                                <div class="utilization-description-container">
                                    <p class="utilization-description-text" l10n="Agito.Hilti.Profis3.Decking.Results.ZoneDesign.PerimeterFastenerSpacing.AlongPanelEnd"></p>
                                    <p>
                                        <decking-unit-value-display
                                            [value]="alongPanelEdge ?? 0"
                                            [unit]="lengthUnitFt"
                                            [appendUnit]="false"
                                            [precision]="2"
                                            [toFixed]="true">
                                        </decking-unit-value-display>/{{lengthStringUnitFt}}
                                        =>
                                        <decking-unit-value-display
                                            [value]="alongPanelEnd ?? 0"
                                            [unit]="lengthUnitFt"
                                            [appendUnit]="false"
                                            [precision]="2"
                                            [toFixed]="true">
                                        </decking-unit-value-display>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </decking-collapsable-up>
        </ng-container>
    </ng-container>
    <decking-main-right-messages [messages]="messages$ | async" [saving]="saving$ | async"></decking-main-right-messages>
</div>
