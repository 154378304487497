import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IDeckingMessage } from 'src/decking/entities/decking-design/decking-message';
import { MessageType } from 'src/decking/entities/enums/message-types';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

@Component({
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
  })
export class BaseDeckingMainRightMessagesComponent {
    @Input()
    saving: boolean;
  
    @Input()
    messages: {
      criticalMessages: IDeckingMessage[];
      scopeCheckMessages: IDeckingMessage[];
      deckCheckMessages: IDeckingMessage[];
    };
  
    // Observable to watch if the current area has at least one verified zone.
    protected isMissingVerifiedZones$: Observable<boolean>;
  
    protected isSteelRoofDeck$: Observable<boolean>;
  
    protected disableDeflectionCalculation$: Observable<boolean>;
  
    protected readonly messageTypes = MessageType;
  
    MessagesWithButton: { Type: MessageType; Title: string; Text: string; ButtonText: string; ButtonClick(): void; disabled$: Observable<boolean> }[];
  
    currentSettingsLengthUnit$: Observable<Unit>;
}