import { Component, ElementRef, OnInit } from '@angular/core';
import { SubstitutionZoneModel } from '../../../../../../entities/decking-substitution/substitution-zone';
import { DeckingSubstitutionCalculationService } from '../../../../../../services/decking-design/calculation/substitution/decking-substitution-calculation.service';
import { DeckingSubstitutionService } from '../../../../../../services/decking-design/decking-substitution.service';
import { DeckingCommonZonesRowActionButtonsComponent } from './../../../../../../components/decking-main-bottom/decking-zones-common/decking-common-zones-row-action-buttons.component';
import { LocalizationService } from './../../../../../../services/external/localization.service';
import { ModalService } from './../../../../../../services/external/modal.service';

@Component({
    templateUrl: './decking-substitution-zones-row-action-buttons.component.html',
    styleUrls: ['./decking-substitution-zones-row-action-buttons.component.scss']
})
export class DeckingSubstitutionZonesRowActionButtonsComponent extends DeckingCommonZonesRowActionButtonsComponent<SubstitutionZoneModel> implements OnInit {
    constructor(
        public deckingSubstitutionCalculationService: DeckingSubstitutionCalculationService,
        public deckingSubstitutionService: DeckingSubstitutionService,
        public override localization: LocalizationService,
        public override modalService: ModalService,
        public override elementRef: ElementRef<HTMLElement>
    ) {
        super(localization, modalService, elementRef);
    }

    public get isSubstituteButtonDisabled(): boolean {
        return this.zone?.zoneSpecified?.deckGauge == null;
    }

    public substitute(): void {
        this.deckingSubstitutionCalculationService.substituteZone(this.index);
    }

    public override deleteZone(): void {
        this.deckingSubstitutionService.deleteZone(this.index);
    }
}
