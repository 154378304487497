import { Injectable } from '@angular/core';
import { Design, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { IDetailedDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { DesignExternalMetaData, DocumentAccessMode, IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { BaseDesign } from '../../entities/decking-design/base-design';
import { Project } from '../../entities/project';

@Injectable({
  providedIn: 'root'
})
export abstract class DeckingDocumentBaseService {
  public abstract get projects(): Record<string, Project>;
  public abstract get draftsProject(): Project;
  public abstract get designsFlat(): Record<string, IDesignListItem>;

  public abstract findDesignById(designId: string): IDesignListItem;
  public abstract findProjectById(projectId: string): Project;
  public abstract createUniqueName(oldName: string, usedNames: string[]): string;
  public abstract addDesign(projectId: string, design: Design, canGenerateUniqueName: boolean, ignoreConflict: boolean, useDeckingDesign: boolean, deckingProject: BaseDesign): Promise<IDesignListItem>;
  public abstract getDeckingDesignContent(designId: string, sessionId: string, documentId: string, isLock: boolean): Promise<any>;
  public abstract openNewSessionForDesign(designId: string): string;
  public abstract updateDocumentDesignContent(document: IDesignListItem, base64Content: string,  unlock: boolean, exclusiveLock: boolean, documentAccessMode: DocumentAccessMode): Promise<void>;
  public abstract updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void>;
  public abstract getSessionKeyForDesign(designId: string): string;
  public abstract getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>>;
  public abstract uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void>;
  public abstract openDesignExclusiveDecking(project: IBaseDesign): Promise<any>;  
  public abstract updateDesignWithNewContent(designId: string, projectId: string, designName: string, contentOverride: any, metadataOverride: DesignExternalMetaData, displayDesign: IDetailedDisplayDesign, unlock?: boolean, exclusiveLock?: boolean, documentAccessMode?: DocumentAccessMode): Promise<void>;
  public abstract findProjectByDesignId(designId: string): Project;
}