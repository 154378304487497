import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { DeckingScene } from '@profis-engineering/decking-gl-model/components/scenes/decking-scene';
import { DeckingGlConstants } from '@profis-engineering/decking-gl-model/components/utils/decking-gl-constants';
import { CheckboxButtonProps } from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    Observable,
    Subscription,
    combineLatest,
    distinctUntilChanged,
    map,
    shareReplay,
} from 'rxjs';
import { AreaListItem } from 'src/decking/entities/decking-code-list/code-list/area-list-item';
import { DeckGaugeListItem } from 'src/decking/entities/decking-code-list/code-list/deck-gauge-list-item';
import { FrameFastenerListItem } from 'src/decking/entities/decking-code-list/code-list/frame-fastener-list-item';
import { PanelWidthListItem } from 'src/decking/entities/decking-code-list/code-list/panel-width-list-item';
import { SidelapConnectorListItem } from 'src/decking/entities/decking-code-list/code-list/sidelap-connector-list-item';
import { ZoneListItem } from 'src/decking/entities/decking-code-list/code-list/zone-list-item';
import { DeckType } from 'src/decking/entities/decking-code-list/enums/deck-type';
import {
    AreaModel,
    AreaSummaryModel,
} from 'src/decking/entities/decking-design/area-model';
import { DeckingFieldState } from 'src/decking/entities/decking-design/decking-field-state';
import { ZoneModel } from 'src/decking/entities/decking-design/zone-model';
import { FieldState } from 'src/decking/entities/enums/field-state';
import { IDecking3DSettings } from 'src/decking/entities/settings/decking-3D-settings';
import { DesignSettings } from 'src/decking/entities/settings/design-settings';
import { Decking3dSettingsService } from 'src/decking/services/decking-3d-settings/decking-3d-settings.service';
import { DeckingCodeListService } from 'src/decking/services/decking-code-list/decking-code-list.service';
import { DeckingDesignVerificationService } from 'src/decking/services/decking-design/calculation/verification/decking-design-verification.service';
import { DeckingDesignService } from 'src/decking/services/decking-design/decking-design.service';
import { DeckingGlModelService } from 'src/decking/services/decking-gl-model/decking-gl-model.service';
import { DeckingUnitsHelperService } from 'src/decking/services/decking-units-helper/decking-units-helper.service';
import { DesignerLayoutService } from 'src/decking/services/designer-layout/designer-layout.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { UnitService } from 'src/decking/services/external/unit.service';

@Component({
    selector: 'design-main-center',
    templateUrl: './decking-main-center.component.html',
    styleUrls: ['./decking-main-center.component.scss'],
})
export class DeckingMainCenterComponent implements OnInit, OnDestroy {
    // Constants
    public readonly suffixElement = '_id';
    public readonly deckGaugeDropDownId =
        DeckingGlConstants.LINE_OPTIONS.DECK_GAUGE + this.suffixElement;
    public readonly frameFastenerDropDownId =
        DeckingGlConstants.LINE_OPTIONS.FASTENER + this.suffixElement;
    public readonly sidelapDropDownId =
        DeckingGlConstants.LINE_OPTIONS.SIDELAP + this.suffixElement;
    public readonly panelWidthDropDownId =
        DeckingGlConstants.LINE_OPTIONS.DECK_WIDTH + this.suffixElement;
    public readonly shearInputTextId =
        DeckingGlConstants.LINE_OPTIONS.SHEAR_Q + this.suffixElement;
    public readonly upliftInputTextId =
        DeckingGlConstants.LINE_OPTIONS.UPLIFT_W + this.suffixElement;
    public readonly shearStiffnessInputTextId =
        DeckingGlConstants.LINE_OPTIONS.STIFFNESS_G + this.suffixElement;
    public readonly beamSpacingInputTextId =
        DeckingGlConstants.LINE_OPTIONS.BEAM_SPACING + this.suffixElement;

    // Common Observables
    public currentArea$: Observable<AreaModel>;
    public isConcreteFill$: Observable<boolean>;
    public isDeckFilled$: Observable<boolean>;
    public zoneSelected$: Observable<ZoneModel>;
    public panelWidthUnit$: Observable<Unit>;
    public lengthGeneralUnit$: Observable<Unit>;
    public forcePerLengthUnit$: Observable<Unit>;
    public stressUnit$: Observable<Unit>;
    public shearStiffnessUnit$: Observable<Unit>;
    public joistBeamSpacingUnit$: Observable<Unit>;
    public isImperialUnit$: Observable<boolean>;
    public currentSettings$: Observable<DesignSettings>;
    public currentDeckingDesignSettings$: Observable<DesignSettings>;
    public saving$: Observable<boolean>;
    public canUndo$: Observable<boolean>;
    public canRedo$: Observable<boolean>;

    public currentAreasSummary$: Observable<AreaSummaryModel[]>;

    // Zone Items Observables
    public deckGaugeItems$: Observable<DropdownItem<DeckGaugeListItem>[]>;
    public frameItems$: Observable<DropdownItem<FrameFastenerListItem>[]>;
    public sidelapItems$: Observable<DropdownItem<SidelapConnectorListItem>[]>;

    // Area Items Osbervables
    public panelWidthItems$: Observable<DropdownItem<PanelWidthListItem>[]>;

    public currentZone: ZoneModel;
    public currentZoneIndex: number;
    public currentArea: AreaModel;
    public currentAreaIndex: number;
    public percentageUnit: Unit = Unit.percent;
    public currentZoomPercentage = 50;
    public cssClassCanvas: string;
    public settingsCheckbox: Pick<
        CheckboxButtonProps<number>,
        'selectedValues' | 'items'
    >;
    public htmlElement: HTMLElement;
    public deckingScene: DeckingScene;
    public designAreaItems: DropdownItem<AreaListItem>[] = [];
    public designCurrentAreaZoneItems: DropdownItem<ZoneListItem>[] = [];
    public areasSummary: AreaSummaryModel[];

    @ViewChild('helperContainer', { static: true })
    helperContainerRef: ElementRef;
    @ViewChild('canvasContainer', { static: true })
    canvasContainerRef: ElementRef;
    private documentClickListener: (event: MouseEvent) => void;

    public settingsInputMapping;

    isSettings3dLoading = false;

    private subscriptions = new Subscription();

    constructor(
        public designerLayoutService: DesignerLayoutService,
        public deckingDesignService: DeckingDesignService,
        public deckingGlModelService: DeckingGlModelService,
        private deckingDesignVerificationService: DeckingDesignVerificationService,
        private localizationService: LocalizationService,
        private decking3dSettingsService: Decking3dSettingsService,
        private deckingCodeListService: DeckingCodeListService,
        private deckingUnitsHelperService: DeckingUnitsHelperService,
        private unitService: UnitService
    ) {
        this.settingsInputMapping = {
            transparentConcreteFill: 1,
            sidelapDimensions: 2,
            loads: 3,
            inputs: 4,
            deckProperties: 5,
        };

        this.settingsCheckbox = {
            items: [
                {
                    value: 1,
                    text: this.localizationService.getString( 'Agito.Hilti.Profis3.Decking.DisplayOptions.TransparentConcreteFill'), // "Transparent concrete fill"
                },
                {
                    value: 2,
                    text: this.localizationService.getString('Agito.Hilti.Profis3.Decking.DisplayOptions.SidelapDimensions'), // "Sidelap Dimensions"
                },
                {
                    value: 3,
                    text: this.localizationService.getString('Agito.Hilti.Profis3.Decking.DisplayOptions.Loads'), // "Loads"
                },
                {
                    value: 4,
                    text: this.localizationService.getString('Agito.Hilti.Profis3.Decking.DisplayOptions.Input'), // "Input"
                },
                {
                    value: 5,
                    text: this.localizationService.getString('Agito.Hilti.Profis3.Decking.DisplayOptions.DeckProperties'), // "Deck properties"
                },
            ],
            selectedValues: this.load3dSettings(),
        };

        this.cssClassCanvas = 'center-middle-container';
    }

    ngOnInit(): void {
        this.htmlElement = this.helperContainerRef.nativeElement;
        const canvasContainer = this.canvasContainerRef.nativeElement;
        if (canvasContainer) {
            this.onZoomChange = this.onZoomChange.bind(this);
            this.deckingGlModelService.setZoomChangeEvent(this.onZoomChange);
            this.deckingScene = this.deckingGlModelService.createGLModel3D(
                this.deckingDesignService.getCurrentDesign().settings,
                this.deckingDesignService.currentArea,
                this.deckingDesignService.currentZone,
                'gl-model',
                canvasContainer
            );
            this.deckingScene.setHtmlElement(
                this.htmlElement
            );
            this.documentClickListener = this.onDocumentClick.bind(this);
            document.addEventListener('click', this.documentClickListener);
        }
        this.currentDeckingDesignSettings$ =
            this.deckingDesignService.currentSettings$;
        this.saving$ = this.deckingDesignVerificationService.saving$;
        this.currentArea$ = this.deckingDesignService.currentArea$;
        this.isConcreteFill$ = this.currentArea$.pipe(
            map(
                (currentArea) =>
                    currentArea.deckType.id === DeckType.ConcreteFilledDeck
            ),
            distinctUntilChanged()
        );
        this.isDeckFilled$ = this.deckingDesignService.currentArea$.pipe(
            map((currentArea) => currentArea.deckFill.value === 'Agito.Hilti.PE.Decking.CalculationService.DeckFill.NoFill'),
            distinctUntilChanged(),
            shareReplay(1)
        );
        this.zoneSelected$ = this.deckingDesignService.currentZone$;
        this.currentAreasSummary$ = this.deckingDesignService.currentAreasSummary$;
        this.canRedo$ = this.deckingDesignService.canRedo$;
        this.canUndo$ = this.deckingDesignService.canUndo$;
        this.setCanvasCSSClass();
        this.currentSettings$ = this.deckingDesignService.currentSettings$;
        this.subscriptions.add(
            this.currentAreasSummary$.subscribe((summary) => {
                this.areasSummary = summary;
                this.setAreaDropDownItems();
            })
        );
        this.subscriptions.add(
            this.zoneSelected$.subscribe((zone) => {
                this.currentZone = zone;
                this.currentZoneIndex = this.deckingDesignService.getZoneCurrentIndex(zone);
            })
        );
        this.subscriptions.add(
            this.currentArea$.subscribe((area) => {
                this.currentArea = area;
                this.currentAreaIndex = this.deckingDesignService.getCurrentAreaIndex();
                this.setZoneDropDownItems();
            })
        );
        this.initUnits();
        this.initDropDowns();
        this.settingsCheckboxItemToggle();
        
    }

    ngOnDestroy(): void {
        this.deckingGlModelService.dispose();
        this.subscriptions.unsubscribe();
        document.removeEventListener('click', this.documentClickListener);
    }

    onEnterKeyDown(elementId: string) {
        const select: HTMLElement = this.htmlElement.querySelector(
            `#${elementId}`
        );
        select.style.display = 'none';
    }

    public valueSelected<TId, TValue>( item: DeckingFieldState<TId, TValue> ): void {
        this.htmlElement.style.display = 'none';
        if (item != null) {
            item.fieldState = FieldState.Selected;
        }
        this.valueChanged();
    }

    public valueChanged(): void {
        this.deckingDesignService.updateZone( this.currentZone, this.deckingDesignService.getZoneCurrentIndex(this.currentZone));
    }

    public updateAreas() {
        this.deckingDesignService.updateCurrentArea(this.currentArea, true, false);
    }

    public onPanelWidthSelected(): void {
        this.deckingDesignService.updatePanelWidth(this.currentArea);
    }

    public toggleRightColumnVisibility(): void {
        this.designerLayoutService.rightColumnVisible =
            !this.designerLayoutService.rightColumnVisible;
        this.setCanvasCSSClass();
    }

    public toggleLeftColumnVisibility(): void {
        this.designerLayoutService.leftColumnVisible =
            !this.designerLayoutService.leftColumnVisible;
        this.setCanvasCSSClass();
    }

    public undo(): void {
        this.deckingDesignService.undoChange();
    }

    public redo(): void {
        this.deckingDesignService.redoChange();
    }

    public load3dSettings(decking3DSettings: IDecking3DSettings = this.decking3dSettingsService.settings3d): Set<number> {
        const selectedSettings = new Set<number>();
        for (const keyIndex in this.settingsInputMapping) {
            const key = keyIndex as keyof typeof this.settingsInputMapping;
            const designSetting = !!decking3DSettings[key];
            if (designSetting) {
                selectedSettings.add(this.settingsInputMapping[key]);
            }
        }
        return selectedSettings;
    }

    public async settingsCheckboxItemToggle(): Promise<void> {
        this.isSettings3dLoading = true;
        const new3dSettings: IDecking3DSettings = {
            sidelapDimensions: this.settingsCheckbox.selectedValues.has(this.settingsInputMapping.sidelapDimensions),
            transparentConcreteFill: this.settingsCheckbox.selectedValues.has(this.settingsInputMapping.transparentConcreteFill),
            loads: this.settingsCheckbox.selectedValues.has(this.settingsInputMapping.loads),
            inputs: this.settingsCheckbox.selectedValues.has(this.settingsInputMapping.inputs),
            deckProperties: this.settingsCheckbox.selectedValues.has(this.settingsInputMapping.deckProperties),
        };
        await this.decking3dSettingsService.setSettings3d(new3dSettings);
        this.isSettings3dLoading = false;
    }

    public resetCamera(): void {
        this.deckingGlModelService.resetCamera();
    }

    public fitToScreen(): void {
        this.deckingGlModelService.fitToScreen();
    }

    public zoomPercentageChanged(percentage: number): void {
        this.currentZoomPercentage=percentage;
        this.deckingGlModelService.cameraZoom(percentage);
    }

    private onZoomChange(zoom: number) {
        this.currentZoomPercentage = Math.round(zoom);
        this.htmlElement.style.display = 'none';
    }

    public onSelectedAreaChange(area: AreaListItem) {
        this.deckingDesignService.setCurrentArea(area.index);
    }

    public onSelectedZoneChange(zone: ZoneListItem) {
        this.deckingDesignService.setCurrentZone(zone.index);
    }

    private setCanvasCSSClass(): void {
        if (
            !this.designerLayoutService.leftColumnVisible &&
            !this.designerLayoutService.rightColumnVisible
        ) {
            this.cssClassCanvas = 'center-middle-container-full';
        } else if (
            this.designerLayoutService.leftColumnVisible &&
            this.designerLayoutService.rightColumnVisible
        ) {
            this.cssClassCanvas = 'center-middle-container';
        } else if (
            !this.designerLayoutService.leftColumnVisible &&
            this.designerLayoutService.rightColumnVisible
        ) {
            this.cssClassCanvas = 'center-middle-container-right';
        } else if (
            this.designerLayoutService.leftColumnVisible &&
            !this.designerLayoutService.rightColumnVisible
        ) {
            this.cssClassCanvas = 'center-middle-container-left';
        }
        this.deckingGlModelService.resize();
    }

    private initDropDowns(): void {
        const panelId$ = this.currentArea$.pipe(
            map((a) => a.deckPanel.id),
            distinctUntilChanged()
        );
        const panelType$ = this.currentArea$.pipe(
            map((a) => a.panelType.id),
            distinctUntilChanged()
        );
        this.deckGaugeItems$ = panelId$.pipe(
            map((panelId) =>
                this.deckingCodeListService.GetDeckGaugesDropdownItems(panelId)
            ),
            shareReplay(1)
        );
        this.frameItems$ = panelId$.pipe(
            map((panelId) =>
                this.deckingCodeListService.GetFrameFastenersDropdownItems(
                    panelId
                )
            ),
            shareReplay(1)
        );
        this.sidelapItems$ = combineLatest([panelId$, panelType$]).pipe(
            map(([panelId, panelType]) =>
                this.deckingCodeListService.GetSidelapConnectorsDropdownItems(
                    panelId,
                    panelType
                )
            ),
            shareReplay(1)
        );
        this.initPanelWidthDropDownItems();
    }

    private initUnits(): void {
        this.isImperialUnit$ = this.currentSettings$.pipe(
            map((s) => s.length.id),
            distinctUntilChanged(),
            map((unitValue: Unit) => {
                return !this.deckingUnitsHelperService.isInternationalSystemUnit(
                    unitValue
                );
            })
        );
        this.lengthGeneralUnit$ = this.currentDeckingDesignSettings$.pipe(
            map((settings) => settings.length.id),
            distinctUntilChanged()
        );
        this.forcePerLengthUnit$ = this.currentSettings$.pipe(
            map((s) => s.forcePerLength.id),
            distinctUntilChanged()
        );
        this.shearStiffnessUnit$ = this.currentSettings$.pipe(
            map((s) => s.shearStiffness.id),
            distinctUntilChanged()
        );
        this.stressUnit$ = this.currentSettings$.pipe(
            map((s) => s.stress.id),
            distinctUntilChanged()
        );
        this.joistBeamSpacingUnit$ = this.lengthGeneralUnit$.pipe(
            map((unitStringValue) => {
                return this.deckingUnitsHelperService.getBeamSpacingUnit(
                    unitStringValue
                );
            })
        );
        this.panelWidthUnit$ = this.lengthGeneralUnit$.pipe(
            map((lengthGeneralUnit) => {
                return this.deckingUnitsHelperService.getStandardLengthUnit(
                    lengthGeneralUnit
                );
            })
        );
    }

    private setAreaDropDownItems(): void {
        this.designAreaItems = this.areasSummary.map<
            DropdownItem<AreaListItem>
        >((area, index) => {
            return {
                text: area.name.value,
                value: {
                    id: area.id,
                    index: index,
                    value: area.id,
                },
                index: index,
            };
        });
    }

    private setZoneDropDownItems(): void {
        this.designCurrentAreaZoneItems = this.currentArea.zones.map<
            DropdownItem<AreaListItem>
        >((zone, index) => {
            return {
                text: zone.name.value,
                value: {
                    id: zone.id,
                    index: index,
                    value: zone.id,
                },
                index: index,
            };
        });
    }

    private initPanelWidthDropDownItems(): void {
        const panelId$ = this.currentArea$.pipe(
            map((a) => a.deckPanel.id),
            distinctUntilChanged()
        );
        this.panelWidthItems$ = combineLatest([
            panelId$,
            this.panelWidthUnit$,
        ]).pipe(
            map(([panelId, panelWidthUnit]) => {
                const panelWidthItems =
                    this.deckingCodeListService.GetPanelWidthDropdownItems(
                        panelId,
                        panelWidthUnit
                    );
                return panelWidthItems.map<DropdownItem<PanelWidthListItem>>(
                    (i) => {
                        return {
                            text: `${i.text} ${this.unitService.formatUnit(
                                panelWidthUnit
                            )}`,
                            value: i.value,
                        };
                    }
                );
            })
        );
    }

    private onDocumentClick(event: MouseEvent): void {
        const clickedElement = event.target as HTMLElement;
        const isClickedInsideCanvas =
            this.canvasContainerRef.nativeElement.contains(clickedElement);
        const isClickedInsideInputs =
            this.helperContainerRef.nativeElement.contains(clickedElement);

        if (!isClickedInsideCanvas && !isClickedInsideInputs) {
            this.deckingScene.resetHtmlElements();
        }
    }
}
