import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonUserSettingsService } from './../../../services/external/common-user-settings.service';
import { LocalizationService } from './../../../services/external/localization.service';
import { UserService } from './../../../services/external/user.service';
import { LicenseService } from 'src/decking/services/external/license.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {

  @Input() designTitle: string;

  @Input() settingsButtonHidden: boolean;

  @Input() showSaveAsTemplateButton: boolean;

  @Output() settingsButtonClick = new EventEmitter();

  @Output() saveAsTemplateButtonClick = new EventEmitter();

  public readonly MAX_LENGTH_DESIGN_NAME: number = 150;
  public isPremiumUser = false;
  public projectTemplateTooltipMessage : string;

  constructor(
    private userService: UserService,
    private userSettingsService: CommonUserSettingsService,
    public localizationService: LocalizationService,
    public licenseService: LicenseService
  ) { 
    this.isPremiumUser = this.licenseService.getLicense() === 'PREMIUM';
    this.projectTemplateTooltipMessage = 'Agito.Hilti.Profis3.Main.SaveAsTemplate';
    if (!this.isPremiumUser) {
      this.projectTemplateTooltipMessage = 'Agito.Hilti.Profis3.Features.Application.DesignTemplate.Tooltip';
    }
  }

  public getDesignName(): string {
    return this.designTitle.length > this.MAX_LENGTH_DESIGN_NAME ? `${this.designTitle.substring(0,this.MAX_LENGTH_DESIGN_NAME)}...` : this.designTitle;
  }

  public get userName() {
    return this.userSettingsService.settings.user.generalName.value?.trim()
                ? this.userSettingsService.settings.user.generalName.value
                : this.userService.getUserName();
  }
}
