<!-- @ngIgnoreWarnings: invalid_assignment -->

<!-- Active -->
<td>
    <pe-radio-button
        *ngIf="selectedLoadItems"
        [id]="'radio-button-zonerow-' + index"
        [disabled]="saving"
        [items]="selectedLoadItems"
        [selectedValue]="isSelected$ | async"
        (selectedValueChange)="selectCurrentZone()"
    >
    </pe-radio-button>
</td>

<!-- Name -->
<td class="input-cell">
    <pe-text-box
        SelectTextOnClick
        [id]="'zone-name-input-' + index"
        [marginBottom]="'None'"
        [disabled]="saving"
        [value]="zone.name.value"
        [updateOnBlur]="true"
        (valueChange)="zone.name.value=$any($event).detail;valueChanged(false)"
        [maxLength]="60"
        [display]="(isSelected$ | async )? 'Bold' : 'Normal' "
    ></pe-text-box>
</td>

<!-- Type -->
<td class="input-cell">
    <div id="type_buttons_container" class="type-buttons">
        <button
            type="button"
            class="type-button btn btn-default wind-load"
            [class.active]="zone.relevantLoads.value === relevantLoads.wind"
            [disabled]="saving || (isRelevantLoadAtZoneLevelSetting$ | async) == false"
            (click)="setZoneType(relevantLoads.wind)"
            [attr.id]="'zones-type-wind-button-' + index"
        >
            <span class="decking-sprite decking-sprite-wind-icon"></span>
        </button>
        <button
            type="button"
            class="type-button btn btn-default"
            [class.active]="zone.relevantLoads.value === relevantLoads.seismic"
            [disabled]="saving || (isRelevantLoadAtZoneLevelSetting$ | async) == false"
            (click)="setZoneType(relevantLoads.seismic)"
            [attr.id]="'zones-type-seismic-button-' + index"
        >
            <span class="decking-sprite decking-sprite-seismic-icon"></span>
        </button>
    </div>
</td>

<!-- S -->
<td *ngIf="zoneSpacing" class="input-cell">
    <pe-numeric-text-box
        SelectTextOnClick
        [id]="'S-input-' + index"
        [value]="zone.s.value"
        [showStepper]="false"
        [updateOnBlur]="true"
        [required]="true"
        [unit]="lengthUnit"
        [minValue]="304.8"
        [maxValue]="18288"
        [disabled]="saving"
        (valueChange)="zone.s.value=$any($event).detail;valueChanged()"
    ></pe-numeric-text-box>
</td>

<!-- Q -->
<td class="input-cell">
    <pe-numeric-text-box
        SelectTextOnClick
        [id]="'Q-input-' + index"
        [value]="zone.q.value"
        [showStepper]="false"
        [updateOnBlur]="true"
        [required]="true"
        [unit]="forcePerLengthUnit"
        [minValue]="0"
        [maxValue]="87.5633954605"
        [disabled]="saving"
        (valueChange)="zone.q.value=$any($event).detail;valueChanged()"
        [precision]="2"
    ></pe-numeric-text-box>
</td>

<!-- W -->
<td class="input-cell" [class.d-none]="(isRequiredUpliftSubmittalSetting$ | async) == false">
    <pe-numeric-text-box
        SelectTextOnClick
        [id]="'W-input-' + index"
        [value]="zone.w.value"
        [showStepper]="false"
        [updateOnBlur]="true"
        [required]="true"
        [unit]="stressUnit"
        [minValue]="0"
        [maxValue]="0.07182000000000001"
        (valueChange)="zone.w.value=$any($event).detail;valueChanged()"
        [disabled]="
            (saving || deckType === deckTypes.ConcreteFilledDeck && ((isDeckFilled$ | async ) === false))"
        [precision]="2"
    >
    </pe-numeric-text-box>
</td>

<!-- G -->
<td class="input-cell" [class.d-none]="(isRequiredShearStiffnessSetting$ | async) == false">
    <pe-numeric-text-box
        SelectTextOnClick
        [id]="'G-input-' + index"
        [value]="zone.g.value"
        [showStepper]="false"
        [updateOnBlur]="true"
        [required]="true"
        [unit]="shearStiffnessUnit"
        (valueChange)="zone.g.value=$any($event).detail;valueChanged()"
        [disabled]="saving"
        [minValue]="0"
        [maxValue]="35025.367064830236"
        [precision]="2"
    >
    </pe-numeric-text-box>
</td>

<!-- DETAILS -->
<!-- Gauge -->
<td class="details input-cell"
[ngClass]="getClassByFieldState($any(zone).deckGauge)">
    <decking-bootstrap-dropdown
        [id]="'deckgauge-dropdown-' + index"
        [items]="deckGaugeItems"
        [(selectedValue)]="$any(zone).deckGauge"
        (selectedValueChange)="valueSelected($any(zone).deckGauge)"
        [disabled]="saving"
        [container]="'body'"
        [placement]="'top-left'"
        [placeholder]="placeholderText"
    >
    </decking-bootstrap-dropdown>
</td>

<!-- Pattern -->
<td class="details input-cell"
[ngClass]="getClassByFieldState($any(zone).pattern)">
    <decking-bootstrap-dropdown
        [id]="'pattern-dropdown-' + index"
        [items]="$any(patternItems)"
        [disabled]="saving"
        [(selectedValue)]="$any(zone).pattern"
        (selectedValueChange)="valueSelected($any(zone).pattern)"
        [container]="'body'"
        [placement]="'top-left'"
        [placeholder]="placeholderText"
    >
    </decking-bootstrap-dropdown>
</td>

<!-- Frame Fastener -->
<td class="details input-cell"
[ngClass]="getClassByFieldState($any(zone).frameFastener)">
    <decking-bootstrap-dropdown
        [id]="'framefastener-dropdown-' + index"
        [disabled]="saving"
        [items]="$any(frameItems)"
        [(selectedValue)]="$any(zone).frameFastener"
        (selectedValueChange)="valueSelected($any(zone).frameFastener)"
        [container]="'body'"
        [placement]="'top-left'"
        [placeholder]="placeholderText"
    >
    </decking-bootstrap-dropdown>
</td>

<!-- Sidelap Connector -->
<td class="details input-cell"
[ngClass]="getClassByFieldState($any(zone).sidelapConnector)">
    <decking-bootstrap-dropdown
        [id]="'sidelapconnector-dropdown-' + index"
        [items]="$any(sidelapItems)"
        [disabled]="saving"
        [(selectedValue)]="$any(zone).sidelapConnector"
        (selectedValueChange)="valueSelected($any(zone).sidelapConnector)"
        [container]="'body'"
        [placement]="'top-left'"
        [placeholder]="placeholderText"
    >
    </decking-bootstrap-dropdown>
</td>

<!-- Side spacing -->
<td class="details input-cell"
[ngClass]="getClassByFieldState($any(zone).side)">
    <design-zone-side
        [id]="'spacing-dropdown-' + index"
        [cssClass]="getClassByFieldState($any(zone).side)"
        [notSelectedText]="' '"
        [saving]="saving"
        [currentZone]="$any(zone)"
        (valueChanged)="valueSelected($any(zone).side)"
        [container]="'body'"
        [placement]="'top-left'"
    >
    </design-zone-side>
</td>

<!-- RESULTS -->
<!-- Q Load -->
<td [class.d-none]="!areResultsExpanded"
[ngClass]="getClassByCalculationState()">
    <decking-unit-value-display
        *ngIf="!hasCriticalScopeChecks; else placeholder"
        [id]="'Q-results-' + index"
        [value]="zone.result.qLoad.value"
        [appendUnit]="true"
        [unit]="forcePerLengthUnit"
    ></decking-unit-value-display>
</td>

<!-- W Load -->
<td [class.d-none]="!areResultsExpanded" *ngIf="(isRequiredUpliftSubmittalSetting$ | async)"
[ngClass]="getClassByCalculationState()">
    <decking-unit-value-display
        *ngIf="!hasCriticalScopeChecks; else placeholder"
        [id]="'W-results-' + index"
        [value]="zone.result.wLoad.value"
        [appendUnit]="true"
        [unit]="stressUnit"
    ></decking-unit-value-display>
</td>

<!-- G Load -->
<td [class.d-none]="!areResultsExpanded" *ngIf="(isRequiredShearStiffnessSetting$ | async)"
[ngClass]="getClassByCalculationState()">
    <decking-unit-value-display
        *ngIf="!hasCriticalScopeChecks; else placeholder"
        [id]="'G-results-' + index"
        [value]="zone.result.gLoad.value"
        [appendUnit]="true"
        [unit]="shearStiffnessUnit"
    ></decking-unit-value-display>
</td>

<!-- Action buttons -->
<td id="action_buttons_cell">
    <decking-zones-row-action-buttons
        [zone]="zone"
        [index]="index"
        [saving]="saving"
        [canDeleteZone]="canDeleteZone"
    ></decking-zones-row-action-buttons>
</td>

<!-- Failing Scope Check placeholder -->
<ng-template #placeholder>
    <span>-</span>
</ng-template>