import { Component, ElementRef, OnInit } from '@angular/core';
import { DeckingDesignOptimizationService } from './../../../../../services/decking-design/calculation/optimization/decking-design-optimization.service';
import { DeckingDesignService } from './../../../../../services/decking-design/decking-design.service';
import { LocalizationService } from './../../../../../services/external/localization.service';
import { ModalService } from './../../../../../services/external/modal.service';
import { DeckingCommonZonesRowActionButtonsComponent } from '../../../decking-zones-common/decking-common-zones-row-action-buttons.component';
import { ZoneModel } from '../../../../../entities/decking-design/zone-model';

@Component({
    templateUrl: './decking-zones-row-action-buttons.component.html',
    styleUrls: ['./decking-zones-row-action-buttons.component.scss']
})
export class DeckingZonesRowActionButtonsComponent extends DeckingCommonZonesRowActionButtonsComponent<ZoneModel> implements OnInit {
    constructor(
        public deckingDesignOptimizationService: DeckingDesignOptimizationService,
        public deckingDesignService: DeckingDesignService,
        public override localization: LocalizationService,
        public override modalService: ModalService,
        public override elementRef: ElementRef<HTMLElement>
    ) {
        super(localization, modalService, elementRef);
    }

    public optimize(): void {
        this.deckingDesignOptimizationService.optimizeZone(this.index);
    }

    public override deleteZone(): void {
        this.deckingDesignService.deleteZone(this.index);
    }
}
