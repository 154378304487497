import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Pipe({
    name: 'basesidelapConnectorTitle',
    pure: false,
})
export class BaseSidelapConnectorTitle implements PipeTransform, OnDestroy {
    public isBySpacing$: Observable<boolean>;
    public connectorSpacingTitle = '';
    public unsubscribe$ = new Subject<void>();

    transform(translationKey: string): string {
        if (!translationKey) {
            return '';
        }

        return translationKey + this.connectorSpacingTitle;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
