import { IBaseDesign, Design, IDesignStateBase } from '@profis-engineering/pe-ui-common/entities/design';
import { IImportData } from '@profis-engineering/pe-ui-common/entities/import-data';
import { IImportDesignProvider, IProjectDesignEntity } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { DeckingUrlPath } from '../../entities/module-constants';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';
import { DeckingSubstitutionImportService } from './decking-substitution-import.service';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';
import { DeckingDesignImportService } from './decking-design-import.service';
import { DeckingDesign } from 'src/decking/entities/decking-design/decking-design';
import { BaseDesign } from '../../entities/decking-design/base-design';
import { DeckingDesignService } from '../decking-design/decking-design.service';
import { DeckingMainService } from '../decking-main/decking-main.service';

export class DeckingImportProvider implements IImportDesignProvider {
    public path = DeckingUrlPath.mainDecking;
    public isDeckingTemplateFileImported = false;

    constructor(
        private deckingDesignImportService: DeckingDesignImportService,
        private deckingSubstitutionImportService: DeckingSubstitutionImportService,
        private deckingDesignService: DeckingDesignService,
        private deckingMainService: DeckingMainService,
    ) { }

    public getValidFileExtensions(): string[] {
        return ['.pe', '.pa2', '.profis3'];
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public checkFile(fileContent: string, fileName?: string): boolean {
        return this.checkDeckingDesign(fileContent);
    }

    private checkDeckingDesign(fileContent: string): boolean {
        try {
            const designEntity = JSON.parse(fileContent);
            this.isDeckingTemplateFileImported = false;
            if (Object.keys(designEntity).length == 3) {
                this.isDeckingTemplateFileImported = true;
            }
            //TODO: need proper fix to identify decking design when download from home page
            return (designEntity?.isDeckingDesign || designEntity?.areas && designEntity?.currentAreaIndex >= 0 && designEntity?.currentZoneIndex >= 0 || this.isDeckingTemplateFileImported);
        }
        catch (err) {
            return false;
        }
    }

    public async createProjectDesignEntity(projectDesign: File | Blob, projectName: string, fileNameProposal: string, designName: string, projectDesignContent?: string): Promise<IProjectDesignEntity> {
        const project = JSON.parse(projectDesignContent);
        project.name = fileNameProposal;
        return project;
    }

    public updateDesignFromExternalFile(oldDesign: IBaseDesign, projectDesign: IProjectDesignEntity, disableCalcMessages?: boolean): Promise<Design<unknown, IDesignStateBase>> {
        const projectType = (projectDesign as BaseDesign).projectType;

        this.setDesignMode(projectType);

        if (projectType === DeckingDesignModeType.SubstitutionBuilderMode)
            return this.deckingSubstitutionImportService.updateDesignFromExternalFile(oldDesign, projectDesign as DeckingSubstitution);

        return this.deckingDesignImportService.updateDesignFromExternalFile(oldDesign, projectDesign as DeckingDesign);
    }

    public createDesign(importData: IImportData, projectId: string, name: string, secondTry: boolean, disableCalcMessages: boolean, trackingEnabled?: boolean): Promise<IImportData> {
        return this.createDeckingDesign(importData, projectId);
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */

    private async createDeckingDesign(importData: IImportData, projectId: string) {
        if(this.isDeckingTemplateFileImported) {
            this.isDeckingTemplateFileImported = false;
            await this.deckingDesignService.loadDeckingTemplateFromExternalFile((importData.projectDesign as any).designId);
        } else{
            const projectType = (importData.projectDesign as BaseDesign).projectType;
        
            this.setDesignMode(projectType);

            if (projectType === DeckingDesignModeType.SubstitutionBuilderMode) {
                await this.deckingSubstitutionImportService.createAndOpenFromProjectDesign(importData.projectDesign as DeckingSubstitution, projectId);
            } else {
                await this.deckingDesignImportService.createAndOpenFromProjectDesign(importData.projectDesign as DeckingDesign, projectId);
            }
        }
        
        return importData;
    }

    public setDesignName(projectDesignEntity: IProjectDesignEntity, name: string): void {
        (projectDesignEntity as BaseDesign).name = name;
    }

    private setDesignMode(projectType: DeckingDesignModeType) {
        if(projectType == undefined) {
            this.deckingMainService.setSelectedModeType(DeckingDesignModeType.DesignMode);
        } else {
            this.deckingMainService.setSelectedModeType(projectType);
        }
    }
}