import { Injectable } from '@angular/core';
import { ZoneModel } from './../../entities/decking-design/zone-model';
import { AreaModel } from './../../entities/decking-design/area-model';
import { NumberComparator } from 'src/decking/helpers/numbers/number-comparator';
import { BaseAreas } from './base-areas';

@Injectable({
    providedIn: 'root'
})
export class DeckingDesignAreasService extends BaseAreas {
    public readonly maxZonesAllowed = 10;

    public areInputsEquals(currentArea: AreaModel, newArea: AreaModel) {
        return this.areBaseAreaInputsEquals(currentArea, newArea) &&
            NumberComparator.equal(currentArea.deflectionCalculation?.angleAreaA.value, newArea.deflectionCalculation.angleAreaA?.value) &&
            NumberComparator.equal(currentArea.deflectionCalculation?.wSeismic.value, newArea.deflectionCalculation.wSeismic?.value) &&
            NumberComparator.equal(currentArea.deflectionCalculation?.wWind.value, newArea.deflectionCalculation.wWind?.value) &&
            NumberComparator.equal(currentArea.deflectionCalculation?.width.value, newArea.deflectionCalculation.width?.value) &&
            NumberComparator.equal(currentArea.deflectionCalculation?.length.value, newArea.deflectionCalculation.length?.value);
    }

    public addZone(area: AreaModel, zone: ZoneModel) {
        const zones: number = area.zones.length;
        if (zones < this.maxZonesAllowed) {
            area.zones.push(zone);
        }
    }

    public deleteZone(area: AreaModel, zoneIndex: number) {
        const zonesCount: number = area.zones.length;
        if (zonesCount > 1) {
            area.zones.splice(zoneIndex, 1);
        }
    }
}
