import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CalculatingIndicatorService {
    calculating$: Observable<boolean>;
    private calculating = new BehaviorSubject<boolean>(false);

    constructor() {
        this.calculating$ = this.calculating.asObservable();
    }

    setCalculating(value: boolean) {
        this.calculating.next(value);
    }
}
