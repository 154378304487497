import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeckingCommonAreaComponent } from 'src/decking/components/decking-main-left/decking-areas-common/decking-common-area.component';
import { DeckingTabsWrapperService } from 'src/decking/services/decking-tabs-wrapper/decking-tabs-wrapper.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { DeckingSubstitutionService } from '../../../../services/decking-design/decking-substitution.service';
import { SubstitutionAreaModel } from './../../../../entities/decking-substitution/substitution-area';

@Component({
  selector: 'substitution-area',
  templateUrl: './substitution-area.component.html',
  styleUrls: ['./substitution-area.component.scss']
})
export class SubstitutionAreaComponent extends DeckingCommonAreaComponent implements OnChanges{

  @Input()
  substitutionAreaSelected: SubstitutionAreaModel;

  constructor(
    public override modalService: NgbModal, 
    public override localization: LocalizationService, 
    public override deckingTabsWrapperService: DeckingTabsWrapperService,
    private deckingSubstitutionService: DeckingSubstitutionService
  ){
    super(modalService, localization, deckingTabsWrapperService);
  }

  ngOnChanges(_: SimpleChanges): void {
    this.isSelected = this.deckingTabsWrapperService.allAreasCollapsed ? false : this.deckingSubstitutionService.isAreaSelected(this.index);
  }

  public toggleClicked(): void {
    this.deckingSubstitutionService.setCurrentArea(this.index);
    this.deckingTabsWrapperService.allAreasCollapsed = false;
  }

  override deleteArea(): void {
    this.deckingSubstitutionService.deleteArea(this.index);
    super.deleteArea();
  }

  duplicateArea(): void {
    this.deckingSubstitutionService.duplicateArea(this.index);
  }
}
