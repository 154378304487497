import { ReportTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';

export abstract class ReportTemplateBaseService {
    public abstract getTemplates(options?: ApiOptions): Promise<ReportTemplateEntity[]>;
}





