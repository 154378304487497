import { Injectable } from '@angular/core';
import { OfflineServiceBase } from '@profis-engineering/pe-ui-common/services/offline.common';

@Injectable({
    providedIn: 'root',
})
export class OfflineService extends OfflineServiceBase {
    override isOffline: boolean;

    /* eslint-disable @typescript-eslint/no-unused-vars */
    /**
     * Opens the file from a specified local path or URL.
     * @param path - PAth to the file that needs to be opened.
     * @param fileName - Name of the file.
     * @param storeInTemp - Store the file temp folder, or show the save as dialog.
     * @param openAfterSave - Open the file after save.
     */
    public nativeLocalPathOpen(
        path: string,
        fileName: string,
        storeInTemp: boolean,
        openAfterSave: boolean
    ): void {
        window.open(path, '_blank');
    }

    /**
     * Open the URL link from the INTERNET.
     * @param path - Path to the web page.
     */
    public nativeExternalLinkOpen(path: string): void {
        window.open(path, '_blank');
    }

    public nativeLocalFileOpen(path: string): void {
        window.open(path, '_blank');
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */

}
