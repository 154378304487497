<ng-container [ngSwitch]="reportTemplateType">
    <span *ngSwitchCase="1">
        <span l10n="Agito.Hilti.Profis3.CodeList.ReportTypeEntity.Long"></span>
    </span>
    <span *ngSwitchCase="2">
        <span l10n="Agito.Hilti.Profis3.CodeList.ReportTypeEntity.Short"></span>
    </span>
    <span *ngSwitchCase="3">
        <span l10n="Agito.Hilti.Profis3.CodeList.ReportTypeEntity.Detailed"></span>
    </span>
    <span *ngSwitchDefault>
        Undefined
    </span>
</ng-container>