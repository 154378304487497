<div id="decking-center-top-container" style="margin-top: 15px;">
    <div id="decking-floating-dropdowns">
        <decking-product-selector
            [currentArea]="currentSubstitutionArea$ | async"
            [currentDeckingZone]="(zoneSelected$ | async)">
        </decking-product-selector>
    </div>
</div>

<div id="zones-container" *ngIf="currentSubstitutionArea$ | async as currentArea">
    <div id="substitution-heading">
        <span  l10n="Agito.Hilti.Profis3.Decking.Substitution.Header.Area.Title"></span>&nbsp;<span>{{ currentArea.name.value | uppercase}}</span> 
    </div>
   
    <div id="zones-bottom-container">
        <div id="zones_table">
            <table class="table table-borderless" aria-describedby="Zones management table">
                <colgroup>
                    <col id="zone_selection_col" />
                    <col id="zone_name_col" />
                    <col id="zone_type_col" />

                    <col id="zone_loadtype_col"/>

                    <!--Dimensions-->
                    <!--S-->
                    <col class="sb_default_columns_size" *ngIf="currentArea.zoneSpacing.value" />

                    <!-- Details -->
                    <col id="deck_gauge_col" />
                    <col id="pattern_col" />
                    <col id="frame_fastener_col"/>
                    <col id="sidelap_connector_col"/>
                    <col id="side_col"/>

                    <!-- Enable loads checkbox-->
                    <col class="sb_default_columns_size"/>        
                    <!-- Results-->
                    <!--Q, W, G -->
                    <col class="sb_default_columns_size"/>
                    <col *ngIf="isSubstitutionRequiredUpliftSubmittalSetting$ | async" class="sb_default_columns_size"/>
                    <col *ngIf="isSubstitutionRequiredShearStiffnessSetting$ | async" class="sb_default_columns_size"/>

                <!--Action buttons-->
                <col id="action_buttons_col" />
                    </colgroup>
                <thead
                    decking-substitution-zones-header
                    [areaName]="currentArea.name.value"
                    [zoneSpacing]="currentArea.zoneSpacing.value"
                ><tr class="hideHeader"><th></th></tr></thead>
                <tbody
                        decking-substitution-zones-row
                        *ngFor="let zone of currentArea.zones; let i = index; trackBy: trackByFn"
                        [attr.id]="'zones-row-' + i"
                        [index]="i"
                        [zone]="zone"
                        [deckType]="currentArea.deckType.id"
                        [deckGaugeItems]="deckGaugeItems$ | async"
                        [patternItems]="patternItems$ | async"
                        [frameItems]="frameItems$ | async"
                        [specifiedFrameItems]="specifiedFrameItems$ | async"
                        [substitutedFrameItems]="substitutedFrameItems$ | async"
                        [sidelapItems]="sidelapItems$ | async"
                        [lengthUnit]="lengthUnit$  | async"
                        [stressUnit]="stressUnit$ | async"
                        [forcePerLengthUnit]="forcePerLengthUnit$ | async"
                        [shearStiffnessUnit]="shearStiffnessUnit$ | async"
                        [forceUnit]="forceUnit$ | async"
                        [zoneSpacing]="currentArea.zoneSpacing.value"
                        [saving]="(saving$ | async)"
                        [canDeleteZone]="canDeleteZone$ | async"
                    >        
                </tbody>
            </table>
        </div>
        <div id="buttons_container">
            <decking-substitution-zones-action-buttons
                [saving]="saving$ | async"
                [currentArea]="currentSubstitutionArea$ | async"
            ></decking-substitution-zones-action-buttons>
        </div>
    </div>
</div>