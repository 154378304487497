import { Injectable } from '@angular/core';
import { BaseAreas } from './base-areas';
import { SubstitutionAreaModel } from './../../entities/decking-substitution/substitution-area';
import { SubstitutionZoneModel } from './../../entities/decking-substitution/substitution-zone';

@Injectable({
    providedIn: 'root'
})
export class DeckingSubstitutionAreasService extends BaseAreas {
    public readonly maxZonesAllowed = 10;

    public areInputsEquals(currentArea: SubstitutionAreaModel, newArea: SubstitutionAreaModel) {
        return this.areBaseAreaInputsEquals(currentArea, newArea);
    }

    public addZone(area: SubstitutionAreaModel, zone: SubstitutionZoneModel) {
        const zones: number = area.zones.length;
        if (zones < this.maxZonesAllowed) {
            area.zones.push(zone);
        }
    }

    public deleteZone(area: SubstitutionAreaModel, zoneIndex: number) {
        const zonesCount: number = area.zones.length;
        if (zonesCount > 1) {
            area.zones.splice(zoneIndex, 1);
        }
    }
}
