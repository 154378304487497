import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { SubstitutionAreaModel } from '../../../../../entities/decking-substitution/substitution-area';
import { DeckingSubstitutionAreasService } from '../../../../../services/decking-areas/decking-substitution-areas.service';
import { DeckingSubstitutionCalculationService } from '../../../../../services/decking-design/calculation/substitution/decking-substitution-calculation.service';
import { DeckingSubstitutionService } from '../../../../../services/decking-design/decking-substitution.service';
import { ModalService } from './../../../../../services/external/modal.service';
import { combineLatestWith } from 'rxjs/internal/operators/combineLatestWith';
import { CalculationState } from 'src/decking/entities/enums/calculation-state';
import { map } from 'rxjs/internal/operators/map';
import { DeckingDesignVerificationService } from 'src/decking/services/decking-design/calculation/verification/decking-design-verification.service';

@Component({
    templateUrl: './decking-substitution-zones-action-buttons.component.html',
    styleUrls: ['./decking-substitution-zones-action-buttons.component.scss'],
})
export class DeckingSubstitutionZonesActionButtonsComponent
    implements OnInit, OnChanges
{
    @Input()
    public currentArea: SubstitutionAreaModel;

    @Input()
    public saving: boolean;

    public readonly maxZonesAllowed: number;
    public disableAlternatives$: Observable<boolean>;
    public disableOptimizeAll = false;

    constructor(
        private deckingSubstitutionService: DeckingSubstitutionService,
        private deckingSubstitutionCalculationService: DeckingSubstitutionCalculationService,
        private modal: ModalService,
        private deckingSubstitutionAreasService: DeckingSubstitutionAreasService,
        private deckingVerificationService: DeckingDesignVerificationService,
    ) {
        this.maxZonesAllowed = this.deckingSubstitutionAreasService.maxZonesAllowed;
    }

    public ngOnInit(): void {
        this.disableAlternatives$ = this.deckingSubstitutionService.currentZone$.pipe(combineLatestWith(this.deckingVerificationService.saving$)).pipe(map(([currentZone, saving]) => {
            return saving || currentZone.zoneSubstituted.calculationState == CalculationState.Empty || currentZone.zoneSubstituted.calculationState == CalculationState.NotSolution;
        }));
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['currentArea']) {
            this.optimizeAllZones();
        }
    }

    public substituteAll(): void {
        this.deckingSubstitutionCalculationService.substituteAll();
    }

    public addZone(): void {
        this.deckingSubstitutionService.addNewZoneToCurrentArea();
    }

    public compareAlternatives() {
        this.modal.openWebComponentModal('decking-compare-substitution-zone-alternatives-modal', { size: 'xl' });
    }

    public optimizeAllZones() {
        this.disableOptimizeAll = false;
        if (
            this.currentArea !== null ||
            this.currentArea.zones !== null ||
            this.currentArea.zones.length !== 0
        ) {
            this.currentArea.zones.forEach((zone) => {
                if (this.handleOptimiseClick(zone)) {
                    this.disableOptimizeAll = true;
                }
            });
        }
    }

    private handleOptimiseClick(zone: any) {
        if (
            zone.zoneSpecified.frameFastener !== null &&
            zone.zoneSpecified.sidelapConnector !== null &&
            zone.zoneSpecified.pattern !== null &&
            zone.zoneSpecified.side !== null &&
            zone.zoneSpecified.deckGauge !== null &&
            !zone.zoneSpecified.enableLoad
        ) {
            return false;
        } else if (
            zone.zoneSpecified.deckGauge !== null &&
            zone.zoneSpecified.enableLoad
        ) {
            return false;
        } else {
            return true;
        }
    }
}
