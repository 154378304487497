<p class="title-strike title" [ngClass]="{ 'no-margin': !isExpanded}">
    <ng-content select="[collapsable-title]"></ng-content>
    <button class="section-collapse" type="button" [id]="buttonId" (click)="toggle()"
        [title]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n:localization.selectedLanguage"
        [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n:localization.selectedLanguage">
        <span class="decking-sprite {{isExpanded ? 'decking-sprite-lines' : 'decking-sprite-lines-expanded'}}"></span>
    </button>
</p>
<div [hidden]="!isExpanded">
    <ng-content select="[collapsable-content]"></ng-content>
</div>
