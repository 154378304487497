import { Component, Input, OnInit } from '@angular/core';
import { RadioButtonProps } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from './../../../services/external/localization.service';
import { DeckingDesignModeType } from './../../../entities/enums/decking-design-mode-type';
import { DeckingUserSettingsService } from './../../../services/decking-user-settings/user-settings.service';
import { DeckingMainService } from 'src/decking/services/decking-main/decking-main.service';

export interface IModalSelectorInputValues {
  onSelectedMode?: (selectedMode: DeckingDesignModeType) => void;
}

@Component({
  templateUrl: './mode-selector.component.html',
  styleUrls: ['./mode-selector.component.scss']
})
export class ModeSelectorComponent implements OnInit{

  @Input()
  public modalInstance!: ModalInstance<IModalSelectorInputValues>;
  public modeSelectorOptions!: RadioButtonProps<DeckingDesignModeType>;
  public modeSelected: DeckingDesignModeType;

  constructor(
      public localizationService: LocalizationService,
      public userSettingsService: DeckingUserSettingsService,
      public deckingMainService: DeckingMainService
    ){}

  ngOnInit(): void {
    this.modeSelected = this.userSettingsService?.deckingSettings?.designMode?.value ?? DeckingDesignModeType.AlwaysAskMode;
    this.modeSelectorOptions = {
      id: 'decking-modeselection-options',
      selectedValue: this.modeSelected,
      items: [
          {
              id: 'decking-mode-diaphragm',
              text: this.localizationService.getString('Agito.Hilti.Profis3.Decking.ModeSelectorModal.NewDesignText'),
              description: this.localizationService.getString('Agito.Hilti.Profis3.Decking.ModeSelectorModal.NewDesignDescriptionText'),
              value: DeckingDesignModeType.DesignMode,
              disabled: false
          },
          {
              id: 'decking-mode-substitution',
              text: this.localizationService.getString('Agito.Hilti.Profis3.Decking.ModeSelectorModal.NewSubstitutionText'),
              description: this.localizationService.getString('Agito.Hilti.Profis3.Decking.ModeSelectorModal.NewSubstitutionDescriptionText'),
              value: DeckingDesignModeType.SubstitutionBuilderMode,
              disabled: false
          }
      ]
    };
  }

  public get isContinueDisabled():boolean{
    return this.modeSelected === DeckingDesignModeType.AlwaysAskMode || this.modeSelected === null;
  }

  modeSelectedValueChange(event: Event){
    const customEvent: CustomEvent = event as CustomEvent;
    this.modeSelected = customEvent.detail;
  }

  public close(selection:DeckingDesignModeType = null) {
    this.modalInstance.close(selection);
  }

  public continue(){
    this.deckingMainService.setSelectedModeType(this.modeSelected);
    
    if (this.modalInstance.input?.onSelectedMode != null) {
      this.modalInstance.input.onSelectedMode(this.modeSelected);
    }
    this.close(this.modeSelected);
  }

}
