import { RadioLook } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { LanguageCulture } from '@profis-engineering/pe-ui-common/services/localization.common';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import { PaperSize, ReportType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import { trim } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { ReportTemplateEntity, ReportTemplateOptionEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import { Validators, ControlContainer, NgForm } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { LocalizationService } from './../../../../services/external/localization.service';
import { DeckingReport } from './../../../../entities/decking-design/decking-report-info';
import { NumberService } from './../../../../services/external/number.service';
import { CommonCodeListService } from './../../../../services/external/common-code-list.service';
import { ModalService } from './../../../../services/external/modal.service';
import { ReportTemplateService } from './../../../../services/decking-report-template/decking-report-template.service';
import { DeckingDesignService   } from './../../../../services/decking-design/decking-design.service';
import { DeckingMainService } from 'src/decking/services/decking-main/decking-main.service';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';
import { DeckingSubstitutionService } from './../../../../services/decking-design/decking-substitution.service';

@Component({
    selector: 'decking-report-generation-options-layout',
    templateUrl: './decking-report-generation-options-layout.component.html',
    styleUrls: ['./decking-report-generation-options-layout.component.scss'],
    // Provide the existing ng form (coming from the parent) to be used by the form controls in this component
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DeckingReportGenerationOptionsLayoutComponent implements OnInit {

    @Input()
    reportInfo: DeckingReport;

    @Input()
    useSIUnits: boolean;

    @Output()
    useSIUnitsChange = new EventEmitter<boolean>();

    @Output()
    reportTemplateDataLoaded = new EventEmitter();

    languages: Language[] = [];
    languageDropdown: DropdownProps<number>;
    templates: ReportTemplateEntity[];
    defaultTemplate: ReportTemplateEntity;
    templateDropdown: DropdownProps<number> = {
        items: [],
        selectedValue: null,
    };

    unitsDropdown: DropdownProps<boolean> = {
        items: [],
        selectedValue: null,
    };

    reportTypesDropdown: DropdownProps<number> = {
        items: [],
        selectedValue: null,
    };

    reportTypeNotSupportedMessage: string | null = null;
    reportTypeFromTemplate: ReportType;
    isReportTypeSupported = true;

    paperSizeDropdown: DropdownProps<number> = {
        items: [],
        selectedValue: null,
    };

    unitRadioLook = RadioLook.Horizontal;

    emailValidator = Validators.pattern(/^[^\s@]+@([^\s@.,\d]+\.)+[^\s@.,\d]{2,}$/);

    public requiredValidator = Validators.required;
    public deckingDesignModeType = DeckingDesignModeType;

    constructor(
        public localization: LocalizationService,
        private codeListCommon: CommonCodeListService,
        private deckingDesignService: DeckingDesignService,
        private numberService: NumberService,
        private modalService: ModalService,
        private reportTemplateService: ReportTemplateService,
        public deckingMainService: DeckingMainService,
        private deckingSubstitutionService: DeckingSubstitutionService
    ) { }


    ngOnInit(): void {
        this.populateDropdowns();
        if (this.codeListCommon.commonCodeLists) {
            this.languages = (this.codeListCommon.commonCodeLists[CommonCodeList.Language] as Language[]).filter(language => language.culture != LanguageCulture.pseudoLanguage);
        }
    }

    private async populateDropdowns() {
        this.populateUnitsDropdown();
        this.populateReportTypesDropdown();
        this.populatePaperSizeDropdown();
        await this.populateTemplateDropdown();
        this.populateLanguageDropdown();
        this.populateReportLayout();
    }

    private populateLanguageDropdown() {
        this.reportInfo.reportLanguageId = { value: this.languages.find(x => x.culture === this.localization.selectedLanguage)?.id};
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        this.languageDropdown = {
            items: this.languages.map((language) => {
                return {
                    value: language.id,
                    text: language.getTranslatedNameText(codeListDeps)
                };
            }),
            selectedValue: this.reportInfo.reportLanguageId.value
        };
    }

    public async populateTemplateDropdown() {
        this.templates = await this.reportTemplateService.getTemplates();
        this.defaultTemplate = this.templates.find((template) => { return template.Name === 'Default'; });
        this.templateDropdown = {
            items: [
                { text: 'Custom', value: 0 },
                ...this.templates.map((template) => {
                    return { text: template.Name.length > 12 ? trim(template.Name, 9) : template.Name, value: template.Id };
                }),
            ],
            selectedValue: this.reportInfo.templateIdSelected.value,
        };

        this.onTemplateSelectedValueChange(this.reportInfo.templateIdSelected.value);
        this.reportTemplateDataLoaded.emit();
    }

    private populateUnitsDropdown() {
        this.unitsDropdown = {
            items: [
                {
                    text: this.localization.getString(
                        'Agito.Hilti.Profis3.Decking.Report.Layout.SIUnits'
                    ),
                    value: true,
                    id: 'report_generator_units_si',
                },
                {
                    text: this.localization.getString(
                        'Agito.Hilti.Profis3.Decking.Report.Layout.ImperialUnits'
                    ),
                    value: false,
                    id: 'report_generator_units_imperial',
                },
            ],
            selectedValue: this?.reportInfo?.useUnits?.value,
        };
    }

    private populateReportTypesDropdown() {
        this.reportTypesDropdown = {
            items: [
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.Decking.Report.Layout.ReportType.Short'),
                    value:  ReportType.Short,
                    id: 'report_generator_type_short',
                },
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.Decking.Report.Layout.ReportType.Long'),
                    value: ReportType.Long,
                    id: 'report_generator_type_long',
                },
            ],
            selectedValue: this.reportInfo?.reportType?.value,

        };
    }

    private populatePaperSizeDropdown() {
        this.paperSizeDropdown = {
            items: [
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.Decking.Report.Layout.PaperSize.A4'),
                    value: PaperSize.A4,
                    id: 'report_generator_papersize_a4',
                },
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.Decking.Report.Layout.PaperSize.Letter'),
                    value: PaperSize.Letter,
                    id: 'report_generator_papersize_letter',
                },
            ],
            selectedValue: null,
        };
    }

    public openReportTemplates() {
        const selectedTemplate = this.reportInfo.templateIdSelected.value !== 0 ? this.reportInfo.templateIdSelected.value : this.templateDropdown.items[1].value;
        this.modalService
            .openReportTemplates(selectedTemplate)
            .closed.then(() => { this.populateTemplateDropdown(); });
    }
    
    public onTemplateSelectedValueChange(event: number | null) {
        const selectedTemplate = this.templates.find((template) => { return template.Id == event; });
        if (event !== 0 && selectedTemplate) {            
            this.reportInfo.company = { value: selectedTemplate.Company };
            this.reportInfo.address = { value: selectedTemplate.Address };
            this.reportInfo.contactPerson = { value: selectedTemplate.ContactPerson };
            this.reportInfo.phoneNumber = { value: selectedTemplate.PhoneNumber };
            this.reportInfo.email = { value: selectedTemplate.Email };
            this.reportInfo.logo = { value: selectedTemplate.Logo };
            this.reportInfo.templateOptions = this.replaceNullValues(selectedTemplate);
            this.reportInfo.faxNumber = { value: selectedTemplate.FaxNumber };
            this.reportInfo.paperSize = { value: selectedTemplate.PaperSize };
            this.reportInfo.removeProjectHeader = { value: selectedTemplate.RemoveProjectHeader };
            this.reportTypeFromTemplate = selectedTemplate.ReportType;
            this.isReportTypeSupported = this.checkSupportedReportType(selectedTemplate.ReportType);
        } else {
            this.populateReportLayout();
            this.reportInfo.logo = { value: '' };
            this.reportInfo.faxNumber = { value: '' };
            this.reportInfo.templateIdSelected = { value: 0 };
            this.reportInfo.templateOptions = [];
            this.reportInfo.removeProjectHeader = { value: false };
 
            if (this.deckingMainService.getSelectedModeType() === DeckingDesignModeType.SubstitutionBuilderMode ) {
                const deckingSubstitution = this.deckingSubstitutionService.getCurrentSubstitution();
                if (!deckingSubstitution) return;
            } else
            {
                const deckingDesign = this.deckingDesignService.getCurrentDesign();
                if (!deckingDesign) return;
            }
        }
        this.reportInfo.templateIdSelected.value = selectedTemplate ? selectedTemplate.Id : 0;
    }

    public populateReportLayout(){
        const deckingDesign = this.deckingDesignService.getCurrentDesign();
        if (!deckingDesign) return;
    }

    public setUnitsDropdownValue(event: boolean) {
        this.useSIUnits = event;
        this.useSIUnitsChange.emit(event);
        this.reportInfo.useUnits = { value: this.useSIUnits };
    }

    private replaceNullValues(selectedTemplate: { TemplateOptions: ReportTemplateOptionEntity[] }): ReportTemplateOptionEntity[] {
        return selectedTemplate.TemplateOptions.map((option) => ({
            ...option,
            Value: option.Value ?? ''
        }));
    }

    checkSupportedReportType(reportType: ReportType): boolean {
        const supportedReportType = this.reportTypesDropdown.items.find((type) => { return type.value === reportType; });
        if (supportedReportType) {
            this.reportInfo.reportType = { value: reportType };
            return true;
        }
        this.reportInfo.reportType = { value: null };
        return false;
    }

    public isReportTypeAvailable() {
        return (!this.isReportTypeSupported || this.reportInfo.templateIdSelected.value === 0) && this.deckingMainService.getSelectedModeType() != DeckingDesignModeType.SubstitutionBuilderMode;
    }
}
