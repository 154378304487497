import { IDeckingValue } from './decking-value';

export class ScopeCheckResult {
    public scopeCheckTranslationId: IDeckingValue<string>;
    public severity: ScopeCheckSeverity;
    public parameters: ScopeCheckParameter[];
}

export class ScopeCheckParameter{
    public name: string;
    public value: string;
}

export enum ScopeCheckSeverity{
    Info,
    Warning,
    Critical
}
