<div class="modal-content" *ngIf="currentArea$ | async; let currentArea">
    <div class="modal-header">
        <h3 id="fastenerEdit-headerModal-panelTitle" class="modal-title" l10n="Agito.Hilti.Profis3.Decking.Areas.FastenerEstimation.Title"></h3>

        <button id="fastenerEdit-headerModal-closeButton" type="button" class="close" (click)="close(currentArea)">
            <span>&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div id="fastenerEdit-table" class="fastenerTable-container">
            <div id="fastener-table-body">
                <div class="fastener-table-row">
                    <div class="table-body-header"></div>
                    <div class="table-body-header" l10n="Agito.Hilti.Profis3.Decking.Areas.FastenerEstimation.ZoneArea"></div>
                    <div class="table-body-header" l10n="Agito.Hilti.Profis3.Decking.Areas.FastenerEstimation.PerimeterFastenerSpacing"></div>
                    <div class="table-body-header" l10n="Agito.Hilti.Profis3.Decking.Areas.FastenerEstimation.FrameFasteners"></div>
                    <div class="table-body-header" l10n="Agito.Hilti.Profis3.Decking.Areas.FastenerEstimation.SidelapConnectors"></div>
                </div>
                <app-fastener-estimation-row *ngFor="let zone of currentArea.zones | filterVerifiedZones; let i = index"
                    [index] = "i"
                    [deckingZone] = "zone">
                </app-fastener-estimation-row>
            </div>
        </div>

        <div class="section label-section">
            <span id="fastenerEdit-noteLabel">{{FastenerEditDescription}}</span>
        </div>
    </div>


    <div class="modal-footer">
        <button type="button" id="fastenerEdit-footerPanel-closeButton" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.Decking.Areas.FastenerEstimation.Close"
            (click)="close(currentArea)">
        </button>
    </div>
</div>
