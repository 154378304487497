import { DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, inject, ChangeDetectorRef } from '@angular/core';
import { IDeckingDocument } from './../../../entities/decking-design/decking-document';

@Component({
    selector: 'decking-bootstrap-dropdown',
    templateUrl: './decking-bootstrap-dropdown.html',
    styleUrls: ['./decking-bootstrap-dropdown.scss']
})
export class DeckingBootstrapDropdownComponent<TId, TValue> implements OnChanges {

    @Input()
    public id: string;

    @Input()
    public title: string;

    @Input()
    public items: DropdownItem<IDeckingDocument<TId, TValue>>[];

    @Input()
    public selectedValue: IDeckingDocument<TId, TValue>;

    @Input()
    public disabled = false;

    @Input()
    public container: null | 'body' = null;

    @Input()
    public placement = 'dynamic';

    @Input()
    public placeholder: string;

    @Output()
    public selectedValueChange = new EventEmitter<IDeckingDocument<TId, TValue>>();

    @Input()
    public trackBy: (i: DropdownItem<IDeckingDocument<TId, TValue>>) => boolean = (i => i.value?.id === this.selectedValue?.id);

    public selectedItem: DropdownItem<IDeckingDocument<TId, TValue>>;

    public changeDetectorRef = inject(ChangeDetectorRef);

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['items'] || changes['selectedValue']) {
            this.updateSelectItem();
        }
    }

    public selectValue(value: IDeckingDocument<TId, TValue>): void {
        if (this.selectedValue?.id === value?.id) return;
        this.selectedValue = value;
        this.updateSelectItem();
        this.selectedValueChange.emit(this.selectedValue);
    }

    private updateSelectItem(): void {
        this.selectedItem = this.items.find(this.trackBy);
    }

    public detectChanges(): void {
        this.changeDetectorRef.detectChanges();
    }
}
