import { Injectable, NgZone } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { DeckingDesign } from '../../entities/decking-design/decking-design';
import { ApiService } from '../external/api.service';
import { FeatureVisibilityService } from '../external/feature-visibility.service';
import { LocalizationService } from '../external/localization.service';
import { LoggerService } from '../external/logger.service';
import { ErrorHandlerService } from '../decking-error-handler/error-handler.service';
import { BaseSignalrService } from './base-signalr';
import { environment } from '../../../environments/environmentDecking';
import { OptimizeDesign } from '../../entities/calculation/optimization/optimize-design';
import { CommonUserSettingsService } from '../external/common-user-settings.service';

@Injectable({
    providedIn: 'root'
})
export class DeckingDesignSignalrService extends BaseSignalrService {

    designCalculated$: Observable<DeckingDesign>;
    designSaved$: Observable<DeckingDesign>;

    protected designCalculated = new Subject<DeckingDesign>();
    protected designSaved = new Subject<DeckingDesign>();

    constructor(
        protected override apiService: ApiService,
        protected override zone: NgZone,
        protected override featureVisibilityService: FeatureVisibilityService,
        protected override ErrorHandlerService: ErrorHandlerService,
        protected override localizationService: LocalizationService,
        protected override userSettings: CommonUserSettingsService,
        protected override loggerService: LoggerService
    ) {
        super(apiService, zone, featureVisibilityService, ErrorHandlerService, localizationService, userSettings, loggerService);
        this.designCalculated$ = this.designCalculated.asObservable();
        this.designSaved$ = this.designSaved.asObservable();
    }

    public init(): void {
        try {
            if (this.deckingEnabled && !this.connection) {
                // connection options
                const options = {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                };

                // connection creation
                this.connection = new signalR.HubConnectionBuilder()
                    .withUrl(`${environment.deckingDesignServiceSignalRUrl}deckingHub`, options)
                    .configureLogging(signalR.LogLevel.Warning)
                    .build();

                // subscribing to client function invocation from server
                this.connection.on('onDesignSaved', (design: DeckingDesign) => this.onDesignSaved(design));
                this.connection.on('OnSaveDesignFailed', (design: DeckingDesign) => this.OnDesignFailed(design));
                this.connection.on('onDesignVerified', (design: DeckingDesign) => this.onDesignVerified(design));
                this.connection.on('OnVerifyDesignFailed', (design: DeckingDesign) => this.OnDesignFailed(design));
                this.connection.on('onDesignOptimized', (design: DeckingDesign) => this.onDesignOptimized(design));
                this.connection.on('OnOptimizeDesignFailed', (design: DeckingDesign) => this.OnDesignFailed(design));
            }
        } catch (e) {
            console.error(e);
        }
    }

    public async VerifyDesign(deckingDesign: DeckingDesign): Promise<void> {
        try {
            await this.checkConnection(deckingDesign.id, 'SubscribeToDesign');
            await this.connection.send('VerifyDesign', deckingDesign);
        }
        catch (e) {
            let title = '';
            let message = '';
            [title, message] = this.getSignalRErrorFieldValues();
            this.ErrorHandlerService.showDesignErrorModal(title, message, null, 'VerifyDesign', null);
            throw e;
        }
    }

    public async SaveDesign(deckingDesign: DeckingDesign): Promise<void> {
        try {
            await this.checkConnection(deckingDesign.id, 'SubscribeToDesign');
            await this.connection.send('SaveDesign', deckingDesign);
        }
        catch (e) {
            let title = '';
            let message = '';
            [title, message] = this.getSignalRErrorFieldValues();
            this.ErrorHandlerService.showDesignErrorModal(title, message, null, 'SaveDesign', null);
            throw e;
        }
    }

    public async optimizeDesign(optimizeDesign: OptimizeDesign): Promise<void> {
        try {
            await this.checkConnection(optimizeDesign.designId, 'SubscribeToDesign');
            await this.connection.send('OptimizeDesign', optimizeDesign);
        }
        catch (e) {
            let title = '';
            let message = '';
            [title, message] = this.getSignalRErrorFieldValues();
            this.ErrorHandlerService.showDesignErrorModal(title, message, null, 'OptimizeDesign', null);
            throw e;
        }
    }

    private onDesignSaved = (deckingDesignSaved: DeckingDesign) => {
        // Explicitly run on angular because signal r library runs the code outside the angular zone (see https://angular.io/guide/zone)
        this.zone.run(() => {
            this.designSaved.next(deckingDesignSaved);
        });
    };

    private onDesignVerified = (deckingDesignSaved: DeckingDesign) => {
        this.updateDesignState(deckingDesignSaved);
    };

    private onDesignOptimized = (deckingDesignSaved: DeckingDesign) => {
        this.updateDesignState(deckingDesignSaved);
    };

    private OnDesignFailed = (deckingDesignSaved: DeckingDesign) => {
        let title = '';
        let message = '';
        [title, message] = this.getSignalRErrorFieldValues();
        this.updateDesignState(deckingDesignSaved);
        this.ErrorHandlerService.showDesignErrorModal(title, message, null, 'OnDesignFailed', null);
    };

    private updateDesignState = (deckingDesignSaved: DeckingDesign) => {
        // Explicitly run on angular because signal r library runs the code outside the angular zone (see https://angular.io/guide/zone)
        this.zone.run(() => {
            this.designCalculated.next(deckingDesignSaved);
        });
    };
}
