<div class="modal-content base-modal">
    <div class="modal-header">
        <h3 class="modal-title" l10n="Agito.Hilti.Profis3.Decking.SpecificationTextModal.Title"></h3>
        <button type="button" class="close" (click)="close()">
            <span>&times;</span>
        </button>
    </div>

    <div #specificationTextDiv class="modal-body">
        <div class="line-break" [l10n]="getSpecificationTextKeyForCurrentRegion()"></div>
        <div *ngFor="let area of currentDeckingDesign.areas">
            <h6 class="mt-3 table-title">{{area.name?.value}}</h6>
            <table class="table table-bordered">
                <caption style="display: none;">Specifications</caption>
                <thead>
                    <tr>
                        <th l10n="Agito.Hilti.Profis3.Decking.SpecificationTextModal.Table.Header.Zone"></th>
                        <th l10n="Agito.Hilti.Profis3.Decking.SpecificationTextModal.Table.Header.DeckGauge"></th>
                        <th l10n="Agito.Hilti.Profis3.Decking.SpecificationTextModal.Table.Header.Pattern"></th>
                        <th l10n="Agito.Hilti.Profis3.Decking.SpecificationTextModal.Table.Header.FrameFastener"></th>
                        <th l10n="Agito.Hilti.Profis3.Decking.SpecificationTextModal.Table.Header.SidelapConnector"></th>
                        <th [l10n]="'Agito.Hilti.Profis3.Decking.Zones.Subheader.' | sidelapConnectorTitle"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let zone of area.zones">
                        <td>{{zone.name.value}}</td>
                        <td>{{zone.deckGauge?.value ? zone.deckGauge?.value + ' ga' : '-'}}</td>
                        <td>{{zone.pattern?.value ?? '-'}}</td>
                        <td>{{zone.frameFastener?.value ?? '-'}}</td>
                        <td>{{zone.sidelapConnector?.value ?? '-'}}</td>
                        <td>
                            <decking-unit-value-display *ngIf="zone.side?.value" [value]="zone.side?.value"
                                [precision]="0" [appendUnit]="isBySpacing$ | async" [unit]="sidelapSpacingUnit$ | async"></decking-unit-value-display>
                            <ng-container *ngIf="!zone.side?.value">-</ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.Decking.SpecificationTextModal.CloseButtonText" (click)="close()">
        </button>

        <button type="button" class="modal-button button button-CTA" (click)="copyToClipboard()">
            <pe-loading-button [loading]="copying"
                [text]="'Agito.Hilti.Profis3.Decking.SpecificationTextModal.CopyButtonText' | l10n:localizationService.selectedLanguage">
            </pe-loading-button>
        </button>
    </div>
</div>