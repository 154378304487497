import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { LocalizationService } from 'src/decking/services/external/localization.service';

@Component({
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
  })
export abstract class BaseDeckingMainRightOptionsComponent {

    @ViewChild('openDesignInput')
    public inputElement!: ElementRef<HTMLInputElement>;
    
    constructor(
        protected localizationService: LocalizationService,
        protected elementRef: ElementRef<HTMLElement>) {
      }

      protected abstract import(projectDesign: File | Blob, name?: string): void;

      public selectOpenFile() {
        if (this.inputElement.nativeElement.value == null || this.inputElement.nativeElement.value == '') {
          setTimeout(() => {
            this.inputElement.nativeElement.click();
          });
        }
      }
    
      public importFileSelected() {
        if (this.inputElement.nativeElement.value != null
          && this.inputElement.nativeElement.value != ''
          && this.inputElement.nativeElement.files != null) {
          const file = this.inputElement.nativeElement.files[0];
          this.inputElement.nativeElement.value = '';
          this.import(file, file.name);
        }
      }
}