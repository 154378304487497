import { Injectable } from '@angular/core';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ModalServiceInjected } from '@profis-engineering/pe-ui-common/services/modal.common';
import { IAddEditDesignComponentInput } from './../../entities/decking-design/add-edit-design';

@Injectable({
  providedIn: 'root'
})
export class ModalService extends ModalServiceInjected { 
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public openAddEditDesign(props: IAddEditDesignComponentInput): IModalOpened {
      return {} as IModalOpened;
    }
}
