import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ReportType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import { LanguageCulture } from '@profis-engineering/pe-ui-common/services/localization.common';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';

import { DeckingReport } from './../../entities/decking-design/decking-report-info';
import { IDeckingDocument } from './../../entities/decking-design/decking-document';
import { environment } from './../../../environments/environmentDecking';
import { ApiService } from  './../../services/external/api.service';
import { CommonCodeListService } from  './../../services/external/common-code-list.service';

@Injectable({
  providedIn: 'root'
})

export class DeckingReportService {
  private languages: Language[] = [];

  constructor(
    private apiService: ApiService,
    private codeListCommon: CommonCodeListService,
  ) {
    if (this.codeListCommon.commonCodeLists) {
      this.languages = (this.codeListCommon.commonCodeLists[CommonCodeList.Language] as Language[]).filter((language: Language) => language.culture !== LanguageCulture.pseudoLanguage);
    }
  }

  public async generateReportContent(designId: string, eTag: string, generationReportOptions: {
    reportData: DeckingReport;
    reportType: ReportType;
    useSiUnits: boolean;
    patternImages: IDeckingDocument<number, string>[];
  }): Promise<string> {
    generationReportOptions.reportData.languageCode = { value : this.languages.find(x => x.id === generationReportOptions.reportData.reportLanguageId.value)?.culture};
    const url = `${environment.deckingReportProcessorServiceUrl}api/ReportProcessorService/SaveAndCreateReportContent/${designId}/${eTag}`;
    const response = await this.apiService.request<{htmlContent: string}>(new HttpRequest('POST', url, generationReportOptions));
    return response.body.htmlContent;
  }

  public async generateSubstitutionReportContent(designId: string, eTag: string, generationReportOptions: {
    reportData: DeckingReport;
    reportType: ReportType;
    useSiUnits: boolean;
    patternImages: IDeckingDocument<number, string>[];
  }): Promise<string> {
    
    generationReportOptions.useSiUnits = false;
    generationReportOptions.reportData.languageCode= {value: ''};
    generationReportOptions.reportData.logo= {value: ''};
    generationReportOptions.reportData.languageCode = { value : this.languages.find(x => x.id === generationReportOptions.reportData.reportLanguageId.value)?.culture};
    const url = `${environment.deckingReportProcessorServiceUrl}api/ReportProcessorService/SaveAndCreateSubstitutionReportContent/${designId}/${eTag}`;
    const response = await this.apiService.request<{htmlContent: string}>(new HttpRequest('POST', url, generationReportOptions));
    return response.body.htmlContent;
  }
}

export interface IReportResponse {
  base64Pdf: string;
}
