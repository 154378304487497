import { CalculatingIndicatorService } from './indicator/calculating-indicator.service';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { exhaustMap, filter, finalize, take, tap } from 'rxjs/operators';
import { DeckingSubstitutionService } from '../../decking-substitution.service';
import { DeckingSubstitutionSignalrService } from '../../../decking-design-signalr/decking-substitution-signalr.service';
import { DeckingSubstitution } from '../../../../entities/decking-substitution/decking-substitution';

/**
 * Abstract class to handle the calculation events. Class template for the calculation services
 */
@Injectable({
    providedIn: 'root'
})
export abstract class DeckingBaseSubstitutionCalculationsService<T> {
    saving$: Observable<boolean>;

    private saveSubstitutionSubscription: Subscription;

    constructor(
        protected deckingSubstitutionSignalRService: DeckingSubstitutionSignalrService,
        protected deckingSubstitutionService: DeckingSubstitutionService,
        private calculatingIndicatorService: CalculatingIndicatorService
    ) {
        this.saving$ = this.calculatingIndicatorService.calculating$;
    }

    init() {
        if (this.saveSubstitutionSubscription) {
            this.dispose();
        }
        const calculationTrigger$ = this.buildTriggerCalculationObservable();
        this.saveSubstitutionSubscription = calculationTrigger$.pipe(
            exhaustMap((calculationInput: T) => this.calculatedDesignObservable(calculationInput)),
        ).subscribe((design) => {
            this.updateCurrentSubstitution(design);
        });

    }

    dispose() {
        this.saveSubstitutionSubscription.unsubscribe();
    }

    /** Observable that triggers the calculation event */
    protected abstract buildTriggerCalculationObservable(): Observable<T>;

    /** Action to be called when the calculation is triggered */
    protected abstract callCalculation(calculationInput: T): void;

    protected abstract getSubstitutionId(calculationInput: T): string;

    private calculatedDesignObservable(calculationInput: T): Observable<DeckingSubstitution> {
        this.calculatingIndicatorService.setCalculating(true);
        this.callCalculation(calculationInput);

        return this.buildSavedDesignObservable(calculationInput);
    }

    /** Observable that triggers when the calculated design was saved. It dispatches the latest design and saves it */
    private buildSavedDesignObservable(calculationInput: T): Observable<DeckingSubstitution> {
        return this.deckingSubstitutionSignalRService.substitutionDesignCalculated$.pipe(
            filter((designSaved: DeckingSubstitution) => designSaved && this.getSubstitutionId(calculationInput) === designSaved.id),
            tap((substitution: DeckingSubstitution) => {
                substitution.saved = true;
            }),
            finalize(() => {
                this.calculatingIndicatorService.setCalculating(false);
            }),
            take(1));
    }

    private updateCurrentSubstitution(substitution: DeckingSubstitution) {
        substitution.saved = true;
        this.deckingSubstitutionService.setSubstitution(substitution);
    }
}
