<div class="modal-content base-modal">
    <div class="modal-header">
        <h3 class="modal-title" [l10n]="titleKey"></h3>
        <button type="button" class="close" (click)="close()">
            <span>&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="section">
            <pe-text-box
                id="create-template-name-wrapper"
                [id]="'create-template-name'"
                [title]="'Agito.Hilti.Profis3.SaveAsTemplate.Name' | l10n:localization.selectedLanguage"
                [validators]="[requiredValidator]"
                [disabled]="submitted"
                [maxLength]="nameMaxLength"
                [value]="name"
                (valueChange)="name = $any($event).detail"
                (isValid)="nameValid = $any($event).detail"
                (enterPressed)="save()">
            </pe-text-box>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.SaveAsTemplate.Cancel" (click)="close()">
        </button>

        <button type="button" class="modal-button button button-CTA" [disabled]="!formValid || submitted" (click)="save()">
            <pe-loading-button [loading]="submitted"
                [text]="'Agito.Hilti.Profis3.SaveAsTemplate.Save' | l10n:localization.selectedLanguage">
            </pe-loading-button>
        </button>
    </div>
</div>
