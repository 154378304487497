import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, combineLatest, distinctUntilChanged, map } from 'rxjs';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { DeckingSubstitutionSignalrService } from '../../../services/decking-design-signalr/decking-substitution-signalr.service';
import { DeckingSubstitutionSavedService } from '../../../services/decking-design/calculation/saving/decking-substitution-saved.service';
import { DeckingSpecificationCalculationService } from '../../../services/decking-design/calculation/specification/decking-specification-calculation.service';
import { DeckingSubstitutionCalculationService } from '../../../services/decking-design/calculation/substitution/decking-substitution-calculation.service';
import { IAddEditDesignComponentInput } from 'src/decking/entities/decking-design/add-edit-design';
import { DesignTypeId } from 'src/decking/entities/enums/design-types';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { CommonCodeListService } from 'src/decking/services/external/common-code-list.service';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { ModalService } from 'src/decking/services/external/modal.service';
import { DeckingDesign } from 'src/decking/entities/decking-design/decking-design';
import { DeckingSubstitution } from 'src/decking/entities/decking-substitution/decking-substitution';
import { SaveAsDeckingTemplateComponent } from '../../decking-popup/save-as-decking-template/save-as-decking-template.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeatureVisibilityService } from 'src/decking/services/external/feature-visibility.service';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { DeckingTrackingService } from 'src/decking/services/decking-tracking/decking-tracking.service';
import { DeckingUserSettingsService } from 'src/decking/services/decking-user-settings/user-settings.service';

@Component({
  selector: 'substitution-main-top',
  templateUrl: './substitution-main-top.component.html',
  styleUrls: []
})
export class SubstitutionMainTopComponent implements OnInit, OnDestroy{
  @Input()
  public processDesignClose!: () => Promise<void>;
  @Input()
  public processDesignBrowserUnload!: () => Promise<void>;
  
  public currentDocument: IDesignListItem;
  public deckingSubstitution$: Observable<DeckingSubstitution>;
  public deckingDesign$: Observable<DeckingDesign>;
  public isSubstitutionTemplate$: Observable<boolean>;
  public substitutionDesignTitle$: Observable<string>;
  public isDeckingNewHeaderEnabled: boolean;
  public regionId: number;
  public userLogout = false;

  designName$: Observable<string>;

  public currentDocument$: Subscription;
  constructor(
    private deckingSubstitutionService: DeckingSubstitutionService,
    private deckingSpecificationCalculationService: DeckingSpecificationCalculationService,
    private deckingSubstitutionCalculationService: DeckingSubstitutionCalculationService,
    private deckingSubstitutionSavedService: DeckingSubstitutionSavedService,
    private deckingSubstitutionSignalRService: DeckingSubstitutionSignalrService,
    private commonCodeList: CommonCodeListService,
    private modal: ModalService,
    private ngModal: NgbModal,
    private featureVisibilityService: FeatureVisibilityService,
    private deckingUserSettingsService: DeckingUserSettingsService,
    public localizationService: LocalizationService,
    private trackingService: DeckingTrackingService,
  ){}

  public get isNewHomePage() {
    return this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
  }

  ngOnInit(): void {
    // TUDU: initialization can be moved to decking-main component when modularized
    this.isDeckingNewHeaderEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_NewHeader');
    this.deckingSubstitutionSignalRService.init();
    this.deckingSpecificationCalculationService.init();
    this.deckingSubstitutionCalculationService.init();
    this.deckingSubstitutionSavedService.init();

    this.deckingSubstitution$ = this.deckingSubstitutionService.currentDeckingSubstitution$;
    this.isSubstitutionTemplate$ = this.deckingSubstitutionService.currentDeckingSubstitution$.pipe(map(design => design.isTemplate), distinctUntilChanged());
    this.currentDocument$ =  this.deckingSubstitutionService.currentDocument$.subscribe((data: IDesignListItem) => {
      this.currentDocument = data;
    });

    this.substitutionDesignTitle$ = combineLatest([this.isSubstitutionTemplate$, this.deckingSubstitution$]).pipe(map(([isTemplate, deckingSubstitution]) => {
        return isTemplate ? deckingSubstitution.name : this.currentDocument?.designName;
    }), distinctUntilChanged());
    this.regionId = this.getRegionByCountryCode(this.deckingUserSettingsService.deckingSettings.region.countryCode).id;
    this.onCloseEventTrackFromSubstitution = this.onCloseEventTrackFromSubstitution.bind(this);
    this.beforeSubstitutionLogout = this.beforeSubstitutionLogout.bind(this);
    this.openDiaphragmSubstitutionDesign = this.openDiaphragmSubstitutionDesign.bind(this);
    this.substitutionTopBarSaveAsTemplateClick = this.substitutionTopBarSaveAsTemplateClick.bind(this);
    this.openGeneralNotesFromSubstitution = this.openGeneralNotesFromSubstitution.bind(this);
    this.startTourFromSubstitution = this.startTourFromSubstitution.bind(this);

    this.TrackDataOnTabCloseFromSubstitution();
  }

  ngOnDestroy(): void {
    this.deckingSpecificationCalculationService.dispose();
    this.deckingSubstitutionCalculationService.dispose();
    this.deckingSubstitutionSavedService.dispose();
    this.deckingSubstitutionSignalRService.disconnect();
    this.currentDocument$?.unsubscribe();
    this.deckingSubstitutionService?.dispose();

    window.removeEventListener('beforeunload', this.onCloseEventTrackFromSubstitution, false);
  }

  public openDiaphragmSubstitutionDesign(): void {
    const data: IAddEditDesignComponentInput = {
        addEditType: AddEditType.edit,
        selectedModuleDesignInfo: null,
        designType: DesignTypeId.DiaphragmDesign,
        unitLength: Unit.ft
    };
    const region = (this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[])
                        .find((row) => row.id == this.currentDocument.metaData.region);

    data.design = {
        id: this.currentDocument.id,
        name: this.currentDocument.designName,
        projectId: this.currentDocument.projectId,
        projectName: this.currentDocument.projectName,
        region: region,
        designType: DesignTypeId.DiaphragmDesign,
        displayDesignType: DisplayDesignType.design,
        unitLength: null,
        unitLengthLarge: null,
        unitArea: null,
        unitStress: null,
        unitStressSmall: null,
        unitForce: null,
        unitMoment: null,
        unitTemperature: null,
        unitForcePerLength: null,
        unitMomentPerLength: null,
        unitDensity: null,
        unitAreaPerLength: null
    };
    this.modal.openAddEditDesignFromModule(data);
  }

  public substitutionTopBarSaveAsTemplateClick(): void {
    this.ngModal.open(SaveAsDeckingTemplateComponent, { size: 'md' });
  }

  public async beforeSubstitutionLogout() {
    this.userLogout = true; // we set this to true so we ignore beforeunload event
    await this.processDesignClose();
  }

  public openGeneralNotesFromSubstitution() {
      const copyTextLabel = this.localizationService.getString('Agito.Hilti.Profis3.GeneralNotes.CopyText');
      const textLabel = this.localizationService.getString('Agito.Hilti.Profis3.GeneralNotes.DisplayText');

      this.modal.openGeneralNotes(textLabel, copyTextLabel);
  }

  public menuOpenedFromSubstitution() {
      this.trackingService.menuOpened();
  }

  public hiltiDataPrivacyUrlOpenedFromSubstitution() {
      this.trackingService.hiltiDataPrivacyUrlOpened();
  }

  public regionLinkOpenedFromSubstitution() {
      this.trackingService.regionLinkOpened();
  }

  public get displayLDFlags() {
      return this.featureVisibilityService.isFeatureEnabled('LD_AutomatedPage');
  }

  public TrackDataOnTabCloseFromSubstitution() {
      window.addEventListener('beforeunload', this.onCloseEventTrackFromSubstitution, false);
  }

  public startTourFromSubstitution() {
      this.modal.openVirtualTourPopup();
  }

  private onCloseEventTrackFromSubstitution() {
      if (!this.userLogout) {
          this.processDesignBrowserUnload();
      }
  }

  private getRegionByCountryCode(countryCode: string) {
      const regionCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
      return regionCodeList.find(region => region.countryCode == countryCode);
  }
}
