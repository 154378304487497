import { Component, Input } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { Observable } from 'rxjs/internal/Observable';
import { ZoneAlternative } from '../../../../entities/decking-design/zone-alternative-model';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { DesignSettings } from '../../../../entities/settings/design-settings';
import { DeckingUnitsHelperService } from '../../../../services/decking-units-helper/decking-units-helper.service';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { DeckingCodeListService } from '../../../../services/decking-code-list/decking-code-list.service';

@Component({
    template: '',
})
export abstract class CompareZoneAlternativesCommonComponent {

    @Input()
    public modalInstance!: ModalInstance;

    isSubmitting = false;
    alternatives: ZoneAlternative[] = [];
    selectedAlternatives: Set<string> = new Set();
    readonly maxAllowedSelection: number = 3;
    currentZoneAlternatives$: Observable<ZoneAlternative[]>;

    // Units
    public currentSettings$: Observable<DesignSettings>;
    public isImperialUnit$: Observable<boolean>;
    public lengthUnit$: Observable<Unit>;
    public shearStiffnessUnit$: Observable<Unit>;
    public sidelapSpacingUnit$: Observable<Unit>;
    public isBySpacing$: Observable<boolean>;

    public DECK_GAUGE_UNIT = '';

    constructor(
        protected deckingUnitsHelperService: DeckingUnitsHelperService,
        protected deckingCodeListService: DeckingCodeListService
    ) {
        this.DECK_GAUGE_UNIT = this.deckingUnitsHelperService.DECK_GAUGE_UNIT;
    }

    public close() {
        this.modalInstance.close();
    }

    public selectAlternative(alternative: ZoneAlternative, checked: boolean): void {
        if (checked && this.selectedAlternatives.size < this.maxAllowedSelection) {
            alternative.selected = true;
            this.selectedAlternatives.add(alternative.id);
        } else {
            alternative.selected = false;
            this.selectedAlternatives.delete(alternative.id);
        }
    }

    public getFastenerName(fastenerId: number): string {
        return this.deckingCodeListService.getFastenerTranslatedName(fastenerId);
    }

    public getSidelapName(sidelapId: number): string {
        return this.deckingCodeListService.getSidelapTranslatedName(sidelapId);
    }

    protected initUnits(): void {
        this.initLengthUnit();
        this.sidelapSpacingUnit$ = combineLatest([this.lengthUnit$, this.isBySpacing$]).pipe(
            map(([lengthUnit, isBySpacing]) => isBySpacing ? lengthUnit : Unit.None)
        );
        this.shearStiffnessUnit$ = this.currentSettings$.pipe(map(s => s.shearStiffness.id), distinctUntilChanged());
    }

    public async loadAlternatives(zoneAlternatives?: ZoneAlternative[]): Promise<void> {
        if (zoneAlternatives && zoneAlternatives.length > 0) {
            this.alternatives = zoneAlternatives;
            this.alternatives.forEach((alternative) => {
                if (alternative.selected) {
                    this.selectedAlternatives.add(alternative.id);
                }
            });
            return;
        }
        await this.loadAlternativesFromBackend();
    }

    protected abstract loadAlternativesFromBackend(): Promise<void>;

    protected abstract initLengthUnit(): void;
}
