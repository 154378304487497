import { Component } from '@angular/core';
import { DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { CheckboxButtonItem } from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { RadioButtonProps } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { LocalizationService } from './../../../services/external/localization.service';
import { IDeckingSettingsCollection, IUserSettingDocument, IUserSettingRegion } from './../../../entities/settings/decking-user-settings';
import { DeckingUserSettingsService } from './../../../services/decking-user-settings/user-settings.service';

@Component({
  template: ''
})
export abstract class BaseDiaphragmDesignSettingsComponent {
  // regions dropdown
  regionsItems: DropdownItem<number>[];

  // length dropdown
  lengthItems: DropdownItem<number>[];

  // area dropdown
  areaItems: DropdownItem<number>[];

  // stress dropdown
  stressItems: DropdownItem<number>[];

  // force dropdown
  forceItems: DropdownItem<number>[];

  // moment dropdown
  momentItems: DropdownItem<number>[];

  // for per length dropdown
  forcePerLengthItems: DropdownItem<number>[];

  // shear stifness dropdown
  shearStiffnessItems: DropdownItem<number>[];

  // moment per length dropdown
  momentPerLengthItems: DropdownItem<number>[];

  // design methods dropdown
  designMethodItems: DropdownItem<number>[];

  // design standard dropdown
  designStandardItems: DropdownItem<number>[];

  // default loads dropdown
  relevantLoadItems: DropdownItem<number>[];

  // sidelap connector dropdown
  sidelapConnectorItems: DropdownItem<number>[];

  originalIncrementItems: DropdownItem<number>[];
  // sidelap connector spacing increment dropdown
  incrementItems: DropdownItem<number>[];

  // design zone input checkboxes
  zoneInputCheckboxesItems: CheckboxButtonItem<number>[];

  // substitution zone input checkboxes
  substitutionZoneInputCheckboxesItems: CheckboxButtonItem<number>[];

  isImperial: boolean;

  measureUnits: RadioButtonProps<boolean>;

  constructor(
    public localization: LocalizationService,
    protected userSettingsService: DeckingUserSettingsService) {
    const dropdownItems = this.buildDropdownItemsCollection(this.userSettingsService.deckingSettingsCollection);
    this.assignItemsToDropdowns(dropdownItems);
    this.initUnitMeasureItems();
  }

  /**
   * Return a collection of dropdown items based in the settings collection.
   * @param data - the data to be used to initialize the dropdown items
   * @returns - a dictionary of fields and dropdown items
   */
  private buildDropdownItemsCollection(data: IDeckingSettingsCollection): { [name: string]: DropdownItem<number>[] } {
    try {
      const settings: { [name: string]: DropdownItem<number>[] } = {};
      settings['region'] = this.MapRegionsItems(data.region);
      settings['length'] = this.MapDropDownItems(data.length);
      settings['area'] = this.MapDropDownItems(data.area);
      settings['stress'] = this.MapDropDownItems(data.stress);
      settings['force'] = this.MapDropDownItems(data.force);
      settings['moment'] = this.MapDropDownItems(data.moment);
      settings['forcePerLength'] = this.MapDropDownItems(data.forcePerLength);
      settings['momentPerLength'] = this.MapDropDownItems(data.momentPerLength);
      settings['designStandard'] = this.MapDropDownItems(data.designStandard);
      settings['designMethod'] = this.MapDropDownItems(data.designMethod);
      settings['relevantLoads'] = this.MapDropDownItems(data.relevantLoads);
      settings['definitionOfSidelapConnectors'] = this.MapDropDownItems(data.definitionOfSidelapConnectors);
      settings['sidelapConnectorSpacingIncrement'] = this.MapDropDownItems(data.sidelapConnectorSpacingIncrement);
      settings['shearStiffness'] = this.MapDropDownItems(data.shearStiffness);
      return settings;
    } catch (ex: any) {
      console.error('Error while building dropdown items', ex);
      throw ex;
    }
  }

  /**
   * Assign every dropdown items to be bind to every dropdown component.
   * @param data - the data to be used to build the dropdown items collection
   */
  private assignItemsToDropdowns(data: { [name: string]: DropdownItem<number>[] }): void {
    this.regionsItems = data['region'];
    this.lengthItems = data['length'];
    this.areaItems = data['area'];
    this.stressItems = data['stress'];
    this.forceItems = data['force'];
    this.momentItems = data['moment'];
    this.forcePerLengthItems = data['forcePerLength'];
    this.shearStiffnessItems = data['shearStiffness'];
    this.momentPerLengthItems = data['momentPerLength'];
    this.designMethodItems = data['designMethod'];
    this.designStandardItems = data['designStandard'];
    this.relevantLoadItems = data['relevantLoads'];
    this.sidelapConnectorItems = data['definitionOfSidelapConnectors'];
    this.originalIncrementItems = data['sidelapConnectorSpacingIncrement'];
    this.initZoneInputCheckboxesItems();
  }

  private MapRegionsItems(items: IUserSettingRegion[]): DropdownItem<number>[] {
    if (items) {
      return items.map(item => ({
        text: item.value,
        value: item.index
      }) as DropdownItem<number>);
    }
    return null;
  }

  private MapDropDownItems(items: IUserSettingDocument[]): DropdownItem<number>[] {
    if (items) {
      return items.map(item => ({
        text: item.value,
        value: item.index,
        level: item.value
      }) as DropdownItem<number>);
    }
    return null;
  }

  private initZoneInputCheckboxesItems(): void {
    this.zoneInputCheckboxesItems = [
      {
        text: this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.RequiredShearStiffness'),
        value: 1,
        id: 'deckDesignZone-stiffnessCheck'
      }, {
        text: this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.RequiredUpliftSubmittal'),
        value: 4,
        id: 'deckDesignZone-upliftCheck',
      }, {
        text: this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.WindSeismicLoadsAtZoneLevel'),
        value: 3,
        id: 'deckDesignZone-windCheck'
      }
    ];

    this.substitutionZoneInputCheckboxesItems = [
      {
        text: this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.RequiredShearStiffness'),
        value: 1,
        id: 'deckSubstitutionZone-stiffnessCheck'
      }, {
        text: this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.RequiredUpliftSubmittal'),
        value: 4,
        id: 'deckSubstitutionZone-upliftCheck',
      }, {
        text: this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.WindSeismicLoadsAtZoneLevel'),
        value: 3,
        id: 'deckSubstitutionZone-windCheck'
      }
    ];
  }

  private initUnitMeasureItems(): void {
    this.measureUnits = {
      items: [
        {
          id: '1',
          value: true,
          text: this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.Imperial'),
          description: 'Imperial Units'
        },
        {
          id: '2',
          value: false,
          text: this.localization.getString('Agito.Hilti.Profis3.Decking.ApplicationSettings.Metric'),
          description: 'Metric Units'
        }
      ]
    };
  }
}
